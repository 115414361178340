import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import {
  getAllTournamentEligibleSchools,
  getTournament,
  getTournamentEligibleSchools,
  getTournamentEligibleSchoolsCount
} from 'Src/helpers/service/adminSU/tournamentsTable';
import {
  addTournamentParticipants,
  removeTournamentParticipant
} from 'Src/helpers/service/adminSU/tournamentParticipantsTable';
import {
  getTournamentSchoolAreas,
  getIsParticipant,
  getSchoolAvailableAges,
  getSchoolAvailableAgesGroup,
  getSchoolAvailableGender,
  getTournamentInviteDate,
  getTournamentInviteStatus
} from 'Src/helpers/accessor/accessor';
import { Tournament } from 'Src/models/tournament';
import { EligibleSchool } from 'Src/models/school';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { sendTournamentInvite } from 'Src/helpers/service/adminSU/tournamentEligibleSchoolsTable';
import { getSelectOptionForTournamentParticipantsInviteStatus } from 'Src/helpers/twoPanelEditor/twoPanelEditorSelect';
import {
  getSelectOptionForAreaSU,
  getSelectOptionForAvailableAges,
  getSelectOptionForAvailableGender,
  getSelectOptionForBooleanQuestion
} from 'Src/helpers/table/select';
import { getAllSchoolUnionSchools } from '../../../../helpers/service/adminSU/schoolsTable';
import { KIND } from '../../../../consts/school';
import { ChampionshipPublicSiteSettingsForm } from '../Championships/ChampionshipPublicSiteSettingsForm/ChampionshipPublicSiteSettingsForm';
import { TournamentEligibleSchoolsCustomNotificationsView } from './TournamentEligibleSchoolsCustomNotificationsView/TournamentEligibleSchoolsCustomNotificationsView';
import { sendTournamentNotification } from '../../../../helpers/service/adminSU/tournamentNotificationTable';
import { TEAM_TYPE } from 'Src/consts/team';
import { addTournamentTeamsBatch } from 'Src/helpers/service/admin/tournamentTeams';
import { isIndividualTournament } from '../../../../helpers/tournament/tournament';
import { GENDER } from 'Src/consts/common';
import { AGE_GROUPS } from 'Src/consts/school';
import { TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/tournament';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Available ages',
    field: 'availableAges',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getSchoolAvailableAgesGroup
  },
  {
    text: 'Available gender',
    field: 'availableGender',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getSchoolAvailableGender
  },
  {
    text: 'Participant',
    field: 'participant',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getIsParticipant
  },
  {
    text: 'Invite status',
    field: 'inviteStatus',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getTournamentInviteStatus
  },
  {
    text: 'Date',
    field: 'statusSetAt',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getTournamentInviteDate
  },
  {
    text: 'Area',
    field: 'areaSU',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getTournamentSchoolAreas
  }
];

interface State {
  items: EligibleSchool[];
  currentPage: number;
  selectedItems: EligibleSchool[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  isSchoolsDeleteInfoModalShow: boolean;
  isSchoolsCustomNotificationModalShow: boolean;
  isError: boolean;
  tournament: Tournament;
  schoolUnions: any[];
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class TournamentEligibleSchools extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      isSchoolsDeleteInfoModalShow: false,
      isSchoolsCustomNotificationModalShow: false,
      isError: false,
      tournament: undefined,
      schoolUnions: []
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    return BPromise.all([
      getTournament(user, tournamentId),
      getAllSchoolUnionSchools(user, { kind: { $in: [KIND.SCHOOL_UNION] } })
    ]).then(([tournament, schoolUnions]) => {
      this.setState({
        tournament: tournament,
        schoolUnions: schoolUnions
      });

      return true;
    });
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;
    return tournamentId;
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const tournamentId = this.getTournamentId();

    const getItemsPromise = getTournamentEligibleSchools(user, tournamentId, serverQueryFilter);
    const getItemsCountPromise = getTournamentEligibleSchoolsCount(user, tournamentId, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;
    const { history } = this.props;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const tournamentId = this.getTournamentId();

    getAllTournamentEligibleSchools(user, tournamentId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;
    const { history } = this.props;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onClearFilterClick = (): void => {
    const { history } = this.props;
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}`,
      state: history.location.state
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  addTournamentEligibleSchools = (): void => {
    const { selectedItems } = this.state;
    const itemIds = selectedItems.map(selectedItem => selectedItem.id);

    const dataToTransfer = {
      participants: itemIds
    };

    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    this.setState({
      isLoading: true
    });

    addTournamentParticipants(user, tournamentId, dataToTransfer)
      .then(participants => {
        const schools = participants.map(participant => participant.school);

        return this.createDefaultTournamentTeams(schools);
      })
      .then(() => {
        this.setState({
          isLoading: false,
          selectedItems: [],
          isSelectAllChecked: false
        });
        this.setItems();
      });
  };

  createDefaultTournamentTeams = schools => {
    const { user } = this.props;
    const { tournament } = this.state;
    const { id: tournamentId, gender, ages, sportId } = tournament;

    const isIndividual = isIndividualTournament(tournament);

    let teamsData = [];

    if (isIndividual) {
      teamsData = schools.map(school => {
        const { id: schoolId, name: schoolName } = school;
        return {
          name: schoolName,
          ages,
          gender,
          sportId,
          schoolId,
          isAutoEnrolledTeam: true,
          teamType: TEAM_TYPE.ADHOC,
          players: []
        };
      });
    } else {
      const teamAge = Array.isArray(ages) ? ages[0] : ages;
      const teamAgeConverted = AGE_GROUPS['U5-U18'][teamAge];

      let baseName = '';
      if (gender === GENDER.MALE_ONLY || gender === GENDER.FEMALE_ONLY) {
        baseName = teamAgeConverted;
      } else {
        baseName = `${teamAgeConverted} ${TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING[gender]}`;
      }

      teamsData = schools.map(school => {
        return {
          name: baseName,
          ages,
          gender,
          sportId,
          schoolId: school.id,
          isAutoEnrolledTeam: true,
          teamType: TEAM_TYPE.ADHOC,
          players: []
        };
      });
    }

    return addTournamentTeamsBatch(user, tournamentId, teamsData);
  };

  isAddToTournamentActive(): boolean {
    const { selectedItems } = this.state;
    const isSomeParticipant = selectedItems.some(item => item.isParticipant === true);

    return !isSomeParticipant && selectedItems.length > 0;
  }

  isSendInvitesActive(): boolean {
    const { selectedItems } = this.state;
    const isEverySchoolsWithoutInvites = selectedItems.every(item => typeof item.invite === 'undefined');

    return isEverySchoolsWithoutInvites && selectedItems.length > 0;
  }

  isRemoveFromTournamentActive(): boolean {
    const { selectedItems } = this.state;
    const isEveryParticipant = selectedItems.every(item => item.isParticipant === true);

    return isEveryParticipant && selectedItems.length > 0;
  }

  isSchoolsDeleteModalClose = () => {
    this.setState({
      isSchoolsDeleteInfoModalShow: false
    });
  };

  isSchoolsDeleteModalOpen = (): void => {
    this.setState({
      isSchoolsDeleteInfoModalShow: true
    });
  };

  removeTournamentParticipants = () => {
    this.setState({
      isLoading: true
    });

    const { selectedItems } = this.state;
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    BPromise.each(selectedItems, (item, index) => {
      return removeTournamentParticipant(user, tournamentId, item.participantId);
    })
      .then(res => {
        this.setState({
          isSchoolsDeleteInfoModalShow: false,
          selectedItems: [],
          isSelectAllChecked: false
        });
        this.setItems();
      })
      .catch(error => {
        this.setState({
          isSchoolsDeleteInfoModalShow: false,
          selectedItems: [],
          isSelectAllChecked: false,
          isError: true
        });
        this.setItems();
      });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search, prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: prevPath,
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  onCloseErrorClick = () => {
    this.setState({
      isError: false
    });
  };

  renderSchoolsDeleteInfoModal(): React.ReactNode {
    const { selectedItems, isSchoolsDeleteInfoModalShow } = this.state;
    const infoText = `Are you sure you want to remove this ${
      selectedItems.length === 1 ? 'school' : 'schools'
    } from tournament?`;

    return (
      <SimpleModal
        isOpen={isSchoolsDeleteInfoModalShow}
        title={'Remove from tournament'}
        body={infoText}
        buttonText={'Remove'}
        onButtonClick={this.removeTournamentParticipants}
        onCloseClick={this.isSchoolsDeleteModalClose}
      />
    );
  }

  renderError(): React.ReactNode {
    const { isError } = this.state;
    const errorMessage =
      'The school you are trying to remove is taking part in some events within the tournament. ' +
      'Please, remove those events first to be able to exclude the school from the competition.';

    return (
      <SimpleModal
        isOpen={isError}
        title={'Remove from tournament'}
        body={errorMessage}
        onCloseClick={this.onCloseErrorClick}
        buttonCancelText={'Ok'}
      />
    );
  }

  sendInvites = () => {
    const { selectedItems: schools } = this.state;
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    this.setState({
      isLoading: true
    });

    BPromise.all(schools.map(school => sendTournamentInvite(user, tournamentId, { invitedSchoolId: school.id }))).then(
      res => {
        this.setState({
          selectedItems: [],
          isSelectAllChecked: false
        });
        this.setItems();
      }
    );
  };

  onCloseSchoolsCustomNotificationsClick = () => {
    this.setState({
      isSchoolsCustomNotificationModalShow: false
    });
  };

  isSchoolsCustomNotificationsModalOpen = () => {
    this.setState({
      isSchoolsCustomNotificationModalShow: true
    });
  };

  onSubmitSchoolsCustomNotifications = data => {
    const { user } = this.props;
    const { tournament } = this.state;
    this.setState({
      isLoading: true
    });

    sendTournamentNotification(user, data, tournament.id).then(res => {
      this.setState({
        isLoading: false,
        isSchoolsCustomNotificationModalShow: false
      });
    });
  };

  renderSchoolsCustomNotificationsModal() {
    const { isSchoolsCustomNotificationModalShow, selectedItems } = this.state;
    const { user } = this.props;
    return (
      <SimpleModal
        isOpen={isSchoolsCustomNotificationModalShow}
        title={'Custom notifications'}
        customClass={'modal-xl'}
      >
        <TournamentEligibleSchoolsCustomNotificationsView
          user={user}
          onSubmit={this.onSubmitSchoolsCustomNotifications}
          onCancel={this.onCloseSchoolsCustomNotificationsClick}
          schools={selectedItems}
        />
      </SimpleModal>
    );
  }

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      tournament,
      schoolUnions
    } = this.state;

    const { user } = this.props;

    const actionItems = [
      {
        itemText: 'Send invites',
        onItemClick: this.sendInvites,
        isActive: this.isSendInvitesActive()
      },
      {
        itemText: 'Add to tournament',
        onItemClick: this.addTournamentEligibleSchools,
        isActive: this.isAddToTournamentActive()
      },
      {
        itemText: 'Remove from tournament',
        onItemClick: this.isSchoolsDeleteModalOpen,
        isActive: this.isRemoveFromTournamentActive()
      },
      {
        itemText: 'Send notifications',
        onItemClick: this.isSchoolsCustomNotificationsModalOpen,
        isActive: selectedItems.length > 0
      }
    ];

    const filterOptions = {
      inviteStatus: getSelectOptionForTournamentParticipantsInviteStatus(),
      participant: getSelectOptionForBooleanQuestion(),
      availableAges: getSelectOptionForAvailableAges(),
      availableGender: getSelectOptionForAvailableGender(),
      areaSU: getSelectOptionForAreaSU(schoolUnions)
    };

    const gridTitle = typeof tournament !== 'undefined' ? `${tournament.name} / Eligible schools` : '';

    const dataItems = items.map(item => {
      const { id: schoolId } = item;

      const areaSU = schoolUnions.filter(item => {
        const { schoolIds } = item;
        return schoolIds.includes(schoolId);
      });

      return {
        ...item,
        areaSU
      };
    });

    return (
      <Grid2
        user={user}
        dataItems={dataItems}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
      />
    );
  }

  render() {
    const { isLoading, isSchoolsDeleteInfoModalShow, isError, isSchoolsCustomNotificationModalShow } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isSchoolsDeleteInfoModalShow || isError || isSchoolsCustomNotificationModalShow ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderError()}
        {this.renderSchoolsDeleteInfoModal()}
        {this.renderSchoolsCustomNotificationsModal()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}
