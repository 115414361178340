import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import {
  getArchiveTournamentConfirmationRequests,
  getArchiveTournamentConfirmationRequestsCount
} from 'Src/helpers/service/admin/messages';
import { transformUIFilters } from 'Src/helpers/message/message';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { TournamentConfirmationRequestSummary } from 'Src/components/MessageSummary/TournamentConfirmationRequestSummary';
import { MessageAdditionalSummary } from 'Src/components/MessageSummary/MessageAdditionalSummary';
import { MESSAGE_TABS } from 'Src/consts/message';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { ConfirmationRequestViewForm } from '../Messages/ConfirmationRequestForm/ConfirmationRequestViewForm';
import { Button } from 'Src/components/Button/Button';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Tournament name',
    field: 'tournamentName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: message => (message.tournamentData ? message.tournamentData.name : '')
  },
  {
    text: 'Tournament start date',
    field: 'tournamentStartDate',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: (message: any): JSX.Element => {
      let content;
      if (message.tournamentData && message.tournamentData.startTime) {
        const startDate = new Date(message.tournamentData.startTime);
        content = <span>{startDate.toLocaleDateString()}</span>;
      } else {
        content = <span />;
      }
      return (
        <td className="start-date-cell" style={{ width: '150px' }}>
          {content}
        </td>
      );
    }
  },
  {
    text: 'Status',
    field: 'messageStatus',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: (message: any): JSX.Element => {
      const status = message.invitationStatus || 'Pending';
      return <td style={{ width: '150px' }}>{status}</td>;
    }
  }
];

interface State {
  items: any[];
  selectedItems: any[];
  currentPage: number;
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  currentTabIndex: number;
  currentMessage: any | null;
  isConfirmationRequestModalOpen: boolean;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

function tournamentNameSearchFilter(filter: any): string {
  return 'filter=' + encodeURIComponent(JSON.stringify(filter));
}

export class TournamentConfirmationMessagesArchive extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      selectedItems: [],
      currentPage: FIRST_PAGE,
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      currentTabIndex: 0,
      currentMessage: null,
      isConfirmationRequestModalOpen: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({ isLoading: true });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;
    const search = parse(history.location.search);
    const page = search.page ? Number(search.page) : FIRST_PAGE;
    const uiFilters = getFilters2(COLUMNS, search);
    const serverFilters = transformUIFilters(uiFilters);
    const whereClause = serverFilters.where || serverFilters;
    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';
    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }
    const order = sortColumnsName && sortDirection ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, whereClause, order);
    const getItemsPromise = getArchiveTournamentConfirmationRequests(user, serverQueryFilter);
    const getItemsCountPromise = getArchiveTournamentConfirmationRequestsCount(user, whereClause);
    return BPromise.all([getItemsCountPromise, getItemsPromise]).then(([count, items]) => {
      this.setState({
        currentPage: page,
        sortDirection,
        sortColumnsName,
        isShowFilter: isFilterExist2(uiFilters),
        isDataFiltered: isFilterExist2(uiFilters),
        filters: uiFilters,
        items,
        total: count,
        basePath: history.location.pathname,
        isLoading: false,
        selectedItems: [],
        currentMessage: null
      });
      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];
    const { filters, sortColumnsName, sortDirection } = this.state;
    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }
    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }
    if (isFilterExist2(filters)) {
      search.push(tournamentNameSearchFilter(transformUIFilters(filters)));
    }
    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const clickedItem = items[index];
    let newSelected = [...selectedItems];
    const existingIndex = newSelected.findIndex(item => item.id === clickedItem.id);
    if (existingIndex !== -1) {
      newSelected.splice(existingIndex, 1);
    } else {
      newSelected.push(clickedItem);
    }
    this.setState({
      selectedItems: newSelected,
      currentMessage: newSelected.length === 1 ? newSelected[0] : null
    });
  };

  onRowViewClick = (message: any): void => {
    this.setState({
      selectedItems: [message],
      currentMessage: message,
      isConfirmationRequestModalOpen: true
    });
  };

  renderRowActions = (message: any): JSX.Element => {
    return (
      <div>
        <Button
          onClick={e => {
            e.stopPropagation();
            this.onRowViewClick(message);
          }}
          text="View"
          customClass="btn-view"
        />
      </div>
    );
  };

  onSelectAllClick = (): void => {
    this.setState({
      selectedItems: [...this.state.items],
      currentMessage: this.state.items.length === 1 ? this.state.items[0] : null,
      isSelectAllChecked: true
    });
  };

  onSelectAllOnPageClick = (): void => {
    this.onSelectAllClick();
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
      currentMessage: null
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;
    const order = getOrder(sortField, sortDirection, sortColumnsName);
    let search = [];
    search.push(`order=${sortField}:${order}`);
    if (isFilterExist2(filters)) {
      search.push(getSearchFilter(filters));
    }
    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];
    let nextFilters = { ...filters };
    if (filterField !== 'tournamentStartDate') {
      if (typeof options !== 'undefined') {
        switch (options) {
          case DATE_INTERVAL.FROM:
            nextFilters = {
              ...nextFilters,
              [filterField]: { ...currentFilterField, from: filterValue }
            };
            break;
          case DATE_INTERVAL.TO:
            nextFilters = {
              ...nextFilters,
              [filterField]: { ...currentFilterField, to: filterValue }
            };
            break;
        }
      } else {
        nextFilters = { ...nextFilters, [filterField]: filterValue };
      }
      this.setState({ filters: nextFilters });
    }
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let searchParams = [];
    if (isSortExist(sortDirection, sortColumnsName)) {
      searchParams.push(getSearchOrder(sortDirection, sortColumnsName));
    }
    if (isFilterExist2(filters)) {
      searchParams.push(getSearchFilter(filters));
    }
    this.setState({
      selectedItems: [],
      currentMessage: null,
      isSelectAllChecked: false
    });
    this.props.history.push({
      pathname: this.state.basePath,
      search: searchParams.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      filters: {},
      selectedItems: [],
      currentMessage: null,
      isSelectAllChecked: false
    });
    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();
    this.setState({ isShowFilter: !this.state.isShowFilter });
  };

  onTabClick = (event, index: number): void => {
    event.preventDefault();
    this.setState({ currentTabIndex: index });
  };

  getSummaryContent(): React.ReactNode {
    const { currentMessage, currentTabIndex } = this.state;
    if (!currentMessage) {
      return <div className="font-weight-bold">Select a message to view details</div>;
    }
    if (currentTabIndex === 0) {
      return <TournamentConfirmationRequestSummary message={currentMessage} />;
    } else if (currentTabIndex === 1) {
      return <MessageAdditionalSummary message={currentMessage} />;
    }
    return null;
  }

  renderMessageTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      filters,
      selectedItems,
      currentPage,
      total,
      isSelectAllChecked
    } = this.state;
    const columns: ColumnDefinition[] = [
      ...COLUMNS,
      {
        text: '',
        field: 'actions',
        isSort: false,
        type: FILTER_TYPE.NONE,
        align: 'center',
        cell: (message: any): JSX.Element => <td className="action-cell">{this.renderRowActions(message)}</td>
      }
    ];
    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isFilterExist2(filters)}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={columns}
        options={{}}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        actionItems={[]}
      />
    );
  }

  onCloseConfirmationRequestClick = (): void => {
    this.setState({
      isConfirmationRequestModalOpen: false
    });
  };

  renderConfirmationRequestModal = (): React.ReactNode => {
    const { isConfirmationRequestModalOpen, currentMessage } = this.state;
    return (
      <SimpleModal
        customClass=""
        isOpen={isConfirmationRequestModalOpen}
        onCloseClick={this.onCloseConfirmationRequestClick}
        showFooter={false}
      >
        {currentMessage && (
          <div>
            {currentMessage.isActionPerformedSetAt && (
              <div className="timestamp" style={{ marginBottom: '10px', fontStyle: 'italic' }}>
                Action Performed At: {new Date(currentMessage.isActionPerformedSetAt).toLocaleString()}
              </div>
            )}
            <ConfirmationRequestViewForm message={currentMessage} onCloseClick={this.onCloseConfirmationRequestClick} />
          </div>
        )}
      </SimpleModal>
    );
  };

  renderSummary(): React.ReactNode {
    const { currentTabIndex } = this.state;
    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={MESSAGE_TABS} currentTabIndex={currentTabIndex}>
        {this.getSummaryContent()}
      </GenericSummary>
    );
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <Loader />;
    }
    return (
      <div className="mt-3">
        {this.renderConfirmationRequestModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderMessageTable()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
