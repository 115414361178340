import * as React from 'react';
import { FunctionComponent } from 'react';
import { Panel } from './Panel';
import { Button } from '../Button/Button';
import { ColumnDefinition, SelectOption, TABLE_SORT_DIRECTION } from '../../helpers/table/table';
import { SelectOptions } from '../Grid/Grid2';
import './TwoPanelEditor.scss';

interface Props {
  items: any[];
  itemsCount: number;
  itemsTitle: string;
  itemsColumns: ColumnDefinition[];
  itemsSelected: any[];
  itemCurrentPage: number;

  onItemClick: (index: number) => void;

  itemsFilters: any;
  onItemsFilterChange: (event, filterField: string, options?) => void;

  isShowItemsFilter: boolean;
  isItemsFiltered: boolean;
  onItemsFilterClick: (event) => void;

  itemsSetCurrentPageParams: (currentPage: number) => void;

  itemsFiltersOptions?: SelectOptions;

  itemsAccumulator: any[];
  itemsAccumulatorCount: number;
  itemsAccumulatorTitle: string;
  itemsAccumulatorColumns: ColumnDefinition[];
  itemsAccumulatorSelected: any[];
  itemAccumulatorCurrentPage: number;

  onItemAccumulatorClick: (index: number) => void;

  itemsAccumulatorFilters: any;
  onItemsAccumulatorFilterChange: (event, filterField: string, options?) => void;

  isShowItemsAccumulatorFilter: boolean;
  isItemsAccumulatorFiltered: boolean;
  onItemsAccumulatorFilterClick: (event) => void;

  itemsAccumulatorFiltersOptions?: SelectOptions;

  onAddClick: () => void;
  onRemoveClick: () => void;

  itemsOnApplyFilterClick: () => void;
  itemsOnClearFilterClick: () => void;

  itemsAccumulatorOnApplyFilterClick: () => void;
  itemsAccumulatorOnClearFilterClick: () => void;

  onSelectAllItemsClick: () => void;
  onSelectAllItemsOnPageClick: () => void;
  onUnselectAllItemsClick: () => void;
  isSelectAllItemsChecked: boolean;

  onSelectAllItemsAccumulatorClick: () => void;
  onSelectAllItemsAccumulatorOnPageClick: () => void;
  onUnselectAllItemsAccumulatorClick: () => void;
  isSelectAllItemsAccumulatorChecked: boolean;

  sortItemsDirection: TABLE_SORT_DIRECTION;
  sortItemsColumnsName: string;
  onSortItemsClick: (sortField: string) => void;

  sortItemsAccumulatorDirection: TABLE_SORT_DIRECTION;
  sortItemsAccumulatorColumnsName: string;
  onSortItemsAccumulatorClick: (sortField: string) => void;

  itemsAddBuffer: any[];
  itemsRemoveBuffer: any[];

  onCellItemsClick?: (item) => void;
  onCellItemsAccumulatorClick?: (item) => void;
  removeItemFromBuffer?: (itemId: string) => void;
  onEditClick?: (item: any) => void;
}

export const TwoPanelEditor: FunctionComponent<Props> = props => {
  const {
    items,
    itemsTitle,
    itemsColumns,
    itemsAccumulator,
    itemsAccumulatorTitle,
    itemsAccumulatorColumns,
    itemsSelected,
    onItemClick,
    onItemAccumulatorClick,
    itemsAccumulatorSelected,
    onAddClick,
    onRemoveClick,
    onItemsFilterChange,
    itemsFilters,
    itemsFiltersOptions,
    isShowItemsFilter,
    isItemsFiltered,
    onItemsFilterClick,
    itemsAccumulatorFilters,
    isShowItemsAccumulatorFilter,
    isItemsAccumulatorFiltered,
    onItemsAccumulatorFilterClick,
    itemsCount,
    itemsAccumulatorCount,
    itemAccumulatorCurrentPage,
    itemCurrentPage,
    itemsSetCurrentPageParams,
    itemsOnApplyFilterClick,
    itemsOnClearFilterClick,
    itemsAccumulatorOnApplyFilterClick,
    itemsAccumulatorOnClearFilterClick,
    itemsAccumulatorFiltersOptions,
    onItemsAccumulatorFilterChange,
    onSelectAllItemsAccumulatorClick,
    onSelectAllItemsAccumulatorOnPageClick,
    onUnselectAllItemsAccumulatorClick,
    onSelectAllItemsClick,
    onSelectAllItemsOnPageClick,
    onUnselectAllItemsClick,
    isSelectAllItemsChecked,
    isSelectAllItemsAccumulatorChecked,
    sortItemsAccumulatorDirection,
    sortItemsAccumulatorColumnsName,
    onSortItemsAccumulatorClick,
    sortItemsDirection,
    sortItemsColumnsName,
    onSortItemsClick,
    itemsAddBuffer,
    itemsRemoveBuffer,
    onCellItemsClick,
    onCellItemsAccumulatorClick,
    removeItemFromBuffer,
    onEditClick
  } = props;

  const handleRemoveItemFromBuffer = (itemId: string) => {
    if (removeItemFromBuffer) {
      removeItemFromBuffer(itemId);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row buttons-row">
        <div className="col-md-6">
          <div
            onClick={onRemoveClick}
            className={`bMoveButton ${itemsAccumulatorSelected.length > 0 ? 'btn-primary' : 'btn-secondary'}`}
          >
            <img src="/dist/images/arrows_r.png" alt="<<" />
          </div>
        </div>
        <div className="col-md-6">
          <div
            onClick={onAddClick}
            className={`bMoveButton ${itemsSelected.length > 0 ? 'btn-primary' : 'btn-secondary'}`}
          >
            <img src="/dist/images/arrows_l.png" alt=">>" />
          </div>
        </div>
      </div>
      <div className="row">
        {/* Left Panel */}
        <Panel
          items={itemsAccumulator}
          title={itemsAccumulatorTitle}
          columns={itemsAccumulatorColumns}
          itemsSelected={itemsAccumulatorSelected}
          onItemClick={onItemAccumulatorClick}
          filters={itemsAccumulatorFilters}
          isShowFilter={isShowItemsAccumulatorFilter}
          isFiltered={isItemsAccumulatorFiltered}
          onFilterClick={onItemsAccumulatorFilterClick}
          onApplyFilterClick={itemsAccumulatorOnApplyFilterClick}
          onClearFilterClick={itemsAccumulatorOnClearFilterClick}
          filterOptions={itemsAccumulatorFiltersOptions}
          onFilterChange={onItemsAccumulatorFilterChange}
          total={itemsAccumulatorCount}
          currentPage={itemAccumulatorCurrentPage}
          setCurrentPageParams={() => {}}
          paginationLimit={itemsAccumulatorCount}
          onSelectAllClick={onSelectAllItemsAccumulatorClick}
          onSelectAllOnPageClick={onSelectAllItemsAccumulatorOnPageClick}
          onUnselectAllClick={onUnselectAllItemsAccumulatorClick}
          isSelectAllChecked={isSelectAllItemsAccumulatorChecked}
          sortDirection={sortItemsAccumulatorDirection}
          sortColumnsName={sortItemsAccumulatorColumnsName}
          onSortClick={onSortItemsAccumulatorClick}
          itemsAddBuffer={itemsAddBuffer}
          itemsRemoveBuffer={[]} // This remains empty as it's not used here
          onCellClick={onCellItemsAccumulatorClick}
          removeItemFromBuffer={removeItemFromBuffer} // Pass the function here
          isNoVisibleBlock={true}
          onEditClick={onEditClick} // <--- Pass to Left Panel if needed
        />
        {/* Right Panel */}
        <Panel
          items={items}
          title={itemsTitle}
          columns={itemsColumns}
          itemsSelected={itemsSelected}
          onItemClick={onItemClick}
          filters={itemsFilters}
          isShowFilter={isShowItemsFilter}
          isFiltered={isItemsFiltered}
          onFilterClick={onItemsFilterClick}
          onApplyFilterClick={itemsOnApplyFilterClick}
          onClearFilterClick={itemsOnClearFilterClick}
          filterOptions={itemsFiltersOptions}
          onFilterChange={onItemsFilterChange}
          total={itemsCount}
          currentPage={itemCurrentPage}
          setCurrentPageParams={itemsSetCurrentPageParams}
          onSelectAllClick={onSelectAllItemsClick}
          onSelectAllOnPageClick={onSelectAllItemsOnPageClick}
          onUnselectAllClick={onUnselectAllItemsClick}
          isSelectAllChecked={isSelectAllItemsChecked}
          sortDirection={sortItemsDirection}
          sortColumnsName={sortItemsColumnsName}
          onSortClick={onSortItemsClick}
          itemsAddBuffer={[]} // This remains empty as it's not used here
          itemsRemoveBuffer={itemsRemoveBuffer}
          onCellClick={onCellItemsClick}
          onEditClick={onEditClick} // <--- Pass to Left Panel if needed
        />
      </div>
      <div className="row">
        <div className="col-md-6">
          <div
            onClick={onRemoveClick}
            className={`bMoveButton ${itemsAccumulatorSelected.length > 0 ? 'btn-primary' : 'btn-secondary'}`}
          >
            <img src="/dist/images/arrows_r.png" alt="<<" />
          </div>
        </div>
        <div className="col-md-6">
          <div
            onClick={onAddClick}
            className={`bMoveButton ${itemsSelected.length > 0 ? 'btn-primary' : 'btn-secondary'}`}
          >
            <img src="/dist/images/arrows_l.png" alt=">>" />
          </div>
        </div>
      </div>
    </div>
  );
};

TwoPanelEditor.displayName = 'TwoPanelEditor';
