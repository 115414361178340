import { AppUser } from 'Src/views/App/App';
import axios from 'axios';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Count } from '../../../models/count';
import * as Moment from 'moment';
import { getTodayMidnight } from 'Src/helpers/common/common';

const DEFAULT_TOURNAMENT_PARTICIPANT_FILTER: any = {
  isParticipant: true
};
const DEFAULT_SCHOOL_TOURNAMENTS_ORDER = 'startTime ASC';

export function getSchoolTournaments(user: AppUser, filter = DEFAULT_TOURNAMENT_PARTICIPANT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENTS_ORDER
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUpcomingTournaments(user: AppUser, filter = DEFAULT_TOURNAMENT_PARTICIPANT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const todayMidnight = getTodayMidnight();

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          startTime: { $gte: todayMidnight },
          ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENTS_ORDER
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getSchoolPastTournaments(user: AppUser, filter = DEFAULT_TOURNAMENT_PARTICIPANT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const todayMidnight = getTodayMidnight();

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          startTime: { $lte: todayMidnight },
          ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENTS_ORDER
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolTournaments(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => {
    return response.data;
  });
}

export function getSchoolTournamentsCount(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          }
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/count`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUpcomingTournamentsCount(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const todayMidnight = getTodayMidnight();

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $gte: todayMidnight },
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $gte: todayMidnight },
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          }
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/count`, config).then(response => {
    return response.data;
  });
}

export function getSchoolPastTournamentsCount(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const todayMidnight = getTodayMidnight();

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $lte: todayMidnight },
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            startTime: { $lte: todayMidnight },
            ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER
          }
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/count`, config).then(response => {
    return response.data;
  });
}

export function getSchoolTournamentDates(user: AppUser, filter = DEFAULT_TOURNAMENT_PARTICIPANT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENTS_ORDER
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments/dates/distinct`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolTournamentsForWeek(user: AppUser, filter = DEFAULT_TOURNAMENT_PARTICIPANT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const now = new Date();
  const weekStart = new Date(now);
  const offset = (now.getDay() + 6) % 7;
  weekStart.setDate(now.getDate() - offset);
  weekStart.setHours(0, 0, 0, 0);

  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 7);

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          startTime: { $gte: weekStart, $lt: weekEnd },
          ...DEFAULT_TOURNAMENT_PARTICIPANT_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order || DEFAULT_SCHOOL_TOURNAMENTS_ORDER
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/tournaments`, config).then(response => response.data);
}
