import * as React from 'react';
import { useState, useEffect } from 'react';
import { Widget } from '../../../../components/Widget/Widget';
import axios from 'axios';

interface Tip {
  id: number;
  title: string;
  activeDate?: string;
  content: string;
}

export const TipOfTheDayWidget = ({ onRemove }: { onRemove: () => void }) => {
  const [tip, setTip] = useState<Tip | null>(null);

  useEffect(() => {
    async function fetchTips() {
      try {
        const response = await axios.get<Tip[]>('/dist/images/tips.json');
        const tips = response.data;

        const today = new Date().toISOString().split('T')[0];

        const todaysTips = tips.filter(t => t.activeDate === today);

        if (todaysTips.length > 0) {
          const randomIndex = Math.floor(Math.random() * todaysTips.length);
          setTip(todaysTips[randomIndex]);
        } else {
          const randomIndex = Math.floor(Math.random() * tips.length);
          setTip(tips[randomIndex]);
        }
      } catch (error) {
        console.error('Error fetching tips:', error);
      }
    }
    fetchTips();
  }, []);

  return (
    <Widget title="Tip of the Day" onRemove={onRemove}>
      <div className="tip-widget">
        {tip ? (
          <>
            <h4 className="tip-title">{tip.title}</h4>
            <div className="tip-content" dangerouslySetInnerHTML={{ __html: tip.content }} />
          </>
        ) : (
          <p>Loading tip...</p>
        )}
      </div>
    </Widget>
  );
};
