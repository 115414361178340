import * as React from 'react';
import { Component } from 'react';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { School } from '../../../../../models/school';
import { Request } from '../../../../../models/request';
import { Permission } from '../../../../../models/permission';
import { DATE_FORMAT } from '../../../../../consts/date';
import { DEFAULT_LIMIT } from '../../../../../consts/table';
import { PERMISSION_REQUEST_STATUS } from '../../../../../consts/permissionRequest';
import { USER_GENDER, GENDER_SERVER_TO_CLIENT_MAPPING, ROLE } from '../../../../../consts/user';
import {
  getPostcode,
  searchFunctionSchools,
  getName,
  searchFunctionSchoolFormsPublic,
  searchFunctionSchoolHousesPublic,
  searchFunctionSchoolSports,
  searchFunctionSchoolTournaments
} from 'Src/helpers/autocomplete/autocomplete';
import { getPermissions } from 'Src/helpers/service/admin/user';
import { getSelectOptionForRolesRequest } from '../../../../../helpers/table/select';
import { Loader } from '../../../../../components/Loader/Loader';
import { Autocomplete } from 'Src/components/Autocomplete/Autocomplete';
import './RequestForm.scss';
import { LabelWithQuestionIcon } from '../../../../../components/LabelWithQuestionIcon/LabelWithQuestionIcon';
import { getPostcodes } from '../../../../../helpers/venue/venue';

interface Props {
  user: AppUser;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  requests: Request[];
}

interface State {
  postcode: string;
  school: School;
  sportIds: string[];
  isLoading: boolean;
  permissions: Permission[];
}

export class RequestForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      postcode: undefined,
      school: undefined,
      sportIds: [],
      permissions: []
    };
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState({
      isLoading: true
    });

    const filter = {
      limit: DEFAULT_LIMIT,
      skip: 0
    };

    getPermissions(user, filter).then(permissions => {
      this.setState({
        permissions,
        isLoading: false
      });
    });
  }

  getSchools = (text: string) => {
    const { user } = this.props;
    const postcode = this.state.postcode;
    return searchFunctionSchools(user, text, postcode);
  };

  renderRolesOptions(): React.ReactNode {
    const { requests } = this.props;
    const { permissions, school } = this.state;
    const newStatusRequests = requests.filter(request => request.status === PERMISSION_REQUEST_STATUS.NEW);
    const isFirstRequest = permissions.length === 0 && newStatusRequests.length === 0 ? true : false;

    const options = getSelectOptionForRolesRequest(school, permissions, isFirstRequest);

    return (
      <>
        <option value="" key="emptyOption" disabled={true} className="text-secondary">
          Please select role
        </option>
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          );
        })}
      </>
    );
  }

  getSchoolForms = (text: string) => {
    const { school } = this.state;
    return searchFunctionSchoolFormsPublic(text, school.id);
  };

  getSchoolHouses = (text: string) => {
    const { school } = this.state;
    return searchFunctionSchoolHousesPublic(text, school.id);
  };

  getSchoolSports = (text: string) => {
    const { school, sportIds } = this.state;
    return searchFunctionSchoolSports(text, school.id, sportIds);
  };

  getSchoolTournaments = (text: string) => {
    const { school } = this.state;
    return searchFunctionSchoolTournaments(text, school.id);
  };

  render() {
    const { isLoading, school } = this.state;
    const { onCancel, onSubmit, user } = this.props;

    const isSchoolExist = typeof school !== 'undefined';

    if (isLoading) {
      return <Loader />;
    }

    const requestInitial = {
      postcode: undefined,
      comment: '',
      preset: '',
      school: undefined,
      schoolId: '',
      //parent fields
      studentFirstName: '',
      studentLastName: '',
      childDateOfBirth: undefined,
      childGender: USER_GENDER.MALE,
      childHouse: undefined,
      childHouseId: '',
      childForm: undefined,
      childFormId: '',
      //teacher and coach fields
      sportIds: [],
      sports: [],
      //tournament folllower fields
      tournament: undefined,
      tournamentId: ''
    };

    const requestSchema = Yup.object().shape({
      preset: Yup.string().required('Please select a role.'),
      schoolId: Yup.string().required('Please select a school.'),
      childDateOfBirth: Yup.date()
    });

    const isRoleCoachOrTeacher = (role: string) => {
      return role === ROLE.COACH || role === ROLE.TEACHER;
    };

    const isRoleParent = (role: string) => {
      return role === ROLE.PARENT;
    };

    const isRoleParentOrStudent = (role: string) => {
      return role === ROLE.PARENT || role === ROLE.STUDENT;
    };

    const isRoleTournamentFollower = (role: string) => {
      return role === ROLE.TOURNAMENT_FOLLOWER;
    };

    const isRoleGovernor = (role: string) => {
      return role === ROLE.GOVERNOR;
    };

    const isRoleAdminOrManager = (role: string) => {
      return role === ROLE.ADMIN || role === ROLE.MANAGER;
    };

    const isRoleStudent = (role: string) => {
      return role === ROLE.STUDENT;
    };

    const isEmpty = field => {
      return field === '' || typeof field === 'undefined';
    };

    return (
      <div>
        <Formik
          initialValues={requestInitial}
          validationSchema={requestSchema}
          onSubmit={values => {
            const dataCopy = { ...values };
            const { comment } = dataCopy;
            const isCommentExist = typeof comment !== 'undefined' && comment !== '';

            delete dataCopy.school;
            delete dataCopy.postcode;

            if (isRoleParent(values.preset)) {
              dataCopy.comment = `Request to be parent of [${dataCopy.studentFirstName} ${dataCopy.studentLastName}]`;
              delete dataCopy.childHouse;
              delete dataCopy.childForm;
              delete dataCopy.tournament;
              delete dataCopy.tournamentId;
              delete dataCopy.sports;
              delete dataCopy.sportIds;

              if (isEmpty(dataCopy.studentFirstName)) {
                delete dataCopy.studentFirstName;
              }

              if (isEmpty(dataCopy.studentLastName)) {
                delete dataCopy.studentLastName;
              }

              if (isEmpty(dataCopy.childDateOfBirth)) {
                delete dataCopy.childDateOfBirth;
              }

              if (isEmpty(dataCopy.childGender)) {
                delete dataCopy.childGender;
              }

              if (isEmpty(dataCopy.childHouseId)) {
                delete dataCopy.childHouseId;
              }

              if (isEmpty(dataCopy.childFormId)) {
                delete dataCopy.childFormId;
              }
            }

            if (isRoleCoachOrTeacher(values.preset)) {
              if (!isCommentExist) {
                delete dataCopy.comment;
              }
              delete dataCopy.studentFirstName;
              delete dataCopy.studentLastName;
              delete dataCopy.childDateOfBirth;
              delete dataCopy.childGender;
              delete dataCopy.childHouse;
              delete dataCopy.childHouseId;
              delete dataCopy.childForm;
              delete dataCopy.childFormId;
              delete dataCopy.tournament;
              delete dataCopy.tournamentId;
              delete dataCopy.sports;
            }

            if (isRoleTournamentFollower(values.preset)) {
              if (!isCommentExist) {
                delete dataCopy.comment;
              }
              delete dataCopy.studentFirstName;
              delete dataCopy.studentLastName;
              delete dataCopy.childDateOfBirth;
              delete dataCopy.childGender;
              delete dataCopy.childHouse;
              delete dataCopy.childHouseId;
              delete dataCopy.childForm;
              delete dataCopy.childFormId;
              delete dataCopy.sports;
              delete dataCopy.sportIds;
              delete dataCopy.tournament;
            }
            if (isRoleGovernor(values.preset) || isRoleAdminOrManager(values.preset) || isRoleStudent(values.preset)) {
              if (!isCommentExist) {
                delete dataCopy.comment;
              }
              delete dataCopy.studentFirstName;
              delete dataCopy.studentLastName;
              delete dataCopy.childDateOfBirth;
              delete dataCopy.childGender;
              delete dataCopy.childHouse;
              delete dataCopy.childHouseId;
              delete dataCopy.childForm;
              delete dataCopy.childFormId;
              delete dataCopy.sports;
              delete dataCopy.sportIds;
              delete dataCopy.tournament;
              delete dataCopy.tournamentId;
            }

            onSubmit(dataCopy);
          }}
          render={({ setFieldValue, values }) => (
            <Form>
              <div className="eRequestFormTitle">{'New Request'}</div>

              <LabelWithQuestionIcon
                labelText="Postcode"
                hintText={
                  'You may leave this field blank if you are unsure of your school postcode.' +
                  'This will limit the search radius when multiple schools share the same name.'
                }
              />
              <div className="eRequestFormRowSection">
                <Field
                  name="postcode"
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        searchFunction={text => getPostcodes(text, user)}
                        getElementTitle={getPostcode}
                        customClass="mFullWidth mb-3"
                        defaultItem={values.postcode}
                        onSelect={postcode => {
                          this.setState({ postcode });
                          setFieldValue('postcode', postcode);
                        }}
                        placeholder={'Please type your postcode'}
                      />
                    );
                  }}
                />
                <button
                  type="button"
                  className="btn btn-secondary bDeleteButton"
                  onClick={() => {
                    setFieldValue('postcode', undefined);
                    this.setState({ postcode: undefined });
                  }}
                >
                  X
                </button>
              </div>
              <LabelWithQuestionIcon
                labelText="School"
                hintText={
                  'Begin typing your school name into the box; it will appear in the dropdown list. ' +
                  'If you cannot find your school, please contact the Squad In Touch team.'
                }
              />
              <Field
                name="schoolId"
                render={({ field }) => {
                  return (
                    <Autocomplete
                      searchFunction={this.getSchools}
                      getElementTitle={getName}
                      customClass="mFullWidth mb-3"
                      defaultItem={values.school}
                      onSelect={school => {
                        this.setState({ school });
                        setFieldValue('school', school);
                        setFieldValue('schoolId', school.id);
                      }}
                      placeholder={'Please select school'}
                      isItemWithImage={true}
                    />
                  );
                }}
              />
              <ErrorMessage component="div" className="alert alert-danger" name="schoolId" />

              <LabelWithQuestionIcon
                labelText="Role"
                hintText={
                  'Select your role based on the permissions required for your school acccount:\n' +
                  'Admin / Manager - A primary staff member responsible for managing the account.\n' +
                  'Teacher / Coach - Responsible for running activities and fixtures; you do not need access to account settings.\n' +
                  'Parent - Your child attends the school.\n' +
                  'Student - You attend the school and have been invited to create an account.'
                }
              />
              {isSchoolExist ? (
                <Field
                  component="select"
                  name="preset"
                  className="form-control mb-3"
                  onChange={event => {
                    const preset = event.target.value;
                    setFieldValue('preset', preset);
                  }}
                >
                  {this.renderRolesOptions()}
                </Field>
              ) : (
                <Field
                  type="text"
                  name="preset"
                  disabled={true}
                  className="form-control eAutocompleteInput mb-3"
                  placeholder={'Please select school first'}
                />
              )}
              <ErrorMessage component="div" className="alert alert-danger" name="preset" />

              {isRoleParent(values.preset) && (
                <>
                  <LabelWithQuestionIcon
                    labelText="Student first name"
                    hintText={
                      'Input your childs first name, please note you are able to add multiple children ' +
                      'to your account, however you will need to request access for them individually.'
                    }
                  />
                  <Field type="text" name="studentFirstName" className="form-control mb-3" />

                  <LabelWithQuestionIcon labelText="Student last name" hintText={'Input your childs last name.'} />
                  <Field type="text" name="studentLastName" className="form-control mb-3" />

                  <LabelWithQuestionIcon
                    labelText="Gender"
                    hintText={"Your school has requested you input your child's gender."}
                  />
                  <Field component="select" name="childGender" className="form-control mb-3">
                    <option value={USER_GENDER.MALE}>{GENDER_SERVER_TO_CLIENT_MAPPING.MALE}</option>
                    <option value={USER_GENDER.FEMALE}>{GENDER_SERVER_TO_CLIENT_MAPPING.FEMALE}</option>
                  </Field>

                  <LabelWithQuestionIcon
                    labelText="Date of birth"
                    hintText={"Your school has requested you input your child's date of birth."}
                  />
                  <Field
                    name="childDateOfBirth"
                    render={({ field }) => {
                      //convert server field childDateOfBirth
                      let date;

                      if (Moment(values.childDateOfBirth, DATE_FORMAT).isValid()) {
                        date = Moment(values.childDateOfBirth, DATE_FORMAT).toDate();
                      }

                      return (
                        <div className="mb-3">
                          <DatePicker
                            selected={date}
                            onChange={childDateOfBirth => {
                              if (childDateOfBirth !== null) {
                                //empty date
                                setFieldValue('childDateOfBirth', childDateOfBirth);
                              } else {
                                setFieldValue('childDateOfBirth', '');
                              }
                            }}
                            className="form-control"
                            dateFormat={'dd-MM-yyyy'}
                            placeholderText="DD-MM-YYYY"
                            customInput={
                              <MaskedInput mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
                            }
                          />
                        </div>
                      );
                    }}
                  />

                  <LabelWithQuestionIcon
                    labelText="Form"
                    hintText={"Your school has requested you input your child's form group."}
                  />
                  <Field
                    name="childForm"
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          searchFunction={this.getSchoolForms}
                          getElementTitle={getName}
                          customClass="mFullWidth mb-3"
                          defaultItem={values.childForm}
                          onSelect={form => {
                            setFieldValue('childFormId', form.id);
                            setFieldValue('childForm', form);
                          }}
                        />
                      );
                    }}
                  />

                  <LabelWithQuestionIcon
                    labelText="House"
                    hintText={"Your school has requested you input your child's School House."}
                  />
                  <Field
                    name="childHouse"
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          searchFunction={this.getSchoolHouses}
                          getElementTitle={getName}
                          customClass="mFullWidth mb-3"
                          defaultItem={values.childHouse}
                          onSelect={house => {
                            setFieldValue('childHouseId', house.id);
                            setFieldValue('childHouse', house);
                          }}
                        />
                      );
                    }}
                  />
                </>
              )}

              {isRoleCoachOrTeacher(values.preset) && (
                <>
                  <LabelWithQuestionIcon
                    labelText="Activities / Sports"
                    hintText={
                      'Select your chosen sports or activities that you will be involved in, more can be added later if needed.'
                    }
                  />

                  {values.sports.map((sport, index) => (
                    <div className="eRequestFormRowSection">
                      <Field>
                        {() => <input type="text" className="form-control mb-3" value={sport.name} disabled={true} />}
                      </Field>
                      <button
                        className="btn btn-secondary bDeleteButton"
                        type="button"
                        onClick={() => {
                          const sportsUpdated = [...values.sports];
                          const sportIdsUpdated = [...values.sportIds];
                          sportsUpdated.splice(index, 1);
                          sportIdsUpdated.splice(index, 1);
                          setFieldValue('sports', sportsUpdated);
                          setFieldValue('sportIds', sportIdsUpdated);
                          this.setState({
                            sportIds: sportIdsUpdated
                          });
                        }}
                      >
                        X
                      </button>
                    </div>
                  ))}

                  <Field
                    name="sports"
                    render={({ field }) => {
                      return (
                        <Autocomplete
                          searchFunction={this.getSchoolSports}
                          getElementTitle={getName}
                          customClass="mFullWidth mb-3"
                          defaultItem={values.sports}
                          onSelect={sport => {
                            const sportsUpdated = [...values.sports, sport];
                            const sportIdsUpdated = [...values.sportIds, sport.id];
                            setFieldValue('sportIds', sportIdsUpdated);
                            setFieldValue('sports', sportsUpdated);
                            this.setState({
                              sportIds: sportIdsUpdated
                            });
                          }}
                          placeholder={'Please select activities/sports'}
                        />
                      );
                    }}
                  />
                </>
              )}

              {isRoleTournamentFollower(values.preset) && (
                <Field
                  name="tournament"
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        searchFunction={this.getSchoolTournaments}
                        getElementTitle={getName}
                        customClass="mFullWidth mb-3"
                        defaultItem={values.tournament}
                        onSelect={tournament => {
                          setFieldValue('tournamentId', tournament.id);
                          setFieldValue('tournament', tournament);
                        }}
                        placeholder={'Please select tournament'}
                      />
                    );
                  }}
                />
              )}

              {isRoleParentOrStudent(values.preset) && values.school.isOneOffCodeOnRegisterEnabled && (
                <div className="form-group">
                  <LabelWithQuestionIcon
                    labelText="If the school provided you with a unique code please enter it here"
                    hintText={'Please input the one-off code as instructed by your school.'}
                  />
                  <Field name="oneOffCode" className={'form-control mb-3'} />
                </div>
              )}

              <LabelWithQuestionIcon
                labelText="Comment"
                hintText={'If you have any comments you would like to advise the school of please input them here.'}
              />
              <div>
                <Field component="textarea" name="comment" className="form-control mb-3" />
              </div>

              <button className="btn btn-secondary mb-3 mr-3" onClick={onCancel}>
                Cancel
              </button>
              <button type="submit" className="btn btn-primary mb-3 mr-3">
                Submit
              </button>
            </Form>
          )}
        />
      </div>
    );
  }
}
