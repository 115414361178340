import {
  GENDER_SERVER_TO_CLIENT_MAPPING,
  ROLE_SERVER_TO_CLIENT_MAPPING,
  USER_STATUS,
  USER_STATUSES_TO_CLIENT_MAPPING
} from '../../consts/user';
import { SchoolUser } from '../../models/schoolUser';
import * as Lazy from 'lazy.js';
import * as Moment from 'moment';
import * as propz from 'propz';
import { SchoolStudent } from '../../models/schoolStudent';
import {
  GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING,
  GENERAL_MESSAGE_STATUS,
  GENERAL_MESSAGE_STATUS_SERVER_TO_CLIENT_MAPPING
} from '../../consts/generalMessage';
import { News } from '../../models/news';
import { Notification } from '../../models/notification';
import { GeneralMessage } from '../../models/generalMessage';
import { Request } from '../../models/request';
import {
  CLUB_ACADEMIC_YEARS_MAX,
  CLUB_ACADEMIC_YEARS_MIN,
  CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING,
  CLUB_SCHEDULE,
  CLUB_SCHEDULE_SERVER_TO_CLIENT_MAPPING,
  CLUB_STAFF,
  CLUB_STATUS_SERVER_TO_CLIENT_MAPPING
} from '../../consts/club';
import { Club, ClubStaff } from '../../models/club';
import { Subscription } from '../../models/subscription';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  WEEK_DAYS_SERVER_TO_CLIENT_MAPPING,
  WEEK_DAYS_SHORT_SERVER_TO_CLIENT_MAPPING
} from '../../consts/date';
import { ClubMessage } from '../../models/clubMessage';
import { addZeroToFirst } from '../club/club';
import { PAYMENT_ACCOUNT_STATE, PAYMENT_ACCOUNT_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../consts/paymentAccount';
import { PaymentAccount } from '../../models/paymentAccount';
import {
  CONFIRMATION_REQUESTS_STATUS_SERVER_TO_CLIENT_MAPPING,
  MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING,
  MESSAGE_KIND,
  MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING
} from '../../consts/message';
import { MessageDefinition } from '../../types/message';
import { TRANSACTION_CURRENCY_SYMBOL, TRANSACTION_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../consts/transaction';
import {
  INVITE_OUTBOX_STATUS_SERVER_TO_CLIENT_MAPPING,
  INVITE_STATUS,
  INVITE_STATUS_AUTO_ACCEPTED_MAPPING,
  INVITE_STATUS_NOT_SENT_MAPPING,
  INVITE_STATUS_SERVER_TO_CLIENT_MAPPING
} from '../../consts/invite';
import {
  AGE_GROUPS,
  AGE_GROUPS_SORTED,
  AVAILABLE_AGES_MAX,
  AVAILABLE_AGES_MIN,
  SCHOOL_GENDER,
  SCHOOL_GENDER_SERVER_TO_CLIENT_MAPPING
} from '../../consts/school';
import { Record } from '../../models/record';
import { POINTS_DISPLAY } from '../../consts/sport';
import {
  DEFAULT_DISTANCE_MASK,
  DEFAULT_TIME_MASK,
  plainPointsToDistanceString,
  plainPointsToTimeString
} from '../score/score2';
import { convertPoints } from '../points/points';
import { Tournament, TournamentCustomGroup } from '../../models/tournament';
import { getStageByGroupId } from '../tournament/tournament';
import { TOURNAMENT_LOG_ACTION_TYPES_SERVER_TO_CLIENT_MAPPING } from '../../consts/log';
import {
  EVENT_STATUS_SERVER_TO_CLIENT_MAPPING,
  EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING,
  EVENT_TYPES_SERVER_TO_CLIENT_MAPPING
} from '../../consts/event';
import { AppUser } from 'Src/views/App/App';
import { getLocation, getTournamentLocation, isSchoolEventInvite, isSchoolTournamentInvite } from '../invite/invite';
import { Championship } from '../../models/championship';
import { GENDER, SPORT_GENDER_SERVER_TO_CLIENT_MAPPING } from '../../consts/common';
import { Integration } from '../../models/Integration';
import { INTEGRATION_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../consts/integration';
import { PERMISSION_REQUEST_STATUS_SERVER_TO_CLIENT_MAPPING } from '../../consts/permissionRequest';
import { VENUE_SERVER_TO_CLIENT_MAPPING } from '../../consts/venue';
import { isClubEvent, isTournamentEvent } from '../event/event';
import { LeagueNews, SchoolLeague } from '../../models/schoolLeague';
import { LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../consts/league';
import { SchoolEvent } from '../../models/event';
import { EventSchoolInvite, SchoolInvite } from '../../models/invite';
import { Sport } from '../../models/sport';
import { SchoolFile } from '../../models/schoolFile';
import { FILES_CATEGORY_SERVER_TO_CLIENT_MAPPING } from '../../consts/file';
import { SelectOption } from '../table/table';
import { School } from 'Src/models/school';
import { Transaction } from 'Src/models/transaction';
import { sortAges } from 'Src/helpers/event/event';
import {
  SUBSTAGES_OF_TOURNAMENT_SERVER_TO_CLIENT_MAPPING,
  TOURNAMENT_PRIZES_SERVER_TO_CLIENT_MAPPING
} from '../../consts/tournament';
import { YES_NO_OPTIONS } from 'Src/consts/common';

export function getUserRole(user: SchoolUser): string {
  return Lazy(user.permissions)
    .map(permission => permission.preset)
    .map(preset => ROLE_SERVER_TO_CLIENT_MAPPING[preset])
    .uniq()
    .join('\n');
}

export function getUserStatus(user: SchoolUser): string {
  return USER_STATUSES_TO_CLIENT_MAPPING[user.status];
}

export function getUserTags(user: SchoolUser): string {
  return Lazy(user.permissions)
    .map(permission => {
      return permission.tags.map(tag => tag.tagName);
    })
    .flatten()
    .toArray()
    .join('\n');
}

export function getUserForms(user: any): string {
  return Lazy(user.permissions)
    .map(permission => {
      return typeof permission.forms === 'undefined' ? [] : permission.forms.map(form => form.name);
    })
    .flatten()
    .uniq()
    .toArray()
    .join('\n');
}

export function getUserHouses(user: any): string {
  return Lazy(user.permissions)
    .map(permission => {
      return typeof permission.houses === 'undefined' ? [] : permission.houses.map(house => house.name);
    })
    .flatten()
    .uniq()
    .toArray()
    .join('\n');
}

export function getParticipatingStudentHouses(student: any): string {
  const houseName = student.user.houseName;
  return houseName ? houseName : '';
}

export function getStudentHouses(student: any): string {
  const houseName = propz.get(student, ['house', 'name'], '');
  return houseName;
}

export function getUserSchools(user: any): string {
  return Lazy(user.permissions)
    .map(permission => {
      return permission.school.name;
    })
    .flatten()
    .uniq()
    .toArray()
    .join('\n');
}

export function getGender(item: SchoolStudent | SchoolUser | Record): string {
  const gender = item.gender;
  return gender ? GENDER_SERVER_TO_CLIENT_MAPPING[gender] : '';
}

export function getAllowedGender(item: SchoolEvent | SchoolStudent | SchoolUser | Record | Championship): string {
  const gender = item.gender;
  return gender ? SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[gender] : '';
}

export function getAgeGroup(item: Record | Championship | EventSchoolInvite): string {
  const ages = [...item.ages] || [];

  return ages
    .sort((age1, age2) => age1 - age2)
    .map(age => propz.get(AGE_GROUPS, ['ENGLISH', age]))
    .join(', ');
}

export function getAge(item): string {
  const age = propz.get(item, ['form', 'age']);

  return propz.get(AGE_GROUPS, ['ENGLISH', age], '');
}

export function getYear(item: Record): number | string {
  const date = item.date,
    dateObj = new Date(date);

  return typeof date !== 'undefined' ? dateObj.getFullYear() : '';
}

export function getRecord(item: Record): number {
  const sportPointsDisplay = propz.get(item, ['sport', 'points', 'display'], POINTS_DISPLAY.PLAIN);
  const inputMask = propz.get(item, ['sport', 'points', 'inputMask']);
  const record = propz.get(item, ['record'], 0);

  let result, mask;

  // points type
  switch (sportPointsDisplay) {
    case POINTS_DISPLAY.PLAIN:
      result = convertPoints(record, sportPointsDisplay).str;
      break;
    case POINTS_DISPLAY.TIME:
      mask = inputMask ? inputMask : DEFAULT_TIME_MASK;
      result = plainPointsToTimeString(record, mask);
      break;
    case POINTS_DISPLAY.DISTANCE:
      mask = inputMask ? inputMask : DEFAULT_DISTANCE_MASK;
      result = plainPointsToDistanceString(record, mask);
      break;
    default:
      console.error(`Can not find view for sport points display: ${sportPointsDisplay}`);
      break;
  }
  return result;
}

export function getParticipatingStudentsBirthday(student): string {
  const birthday = student.user.birthday;
  const birthdayDate = new Date(birthday);
  const birthdayFormatted = typeof birthday !== 'undefined' ? Moment(birthdayDate).format(DATE_FORMAT) : '';
  return birthdayFormatted;
}

export function getBirthday(item: SchoolStudent | SchoolUser): string {
  const birthday = item.birthday;
  const birthdayDate = new Date(birthday);
  const birthdayFormatted = typeof birthday !== 'undefined' ? Moment(birthdayDate).format(DATE_FORMAT) : '';
  return birthdayFormatted;
}

export function getStudentTags(student: SchoolStudent): string {
  const tags = student.tags;
  const tagNames = typeof tags !== 'undefined' ? tags.map(tag => tag.tagName).join('\n') : '';
  return tagNames;
}

export function getStudentSpecialEducationNeeds(student: SchoolStudent): string {
  const specialEducationNeeds = propz.get(student, ['specialEducationNeeds'], false);

  return specialEducationNeeds ? 'Yes' : 'No';
}

export function getStudentPupilsPremium(student: SchoolStudent): string {
  const pupilsPremium = propz.get(student, ['pupilsPremium'], false);

  return pupilsPremium ? 'Yes' : 'No';
}

export function getStudentFreeSchoolMeals(student: SchoolStudent): string {
  const freeSchoolMeals = propz.get(student, ['freeSchoolMeals'], false);

  return freeSchoolMeals ? 'Yes' : 'No';
}

export function getStudentPhotoConsentOptOut(student: SchoolStudent): string {
  const photoConsentOptOut = propz.get(student, ['photoConsentOptOut'], false);

  return photoConsentOptOut ? 'Yes' : 'No';
}

export function getStudentsParents(student: any): string {
  const parents = student.parents || [];

  return parents.map(parent => `${parent.firstName} ${parent.lastName}`).join('\n');
}

export function getCreatedAt(item: GeneralMessage | Request | Notification | MessageDefinition | Transaction): string {
  const createdAt = item.createdAt;

  if (typeof createdAt !== 'undefined') {
    const createdAtDate = new Date(createdAt);
    const createdAtFormatted = Moment(createdAtDate).format(DATE_FORMAT);
    return createdAtFormatted;
  } else {
    return '';
  }
}

export function getCreatedAtWithTime(
  item: GeneralMessage | Request | Notification | MessageDefinition | Transaction | any
): string {
  const createdAt = item.createdAt;

  if (typeof createdAt !== 'undefined') {
    const createdAtDate = new Date(createdAt);
    const createdAtFormatted = Moment(createdAtDate).format(DATE_TIME_FORMAT);
    return createdAtFormatted;
  } else {
    return '';
  }
}

export function getUpdatedAtWithTime(item: GeneralMessage | Request | Notification | MessageDefinition | any): string {
  const updatedAt = item.updatedAt;

  if (typeof updatedAt !== 'undefined') {
    const updatedAtDate = new Date(updatedAt);
    const updatedAtFormatted = Moment(updatedAtDate).format(DATE_TIME_FORMAT);
    return updatedAtFormatted;
  } else {
    return '';
  }
}

export function getRequestSports(request: Request): string {
  const sports = request.sports;
  const sportNames = sports.map(sport => sport.name).join('\n');
  return sportNames;
}

export function getRequestRole(request: Request): string {
  const role = propz.get(request, ['requestedPermission', 'preset']);
  return ROLE_SERVER_TO_CLIENT_MAPPING[role];
}

export function getRequestSchoolName(request: Request): string {
  const school = propz.get(request, ['requestedPermission', 'school', 'name']);
  return school;
}

export function getRequestComment(request: Request): string {
  const comment = propz.get(request, ['requestedPermission', 'comment'], '');
  return comment;
}

export function getGeneralMessageCreatedBy(generalMessage: GeneralMessage): string {
  const createdBy = generalMessage.createdBy;
  const { firstName, lastName } = createdBy;
  return `${firstName} ${lastName}`;
}

export function getGeneralMessageSender(generalMessage: GeneralMessage): string {
  const status = generalMessage.status;
  const senderData = generalMessage.senderData;
  const firstName = propz.get(senderData, ['firstName'], '');
  const lastName = propz.get(senderData, ['lastName'], '');

  if (status === GENERAL_MESSAGE_STATUS.SENT && typeof senderData !== 'undefined') {
    return `${firstName} ${lastName}`;
  } else {
    return '';
  }
}

export function getGeneralMessageSentAt(generalMessage: GeneralMessage): string {
  let sentAtStr;
  const status = generalMessage.status;
  const sentAt = generalMessage.sentAt;

  switch (true) {
    case status === GENERAL_MESSAGE_STATUS.SENT:
      if (typeof sentAt !== 'undefined') {
        sentAtStr = Moment(sentAt).format(DATE_TIME_FORMAT);
      } else {
        sentAtStr = '';
      }
      break;
    default:
      sentAtStr = 'Not sent yet';
  }

  return sentAtStr;
}

export function getNotificationPermissions(notification: Notification): string {
  const permissionPresets = notification.permissionPresets;
  if (typeof permissionPresets !== 'undefined') {
    return permissionPresets.map(preset => ROLE_SERVER_TO_CLIENT_MAPPING[preset]).join('\n');
  } else {
    return '';
  }
}

export function getSchoolNameNotification(notification: Notification): string {
  const schoolName = notification.schoolName;

  return typeof schoolName !== 'undefined' && schoolName !== null ? schoolName : '';
}

export function getSmsDeliveryStatus(notification: Notification): string {
  const smsChannel = notification.channelStatus.find(channel => channel.channelType === 'DEFAULT_SMS');
  return typeof smsChannel !== 'undefined'
    ? GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[smsChannel.deliveryStatus]
    : '';
}

export function getEmailDeliveryStatus(notification: Notification) {
  const emailChannel = notification.channelStatus.find(channel => channel.channelType === 'DEFAULT_EMAIL');
  return typeof emailChannel !== 'undefined'
    ? GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[emailChannel.deliveryStatus]
    : '';
}

export function getIosDeliveryStatus(notification: Notification): string {
  const iosChannel = notification.channelStatus.find(channel => channel.channelType === 'IOS');
  return typeof iosChannel !== 'undefined'
    ? GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[iosChannel.deliveryStatus]
    : '';
}

export function getAndroidDeliveryStatus(notification: Notification): string {
  const androidChannel = notification.channelStatus.find(channel => channel.channelType === 'ANDROID');
  return typeof androidChannel !== 'undefined'
    ? GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[androidChannel.deliveryStatus]
    : '';
}

export function getNewsDate(news: News): string {
  const date = news.date;
  return typeof date !== 'undefined' ? Moment(date).format(DATE_TIME_FORMAT) : '';
}

export function getDeliveryStatus(notification: Notification): string {
  const deliveryStatus = notification.deliveryStatus;
  return typeof deliveryStatus !== 'undefined'
    ? GENERAL_MESSAGE_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[deliveryStatus]
    : '';
}

export function getGeneralMessageStatus(generalMessage: GeneralMessage): string {
  const status = generalMessage.status;

  return typeof status !== 'undefined' ? GENERAL_MESSAGE_STATUS_SERVER_TO_CLIENT_MAPPING[status] : '';
}

export function getClubStatus(club: Club): string {
  const status = club.status;

  return typeof status !== 'undefined' ? CLUB_STATUS_SERVER_TO_CLIENT_MAPPING[status] : '';
}

export function getPaymentAccountStatus(paymentAccount: PaymentAccount): string {
  const status = paymentAccount.status;

  return typeof status !== 'undefined' ? PAYMENT_ACCOUNT_STATUS_SERVER_TO_CLIENT_MAPPING[status] : '';
}

export function getPaymentAccountState(paymentAccount: PaymentAccount): string {
  switch (true) {
    case typeof paymentAccount.isDefault === 'undefined':
      return '';
    case paymentAccount.isDefault === true:
      return PAYMENT_ACCOUNT_STATE.DEFAULT;
    case paymentAccount.isDefault === false:
      return PAYMENT_ACCOUNT_STATE.CUSTOM;
  }
}

export function getTransactionStatus(transaction: Transaction): string {
  const status = transaction.status;
  return typeof status !== 'undefined' ? TRANSACTION_STATUS_SERVER_TO_CLIENT_MAPPING[status] : '';
}

export function getTransactionClubStatus(transaction: Transaction): string {
  const status = propz.get(transaction, ['clubData', 'status']);
  return typeof status !== 'undefined' ? CLUB_STATUS_SERVER_TO_CLIENT_MAPPING[status] : '';
}

export function getTransactionAmount(transaction: Transaction): string {
  const price = transaction.price;
  return typeof price !== 'undefined' ? `${TRANSACTION_CURRENCY_SYMBOL.POUND}${price / 100}` : '';
}

export function getTransactionNameTournamentOrClub(transaction: Transaction): string {
  const { tournamentData, clubData } = transaction;

  switch (true) {
    case typeof tournamentData !== 'undefined':
      return tournamentData.name;
    case typeof clubData !== 'undefined':
      return clubData.name;
    default:
      return '';
  }
}

export function getSubscriptionPurchaseDate(subscription: Subscription): string {
  const date = subscription.purchaseDate;
  return typeof date !== 'undefined' ? Moment(date).format(DATE_FORMAT) : '';
}

export function getSubscriptionExpireDate(subscription: Subscription): string {
  const date = subscription.expireDate;
  return typeof date !== 'undefined' ? Moment(date).format(DATE_FORMAT) : '';
}

export function getSubscriptionUsedSmsSegmentsCount(subscription: Subscription): number {
  const statistic = subscription.usageStatistic;
  return typeof statistic !== 'undefined' ? statistic.smsSegmentsCount : 0;
}

export function getSubscriptionBalance(subscription: Subscription): number {
  const smsSegmentsCount = getSubscriptionUsedSmsSegmentsCount(subscription);
  const packageVolume = subscription.packageVolume;

  return packageVolume - smsSegmentsCount;
}

export function getClubMessageInvitationStatus(student: any): string {
  const messageStatus = student.messageStatus;

  return typeof messageStatus !== 'undefined' ? CLUB_MESSAGE_INVITE_STATUS_SERVER_TO_CLIENT_MAPPING[messageStatus] : '';
}

export function getClubStudentParents(student: any): string {
  const parents = student.parents || [];

  return parents.map(parent => `${parent.firstName} ${parent.lastName}`).join('\n');
}

export function getIsParticipant(student: any): string {
  const isParticipant = student.isParticipant;

  return isParticipant ? 'Yes' : 'No';
}

export function getIsPublished(schoolFile: SchoolFile): string {
  const isPublished = schoolFile.isPublished;

  return isPublished ? 'Yes' : 'No';
}

export function getFileCategory(schoolFile: SchoolFile): string {
  const category = schoolFile.category;

  return FILES_CATEGORY_SERVER_TO_CLIENT_MAPPING[category];
}

export function getClubMessageUpdatedAt(student: any): string {
  const date = student.messageUpdatedAt;
  return typeof date !== 'undefined' ? Moment(date).format(DATE_TIME_FORMAT) : '';
}

export function getUpdatedAt(message: any): string {
  const date = message.updatedAt;
  return typeof date !== 'undefined' ? Moment(date).format(DATE_TIME_FORMAT) : '';
}

export function getClubMessageStatus(message: any): string {
  const messageStatus = message.invitationStatus;

  return typeof messageStatus !== 'undefined' ? MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING[messageStatus] : '';
}

export function getClubMessageDeadline(message: ClubMessage): string {
  const deadlineForAnswers = propz.get(message, ['deadlineForAnswers'], '');
  const isDeadlineExist = deadlineForAnswers !== '';

  return isDeadlineExist ? Moment(deadlineForAnswers).format(DATE_TIME_FORMAT) : '';
}

export function getClubStartDateFromMessage(message: ClubMessage): string {
  const date = message.clubData.startDate;
  return typeof date !== 'undefined' ? Moment(date).format(DATE_FORMAT) : '';
}

export function getClubFinishDateFromMessage(message: ClubMessage): string {
  const date = message.clubData.finishDate;
  return typeof date !== 'undefined' ? Moment(date).format(DATE_FORMAT) : '';
}

export function getClubDays(message: ClubMessage): string {
  return message.clubData.days.map(day => WEEK_DAYS_SERVER_TO_CLIENT_MAPPING[day]).join('\n');
}

export function getClubStartTimeFromMessage(message: ClubMessage): string {
  const time = message.clubData.time;

  const timeDate = new Date(time);
  const hours = timeDate.getHours();
  const minutes = timeDate.getMinutes();

  const hoursStr = addZeroToFirst(hours);
  const minutesStr = addZeroToFirst(minutes);

  return `${hoursStr}:${minutesStr}`;
}

export function getEmailOrMisEmail(user: SchoolUser): string {
  const { email, misEmail, status } = user;
  const isExistEmail = typeof email !== 'undefined';
  const isExistMisEmail = typeof misEmail !== 'undefined';

  switch (true) {
    case status === USER_STATUS.ACTIVE && isExistEmail:
      return email;
    case status === USER_STATUS.ACTIVE && isExistMisEmail:
      return misEmail;
    case status === USER_STATUS.WIPED && isExistEmail:
      return email;
    case status === USER_STATUS.WIPED && isExistMisEmail:
      return misEmail;
    case status === USER_STATUS.BLOCKED && isExistMisEmail:
      return misEmail;
    default:
      return '';
  }
}

export function getPhoneOrMisPhone(user: SchoolUser): string {
  const { phone, misPhone, status } = user;
  switch (status) {
    case USER_STATUS.ACTIVE:
      return typeof phone !== 'undefined' ? phone : '';
    case USER_STATUS.BLOCKED:
      return typeof misPhone !== 'undefined' ? misPhone : '';
  }
}

export function getMessageTypeByKind(message) {
  const kind = message.kind;
  return typeof kind !== 'undefined' ? MESSAGE_KIND_SERVER_TO_CLIENT_MAPPING[kind] : '';
}

export function getMessageActionStatus(message): string {
  const kind = message.kind;
  switch (kind) {
    case MESSAGE_KIND.AbsenceMessage:
      const isAuthorized = message.isAuthorized;
      switch (true) {
        case typeof isAuthorized === 'undefined':
          return 'Outdated';
        case isAuthorized === true:
          return 'Authorised';
        case isAuthorized === false:
          return 'Unauthorised';
        default:
          return '';
      }
    default:
      return '';
  }
}

export function getMessageInvitationStatus(message): string {
  const invitationStatus = message.invitationStatus;
  return typeof invitationStatus !== 'undefined'
    ? MESSAGE_INVITATION_STATUS_SERVER_TO_CLIENT_MAPPING[invitationStatus]
    : '';
}

export function getMessageTakePart(message): string {
  const kind = message.kind;

  switch (kind) {
    case MESSAGE_KIND.EventParticipationMessage:
      const isTakePart = message.isTakePart;
      switch (true) {
        case typeof isTakePart === 'undefined':
          return '';
        case isTakePart === true:
          return 'Yes';
        case isTakePart === false:
          return 'No';
        default:
          return '';
      }
    default:
      return '';
  }
}

export function getStartTime({ startTime }): string {
  return typeof startTime !== 'undefined' ? Moment(startTime).format(DATE_TIME_FORMAT) : '';
}

export function getSeason({ startTime }): string {
  const tournamentStartTime = new Date(startTime);
  let start = tournamentStartTime.getFullYear();
  if (new Date(tournamentStartTime.getFullYear(), 8, 1) > tournamentStartTime) {
    // the season starts on September 1
    start = tournamentStartTime.getFullYear() - 1;
  }
  const end = start + 1;
  const season = start + '/' + end;

  return season;
}

export function getIsPublishOnTheWebsite(tournament: Tournament): string {
  const isPublishOnTheWebsite = tournament.isPublishOnTheWebsite;

  return isPublishOnTheWebsite ? 'Yes' : 'No';
}

export function getIsPromoteTournament(tournament: Tournament): string {
  const isPromoteTournament = tournament.isPromoteTournament;

  return isPromoteTournament ? 'Yes' : 'No';
}

export function getEndTime({ endTime }): string {
  return typeof endTime !== 'undefined' ? Moment(endTime).format(DATE_TIME_FORMAT) : '';
}

export function getInviteStatus(invite: any): string {
  const inviteStatus = invite.status;

  return typeof inviteStatus !== 'undefined' ? INVITE_STATUS_SERVER_TO_CLIENT_MAPPING[inviteStatus] : '';
}

export function getInviteOutboxStatus(invite: any): string {
  const inviteStatus = invite.status;

  return typeof inviteStatus !== 'undefined' ? INVITE_OUTBOX_STATUS_SERVER_TO_CLIENT_MAPPING[inviteStatus] : '';
}

export function getRequestStatus(request: Request): string {
  const requestStatus = request.status;

  return typeof requestStatus !== 'undefined' ? PERMISSION_REQUEST_STATUS_SERVER_TO_CLIENT_MAPPING[requestStatus] : '';
}

export function getTournamentConfirmationRequestMessageStatus(message: any): string {
  const messageStatus = message.invitationStatus;

  return typeof messageStatus !== 'undefined'
    ? CONFIRMATION_REQUESTS_STATUS_SERVER_TO_CLIENT_MAPPING[messageStatus]
    : '';
}

export function getSenderNameIfNeedInviteInbox(invite: SchoolInvite): string {
  return typeof invite !== 'undefined' ? invite.inviterSchool.name : '';
}

export function getSenderNameIfNeedInviteOutbox(invite: SchoolInvite): string {
  return typeof invite !== 'undefined' ? invite.invitedSchool.name : '';
}

export function getSenderNameIfNeedInviteArchive(invite: SchoolInvite, options: { user: AppUser }): string {
  const { inviterSchool, invitedSchool } = invite;
  const { user } = options;
  const { activeSchoolId } = user;

  const rival = activeSchoolId === inviterSchool.id ? invitedSchool : inviterSchool;

  if (typeof rival === 'undefined') {
    console.error('Broken invite: ', invite.id);
    console.error(invite);
    return '';
  }

  switch (true) {
    case isSchoolEventInvite(invite):
      return inviterSchool.kind === 'SchoolUnion' ? invite.inviterSchool.name : rival.name;
    case isSchoolTournamentInvite(invite):
      return invite.inviterSchool.kind === 'SchoolUnion' ? invite.inviterSchool.name : '';
    default:
      return '';
  }
}

export function getEventDataToInvite(invite: SchoolInvite): string {
  const { event, tournament } = invite;
  const sportName = invite.sport ? invite.sport.name : '';

  switch (true) {
    case isSchoolEventInvite(invite):
      const gender = event.gender ? ` / ${SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender]}` : '';
      const ageGroup = getAgeGroup(event);
      const formAgeGroup = ageGroup !== '' ? ` / ${ageGroup}` : '';
      return `${sportName}${gender}${formAgeGroup}`;
    case isSchoolTournamentInvite(invite):
      const tournamentName = typeof tournament !== 'undefined' ? `${tournament.name} / ` : '';
      return `${tournamentName}${sportName}`;
    default:
      return '';
  }
}

export function getStartFromInvite(invite: SchoolInvite): string {
  const { event, tournament } = invite;

  switch (true) {
    case isSchoolEventInvite(invite):
      const eventDate = new Date(event.startTime);
      return typeof eventDate !== 'undefined' ? Moment(eventDate).format(DATE_TIME_FORMAT) : '';
    case isSchoolTournamentInvite(invite):
      const tournamentDate = new Date(tournament.startTime);
      return typeof tournamentDate !== 'undefined' ? Moment(tournamentDate).format(DATE_TIME_FORMAT) : '';
    default:
      return '';
  }
}

export function getEventVenueInviteInbox(invite: SchoolInvite): string {
  const { invitedSchoolId } = invite;

  switch (true) {
    case isSchoolEventInvite(invite):
      return getLocation(invite.event, invitedSchoolId);
    case isSchoolTournamentInvite(invite):
      return getTournamentLocation(invite, invitedSchoolId);
    default:
      return '';
  }
}

export function getEventVenueInviteOutbox(invite: SchoolInvite): string {
  const { inviterSchoolId } = invite;

  switch (true) {
    case isSchoolEventInvite(invite):
      return getLocation(invite.event, inviterSchoolId);
    case isSchoolTournamentInvite(invite):
      return getTournamentLocation(invite, inviterSchoolId);
    default:
      return '';
  }
}

export function getEventVenueInviteArchive(invite: SchoolInvite, options: { user: AppUser }): string {
  const { user } = options;
  const { activeSchoolId } = user;

  switch (true) {
    case isSchoolEventInvite(invite):
      return getLocation(invite.event, activeSchoolId);
    case isSchoolTournamentInvite(invite):
      return getTournamentLocation(invite, activeSchoolId);
    default:
      return '';
  }
}

export function getEventGeneratedName(event: SchoolEvent, options: { user: AppUser }): string {
  const { user } = options;
  const { activeSchoolId } = user;
  return propz.get(event, ['generatedNames', activeSchoolId], '');
}

export function getEventSportName(event: SchoolEvent): string {
  return propz.get(event, ['sport', 'name'], '');
}

export function getEventSportType(event: SchoolEvent): string {
  return propz.get(event, ['sport', 'players'], '');
}

export function getEventNameForParent(event: SchoolEvent, childIdList: string[]): string {
  const nativeChildrenList = event.players.filter(player => {
    return childIdList.some(childId => childId === player.userId);
  });

  const activeSchoolId = nativeChildrenList.length > 0 ? nativeChildrenList[0].schoolId : undefined;
  return propz.get(event, ['generatedNames', activeSchoolId], '');
}

export function getActiveSchoolIdForParent(event: SchoolEvent, childIdList: string[]): string {
  const nativeChildrenList = event.players.filter(player => {
    return childIdList.some(childId => childId === player.userId);
  });

  return nativeChildrenList.length > 0 ? nativeChildrenList[0].schoolId : '';
}

export function getEventGender(event): string {
  const eventGender = event.gender;

  return typeof eventGender !== 'undefined' ? SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[eventGender] : '';
}

export function getEventGroupName(event): string {
  const tournamentGroupId = event.tournamentGroupId;
  const tournamentCustomGroups = propz.get(event, ['tournament', 'customGroups'], []);

  const customGroup = tournamentCustomGroups.find(group => group.id === tournamentGroupId);
  return typeof customGroup !== 'undefined' ? customGroup.name : '';
}

export function getEventStartTime(event): string {
  const eventStartDate = new Date(event.startTime);
  return typeof eventStartDate !== 'undefined' ? Moment(eventStartDate).format(DATE_TIME_FORMAT) : '';
}

export function getEventEndTime(event): string {
  const eventEndDate = new Date(event.endTime);
  return typeof event.endTime !== 'undefined' ? Moment(eventEndDate).format(DATE_TIME_FORMAT) : '';
}

export function getEventType(event): string {
  const eventType = propz.get(event, ['eventType']);

  return typeof eventType !== 'undefined' ? EVENT_TYPES_SERVER_TO_CLIENT_MAPPING[eventType] : '';
}

export function getEventVenueType(event): string {
  const eventVenueType = propz.get(event, ['venue', 'venueType']);

  return typeof eventVenueType !== 'undefined' ? VENUE_SERVER_TO_CLIENT_MAPPING[eventVenueType] : '';
}

export function getEventVenue(event): string {
  const postcode = propz.get(event.venue, ['postcodeData', 'postcode'], '');
  const venueName = propz.get(event.venue, ['placeData', 'name']);

  if (typeof venueName !== 'undefined') {
    return `${postcode}, ${venueName}`;
  } else {
    return postcode;
  }
}

export function getEventStatus(event): string {
  const eventStatus = event.status;

  return typeof eventStatus !== 'undefined' ? EVENT_STATUS_SERVER_TO_CLIENT_MAPPING[eventStatus] : '';
}

export function getEventPitch(event): string {
  const { pitchId, tournament } = event;
  const pitches = tournament.pitches || [];

  const pitch = pitches.find(pitch => pitch.id === pitchId);

  return typeof pitch !== 'undefined' ? pitch.name : '';
}

export function getCoachEvent(event): string {
  const staff = propz.get(event, ['eventDetails', 'staff'], []);

  const coaches = staff.filter(oneStaff => oneStaff.staffRole === CLUB_STAFF.COACH);

  return coaches
    .map(coach => {
      const firstName = propz.get(coach, ['firstName'], '');
      const lastName = propz.get(coach, ['lastName'], '');
      return `${firstName} ${lastName}`;
    })
    .join('\n');
}

export function getStaffEvent(event): string {
  const staff = propz.get(event, ['eventDetails', 'staff'], []);

  const coaches = staff.filter(oneStaff => oneStaff.staffRole === CLUB_STAFF.MEMBER_OF_STAFF);

  return coaches
    .map(coach => {
      const firstName = propz.get(coach, ['firstName'], '');
      const lastName = propz.get(coach, ['lastName'], '');
      return `${firstName} ${lastName}`;
    })
    .join('\n');
}

export function getEventSubtype(event): string {
  switch (true) {
    case isClubEvent(event):
      return EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING.CLUB_EVENT;
    case isTournamentEvent(event):
      return EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_EVENT;
    default:
      return EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING.FIXTURE_EVENT;
  }
}

export function getEventAges(event): string {
  const ages = event.ages || [];
  return ages.map(age => propz.get(AGE_GROUPS, ['U5-U18', age], '')).join('\n');
}

export function getEventAgesGroup(event, options?): string {
  const schoolEventAgesGroupName = propz.get(options, ['user', 'activeSchool', 'ageGroupsNaming'], '');
  const schoolEventAgesGroup = propz.get(AGE_GROUPS, [schoolEventAgesGroupName], []);
  const eventAges = event.ages || [];

  const result = eventAges.map(age => schoolEventAgesGroup[age]);

  return result.join('\n');
}

export function getTeamPlayerAgesGroup(player, options?): string {
  const agesGroupName = propz.get(options, ['user', 'activeSchool', 'ageGroupsNaming'], '');
  const agesGroup = propz.get(AGE_GROUPS, [agesGroupName], []);
  const playerAge = propz.get(player, ['form', 'age'], '');

  return agesGroup[playerAge];
}

export function getTeamPlayerEvents(player, options?) {
  const { events } = player;
  const eventNames = events.map(event => {
    const name = propz.get(event, ['generatedNames', 'official'], '');

    return name;
  });

  return eventNames.join(', ');
}

export function getIndividualTournamentEventActiveSchoolPlayers(event, options: { user: AppUser }): string {
  const { user } = options;
  const { activeSchoolId } = user;
  const isRelay = propz.get(event, ['sport', 'isRelay'], false);

  let activeSchoolPlayers = [];

  if (isRelay) {
    const teams = propz.get(event, ['teamsData'], []);
    const activeSchoolTeams = teams.filter(team => team.schoolId === activeSchoolId);

    activeSchoolTeams.forEach(team => {
      const players = propz.get(team, ['players'], []);
      activeSchoolPlayers = [...activeSchoolPlayers, ...players];
    });
  } else {
    const allPlayers = propz.get(event, ['individualsData'], []);
    activeSchoolPlayers = allPlayers.filter(player => player.schoolId === activeSchoolId);
  }

  const activeSchoolPlayersNames = activeSchoolPlayers.map(item => `${item.firstName} ${item.lastName}`);

  return activeSchoolPlayersNames.join('\n');
}

export function getParticipatingStudentsPupilsPremium(student): string {
  const isPupilsPremium = student.user.pupilsPremium;

  return isPupilsPremium ? 'Yes' : 'No';
}

export function getParticipatingStudentsSpecialEducationNeeds(student): string {
  const isSpecialEducationNeeds = propz.get(student, ['user', 'specialEducationNeeds'], false);
  return isSpecialEducationNeeds ? 'Yes' : 'No';
}

export function getParticipatingStudentsFreeSchoolMeals(student): string {
  const isFreeSchoolMeals = propz.get(student, ['user', 'freeSchoolMeals'], false);
  return isFreeSchoolMeals ? 'Yes' : 'No';
}

export function getStudentCanSubmitResult(event): string {
  const isStudentCanSubmitResult = event.isStudentCanSubmitResult;

  return isStudentCanSubmitResult ? 'On' : 'Off';
}

export function getParticipatingStudentsAges(student): string {
  return propz.get(student, ['user', 'ageGroupName'], '');
}

export function getFixtureParticipatingAges(student): string {
  const age = student.user.ageGroup;
  return propz.get(AGE_GROUPS, ['ENGLISH', age], '');
}

export function getEventToParticipatingStudentsStart(student): string {
  const eventDate = new Date(student.events[0].startTime);
  return typeof eventDate !== 'undefined' ? Moment(eventDate).format(DATE_TIME_FORMAT) : '';
}

export function getTournamentStageName(
  customGroup: TournamentCustomGroup,
  options: { tournament: Tournament }
): string {
  const { tournament } = options;
  const stage = getStageByGroupId(tournament, customGroup.id);

  return typeof stage !== 'undefined' ? stage.name : '';
}

export function getTournamentCustomGroupPitch(
  customGroup: TournamentCustomGroup,
  options: { tournament: Tournament }
): string {
  const { pitchId } = customGroup;
  const { tournament } = options;
  const pitches = tournament.pitches || [];

  const pitch = pitches.find(pitch => pitch.id === pitchId);

  return typeof pitch !== 'undefined' ? pitch.name : '';
}

export function getTournamentCustomGroupSubstage(customGroup: TournamentCustomGroup): string {
  const { substage } = customGroup;
  const isSubstageExist = typeof substage !== 'undefined' && substage !== '';

  return isSubstageExist ? SUBSTAGES_OF_TOURNAMENT_SERVER_TO_CLIENT_MAPPING[substage] : '';
}

export function getTournamentCustomGroupPrize(customGroup: TournamentCustomGroup): string {
  const { prize } = customGroup;
  const isPrizeExist = typeof prize !== 'undefined' && prize !== '';

  return isPrizeExist ? TOURNAMENT_PRIZES_SERVER_TO_CLIENT_MAPPING[prize] : '';
}

export function getTournamentLogAction(log): string {
  const tournamentLogAction = log.action;

  return typeof tournamentLogAction !== 'undefined'
    ? TOURNAMENT_LOG_ACTION_TYPES_SERVER_TO_CLIENT_MAPPING[tournamentLogAction]
    : '';
}

export function getTeamAges(team, options: { user: AppUser }): string {
  const ages = team.ages || [];
  const AgesGroupName = propz.get(options, ['user', 'activeSchool', 'ageGroupsNaming'], '');

  return ages.map(age => propz.get(AGE_GROUPS, [AgesGroupName, age], '')).join('\n');
}

export function getTeamAgesGroup(team, options?): string {
  const teamEventAgesGroupName = propz.get(options, ['user', 'activeSchool', 'ageGroupsNaming'], '');
  const teamEventAgesGroup = propz.get(AGE_GROUPS, [teamEventAgesGroupName], []);
  const teamAgeIndexes = team.ages || [];

  const result = teamAgeIndexes.map(ageIndex => teamEventAgesGroup[ageIndex]);

  return result.join('\n');
}

export function getTeamGender(team): string {
  const teamGender = team.gender;

  return typeof teamGender !== 'undefined' ? SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[teamGender] : '';
}

export function getTeamGroupsName(team, tournament): string {
  const groupIds = team.groupIds;
  return [...groupIds]
    .map(groupId => {
      const group = tournament.customGroups.find(customGroup => customGroup.id === groupId);
      if (typeof group !== 'undefined') {
        const stage = tournament.tournamentLayout.find(tournamentStage => tournamentStage.id === group.stageId);
        return `${group.name} [${stage.name}]`;
      } else {
        console.error(`Can not find group with id: ${groupId}`);
        return '';
      }
    })
    .sort()
    .join(', ');
}

export function getTournamentInviteStatus(item: any): string {
  const inviteStatus = propz.get(item, ['invite', 'status']);
  const isInviteAutoAccepted = propz.get(item, ['invite', 'isAutoAccepted']);
  const isInviteAutoAcceptedBoolean = Boolean(isInviteAutoAccepted);

  const isInviteStatusAccepted = inviteStatus === INVITE_STATUS.ACCEPTED;
  const isInviteStatusRejected = inviteStatus === INVITE_STATUS.REJECTED;
  const isInviteStatusNotReady = inviteStatus === INVITE_STATUS.NOT_READY;
  const isInviteStatusCanceled = inviteStatus === INVITE_STATUS.CANCELED;
  const isInviteStatusPrebooked = inviteStatus === INVITE_STATUS.PREBOOKED;
  const isInviteStatusOutdated = inviteStatus === INVITE_STATUS.OUTDATED;
  const isInviteStatusNotDefined = typeof inviteStatus === 'undefined';

  switch (true) {
    case isInviteStatusAccepted && !isInviteAutoAcceptedBoolean:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.ACCEPTED;
    case isInviteStatusAccepted && isInviteAutoAcceptedBoolean:
      return INVITE_STATUS_AUTO_ACCEPTED_MAPPING;
    case isInviteStatusRejected:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.REJECTED;
    case isInviteStatusNotReady:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.NOT_READY;
    case isInviteStatusCanceled:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.CANCELED;
    case isInviteStatusOutdated:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.OUTDATED;
    case isInviteStatusPrebooked:
      return INVITE_STATUS_SERVER_TO_CLIENT_MAPPING.PREBOOKED;
    case isInviteStatusNotDefined:
      return INVITE_STATUS_NOT_SENT_MAPPING;
    default:
      return inviteStatus;
  }
}

export function getTournamentInviteDate(item: any): string {
  const inviteStatus = propz.get(item, ['invite', 'status']);
  const inviteStatusSetAt = propz.get(item, ['invite', 'statusSetAt']);
  const isInviteStatusDefined = typeof inviteStatus !== 'undefined';
  return isInviteStatusDefined ? Moment(inviteStatusSetAt).format(DATE_TIME_FORMAT) : '';
}

export function getIntegrationType(integration: Integration): string {
  const type = integration.type;
  return typeof type !== 'undefined' ? INTEGRATION_TYPE_SERVER_TO_CLIENT_MAPPING[type] : '';
}

export function getClubStartDate(club: Club): string {
  const { schedule } = club;
  const { scheduleType, intervals } = schedule;

  switch (scheduleType) {
    case CLUB_SCHEDULE.WEEKLY_SCHEDULE: {
      const { startDate } = schedule;
      return Moment.utc(startDate).format(DATE_FORMAT);
    }

    case CLUB_SCHEDULE.INTERVALS: {
      const [firstInterval] = intervals;
      const startDate = firstInterval.start;
      return Moment.utc(startDate).format(DATE_FORMAT);
    }

    default: {
      console.error('schedule type not defined');
      return Moment.utc().format(DATE_FORMAT);
    }
  }
}

export function getClubStartDateWithoutFormatting(club: Club): string {
  const { schedule } = club;
  const { scheduleType, intervals } = schedule;

  switch (scheduleType) {
    case CLUB_SCHEDULE.WEEKLY_SCHEDULE: {
      const { startDate } = schedule;
      return startDate;
    }

    case CLUB_SCHEDULE.INTERVALS: {
      const [firstInterval] = intervals;
      const startDate = firstInterval.start;
      return startDate;
    }

    default: {
      console.error('schedule type not defined');
      return '';
    }
  }
}

export function getClubFinishDateWithoutFormatting(club: Club): string {
  const { schedule } = club;
  const { scheduleType, intervals } = schedule;

  switch (scheduleType) {
    case CLUB_SCHEDULE.WEEKLY_SCHEDULE: {
      const { finishDate } = schedule;
      return finishDate;
    }

    case CLUB_SCHEDULE.INTERVALS: {
      const lastInterval = intervals[intervals.length - 1];
      const finishDate = lastInterval.end;
      return finishDate;
    }

    default: {
      console.error('schedule type not defined');
      return '';
    }
  }
}

export function getClubAcademicYear(club: Club): string {
  const { schedule } = club;
  const { scheduleType, intervals } = schedule;

  switch (scheduleType) {
    case CLUB_SCHEDULE.WEEKLY_SCHEDULE: {
      const { startDate, finishDate } = schedule;
      const start = new Date(startDate);
      const finish = new Date(finishDate);
      const startYear = start.getFullYear();
      const finishYear = finish.getFullYear();

      const startAcademic = new Date(startYear, 8, 1);
      const newYearNextAcademic = new Date(startYear + 1, 0, 1);
      const newYearCurrentAcademic = new Date(startYear, 0, 1);
      const endAcademic = new Date(startYear, 4, 31);

      switch (true) {
        case Number(start) > Number(startAcademic) &&
          Number(finish) < Number(newYearNextAcademic) &&
          finishYear === startYear:
          return `${startYear} / ${finishYear + 1}`;
        case Number(start) > Number(newYearCurrentAcademic) &&
          Number(finish) < Number(endAcademic) &&
          finishYear === startYear:
          return `${startYear - 1} / ${finishYear}`;
        default:
          return `${finishYear === startYear ? startYear - 1 : startYear} / ${finishYear}`;
      }
    }

    case CLUB_SCHEDULE.INTERVALS: {
      const [firstInterval] = intervals;
      const lastInterval = intervals[intervals.length - 1];
      const startDate = firstInterval.start;
      const finishDate = lastInterval.end;
      const start = new Date(startDate);
      const finish = new Date(finishDate);
      const startYear = start.getFullYear();
      const finishYear = finish.getFullYear();

      const startAcademic = new Date(startYear, 8, 1);
      const newYearNextAcademic = new Date(startYear + 1, 0, 1);
      const newYearCurrentAcademic = new Date(startYear, 0, 1);
      const endAcademic = new Date(startYear, 4, 31);

      switch (true) {
        case Number(start) > Number(startAcademic) &&
          Number(finish) < Number(newYearNextAcademic) &&
          finishYear === startYear:
          return `${startYear} / ${finishYear + 1}`;
        case Number(start) > Number(newYearCurrentAcademic) &&
          Number(finish) < Number(endAcademic) &&
          finishYear === startYear:
          return `${startYear - 1} / ${finishYear}`;
        default:
          return `${finishYear === startYear ? startYear - 1 : startYear} / ${finishYear}`;
      }
    }

    default: {
      console.error('schedule type not defined');
      return '';
    }
  }
}

export function getClubGender(club: Club): string {
  const clubGender = club.gender;

  return typeof clubGender !== 'undefined' ? SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[clubGender] : '';
}

export function getClubAges(club: Club): string {
  const { ages } = club;
  const agesCount = ages.length;

  switch (agesCount) {
    case 0:
      return 'All ages';
    case 1: {
      const [age] = ages;
      return `Year ${age}`;
    }

    default: {
      const [age] = ages;
      const lastAge = [...ages].sort()[agesCount - 1];
      return `Year ${age} - Year ${lastAge}`;
    }
  }
}

export function getAgesGroupFormatted(event: any, options?): string {
  const eventAgesGroupName = propz.get(options, ['user', 'activeSchool', 'ageGroupsNaming'], '');
  const eventAgesGroup = propz.get(AGE_GROUPS, [eventAgesGroupName], []);
  const { ages: ageIndexes } = event;
  const agesCount = ageIndexes.length;

  const sortedAgeIndexes = ageIndexes.sort((prev: number, next: number) => {
    return sortAges(prev, next, eventAgesGroupName);
  });

  const firstAgeIndex = sortedAgeIndexes[0];
  const lastAgeIndex = sortedAgeIndexes[agesCount - 1];

  const isAgeIndexesSerial = sortedAgeIndexes.every((ageIndex: number, index: number, array: number[]) => {
    const arrayLastIndex = array.length - 1;
    const increasedAgeIndex = ageIndex + 1;
    const decrementedAgeIndex = ageIndex - 1;

    const prevIndex = array[index - 1];
    const nextIndex = array[index + 1];

    switch (true) {
      case eventAgesGroup[ageIndex] === 'N1' && eventAgesGroup[nextIndex] === 'N2':
        return true;

      case eventAgesGroup[ageIndex] === 'N2' && eventAgesGroup[nextIndex] === 'Reception':
        return true;

      case (eventAgesGroup[ageIndex] === 'Y14' && eventAgesGroup[nextIndex] === 'Post-19') ||
        (eventAgesGroup[ageIndex] === 'Post-19' && eventAgesGroup[prevIndex] === 'Y14'):
        return true;

      case increasedAgeIndex === nextIndex:
        return true;

      case index === arrayLastIndex:
        return decrementedAgeIndex === prevIndex;

      default:
        return false;
    }
  });

  const getNotSerialAgesString = (ageIndexes: number[]): string => {
    let resultAgesString = '';
    let arraysOfSerialOrSingleAgesIndexes: any[] = [];
    let firstSerialIndex = 0;

    ageIndexes.forEach((ageIndex, index, array) => {
      const increasedAgeIndex = ageIndex + 1;
      const nextAgeIndex = array[index + 1];
      const nextIndex = index + 1;

      const isPreSchoolAge =
        (eventAgesGroup[ageIndex] === 'N2' && eventAgesGroup[nextAgeIndex] === 'Reception') ||
        (eventAgesGroup[ageIndex] === 'N1' && eventAgesGroup[nextAgeIndex] === 'N2');

      if (increasedAgeIndex !== nextAgeIndex && !isPreSchoolAge) {
        const serialAgesIndexes: number[] = array.slice(firstSerialIndex, nextIndex);
        arraysOfSerialOrSingleAgesIndexes.push(serialAgesIndexes);
        firstSerialIndex = nextIndex;
      }
    });

    arraysOfSerialOrSingleAgesIndexes.forEach((serialOrSingleAgesIndexes, index, array) => {
      const isSingleAgeIndex = serialOrSingleAgesIndexes.length === 1;
      const isLastArrayIteration = index === array.length - 1;

      const firstSerialAgeIndex = serialOrSingleAgesIndexes[0];
      const lastSerialAgeIndex = serialOrSingleAgesIndexes[serialOrSingleAgesIndexes.length - 1];
      const firstEventAge = eventAgesGroup[firstSerialAgeIndex];
      const lastEventAge = eventAgesGroup[lastSerialAgeIndex];

      switch (true) {
        case isLastArrayIteration && isSingleAgeIndex:
          resultAgesString += `${firstEventAge}`;
          break;

        case isLastArrayIteration && !isSingleAgeIndex:
          resultAgesString += `${firstEventAge} - ${lastEventAge}`;
          break;

        case !isLastArrayIteration && isSingleAgeIndex:
          resultAgesString += `${firstEventAge}, `;
          break;

        case !isLastArrayIteration && !isSingleAgeIndex:
          resultAgesString += `${firstEventAge} - ${lastEventAge}, `;
          break;
      }
    });

    return resultAgesString;
  };

  const firstEventAge = eventAgesGroup[firstAgeIndex];
  const lastEventAge = eventAgesGroup[lastAgeIndex];

  switch (true) {
    case agesCount === 0 || agesCount === 18: {
      return 'All ages';
    }

    case agesCount === 1: {
      return firstEventAge;
    }

    case agesCount >= 2 && isAgeIndexesSerial: {
      return `${firstEventAge} - ${lastEventAge}`;
    }

    case agesCount === 2: {
      return `${firstEventAge}, ${lastEventAge}`;
    }

    case agesCount > 2 && !isAgeIndexesSerial: {
      return getNotSerialAgesString(sortedAgeIndexes);
    }

    default: {
      return `${firstEventAge} - ${lastEventAge}`;
    }
  }
}

export function getClubCoaches(club: Club): string {
  const staff: ClubStaff[] = propz.get(club, ['staff'], []);

  return staff
    .filter(singleStaff => singleStaff.staffRole === CLUB_STAFF.COACH)
    .map(singleStaff => `${singleStaff.firstName} ${singleStaff.lastName}`)
    .join(', ');
}
export function getClubMembersOfStaff(club: Club): string {
  const staff: ClubStaff[] = propz.get(club, ['staff'], []);

  return staff
    .filter(singleStaff => singleStaff.staffRole === CLUB_STAFF.MEMBER_OF_STAFF)
    .map(singleStaff => `${singleStaff.firstName} ${singleStaff.lastName}`)
    .join(', ');
}

export function getClubWeekDays(club: Club): string {
  const { schedule } = club;
  const { days } = schedule;

  return days.map(day => WEEK_DAYS_SHORT_SERVER_TO_CLIENT_MAPPING[day]).join(' ');
}

export function getClubRecurrence(club: Club): string {
  const { schedule } = club;
  const { scheduleType } = schedule;

  return typeof scheduleType !== 'undefined' ? CLUB_SCHEDULE_SERVER_TO_CLIENT_MAPPING[scheduleType] : '';
}

export function getClubStartTime(club: Club): string {
  const { schedule } = club;
  const { time } = schedule;

  const timeDate = new Date(time);
  const hours = timeDate.getHours();
  const minutes = timeDate.getMinutes();

  const hoursStr = addZeroToFirst(hours);
  const minutesStr = addZeroToFirst(minutes);

  return `${hoursStr}:${minutesStr}`;
}

export function getAggregationType(item: SchoolLeague): string {
  const aggregationType = item.aggregationType;
  return aggregationType ? LEAGUE_AGGREGATION_TYPE_SERVER_TO_CLIENT_MAPPING[aggregationType] : '';
}

export function getLeagueNewsDate(news: LeagueNews): string {
  const newsDate = new Date(news.date);
  return typeof news.date !== 'undefined' ? Moment(newsDate).format(DATE_TIME_FORMAT) : '';
}

export function getAgesFromClubMultiSelect(ageItems): string {
  const ages = ageItems.filter(ageItems => ageItems.isChecked);

  return ages.length > 0 ? ages.map(age => age.name).join(' - ') : 'All ages';
}

export function getFavoriteSports(sport: Sport): string {
  return sport.isFavorite ? 'Yes' : 'No';
}

export function getSchoolAvailableAges(school: School): string {
  const availableAges = school.availableAges || [];

  return [...availableAges]
    .sort((age1, age2) => age1 - age2)
    .map(age => propz.get(AGE_GROUPS, ['ENGLISH', age]))
    .join(', ');
}

export function getSchoolAvailableAgesGroup(school: School, options): string {
  const tournamentAgesGroupName = propz.get(options, ['user', 'activeSchool', 'ageGroupsNaming'], 'ENGLISH');
  const availableAgeIndexes = school.availableAges || [];

  const ageGroup = propz.get(AGE_GROUPS, [tournamentAgesGroupName], null);
  if (!ageGroup || typeof ageGroup !== 'object') {
    return 'No age groups available';
  }

  return availableAgeIndexes
    .sort((a, b) => a - b)
    .map(ageIndex => {
      const mappedAge = ageGroup[ageIndex];
      if (!mappedAge) {
      }
      return mappedAge || '';
    })
    .join(', ');
}

export function getSchoolAvailableGender(school: School): string {
  const availableGender = school.availableGender || SCHOOL_GENDER.MIXED;

  return SCHOOL_GENDER_SERVER_TO_CLIENT_MAPPING[availableGender];
}

export function geEmptyAccessor(item: any): string {
  return '';
}

export function getTournamentSchoolAreas(item): string {
  const areaSU = item?.areaSU || [];

  if (!Array.isArray(areaSU) || areaSU.length === 0) {
    return '';
  }

  const areas = areaSU.map(area => area?.name || 'Unknown area');

  return areas.join(', ');
}

export function convertBooleanToYesNo(value: string | boolean): string {
  // This is done this way because different value options can come from the server: 'true', true, 'yes'

  const isString = typeof value === 'string';
  const isBoolean = typeof value === 'boolean';

  switch (true) {
    case isString:
      return value === 'true' || value === 'yes' || value === 'Yes' ? YES_NO_OPTIONS.YES : YES_NO_OPTIONS.NO;

    case isBoolean:
      return value ? YES_NO_OPTIONS.YES : YES_NO_OPTIONS.NO;

    default:
      return '';
  }
}

export function getTournamentTagNames(tournament) {
  if (tournament.tournamentTags && tournament.tournamentTags.length > 0) {
    return tournament.tournamentTags.map(tournamentTag => tournamentTag.tagName);
  }
  return [];
}

export function getTournamentTagNamesPublicOnly(tournament) {
  const tournamentTags = propz.get(tournament, ['tournamentTags'], []);
  return tournamentTags
    .filter(tournamentTag => tournamentTag.isPublic)
    .map(tournamentTag => tournamentTag.tagName)
    .join(', ');
}

export function formatWaitingListPosition(position) {
  switch (position) {
    case 1:
      return '1st reserve';
    case 2:
      return '2nd reserve';
    case 3:
      return '3rd reserve';
    default:
      return `${position}`;
  }
}
