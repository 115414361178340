import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button } from '../../../components/Button/Button';
import * as Yup from 'yup';
import { checkAvailability } from '../../../helpers/service/public/register';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { PHONE_CODE } from '../../../consts/common';
import { getUKPhoneWithoutFirstZero } from '../../../helpers/phone/phone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface Props {
  onSubmit: (data) => void;
  onCancel: () => void;
}

const TERMS_TEXT = (
  <>
    By checking this box, I confirm that I have read and agree to the
    <a href="https://www.squadintouch.com/privacy-policy/" target="_blank">
      {' '}
      Privacy Policy
    </a>
    ,
    <a href="https://www.squadintouch.com/terms-of-use/" target="_blank">
      {' '}
      Terms of Use
    </a>{' '}
    and
    <a href="https://www.squadintouch.com/acceptable-use-policy/" target="_blank">
      {' '}
      Acceptable Use Policy
    </a>
  </>
);

const ADDITIONAL_TERMS_TEXT = (
  <>
    <p>
      I confirm that I am at least 13 years old. I understand that if it is determined that I am under 13, my account
      may be disabled until a parent or guardian provides verified permission for me to use Squad In Touch.
    </p>
    <p>
      However, if you are under 13, you may still be eligible for an account. Please contact our support team at{' '}
      <a href="mailto:support@squadintouch.com">support@squadintouch.com</a> for assistance.
    </p>
  </>
);

export function PersonalDetailsForm(props: Props) {
  const { onSubmit, onCancel } = props;

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    phoneCode: PHONE_CODE.UK,
    terms: false,
    additionalTerms: false
  };

  const PersonalDetailsSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name.'),
    lastName: Yup.string().required('Please enter your last name.'),
    password: Yup.string()
      .required('Please create a password.')
      .test(
        'password',
        'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one number.',
        value => {
          const passwordRegExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/);

          return value && passwordRegExp.test(value);
        }
      ),
    confirmPassword: Yup.string().test(
      'confirmPassword',
      'The passwords you entered do not match. Please try again.',
      function(value) {
        return value === this.parent.password;
      }
    ),
    email: Yup.string()
      .required('Please enter a valid email address.')
      .email('Please enter a valid email address')
      .test('email', 'This email address is already registered.', value => {
        if (!value) {
          return false;
        } else {
          return checkAvailability({ email: value }).then(res => {
            return res.isAvailable;
          });
        }
      }),
    phone: Yup.string()
      .required('Please enter a valid phone number.')
      .test('phone', 'The phone number is either already registered or contains invalid characters.', function(value) {
        const phoneRegExp = /^\d+$|^\d+#\d+$/;
        const phoneCode = this.parent.phoneCode;
        const phone = getUKPhoneWithoutFirstZero(value, phoneCode);
        const result = phoneRegExp.test(phone);
        const phoneForCheck = `${phoneCode}${phone}`;

        return result
          ? checkAvailability({ phone: phoneForCheck }).then(res => {
              return res.isAvailable;
            })
          : false;
      })
      .test('phone', 'Please enter a valid mobile phone number', value => {
        if (value && value.length < 7) {
          return false;
        } else {
          return true;
        }
      }),
    terms: Yup.boolean().test(
      'terms',
      'Please agree to our Privacy Policy, Terms of Use, and Acceptable Use Policy to continue.',
      value => value
    ),
    additionalTerms: Yup.boolean().test(
      'additionalTerms',
      'Please confirm that you are at least 13 years old to continue.',
      value => value
    )
  });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={initialValues}
            validationSchema={PersonalDetailsSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            render={({ touched, errors, setFieldValue, values }) => (
              <Form className={'mt-3 text-left'}>
                <div className="form-group">
                  <label>Name</label>
                  <Field name="firstName" className={'form-control mb-3'} />
                  {touched.firstName && errors.firstName && (
                    <div className="alert alert-danger">{errors.firstName}</div>
                  )}
                </div>

                <div className="form-group">
                  <label>Surname</label>
                  <Field name="lastName" className={'form-control mb-3'} />
                  {touched.lastName && errors.lastName && <div className="alert alert-danger">{errors.lastName}</div>}
                </div>

                <div className="form-group">
                  <label>Email</label>
                  <Field name="email" className={'form-control mb-3'} />
                  {touched.email && errors.email && <div className="alert alert-danger">{errors.email}</div>}
                </div>

                <div className="form-row">
                  <div className="form-group col-md-2">
                    <label>Phone code</label>
                    <Field name="phoneCode" component="select" className="form-control">
                      <option value={PHONE_CODE.UK}>{PHONE_CODE.UK}</option>
                      <option value={PHONE_CODE.US}>{PHONE_CODE.US}</option>
                      <option value={PHONE_CODE.AE}>{PHONE_CODE.AE}</option>
                      <option value={PHONE_CODE.DE}>{PHONE_CODE.DE}</option>
                    </Field>
                  </div>

                  <div className="form-group col-md-10">
                    <label>Phone</label>
                    <Field name="phone" placeholder="Phone" className={'form-control mb-3'} />
                    {touched.phone && errors.phone && <div className="alert alert-danger">{errors.phone}</div>}
                  </div>
                </div>

                {/* Password Field with Eye Icon */}
                <div className="form-group position-relative">
                  <label>Password</label>
                  <div className="input-group">
                    <Field type={showPassword ? 'text' : 'password'} name="password" className="form-control mb-3" />
                    <div
                      className="input-group-append"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }}
                    >
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </div>
                  </div>
                  {touched.password && errors.password && <div className="alert alert-danger">{errors.password}</div>}
                </div>

                {/* Confirm Password Field with Eye Icon */}
                <div className="form-group position-relative">
                  <label>Confirm Password</label>
                  <div className="input-group">
                    <Field
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      className="form-control mb-3"
                    />
                    <div
                      className="input-group-append"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }}
                    >
                      <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                    </div>
                  </div>
                  {touched.confirmPassword && errors.confirmPassword && (
                    <div className="alert alert-danger">{errors.confirmPassword}</div>
                  )}
                </div>

                <Field
                  name="terms"
                  render={({ field }) => (
                    <Checkbox id={'terms'} isOnChangeIdRequired={false} {...field} labelText={TERMS_TEXT} />
                  )}
                />
                {touched.terms && errors.terms && <div className="alert alert-danger">{errors.terms}</div>}

                <div className="mt-3">
                  <Field
                    name="additionalTerms"
                    render={({ field }) => (
                      <Checkbox
                        id={'additionalTerms'}
                        isOnChangeIdRequired={false}
                        {...field}
                        labelText={ADDITIONAL_TERMS_TEXT}
                      />
                    )}
                  />
                </div>
                {touched.additionalTerms && errors.additionalTerms && (
                  <div className="alert alert-danger">{errors.additionalTerms}</div>
                )}

                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <button type="submit" className="mt-3 mb-3 btn btn-primary">
                  Submit
                </button>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
}
