import * as React from 'react';
import { Component } from 'react';
import { Tabs } from '../Tabs/Tabs';

interface Props {
  tabs: string[];
}

interface State {
  selectedTab: number;
}

export class GeneralMessagePreviewTabs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { selectedTab: 0 };
  }

  onTabClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
    event.preventDefault();
    this.setState({ selectedTab: index });
  };

  render() {
    const { selectedTab } = this.state;

    return (
      <div>
        <Tabs currentTabIndex={selectedTab} onTabClick={this.onTabClick} tabs={this.props.tabs} />
        <div>{this.props.children[selectedTab]}</div>
      </div>
    );
  }
}
