import { Staffs } from 'Src/views/GenericView/AdminView/Users/Staffs/Staffs';
import { StaffsSU } from 'Src/views/GenericView/AdminSUView/Users/Staffs';
import { Students } from 'Src/views/GenericView/AdminView/Users/Students/Students';
import { Users } from 'Src/views/GenericView/AdminView/Users/AllUsers/Users';
import { Governors } from 'Src/views/GenericView/AdminView/Users/Governors/Governors';
import { UserTags } from 'Src/views/GenericView/AdminView/Users/UserTags/UserTags';
import { Subscriptions } from 'Src/views/GenericView/AdminView/AdminConsole/Subscriptions/Subscriptions';
import { Invitations } from 'Src/views/GenericView/AdminView/Clubs/Invitations/Invitations';
import { Venues } from 'Src/views/GenericView/AdminView/School/Venues/Venues';
import { Forms } from 'Src/views/GenericView/AdminView/School/Forms/Forms';
import { Houses } from 'Src/views/GenericView/AdminView/School/Houses/Houses';
import { SchoolNotifications } from 'Src/views/GenericView/AdminView/Communications/Notifications/SchoolNotifications';
import { NewsList } from 'Src/views/GenericView/AdminView/Communications/News/NewsList';
import { GeneralMessageRouting } from 'Src/views/GenericView/AdminView/Communications/GeneralMessages/GeneralMessageRouting';
import { RequestRouting } from 'Src/views/GenericView/AdminView/Users/Requests/RequestRouting';
import { ClubRouting } from 'Src/views/GenericView/AdminView/Clubs/Clubs/ClubsRouting';
import { Transactions } from 'Src/views/GenericView/AdminView/AdminConsole/PaymentsAccounts/Transactions/Transactions';
import { Dashboard } from 'Src/views/GenericView/AdminView/Dashboard/Dashboard';
import { PaymentsAccountsRouting } from 'Src/views/GenericView/AdminView/AdminConsole/PaymentsAccounts/PaymentsAccountsRouting';
import { ParentMessagesRouting } from 'Src/views/GenericView/ParentView/Messages/MessagesRouting';
import { MessagesRouting } from 'Src/views/GenericView/AdminView/Messages/MessagesRouting';
import { TournamentRouting as TournamentRoutingSU } from '../views/GenericView/AdminSUView/Tournaments/TournamentsRouting';
import { HighLevelTournamentsRouting as HighLevelTournamentsRoutingSU } from '../views/GenericView/AdminSUView/HighLevelTournaments/HighLevelTournamentsRouting';
import { InvitesRouting } from 'Src/views/GenericView/AdminSUView/Invites/InvitesRouting';
import { Records } from 'Src/views/GenericView/AdminSUView/Records/Records';
import { ChampionshipsRouting } from 'Src/views/GenericView/AdminSUView/Championships/ChampionshipsRouting';
import { SchoolInvitesRouting } from 'Src/views/GenericView/AdminView/Invites/SchoolInvitesRouting';
import { SchoolsRouting } from 'Src/views/GenericView/AdminSUView/Schools/SchoolsRouting';
import { SchoolsAdmins } from 'Src/views/GenericView/AdminSUView/SchoolsAdmins/SchoolsAdmins';
import { ClubParticipatingStudents } from 'Src/views/GenericView/AdminView/Statistics/ClubParticipatingStudents/ClubParticipatingStudents';
import { TournamentRouting } from 'Src/views/GenericView/AdminView/Tournaments/TournamentsRouting';
import { Integrations } from 'Src/views/GenericView/AdminView/AdminConsole/Integrations/Integrations';
import { FixtureParticipatingStudents } from 'Src/views/GenericView/AdminView/Statistics/FixtureParticipatingStudents/FixtureParticipatingStudents';
import { RequestsArchive } from 'Src/views/GenericView/AdminView/Users/RequestsArchive/RequestsArchive';
import { SchoolTeamsRouting } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Teams/SchoolTeamsRouting';
import { SchoolEventsRouting } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventsRouting';
import { Calendar } from 'Src/views/GenericView/AdminView/Calendar/Calendar';
import { ParentCalendar } from 'Src/views/GenericView/ParentView/Calendar/Calendar';
import { Children } from 'Src/views/GenericView/ParentView/Children/Children';
import { ProfileRouting } from '../views/GenericView/Profile/ProfileRouting';
import { StudentCalendar } from '../views/GenericView/StudentView/Calendar/Calendar';
import { LeagueRouting } from '../views/GenericView/AdminView/LeaguesAndCompetitions/Leagues/LeaguesRouting';
import { StudentMessagesRouting } from '../views/GenericView/StudentView/Messages/MessagesRouting';
import { StudentEventView } from '../views/GenericView/StudentView/Events/EventView';
import { ClubBookingsRouting as StudentClubBookingsRouting } from 'Src/views/GenericView/StudentView/ClubBookings/ClubBookingsRouting';
import { TournamentWarning } from '../components/TournamentWarning/TournamentWarning';
import { TournamentInvite } from '../views/GenericView/AdminView/Tournaments/Tournament/Invite/TournamentInvite';
import { NotificationSettings } from '../views/GenericView/AdminView/AdminConsole/NotificationSettings/NotificationSettings';
import { UnionNotificationSettings } from '../views/GenericView/AdminSUView/AdminConsole/NotificationSettings/NotificationSettings';
import { Sports } from '../views/GenericView/AdminView/AdminConsole/Sports/Sports';
import { ChildrenEventView } from '../views/GenericView/ParentView/Events/EventView';
import { SchoolFiles } from '../views/GenericView/AdminView/AdminConsole/SchoolFiles/SchoolFiles';
import { SchoolGallery } from '../views/GenericView/AdminView/AdminConsole/SchoolGallery/SchoolGallery';
import { SchoolSettingsRouting } from 'Src/views/GenericView/AdminView/AdminConsole/SchoolSettings/SchoolSettingsRouting/SchoolSettingsRouting';
import { ConsentRequestsRouting } from 'Src/views/GenericView/ParentView/ConsentRequests/ConsentRequestsRouting';
import { ClubBookingsRouting } from 'Src/views/GenericView/ParentView/ClubBookings/ClubBookingsRouting';
import { AvailabilityReportsRouting } from 'Src/views/GenericView/ParentView/AvailabilityReports/AvailabilityReportsRouting';
import { AvailabilityReportsRouting as StudentAvailabilityReportsRouting } from 'Src/views/GenericView/StudentView/AvailabilityReports/AvailabilityReportsRouting';
import { Basket } from 'Src/views/GenericView/ParentView/Basket/Basket';
import { AbsenceRequestsRouting } from 'Src/views/GenericView/ParentView/AbsenceRequests/AbsenceRequestsRouting';
import { PaymentSuccess } from 'Src/views/GenericView/ParentView/PaymentSuccess/PaymentSuccess';
import { ClubTemplateQuestions } from 'Src/views/GenericView/AdminView/AdminConsole/ClubTemplateQuestions/ClubTemplateQuestions';
import { ConsentRequestsTemplateQuestions } from 'Src/views/GenericView/AdminView/AdminConsole/ConsentRequestsTemplateQuestions/ConsentRequestsTemplateQuestions';
import { Terms as SchoolTerms } from 'Src/views/GenericView/AdminView/AdminConsole/Terms/Terms';
import { TournamentConfirmationRequests } from 'Src/views/GenericView/AdminSUView/AdminConsole/TournamentConfirmationRequests/TournamentConfirmationRequests';
import { TournamentTags } from 'Src/views/GenericView/AdminSUView/TournamentTags/TournamentTags';
import { ImportStudentsWrapper } from 'Src/views/GenericView/AdminView/Users/ImportStudents/ImportStudentsWrapper';

interface Route {
  path: string;
  component: any;
  exact: boolean;
}

export const adminRoutes: Route[] = [
  {
    path: '/profile',
    component: ProfileRouting,
    exact: false
  },
  {
    path: '/staff',
    component: Staffs,
    exact: true
  },
  {
    path: '/students',
    component: Students,
    exact: true
  },
  {
    path: '/import-students',
    component: ImportStudentsWrapper,
    exact: true
  },
  {
    path: '/clubParticipation',
    component: ClubParticipatingStudents,
    exact: true
  },
  {
    path: '/fixtureParticipation',
    component: FixtureParticipatingStudents,
    exact: true
  },
  {
    path: '/users',
    component: Users,
    exact: true
  },
  {
    path: '/governors',
    component: Governors,
    exact: true
  },
  {
    path: '/notifications',
    component: SchoolNotifications,
    exact: true
  },
  {
    path: '/generalMessages',
    component: GeneralMessageRouting,
    exact: false
  },
  {
    path: '/messages',
    component: MessagesRouting,
    exact: false
  },
  {
    path: '/news',
    component: NewsList,
    exact: true
  },
  {
    path: '/tags',
    component: UserTags,
    exact: true
  },
  {
    path: '/requests',
    component: RequestRouting,
    exact: false
  },
  // {
  //   path: '/dashboard',
  //   component: Dashboard,
  //   exact: true
  // },
  {
    path: '/forms',
    component: Forms,
    exact: true
  },
  {
    path: '/houses',
    component: Houses,
    exact: true
  },
  {
    path: '/school/settings',
    component: SchoolSettingsRouting,
    exact: false
  },
  {
    path: '/activities',
    component: Sports,
    exact: true
  },
  {
    path: '/template/club',
    component: ClubTemplateQuestions,
    exact: true
  },
  {
    path: '/school/terms',
    component: SchoolTerms,
    exact: true
  },
  {
    path: '/template/consentRequests',
    component: ConsentRequestsTemplateQuestions,
    exact: true
  },
  {
    path: '/schoolGallery',
    component: SchoolGallery,
    exact: true
  },
  {
    path: '/subscriptions',
    component: Subscriptions,
    exact: true
  },
  {
    path: '/notificationSettings',
    component: NotificationSettings,
    exact: true
  },
  {
    path: '/venues',
    component: Venues,
    exact: true
  },
  {
    path: '/accounts',
    component: PaymentsAccountsRouting,
    exact: false
  },
  {
    path: '/integrations',
    component: Integrations,
    exact: false
  },
  {
    path: '/archive',
    component: RequestsArchive,
    exact: false
  },
  {
    path: '/payments',
    component: Transactions,
    exact: true
  },
  {
    path: '/clubs',
    component: ClubRouting,
    exact: false
  },
  {
    path: '/invitations',
    component: Invitations,
    exact: true
  },
  {
    path: '/invites',
    component: SchoolInvitesRouting,
    exact: false
  },
  {
    path: '/tournaments',
    component: TournamentRouting,
    exact: false
  },
  {
    path: '/events',
    component: SchoolEventsRouting,
    exact: false
  },
  {
    path: '/teams',
    component: SchoolTeamsRouting,
    exact: false
  },
  {
    path: '/leagues',
    component: LeagueRouting,
    exact: false
  },
  {
    path: '/calendar',
    component: Calendar,
    exact: true
  },
  {
    path: '/tournament/invite',
    component: TournamentInvite,
    exact: false
  }
];

export const managerRoutes: Route[] = [
  {
    path: '/profile',
    component: ProfileRouting,
    exact: false
  },
  {
    path: '/staff',
    component: Staffs,
    exact: true
  },
  {
    path: '/students',
    component: Students,
    exact: true
  },
  {
    path: '/clubParticipation',
    component: ClubParticipatingStudents,
    exact: true
  },
  {
    path: '/fixtureParticipation',
    component: FixtureParticipatingStudents,
    exact: true
  },
  {
    path: '/users',
    component: Users,
    exact: true
  },
  {
    path: '/governors',
    component: Governors,
    exact: true
  },
  {
    path: '/notifications',
    component: SchoolNotifications,
    exact: true
  },
  {
    path: '/generalMessages',
    component: GeneralMessageRouting,
    exact: false
  },
  {
    path: '/messages',
    component: MessagesRouting,
    exact: false
  },
  {
    path: '/news',
    component: NewsList,
    exact: true
  },
  {
    path: '/tags',
    component: UserTags,
    exact: true
  },
  {
    path: '/requests',
    component: RequestRouting,
    exact: false
  },
  // {
  //   path: '/dashboard',
  //   component: Dashboard,
  //   exact: true
  // },
  {
    path: '/forms',
    component: Forms,
    exact: true
  },
  {
    path: '/houses',
    component: Houses,
    exact: true
  },
  {
    path: '/archive',
    component: RequestsArchive,
    exact: false
  },
  {
    path: '/payments',
    component: Transactions,
    exact: true
  },
  {
    path: '/clubs',
    component: ClubRouting,
    exact: false
  },
  {
    path: '/invitations',
    component: Invitations,
    exact: true
  },
  {
    path: '/invites',
    component: SchoolInvitesRouting,
    exact: false
  },
  {
    path: '/events',
    component: SchoolEventsRouting,
    exact: false
  },
  {
    path: '/teams',
    component: SchoolTeamsRouting,
    exact: false
  },
  {
    path: '/leagues',
    component: LeagueRouting,
    exact: false
  },
  {
    path: '/calendar',
    component: Calendar,
    exact: true
  },
  {
    path: '/tournament/invite',
    component: TournamentInvite,
    exact: false
  }
];

export const teacherRoutes: Route[] = [
  {
    path: '/profile',
    component: ProfileRouting,
    exact: false
  },
  {
    path: '/clubParticipation',
    component: ClubParticipatingStudents,
    exact: true
  },
  {
    path: '/fixtureParticipation',
    component: FixtureParticipatingStudents,
    exact: true
  },
  {
    path: '/messages',
    component: MessagesRouting,
    exact: false
  },
  // {
  //   path: '/dashboard',
  //   component: Dashboard,
  //   exact: true
  // },
  {
    path: '/payments',
    component: Transactions,
    exact: true
  },
  {
    path: '/clubs',
    component: ClubRouting,
    exact: false
  },
  {
    path: '/invitations',
    component: Invitations,
    exact: true
  },
  {
    path: '/invites',
    component: SchoolInvitesRouting,
    exact: false
  },
  {
    path: '/events',
    component: SchoolEventsRouting,
    exact: false
  },
  {
    path: '/teams',
    component: SchoolTeamsRouting,
    exact: false
  },
  {
    path: '/leagues',
    component: LeagueRouting,
    exact: false
  },
  {
    path: '/calendar',
    component: Calendar,
    exact: true
  },
  {
    path: '/tournament/invite',
    component: TournamentInvite,
    exact: false
  }
];

export const coachRoutes: Route[] = [
  {
    path: '/profile',
    component: ProfileRouting,
    exact: false
  },
  {
    path: '/messages',
    component: MessagesRouting,
    exact: false
  },
  // {
  //   path: '/dashboard',
  //   component: Dashboard,
  //   exact: true
  // },
  {
    path: '/payments',
    component: Transactions,
    exact: true
  },
  {
    path: '/clubs',
    component: ClubRouting,
    exact: false
  },
  {
    path: '/invitations',
    component: Invitations,
    exact: true
  },
  {
    path: '/invites',
    component: SchoolInvitesRouting,
    exact: false
  },
  {
    path: '/events',
    component: SchoolEventsRouting,
    exact: false
  },
  {
    path: '/teams',
    component: SchoolTeamsRouting,
    exact: false
  },
  {
    path: '/leagues',
    component: LeagueRouting,
    exact: false
  },
  {
    path: '/calendar',
    component: Calendar,
    exact: true
  },
  {
    path: '/tournament/invite',
    component: TournamentInvite,
    exact: false
  }
];

export const adminSURoutes: Route[] = [
  {
    path: '/profile',
    component: ProfileRouting,
    exact: false
  },
  {
    path: '/tournaments',
    component: TournamentRoutingSU,
    exact: false
  },
  {
    path: '/championships',
    component: ChampionshipsRouting,
    exact: false
  },
  {
    path: '/highLevelTournaments',
    component: HighLevelTournamentsRoutingSU,
    exact: false
  },
  {
    path: '/schools',
    component: SchoolsRouting,
    exact: false
  },
  {
    path: '/schoolsAdmins',
    component: SchoolsAdmins,
    exact: true
  },
  {
    path: '/tournamentTags',
    component: TournamentTags,
    exact: false
  },
  {
    path: '/accounts',
    component: PaymentsAccountsRouting,
    exact: false
  },
  {
    path: '/payments',
    component: Transactions,
    exact: true
  },
  {
    path: '/records',
    component: Records,
    exact: true
  },
  {
    path: '/documents',
    component: SchoolFiles,
    exact: false
  },
  {
    path: '/venues',
    component: Venues,
    exact: true
  },
  {
    path: '/template/tournamentRequests',
    component: TournamentConfirmationRequests,
    exact: true
  },
  {
    path: '/notificationSettings',
    component: UnionNotificationSettings,
    exact: true
  },
  {
    path: '/invites',
    component: InvitesRouting,
    exact: false
  },
  {
    path: '/staff',
    component: StaffsSU,
    exact: true
  },
  {
    path: '/requests',
    component: RequestRouting,
    exact: false
  },
  {
    path: '/archive',
    component: RequestsArchive,
    exact: false
  },
  {
    path: '/tournament/invite',
    component: TournamentWarning,
    exact: false
  }
];

export const parentRoutes: Route[] = [
  {
    path: '/profile',
    component: ProfileRouting,
    exact: false
  },
  {
    path: '/calendar',
    component: ParentCalendar,
    exact: true
  },
  {
    path: '/events',
    component: ChildrenEventView,
    exact: false
  },
  {
    path: '/basket',
    component: Basket,
    exact: false
  },
  {
    path: '/messages',
    component: ParentMessagesRouting,
    exact: false
  },
  {
    path: '/consentRequests',
    component: ConsentRequestsRouting,
    exact: false
  },
  {
    path: '/clubBookings',
    component: ClubBookingsRouting,
    exact: false
  },
  {
    path: '/absenceRequests',
    component: AbsenceRequestsRouting,
    exact: false
  },
  {
    path: '/availabilityReports',
    component: AvailabilityReportsRouting,
    exact: false
  },
  {
    path: '/children',
    component: Children,
    exact: true
  },
  {
    path: '/tournament/invite',
    component: TournamentWarning,
    exact: false
  },
  {
    path: '/paymentSuccess',
    component: PaymentSuccess,
    exact: false
  }
];

export const studentRoutes: Route[] = [
  {
    path: '/profile',
    component: ProfileRouting,
    exact: false
  },
  {
    path: '/calendar',
    component: StudentCalendar,
    exact: true
  },
  {
    path: '/events',
    component: StudentEventView,
    exact: false
  },
  {
    path: '/messages',
    component: StudentMessagesRouting,
    exact: false
  },
  {
    path: '/clubBookings',
    component: StudentClubBookingsRouting,
    exact: false
  },
  {
    path: '/tournament/invite',
    component: TournamentWarning,
    exact: false
  },
  {
    path: '/availabilityReports',
    component: StudentAvailabilityReportsRouting,
    exact: false
  }
];
