import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import { parse } from 'query-string';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { AppUser } from 'Src/views/App/App';
import * as propz from 'propz';
import { StudentSummary } from 'Src/components/StudentSummary/StudentSummary';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere
} from 'Src/helpers/table/table';
import { SchoolStudent } from 'Src/models/schoolStudent';
import { Button } from 'Src/components/Button/Button';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE, DEFAULT_LIMIT, DEFAULT_SKIP, LIMIT } from 'Src/consts/table';
import { getBirthday, getGender } from 'Src/helpers/accessor/accessor';
import { SchoolForm } from 'Src/models/form';
import { History, Location } from 'history';
import {
  getAllSchoolUnionSchoolStudents,
  getSchoolUnionSchoolStudents,
  getSchoolUnionSchoolStudentsCount,
  addSchoolUnionSchoolStudent,
  deleteSchoolUnionSchoolStudent,
  updateSchoolUnionSchoolStudent,
  getAllSchoolUnionSchoolForms
} from 'Src/helpers/service/adminSU/schoolStudentsTable';
import {
  getSelectOptionForAge,
  getSelectOptionForForms,
  getSelectOptionForGender,
  getSelectOptionForBooleanQuestion
} from 'Src/helpers/table/select';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { STUDENT_TABS_UNION } from 'Src/consts/school';
import { getSchoolFromSU } from 'Src/helpers/service/adminSU/schoolAdminsTable';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { SchoolUnionSchoolStudentForm } from 'Src/components/SchoolUnionSchoolStudentForm/SchoolUnionSchoolStudentForm';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'firstName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['firstName']
  },
  {
    text: 'Surname',
    field: 'lastName',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['lastName']
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'Age',
    field: 'ages',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'ageGroup']
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  },
  {
    text: 'Birthday',
    field: 'birthday',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getBirthday
  }
];

interface State {
  items: SchoolStudent[];
  currentPage: number;
  selectedItems: SchoolStudent[];
  similarStudents: SchoolStudent[];
  identicalStudents: SchoolStudent[];
  studentToAdd: SchoolStudent;
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;

  isMessageModalOpen: boolean;

  isTagAssignModalOpen: boolean;
  isTagDeleteModalOpen: boolean;
  selectedTagId: string;

  currentTabIndex: number;
  isAddNextOfKinModalOpen: boolean;
  personIndex: number;
  isEditStudent: boolean;
  isEditStudentModalOpen: boolean;
  isCreateStudentsChain: boolean;
  isDeleteStudentModalOpen: boolean;

  isStudentParticipationInEventsReportModalOpen: boolean; //015
  isStudentParticipationStatisticsByActivitiesReportModalOpen: boolean; //071
  isClubListForStudentReportModalOpen: boolean; //019
  isSelectedStudentTargetCompletionReportModalOpen: boolean; //056

  errorMessage: string;
  isErrorModalOpen: boolean;

  forms: SchoolForm[];
  school: any;
  schoolName: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class SchoolStudents extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      similarStudents: [],
      identicalStudents: [],
      studentToAdd: undefined,
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',

      isMessageModalOpen: false,

      isTagAssignModalOpen: false,
      isTagDeleteModalOpen: false,
      selectedTagId: '',

      currentTabIndex: 0,
      isEditStudent: false,
      isEditStudentModalOpen: false,
      isCreateStudentsChain: false,
      isDeleteStudentModalOpen: false,

      isStudentParticipationInEventsReportModalOpen: false,
      isStudentParticipationStatisticsByActivitiesReportModalOpen: false,
      isClubListForStudentReportModalOpen: false,
      isSelectedStudentTargetCompletionReportModalOpen: false,

      errorMessage: '',
      isErrorModalOpen: false,

      forms: [],
      isAddNextOfKinModalOpen: false,
      personIndex: -1,
      school: undefined,
      schoolName: ''
    };
  }

  getSchoolId(): string {
    const { location } = this.props;
    const searchParams = parse(location.search);
    const schoolId = searchParams.school || this.state.school?.id;
    return schoolId;
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;
    const schoolId = this.getSchoolId();

    return BPromise.all([getSchoolFromSU(user, schoolId), getAllSchoolUnionSchoolForms(user, schoolId)]).then(
      ([school, forms]) => {
        this.setState({
          school: school,
          schoolName: school.name,
          forms: forms
        });
        return true;
      }
    );
  }

  setItems() {
    const { history, user } = this.props;
    const schoolId = this.getSchoolId();

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const options = {
      forms: this.state.forms
    };

    const where = getServerFieldSectionWhere(filters, options);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;

    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getSchoolUnionSchoolStudents(user, schoolId, serverQueryFilter);
    const getItemsCountPromise = getSchoolUnionSchoolStudentsCount(user, schoolId, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      const totalItems = countObj?.count?.count || 0;

      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: totalItems,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    const { filters, sortColumnsName, sortDirection } = this.state;
    const schoolId = this.getSchoolId();

    let search = [];

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);
    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    if (schoolId) {
      search.push(`school=${schoolId}`);
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters, forms } = this.state;
    const schoolId = this.getSchoolId();

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const options = {
      forms: forms
    };

    const where = getServerFieldSectionWhere(filters, options);

    getAllSchoolUnionSchoolStudents(user, schoolId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'forms') {
      nextFilters.ages = [];
    }

    if (filterField === 'ages') {
      nextFilters.forms = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    const schoolId = this.getSchoolId();

    if (!schoolId) {
      console.error('Cannot apply filter: Missing school ID.');
      return;
    }

    let search = [];
    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);
    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: `school=${schoolId}&${search.join('&')}`
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      isSelectAllChecked: false,
      selectedItems: []
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onBackClick = () => {
    const { history } = this.props;
    const schoolId = this.getSchoolId();

    history.push({
      pathname: '/schools',
      search: `school=${schoolId}`
    });
  };

  stylizeCell = cell => {
    const itemWithoutForm = propz.get(cell, ['rowData', 'formId'], '');
    const isHighliteRow = itemWithoutForm === '';
    return isHighliteRow ? 'text-danger' : '';
  };

  renderStudentsTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      forms,
      school
    } = this.state;

    const actionItems = school?.studentImportForAdminAllowed
      ? [
          {
            itemText: 'Add student',
            onItemClick: this.onStudentAddButtonClick,
            isActive: selectedItems.length === 0
          },
          {
            itemText: 'Delete student(s)',
            onItemClick: this.openDeleteStudentModal,
            isActive: selectedItems.length !== 0
          }
        ]
      : [];

    const user = this.props.user;
    const activeSchool = user.activeSchool;
    const ageGroupsNaming = activeSchool.ageGroupsNaming;

    const filterOptions = {
      ages: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForGender(),
      forms: getSelectOptionForForms(forms),
      specialEducationNeeds: getSelectOptionForBooleanQuestion(),
      pupilsPremium: getSelectOptionForBooleanQuestion(),
      freeSchoolMeals: getSelectOptionForBooleanQuestion()
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        stylizeCell={this.stylizeCell}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  renderSummary(): React.ReactNode {
    const isOneStudentSelected = this.isOneStudentSelected();

    if (!isOneStudentSelected) {
      return <div className="font-weight-bold">Select a student above</div>;
    }

    const { currentTabIndex, school } = this.state;

    const studentSummaryTabs = this.getStudentSummaryTabs();

    const isShowEditButton = currentTabIndex === 0 && school?.studentImportForAdminAllowed;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={STUDENT_TABS_UNION} currentTabIndex={currentTabIndex}>
        {isShowEditButton && (
          <Button
            onClick={() => {
              this.setState({
                isEditStudent: true,
                isEditStudentModalOpen: true
              });
            }}
            text={'Edit'}
            customClass={'mt-3 mb-3'}
          />
        )}
        {studentSummaryTabs}
      </GenericSummary>
    );
  }

  onStudentAddButtonClick = (): void => {
    this.setState({
      isEditStudentModalOpen: true
    });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      currentTabIndex: index
    });
  };

  onDeleteStudentsClick = (): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const schoolId = this.getSchoolId();

    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(selectedItem => deleteSchoolUnionSchoolStudent(user, schoolId, selectedItem.id))
    ).then(() => {
      this.setState({
        isDeleteStudentModalOpen: false,
        selectedItems: []
      });

      this.setItems();
    });
  };

  renderDeleteStudentModal = (): React.ReactNode => {
    const { isDeleteStudentModalOpen, selectedItems } = this.state;

    const selectedStudents = selectedItems.map(student => getFullName(student)).join(', ');

    return (
      <SimpleModal
        isOpen={isDeleteStudentModalOpen}
        title="Confirm the action"
        buttonText="Apply"
        body={`You are going to remove ${selectedStudents} students from your school account. Are you sure?`}
        onCloseClick={this.closeDeleteStudentModal}
        onButtonClick={this.onDeleteStudentsClick}
      />
    );
  };

  openDeleteStudentModal = (): void => {
    this.setState({
      isDeleteStudentModalOpen: true
    });
  };

  closeDeleteStudentModal = (): void => {
    this.setState({
      isDeleteStudentModalOpen: false
    });
  };

  getStudentSummaryTabs(): React.ReactNode {
    const { currentTabIndex, selectedItems } = this.state;

    switch (currentTabIndex) {
      case 0:
        return <StudentSummary student={selectedItems[0]} isUnionAdmin />;
      default:
        console.error('Students.tsx \n Wrong tab index ' + currentTabIndex);
        return null;
    }
  }

  isOneStudentSelected() {
    const selectedStudents = this.state.selectedItems;
    return selectedStudents.length === 1;
  }

  onStudentSaveButtonClick = (data: any, isContinue: boolean, isSavingSimilarStudentConfirmed) => {
    const { user } = this.props;
    const schoolId = this.getSchoolId();
    const { selectedItems, isCreateStudentsChain, isEditStudent } = this.state;
    const selectedItemsCount = selectedItems.length;
    const { firstName: studentToAddFirstName, lastName: studentToAddLastName, birthday: studentToAddBirthday } = data;

    this.setState({
      isLoading: true,
      identicalStudents: [],
      similarStudents: []
    });

    if (data.formId === '') {
      delete data.formId;
    }

    const filterForCheckExistingStudentWithoutBirthday = {
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP,
      where: {
        firstName: {
          like: studentToAddFirstName.trim(),
          options: 'i'
        },
        lastName: {
          like: studentToAddLastName.trim(),
          options: 'i'
        }
      }
    };

    const filterForCheckExistingStudentWithoutFirstName = {
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP,
      where: {
        lastName: {
          like: studentToAddLastName.trim(),
          options: 'i'
        },
        birthday: {
          $gte: new Date(studentToAddBirthday),
          $lte: new Date(studentToAddBirthday)
        }
      }
    };

    const filterForCheckExistingStudentWithoutLastName = {
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP,
      where: {
        firstName: {
          like: studentToAddFirstName.trim(),
          options: 'i'
        },
        birthday: {
          $gte: new Date(studentToAddBirthday),
          $lte: new Date(studentToAddBirthday)
        }
      }
    };

    const isStudentToAddWithoutBirthday = typeof studentToAddBirthday === 'undefined';

    let promises = [];

    if (isStudentToAddWithoutBirthday) {
      promises = [getSchoolUnionSchoolStudents(user, schoolId, filterForCheckExistingStudentWithoutBirthday)];
    } else {
      promises = [
        getSchoolUnionSchoolStudents(user, schoolId, filterForCheckExistingStudentWithoutBirthday),
        getSchoolUnionSchoolStudents(user, schoolId, filterForCheckExistingStudentWithoutFirstName),
        getSchoolUnionSchoolStudents(user, schoolId, filterForCheckExistingStudentWithoutLastName)
      ];
    }

    return BPromise.all(promises).then(response => {
      const allFoundSimilarStudents = response.reduce((result, item) => [...result, ...item], []);

      const allFoundSimilarStudentsUniq = Lazy(allFoundSimilarStudents)
        .uniq('id')
        .toArray();

      const identicalStudents = allFoundSimilarStudentsUniq.filter(similarStudent => {
        const {
          firstName: similarStudentFirstName,
          lastName: similarStudentLastName,
          birthday: similarStudentBirthday
        } = similarStudent;

        const similarStudentFirstNameFormatted = similarStudentFirstName.toLowerCase().trim();
        const similarStudentLastNameFormatted = similarStudentLastName.toLowerCase().trim();

        const studentToAddFirstNameFormatted = studentToAddFirstName.toLowerCase().trim();
        const studentToAddLastNameFormatted = studentToAddLastName.toLowerCase().trim();

        return (
          similarStudentFirstNameFormatted === studentToAddFirstNameFormatted &&
          similarStudentLastNameFormatted === studentToAddLastNameFormatted &&
          similarStudentBirthday === studentToAddBirthday
        );
      });

      const similarStudents = allFoundSimilarStudentsUniq.filter(similarStudent => {
        const {
          firstName: similarStudentFirstName,
          lastName: similarStudentLastName,
          birthday: similarStudentBirthday
        } = similarStudent;

        const similarStudentFirstNameFormatted = similarStudentFirstName.toLowerCase().trim();
        const similarStudentLastNameFormatted = similarStudentLastName.toLowerCase().trim();

        const studentToAddFirstNameFormatted = studentToAddFirstName.toLowerCase().trim();
        const studentToAddLastNameFormatted = studentToAddLastName.toLowerCase().trim();

        const isStudentToAddWithoutBirthday = typeof studentToAddBirthday === 'undefined';

        if (isStudentToAddWithoutBirthday) {
          return (
            similarStudentFirstNameFormatted === studentToAddFirstNameFormatted &&
            similarStudentLastNameFormatted === studentToAddLastNameFormatted
          );
        }

        return (
          (similarStudentFirstNameFormatted === studentToAddFirstNameFormatted &&
            similarStudentLastNameFormatted === studentToAddLastNameFormatted &&
            similarStudentBirthday !== studentToAddBirthday) ||
          (similarStudentFirstNameFormatted === studentToAddFirstNameFormatted &&
            similarStudentBirthday === studentToAddBirthday &&
            similarStudentLastNameFormatted !== studentToAddLastNameFormatted) ||
          (similarStudentLastNameFormatted === studentToAddLastNameFormatted &&
            similarStudentBirthday === studentToAddBirthday &&
            similarStudentFirstNameFormatted !== studentToAddFirstNameFormatted)
        );
      });

      const isIdenticalStudentsExist = identicalStudents.length > 0;

      if (
        (isSavingSimilarStudentConfirmed && !isIdenticalStudentsExist) ||
        allFoundSimilarStudentsUniq.length === 0 ||
        isEditStudent
      ) {
        switch (true) {
          case selectedItemsCount === 0 || isCreateStudentsChain:
            return addSchoolUnionSchoolStudent(user, this.getSchoolId(), data).then(student => {
              this.setState(prevState => ({
                isEditStudentModalOpen: isContinue,
                selectedItems: isContinue ? [student, ...prevState.selectedItems] : [student],
                isCreateStudentsChain: isContinue,
                similarStudents: [],
                identicalStudents: [],
                studentToAdd: undefined
              }));

              return this.setItems();
            });

          case selectedItemsCount === 1:
            const studentId = selectedItems[0].id;
            const nextOfKin = propz.get(selectedItems[0], ['nextOfKin'], []);
            const dataUpdated = { ...data, nextOfKin };
            return updateSchoolUnionSchoolStudent(user, this.getSchoolId(), studentId, dataUpdated).then(
              studentUpdated => {
                this.setState({
                  isEditStudentModalOpen: false,
                  isEditStudent: false,
                  selectedItems: [studentUpdated],
                  isCreateStudentsChain: false,
                  similarStudents: [],
                  identicalStudents: [],
                  studentToAdd: undefined
                });

                return this.setItems();
              }
            );

          default:
            return BPromise.resolve(true);
        }
      } else {
        this.setState({
          identicalStudents,
          similarStudents,
          studentToAdd: data,
          isLoading: false
        });
      }
    });
  };

  onStudentCancelEditButtonClick = (): void => {
    this.setState({
      isEditStudentModalOpen: false,
      isEditStudent: false,
      isCreateStudentsChain: false,
      similarStudents: [],
      identicalStudents: [],
      studentToAdd: undefined
    });
  };

  renderStudentFormModal(): React.ReactNode {
    const {
      isEditStudentModalOpen,
      selectedItems,
      isCreateStudentsChain,
      identicalStudents,
      similarStudents,
      studentToAdd,
      forms,
      isEditStudent
    } = this.state;
    const { user } = this.props;
    const schoolId = this.getSchoolId();
    const selectedItemsCount = selectedItems.length;
    const isSelectedItemExist = selectedItemsCount > 0;
    const isFormsExist = forms.length > 0;

    let modalTitle = selectedItemsCount === 0 || isCreateStudentsChain ? 'Add student' : 'Edit student';

    return (
      <SimpleModal isOpen={isEditStudentModalOpen} title={modalTitle}>
        <SchoolUnionSchoolStudentForm
          student={isSelectedItemExist ? selectedItems[0] : studentToAdd}
          onSubmit={this.onStudentSaveButtonClick}
          onCancel={this.onStudentCancelEditButtonClick}
          user={user}
          schoolId={schoolId}
          isCreateStudentsChain={isCreateStudentsChain}
          identicalStudents={identicalStudents}
          similarStudents={similarStudents}
          isFormsExist={isFormsExist}
          isEditStudent={isEditStudent}
        />
      </SimpleModal>
    );
  }

  render() {
    const {
      isLoading,
      isMessageModalOpen,
      isEditStudentModalOpen,
      isAddNextOfKinModalOpen,
      isStudentParticipationInEventsReportModalOpen,
      isStudentParticipationStatisticsByActivitiesReportModalOpen,
      isClubListForStudentReportModalOpen,
      isSelectedStudentTargetCompletionReportModalOpen,
      isErrorModalOpen,
      schoolName
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isMessageModalOpen ||
      isEditStudentModalOpen ||
      isErrorModalOpen ||
      isStudentParticipationInEventsReportModalOpen ||
      isStudentParticipationStatisticsByActivitiesReportModalOpen ||
      isClubListForStudentReportModalOpen ||
      isSelectedStudentTargetCompletionReportModalOpen ||
      isAddNextOfKinModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        <h2>{schoolName} Students</h2>
        <Button onClick={this.onBackClick} text={'Back'} customClass={'mt-3 mb-3'} />
        {this.renderStudentFormModal()}
        {this.renderDeleteStudentModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderStudentsTable()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
