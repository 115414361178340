import * as React from 'react';
import * as propz from 'propz';
import { useState, useEffect } from 'react';
import { REGISTRATION_STAFF_PRESET, RegistrationStaff } from '../Registration';
import { Button } from '../../../components/Button/Button';

interface Props {
  staff: RegistrationStaff;
  onStaffClick: (staff: RegistrationStaff) => void;
  school: any;
}

export function StaffRoleSelector(props: Props) {
  const { staff, onStaffClick, school } = props;

  const [staffPreset, changeStaffPreset] = useState(staff);

  const isSchoolUnion = propz.get(school, ['kind']) === 'SchoolUnion';
  const isAdminAllowed = isSchoolUnion
    ? true
    : propz.get(school, ['allowedPermissionPresets', REGISTRATION_STAFF_PRESET.ADMIN], false);
  const isManagerAllowed = isSchoolUnion
    ? false
    : propz.get(school, ['allowedPermissionPresets', REGISTRATION_STAFF_PRESET.MANAGER], false);
  const isCoachAllowed = isSchoolUnion
    ? false
    : propz.get(school, ['allowedPermissionPresets', REGISTRATION_STAFF_PRESET.COACH], false);
  const isTeacherAllowed = isSchoolUnion
    ? false
    : propz.get(school, ['allowedPermissionPresets', REGISTRATION_STAFF_PRESET.TEACHER], false);

  useEffect(() => {
    if (isSchoolUnion && staffPreset !== REGISTRATION_STAFF_PRESET.ADMIN) {
      changeStaffPreset(REGISTRATION_STAFF_PRESET.ADMIN);
    }
  }, [isSchoolUnion, staffPreset]);

  const isStaffPresetExist = typeof staffPreset !== 'undefined';

  return (
    <>
      <div className="font-weight-bold mb-4">
        Please select the role that best describes your responsibilities. This will help us tailor the platform to your
        needs.
      </div>

      {isAdminAllowed && (
        <div className="form-check mb-4">
          <input
            className="form-check-input"
            type="radio"
            name="admin"
            id="admin"
            value={REGISTRATION_STAFF_PRESET.ADMIN}
            checked={staffPreset === REGISTRATION_STAFF_PRESET.ADMIN}
            onChange={() => changeStaffPreset(REGISTRATION_STAFF_PRESET.ADMIN)}
          />
          <label className="form-check-label" htmlFor="admin">
            {isSchoolUnion
              ? "I need access to my union/association as I'm responsible for organizing or hosting competitions."
              : "I'm a school administrator / SLT, and I require full access to all features, including tools for managing school tournament participation."}
          </label>
        </div>
      )}

      {isManagerAllowed && (
        <div className="form-check mb-4">
          <input
            className="form-check-input"
            type="radio"
            name="manager"
            id="manager"
            value={REGISTRATION_STAFF_PRESET.MANAGER}
            checked={staffPreset === REGISTRATION_STAFF_PRESET.MANAGER}
            onChange={() => changeStaffPreset(REGISTRATION_STAFF_PRESET.MANAGER)}
          />
          <label className="form-check-label" htmlFor="manager">
            I'm a school manager and need access to all features except for core settings.
          </label>
        </div>
      )}

      {isCoachAllowed && (
        <div className="form-check mb-4">
          <input
            className="form-check-input"
            type="radio"
            name="coach"
            id="coach"
            value={REGISTRATION_STAFF_PRESET.COACH}
            checked={staffPreset === REGISTRATION_STAFF_PRESET.COACH}
            onChange={() => changeStaffPreset(REGISTRATION_STAFF_PRESET.COACH)}
          />
          <label className="form-check-label" htmlFor="coach">
            I'm a coach.
          </label>
        </div>
      )}

      {isTeacherAllowed && (
        <div className="form-check mb-4">
          <input
            className="form-check-input"
            type="radio"
            name="teacher"
            id="teacher"
            value={REGISTRATION_STAFF_PRESET.TEACHER}
            checked={staffPreset === REGISTRATION_STAFF_PRESET.TEACHER}
            onChange={() => changeStaffPreset(REGISTRATION_STAFF_PRESET.TEACHER)}
          />
          <label className="form-check-label" htmlFor="teacher">
            I'm a teacher.
          </label>
        </div>
      )}

      <Button
        disabled={!isStaffPresetExist}
        onClick={() => onStaffClick(staffPreset)}
        text="Continue"
        customClass="mt-3 mb-3"
      />
    </>
  );
}
