import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { Tournament } from 'Src/models/tournament';
import {
  assignPitchByTournamentIdAndEventId,
  deleteEvent,
  getAllTournamentEvents,
  getTournamentEvents,
  getTournamentEventsCount,
  unassignPitchByTournamentIdAndEventId
} from 'Src/helpers/service/adminSU/tournamentEventsTable';
import {
  getEventAges,
  getEventGender,
  getEventGeneratedName,
  getEventGroupName,
  getEventPitch,
  getEventStartTime,
  getEventStatus
} from 'Src/helpers/accessor/accessor';
import {
  getSelectOptionForSport,
  getSelectOptionForTournamentCustomGroups,
  getSelectOptionForTournamentEventAges,
  getSelectOptionForTournamentEventGender,
  getSelectOptionForTournamentEventStatus
} from 'Src/helpers/table/select';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { EventPitchForm } from 'Src/components/EventPitchForm/EventPitchForm';
import { isIndividualTournament, isTeamTournament } from 'Src/helpers/tournament/tournament';
import { SchoolEvent } from '../../../../models/event';
import { Sport } from '../../../../models/sport';
import { getAllSchoolTournamentEventSports } from '../../../../helpers/service/admin/tournamentEvents';
import { getTournamentEventNameWithLink, getTeamTournamentEventNameWithLink } from '../../../../helpers/cell/cell';
import { updateSchoolEvent } from 'Src/helpers/service/admin/schoolEvents';
import { Button } from 'Src/components/Button/Button';
import { createTeamScore, updateTeamScore } from 'Src/helpers/service/admin/eventResults';
import { isEventStatusFinished } from 'Src/helpers/event/event';
import { finishEvent } from 'Src/helpers/service/admin/event';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Sport',
    field: 'sport',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['sport', 'name']
  },
  {
    text: 'Event',
    field: 'generatedName',
    isSort: true,
    type: FILTER_TYPE.NONE,
    cell: getTournamentEventNameWithLink
  },
  {
    text: 'Start time',
    field: 'startTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getEventStartTime
  },
  {
    text: 'Age',
    field: 'tournamentAges',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventAges
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventGender
  },
  {
    text: 'Status',
    field: 'status',
    isSort: true,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getEventStatus
  }
];

interface State {
  items: SchoolEvent[];
  currentPage: number;
  selectedItems: SchoolEvent[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  basePath: string;
  isSelectAllChecked: boolean;
  tournament: Tournament;

  isPitchModalOpen: boolean;
  sports: Sport[];

  isInlineEditMode: boolean;
  modifiedStartTimes: { [eventId: string]: string };

  modifiedResults: { [eventId: string]: { score1: string; score2: string } };
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class SchoolUnionTournamentEvents extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      basePath: '',
      isSelectAllChecked: false,
      tournament: undefined,

      isPitchModalOpen: false,
      sports: [],

      isInlineEditMode: false,
      modifiedStartTimes: {},

      modifiedResults: {}
    };
  }

  pad = (n: number): string => (n < 10 ? '0' + n : n.toString());
  formatTimeOnly = (value: string): string => {
    const date = new Date(value);
    return `${this.pad(date.getHours())}:${this.pad(date.getMinutes())}`;
  };

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  getResultsForTeamTournament = (event: SchoolEvent): string => {
    if (
      event.results &&
      event.results.teamScore &&
      event.results.teamScore.length >= 2 &&
      event.invitedSchools &&
      event.invitedSchools.length >= 2
    ) {
      const teamScores = event.results.teamScore;
      const invitedSchool1Id = event.invitedSchools[0].id;

      const teamScoreObjects = teamScores.map(ts => {
        const team = event.embeddedTeams && event.embeddedTeams.find(t => t._id === ts.teamId);
        return { scoreObj: ts, team };
      });

      const ordered = teamScoreObjects.sort((a, b) => {
        if (a.team && a.team.schoolId === invitedSchool1Id) return -1;
        if (b.team && b.team.schoolId === invitedSchool1Id) return 1;
        return 0;
      });
      const [ts0, ts1] = ordered.map(o => o.scoreObj);

      const score1 = ts0.richScore && ts0.richScore.result !== undefined ? ts0.richScore.result : ts0.score;
      const score2 = ts1.richScore && ts1.richScore.result !== undefined ? ts1.richScore.result : ts1.score;

      const team1 = event.embeddedTeams && event.embeddedTeams.find(t => t._id === ts0.teamId);
      const team2 = event.embeddedTeams && event.embeddedTeams.find(t => t._id === ts1.teamId);
      const team1Name = team1 ? team1.name : 'Team 1';
      const team2Name = team2 ? team2.name : 'Team 2';

      return `${team1Name} vs ${team2Name}: ${score1}:${score2}`;
    }

    if (
      event.results &&
      event.results.schoolScore &&
      event.results.schoolScore.length >= 2 &&
      event.invitedSchools &&
      event.invitedSchools.length >= 2
    ) {
      const schoolScores = event.results.schoolScore;
      const score1 = schoolScores[0].score;
      const score2 = schoolScores[1].score;
      const school1 = event.invitedSchools && event.invitedSchools.find(s => s.id === schoolScores[0].schoolId);
      const school2 = event.invitedSchools && event.invitedSchools.find(s => s.id === schoolScores[1].schoolId);
      const school1Name = school1 ? school1.name : 'School 1';
      const school2Name = school2 ? school2.name : 'School 2';
      return `${school1Name} vs ${school2Name}: ${score1}:${score2}`;
    }
    return '';
  };

  getColumns(): ColumnDefinition[] {
    const { tournament, isInlineEditMode } = this.state;
    let baseColumns: ColumnDefinition[] = [];

    if (isTeamTournament(tournament)) {
      baseColumns = [
        {
          text: 'Sport',
          field: 'sport',
          isSort: true,
          type: FILTER_TYPE.MULTISELECT,
          accessor: ['sport', 'name']
        },
        {
          text: 'Event',
          field: 'generatedName',
          isSort: true,
          type: FILTER_TYPE.NONE,
          cell: (row: SchoolEvent) => {
            if (row.invitedSchools && row.invitedSchools.length === 2) {
              return getTeamTournamentEventNameWithLink(row, { user: this.props.user });
            }
            return getTournamentEventNameWithLink(row, { user: this.props.user });
          }
        },
        {
          text: 'Start time',
          field: 'startTime',
          isSort: true,
          type: FILTER_TYPE.DATE_INTERVAL,
          accessor: getEventStartTime
        },
        {
          text: 'Results',
          field: 'results',
          isSort: false,
          type: FILTER_TYPE.NONE,
          cell: (row: SchoolEvent) => <td>{this.getResultsForTeamTournament(row)}</td>
        },
        {
          text: 'Age',
          field: 'tournamentAges',
          isSort: true,
          type: FILTER_TYPE.MULTISELECT,
          accessor: getEventAges
        },
        {
          text: 'Gender',
          field: 'gender',
          isSort: true,
          type: FILTER_TYPE.MULTISELECT,
          accessor: getEventGender
        },
        {
          text: 'Group name',
          field: 'eventTournamentGroup',
          isSort: true,
          type: FILTER_TYPE.SELECT,
          accessor: getEventGroupName
        },
        {
          text: 'Status',
          field: 'status',
          isSort: true,
          type: FILTER_TYPE.MULTISELECT,
          accessor: getEventStatus
        },
        {
          text: 'Pitch',
          field: 'pitch',
          isSort: true,
          type: FILTER_TYPE.NONE,
          accessor: getEventPitch
        }
      ];
    } else {
      baseColumns = COLUMNS;
    }

    if (isInlineEditMode) {
      baseColumns = baseColumns.map(col => {
        if (col.field === 'startTime') {
          return {
            ...col,
            accessor: undefined,
            cell: (row: SchoolEvent) => {
              const currentValue = this.state.modifiedStartTimes[row.id] || this.formatTimeOnly(row.startTime);
              return (
                <input
                  type="time"
                  step="300"
                  className="inline-time"
                  value={currentValue}
                  onChange={e => this.handleStartTimeChange(row.id, e.target.value)}
                />
              );
            }
          };
        }
        if (col.field === 'results') {
          return {
            ...col,
            cell: (row: SchoolEvent) => {
              const eventId = row.id;
              const invitedSchools = row.invitedSchools || [];
              let score1 = '';
              let score2 = '';

              if (this.state.modifiedResults[eventId]) {
                score1 = this.state.modifiedResults[eventId].score1;
                score2 = this.state.modifiedResults[eventId].score2;
              } else if (
                row.results &&
                row.results.teamScore &&
                row.results.teamScore.length >= 2 &&
                invitedSchools.length >= 2
              ) {
                const sortedScores = row.results.teamScore.slice().sort((a, b) => {
                  const teamA = row.embeddedTeams.find(t => t._id === a.teamId);
                  const teamB = row.embeddedTeams.find(t => t._id === b.teamId);
                  const schoolAId = teamA ? teamA.schoolId : '';
                  const schoolBId = teamB ? teamB.schoolId : '';
                  const indexA = invitedSchools.findIndex(s => s.id === schoolAId);
                  const indexB = invitedSchools.findIndex(s => s.id === schoolBId);
                  return indexA - indexB;
                });
                score1 = sortedScores[0].richScore
                  ? sortedScores[0].richScore.result.toString()
                  : sortedScores[0].score.toString();
                score2 = sortedScores[1].richScore
                  ? sortedScores[1].richScore.result.toString()
                  : sortedScores[1].score.toString();
              }
              return (
                <td>
                  <div>
                    <input
                      type="number"
                      style={{ width: '50px' }}
                      value={score1}
                      onChange={e => this.handleResultChange(eventId, 'score1', e.target.value)}
                    />
                    <span> : </span>
                    <input
                      type="number"
                      style={{ width: '50px' }}
                      value={score2}
                      onChange={e => this.handleResultChange(eventId, 'score2', e.target.value)}
                    />
                  </div>
                </td>
              );
            }
          };
        }
        return col;
      });
    }

    return baseColumns;
  }

  handleResultChange = (eventId: string, key: 'score1' | 'score2', value: string): void => {
    this.setState(prevState => ({
      modifiedResults: {
        ...prevState.modifiedResults,
        [eventId]: {
          ...(prevState.modifiedResults[eventId] || { score1: '', score2: '' }),
          [key]: value
        }
      }
    }));
  };

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;
    return tournamentId;
  }

  setAdditionalItems() {
    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    return BPromise.all([
      getTournament(user, tournamentId),
      getAllSchoolTournamentEventSports(user, tournamentId)
    ]).then(([tournament, sports]) => {
      this.setState({
        tournament: tournament,
        sports: sports
      });

      return true;
    });
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(this.getColumns(), search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const tournamentId = this.getTournamentId();

    const getItemsPromise = getTournamentEvents(user, tournamentId, serverQueryFilter);
    const getItemsCountPromise = getTournamentEventsCount(user, tournamentId, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;
    const { history } = this.props;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const tournamentId = this.getTournamentId();

    getAllTournamentEvents(user, tournamentId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;
    const { history } = this.props;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = this.getColumns().find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    const { history } = this.props;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}&${search.join('&')}`,
      state: history.location.state
    });
  };

  onClearFilterClick = (): void => {
    const { history } = this.props;
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: this.state.basePath,
      search: `tournament=${tournamentId}`,
      state: history.location.state
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search, prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: prevPath,
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  onDeleteEventsClick = () => {
    const { selectedItems } = this.state;
    const user = this.props.user;
    const itemIds = selectedItems.map(item => item.id);

    this.setState({
      isLoading: true
    });

    BPromise.all(itemIds.map(itemId => deleteEvent(user, itemId))).then(res => {
      this.setState({
        isSelectAllChecked: false,
        selectedItems: []
      });
      this.setItems();
    });
  };

  toggleInlineEditMode = (): void => {
    this.setState(prevState => ({
      isInlineEditMode: !prevState.isInlineEditMode,
      modifiedStartTimes: prevState.isInlineEditMode ? {} : prevState.modifiedStartTimes
    }));
  };

  handleStartTimeChange = (eventId: string, newValue: string): void => {
    this.setState(prevState => ({
      modifiedStartTimes: {
        ...prevState.modifiedStartTimes,
        [eventId]: newValue
      }
    }));
  };

  saveModifiedInlineChanges = (): void => {
    const { modifiedStartTimes, modifiedResults, items } = this.state;
    const { user } = this.props;
    const updatePromises: Promise<any>[] = [];
    const eventsToFinish = new Set<string>();

    for (const eventId in modifiedStartTimes) {
      const newTime = modifiedStartTimes[eventId];
      const event = items.find(e => e.id === eventId);
      if (event) {
        const origDate = new Date(event.startTime);
        const [newHours, newMinutes] = newTime.split(':').map(Number);
        origDate.setHours(newHours, newMinutes, 0, 0);
        updatePromises.push(
          updateSchoolEvent(user, eventId, {
            startTime: origDate.toISOString()
          })
        );
      }
    }

    for (const eventId in modifiedResults) {
      const newScores = modifiedResults[eventId];
      const event = items.find(e => e.id === eventId);
      if (!event) continue;

      const invitedSchools = event.invitedSchools || [];
      if (invitedSchools.length < 2) continue;

      const teamForFirst = event.embeddedTeams.find(t => t.schoolId === invitedSchools[0].id);
      const teamForSecond = event.embeddedTeams.find(t => t.schoolId === invitedSchools[1].id);

      const resultForFirst =
        event.results && event.results.teamScore
          ? event.results.teamScore.find(ts => ts.teamId === (teamForFirst ? teamForFirst._id : null))
          : undefined;
      const resultForSecond =
        event.results && event.results.teamScore
          ? event.results.teamScore.find(ts => ts.teamId === (teamForSecond ? teamForSecond._id : null))
          : undefined;

      if (resultForFirst) {
        updatePromises.push(
          updateTeamScore(user, eventId, resultForFirst._id, {
            score: Number(newScores.score1)
          })
        );
      } else if (teamForFirst) {
        updatePromises.push(
          createTeamScore(user, eventId, {
            teamId: teamForFirst._id,
            score: Number(newScores.score1)
          })
        );
      }
      if (resultForSecond) {
        updatePromises.push(
          updateTeamScore(user, eventId, resultForSecond._id, {
            score: Number(newScores.score2)
          })
        );
      } else if (teamForSecond) {
        updatePromises.push(
          createTeamScore(user, eventId, {
            teamId: teamForSecond._id,
            score: Number(newScores.score2)
          })
        );
      }
      eventsToFinish.add(eventId);
    }

    BPromise.all(updatePromises)
      .then(() => {
        const finishPromises: Promise<any>[] = [];
        eventsToFinish.forEach(eventId => {
          const event = items.find(e => e.id === eventId);
          if (event && !isEventStatusFinished(event)) {
            finishPromises.push(finishEvent(user, eventId));
          }
        });
        return BPromise.all(finishPromises);
      })
      .then(() => {
        this.setState({
          modifiedStartTimes: {},
          modifiedResults: {},
          isInlineEditMode: false
        });
        this.setItems();
      })
      .catch(err => {
        console.error('Error updating inline changes:', err);
      });
  };

  formatDatetimeLocal = (value: string): string => {
    if (!value) return '';
    const date = new Date(value);
    const pad = (n: number) => (n < 10 ? '0' + n : n);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(
      date.getMinutes()
    )}`;
  };

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      tournament,
      sports
    } = this.state;

    const actionItems = [
      {
        itemText: 'Assign pitch to the event',
        onItemClick: this.onPitchAssignClick,
        isActive: selectedItems.length > 0 && !isIndividualTournament(tournament)
      },
      {
        itemText: 'Unassign pitch from event',
        onItemClick: this.onPitchUnassignClick,
        isActive: selectedItems.length > 0 && !isIndividualTournament(tournament)
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete event' : 'Delete events',
        onItemClick: this.onDeleteEventsClick,
        isActive: selectedItems.length > 0
      }
    ];

    const inlineEditControls = (
      <div className="inline-edit-controls p-3 mb-3 bg-light border rounded d-flex align-items-center">
        <div>
          <Button
            onClick={this.toggleInlineEditMode}
            text={this.state.isInlineEditMode ? 'Exit Inline Edit Mode' : 'Enter Inline Edit Mode'}
            customClass="btn btn-primary mr-2"
          />
          {this.state.isInlineEditMode && (
            <Button onClick={this.saveModifiedInlineChanges} text="Save Changes" customClass="btn btn-success" />
          )}
        </div>
        <div className="ml-auto">
          <small className="text-muted">Adjust event start times directly in the grid.</small>
        </div>
      </div>
    );

    const customGroups = propz.get(tournament, ['customGroups'], []);

    const filterOptions = isTeamTournament(tournament)
      ? {
          eventTournamentGroup: getSelectOptionForTournamentCustomGroups(customGroups),
          tournamentAges: getSelectOptionForTournamentEventAges(tournament),
          gender: getSelectOptionForTournamentEventGender(),
          status: getSelectOptionForTournamentEventStatus(),
          sport: getSelectOptionForSport(sports)
        }
      : {
          tournamentAges: getSelectOptionForTournamentEventAges(tournament),
          gender: getSelectOptionForTournamentEventGender(),
          status: getSelectOptionForTournamentEventStatus(),
          sport: getSelectOptionForSport(sports)
        };
    const gridTitle = typeof tournament !== 'undefined' ? `${tournament.name} / Events` : '';

    const { user } = this.props;

    return (
      <div>
        {inlineEditControls}
        <Grid2
          dataItems={items}
          filters={filters}
          currentPage={currentPage}
          total={total}
          isSelectAllChecked={isSelectAllChecked}
          isDataFiltered={isDataFiltered}
          sortDirection={sortDirection}
          sortColumnsName={sortColumnsName}
          isShowFilter={isShowFilter}
          dataItemsSelected={selectedItems}
          columns={this.getColumns()}
          actionItems={actionItems}
          options={filterOptions}
          onItemClick={this.onItemClick}
          onSortClick={this.onTableSortClick}
          onApplyFilterClick={this.onApplyFilterClick}
          onClearFilterClick={this.onClearFilterClick}
          onTableFilterChange={this.onTableFilterChange}
          onTableFilterClick={this.onTableFilterClick}
          setCurrentPageParams={this.setCurrentPageParams}
          onSelectAllClick={this.onSelectAllClick}
          onSelectAllOnPageClick={this.onSelectAllOnPageClick}
          onUnselectAllClick={this.onUnselectAllClick}
          gridTitle={gridTitle}
          goBack={this.goBack}
          user={user}
        />
      </div>
    );
  }

  onPitchUnassignClick = (): void => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(event => {
        const { id, tournamentId } = event;
        return unassignPitchByTournamentIdAndEventId(user, tournamentId, id);
      })
    ).then(res => {
      this.setState({
        isPitchModalOpen: false
      });
      this.setItems();
    });
  };

  onPitchAssignClick = (): void => {
    this.setState({
      isPitchModalOpen: true
    });
  };

  getPitchesFromTournament() {
    const { tournament } = this.state;

    const pitches = propz.get(tournament, ['pitches'], []);

    return pitches;
  }

  onPitchModalCloseClick = (): void => {
    this.setState({
      isPitchModalOpen: false
    });
  };

  onSaveButtonClick = data => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(event => {
        const { id, tournamentId } = event;
        return assignPitchByTournamentIdAndEventId(user, data, tournamentId, id);
      })
    ).then(items => {
      this.setState({
        isPitchModalOpen: false,
        selectedItems: items
      });
      this.setItems();
    });
  };

  renderAssignPitchModal(): React.ReactNode {
    const { isPitchModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isPitchModalOpen} title={'Assign pitch'}>
        <EventPitchForm
          onCancel={this.onPitchModalCloseClick}
          onSubmit={this.onSaveButtonClick}
          pitches={this.getPitchesFromTournament()}
        />
      </SimpleModal>
    );
  }

  render() {
    const { isLoading, isPitchModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isPitchModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderAssignPitchModal()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}
