import { AppUser } from 'Src/views/App/App';
import { ActionItem } from '../../components/ActionsButton/ActionsButton';
import { TournamentCustomGroup } from '../../models/tournament';
import { SchoolEvent } from '../../models/event';
import * as propz from 'propz';
import {
  getInviteForActiveSchool,
  isClubEvent,
  isEventCreatorSchoolUnion,
  isEventStatusAccepted,
  isEventStatusCanceled,
  isEventStatusCollectingInviteResponse,
  isEventStatusFinished,
  isEventStatusInvitesSent,
  isEventStatusRejected,
  isHousesEvent,
  isIndividualTournamentEvent,
  isInternalEvent,
  isInternalEventForTeamOrTwoOnTwoSportEvent,
  isInterSchoolsEvent,
  isInterSchoolsEventForTeamOrTwoOnTwoSportEvent,
  isInvitedSchool,
  isInviterSchool,
  isLeagueEvent,
  isMultipartyEvent,
  isNonTeamSportEvent,
  isNotExpiredEventTime,
  isSportNameContainCrossCountryRunning,
  isSportNameContainSwimming,
  isSportNameContainTriathlon,
  isTeamSportEvent,
  isTeamTournamentEvent,
  isTournamentEvent,
  isTournamentEventReadOnly
} from './event';
import {
  isUserActiveSchoolKindSchool,
  isUserActiveSchoolKindSU,
  isUserAdminSU,
  isUserParent,
  isUserSchoolWorker,
  isUserStudent
} from '../user/user';
import { EVENT_VENUE, VENUE_SERVER_TO_CLIENT_MAPPING } from '../../consts/venue';
import { isInviteStatusRejected } from '../invite/invite';
import {
  isTournamentSubtypeJuniorTriathlon,
  isTournamentSubtypeRegularIndividual,
  isTournamentSubtypeSwimming
} from '../tournament/tournament';
import { availabilityParentEnabled, availabilityStudentEnabled } from '../school/school';
import { EVENT_ACTION_BUTTONS, EVENT_ACTION_BUTTONS_MAPPING, EVENT_STATUS } from '../../consts/event';
import { EventActionButtonCallbacks } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/SchoolEventView';
import { POINTS_DISPLAY, SPORT_POINTS_SIGN } from '../../consts/sport';
import {
  DEFAULT_TIME_MASK,
  plainPointsToDistanceString,
  plainPointsToTimeString,
  stringDistanceToPoints,
  stringDistanceValidation,
  stringPlainValidation,
  stringTimeToPoints,
  validateStringTime
} from '../score/score2';
import * as Moment from 'moment';
import { DataHashMap } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/TeamInternalResults';
import { ValueHashMap } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/IndividualInternalResults';
import { SchoolEventFromForm } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventManager';
import { CricketDataHashMap } from 'Src/views/GenericView/AdminView/TeamsAndEvents/Events/SchoolEventView/ResultsViews/CricketInternalResults';
import {
  getFilterClubEventsForDay,
  getFilterFixtureEventsForDay,
  getFilterTournamentEventsForDay,
  getFilterTournamentsForDay,
  isClubEventsFilter,
  isSportEventsFilter,
  isTournamentEventsFilter,
  isTournamentsFilter
} from 'Src/helpers/calendar/calendar';
import { FilterType } from 'Src/views/GenericView/AdminView/Calendar/Calendar';
import { NON_FINISHED_PLAYER_RESULTS_ARRAY } from 'Src/consts/player';

const MIN_EVENT_PARTICIPANT_CREATOR_SU = 3;
const MIN_EVENT_PARTICIPANT_CREATOR_SCHOOL = 2;

export function getFilterForNextAndPreviousEvents(eventId: string, user: AppUser): any {
  const { activeSchoolId } = user;
  return {
    id: { $ne: eventId },
    $or: [
      {
        // internal events are always shown no matter what
        eventType: { $in: ['INTERNAL_HOUSES', 'INTERNAL_TEAMS'] }
      },
      {
        // external events created by me always visible with any status
        eventType: { $in: ['EXTERNAL_SCHOOLS'] },
        inviterSchoolId: activeSchoolId
      },
      {
        // external events where I'm invited shown only in some special statuses
        eventType: { $in: ['EXTERNAL_SCHOOLS'] },
        inviterSchoolId: { $ne: activeSchoolId },
        invitedSchoolIds: activeSchoolId,
        status: {
          $in: [
            'INVITES_SENT',
            'COLLECTING_INVITE_RESPONSE',
            'ACCEPTED',
            'REJECTED',
            'FINISHED',
            'CANCELED',
            'COLLECTING_INVITE_RESPONSE'
          ]
        }
      }
    ]
  };
}

export function getNextEventFilter(
  eventId: string,
  startTime: Date,
  user: AppUser,
  calendarFilterType?: FilterType
): any {
  const filterForNextAndPreviousEvents = getFilterForNextAndPreviousEvents(eventId, user);

  const isCalendarFilterTypeExist = typeof calendarFilterType !== 'undefined';

  let calendarFilter: any = {};

  switch (true) {
    case isSportEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterFixtureEventsForDay({});

      break;

    case isClubEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterClubEventsForDay({});

      break;

    case isTournamentEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterTournamentEventsForDay({});

      break;

    case isTournamentsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterTournamentsForDay(startTime);

      break;
  }

  return {
    limit: 1,
    order: 'startTime ASC',
    where: {
      startTime: { $gte: startTime },
      ...filterForNextAndPreviousEvents,
      ...calendarFilter.where
    }
  };
}

export function getPreviousEventFilter(
  eventId: string,
  startTime: Date,
  user: AppUser,
  calendarFilterType?: FilterType
): any {
  const filterForNextAndPreviousEvents = getFilterForNextAndPreviousEvents(eventId, user);

  const isCalendarFilterTypeExist = typeof calendarFilterType !== 'undefined';

  let calendarFilter: any = {};

  switch (true) {
    case isSportEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterFixtureEventsForDay({});

      break;

    case isClubEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterClubEventsForDay({});

      break;

    case isTournamentEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterTournamentEventsForDay({});

      break;

    case isTournamentsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterTournamentsForDay(startTime);

      break;
  }

  return {
    limit: 1,
    order: 'startTime DESC',
    where: {
      startTime: { $lte: startTime },
      ...filterForNextAndPreviousEvents,
      ...calendarFilter.where
    }
  };
}

export function getFilterForNextAndPreviousParentEvents(eventId: string, childIdList: string[]): any {
  const eventAcceptableStatusArray = [
    EVENT_STATUS.INVITES_SENT,
    EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
    EVENT_STATUS.ACCEPTED,
    EVENT_STATUS.FINISHED,
    EVENT_STATUS.CANCELED
  ];

  return {
    status: {
      $in: eventAcceptableStatusArray
    },
    id: { $ne: eventId },
    childIdList
  };
}

export function getNextParentEventFilter(
  eventId: string,
  selectedDate: Date,
  childIdList,
  calendarFilterType?: FilterType
): any {
  const filterForNextAndPreviousEvents = getFilterForNextAndPreviousParentEvents(eventId, childIdList);

  const isCalendarFilterTypeExist = typeof calendarFilterType !== 'undefined';

  let calendarFilter: any = {};

  switch (true) {
    case isSportEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterFixtureEventsForDay({});

      break;

    case isClubEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterClubEventsForDay({});

      break;

    case isTournamentEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterTournamentEventsForDay({});

      break;
  }

  return {
    limit: 1,
    order: 'startTime ASC',
    where: {
      startTime: { $gte: selectedDate },
      ...filterForNextAndPreviousEvents,
      ...calendarFilter.where
    }
  };
}

export function getPreviousParentEventFilter(
  eventId: string,
  selectedDate: Date,
  childIdList,
  calendarFilterType?: FilterType
): any {
  const filterForNextAndPreviousEvents = getFilterForNextAndPreviousParentEvents(eventId, childIdList);

  const isCalendarFilterTypeExist = typeof calendarFilterType !== 'undefined';

  let calendarFilter: any = {};

  switch (true) {
    case isSportEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterFixtureEventsForDay({});

      break;

    case isClubEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterClubEventsForDay({});

      break;

    case isTournamentEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterTournamentEventsForDay({});

      break;
  }

  return {
    limit: 1,
    order: 'startTime DESC',
    where: {
      startTime: { $lte: selectedDate },
      ...filterForNextAndPreviousEvents,
      ...calendarFilter.where
    }
  };
}

export function getFilterForNextAndPreviousStudentEvents(eventId: string, user: AppUser): any {
  const eventAcceptableStatusArray = [
    EVENT_STATUS.INVITES_SENT,
    EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
    EVENT_STATUS.ACCEPTED,
    EVENT_STATUS.FINISHED,
    EVENT_STATUS.CANCELED
  ];

  return {
    status: {
      $in: eventAcceptableStatusArray
    },
    id: { $ne: eventId }
  };
}

export function getNextStudentEventFilter(
  eventId: string,
  startTime: Date,
  user: AppUser,
  calendarFilterType?: FilterType
): any {
  const filterForNextAndPreviousEvents = getFilterForNextAndPreviousStudentEvents(eventId, user);

  const isCalendarFilterTypeExist = typeof calendarFilterType !== 'undefined';

  let calendarFilter: any = {};

  switch (true) {
    case isSportEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterFixtureEventsForDay({});

      break;

    case isClubEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterClubEventsForDay({});

      break;

    case isTournamentEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterTournamentEventsForDay({});

      break;
  }

  return {
    limit: 1,
    order: 'startTime ASC',
    where: {
      ...filterForNextAndPreviousEvents,
      ...calendarFilter.where,
      startTime: { $gte: startTime }
    }
  };
}

export function getPreviousStudentEventFilter(
  eventId: string,
  startTime: Date,
  user: AppUser,
  calendarFilterType?: FilterType
): any {
  const filterForNextAndPreviousEvents = getFilterForNextAndPreviousStudentEvents(eventId, user);

  const isCalendarFilterTypeExist = typeof calendarFilterType !== 'undefined';

  let calendarFilter: any = {};

  switch (true) {
    case isSportEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterFixtureEventsForDay({});

      break;

    case isClubEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterClubEventsForDay({});

      break;

    case isTournamentEventsFilter(calendarFilterType) && isCalendarFilterTypeExist:
      calendarFilter = getFilterTournamentEventsForDay({});

      break;
  }

  return {
    limit: 1,
    order: 'startTime DESC',
    where: {
      ...filterForNextAndPreviousEvents,
      ...calendarFilter.where,
      startTime: { $lte: startTime }
    }
  };
}

export function getActionList(user: AppUser, event: SchoolEvent, callbacks: EventActionButtonCallbacks): ActionItem[] {
  let actions = [];

  const { activeSchoolId } = user;
  const { tournament, invitedSchoolIds, individuals, teamsData } = event;

  const isUserSchoolWorkerCondition = isUserSchoolWorker(user);
  const isUserAdminSUCondition = isUserAdminSU(user);
  const isUserActiveSchoolKindSchoolCondition = isUserActiveSchoolKindSchool(user);
  const isUserActiveSchoolKindSUCondition = isUserActiveSchoolKindSU(user);

  const isMultipartyEventCondition = isMultipartyEvent(event);

  const isInterSchoolsEventCondition = isInterSchoolsEvent(event);
  const isHousesEventCondition = isHousesEvent(event);
  const isInternalEventCondition = isInternalEvent(event);
  const isInternalOrHousesEventCondition = isInternalEventCondition || isHousesEventCondition;

  const isInterSchoolsEventForTeamOrTwoOnTwoSportEventCondition = isInterSchoolsEventForTeamOrTwoOnTwoSportEvent(event);
  const isInternalEventForTeamOrTwoOnTwoSportEventCondition = isInternalEventForTeamOrTwoOnTwoSportEvent(event);

  const isInviterSchoolCondition = isInviterSchool(event, activeSchoolId);
  const isInvitedSchoolCondition = isInvitedSchool(event, activeSchoolId);

  const isEventStatusRejectedCondition = isEventStatusRejected(event);
  const isEventStatusCanceledCondition = isEventStatusCanceled(event);
  const isEventStatusFinishedCondition = isEventStatusFinished(event);
  const isEventStatusAcceptedCondition = isEventStatusAccepted(event);
  const isEventStatusInvitesSentCondition = isEventStatusInvitesSent(event);
  const isEventStatusCollectingInviteResponseCondition = isEventStatusCollectingInviteResponse(event);

  const invite = getInviteForActiveSchool(event, activeSchoolId);
  const isInviteStatusRejectedCondition = isInviteStatusRejected(invite);

  const isTournamentEventReadOnlyCondition = isTournamentEventReadOnly(event);
  const isTeamTournamentEventCondition = isTeamTournamentEvent(event);
  const isIndividualTournamentEventCondition = isIndividualTournamentEvent(event);

  const isTournamentEventCondition = isTournamentEvent(event);
  const isClubEventCondition = isClubEvent(event);
  const isLeagueEventCondition = isLeagueEvent(event);

  const isTournamentSubtypeSwimmingCondition = isTournamentSubtypeSwimming(tournament);
  const isTournamentSubtypeRegularIndividualCondition = isTournamentSubtypeRegularIndividual(tournament);
  const isTournamentSubtypeJuniorTriathlonCondition = isTournamentSubtypeJuniorTriathlon(tournament);

  const isEventCreatorSchoolUnionCondition = isEventCreatorSchoolUnion(event);
  const minNumberParticipants = isEventCreatorSchoolUnionCondition
    ? MIN_EVENT_PARTICIPANT_CREATOR_SU
    : MIN_EVENT_PARTICIPANT_CREATOR_SCHOOL;
  const isNumberInvitedSchoolsLessThanMinNumberParticipants = invitedSchoolIds.length < minNumberParticipants;

  const isSportNameContainSwimmingCondition = isSportNameContainSwimming(event);
  const isSportNameContainCrossCountryRunningCondition = isSportNameContainCrossCountryRunning(event);
  const isSportNameContainTriathlonCondition = isSportNameContainTriathlon(event);

  const isStudentCondition = isUserStudent(user);
  const isParentCondition = isUserParent(user);

  const availabilityParentEnabledCondition = availabilityParentEnabled(user);
  const availabilityStudentEnabledCondition = availabilityStudentEnabled(user);

  const isAvailableForParentCondition = availabilityParentEnabledCondition && isParentCondition;
  const isAvailableForStudentCondition = availabilityStudentEnabledCondition && isStudentCondition;

  const isAvailableForStudentOrParentCondition = isAvailableForStudentCondition || isAvailableForParentCondition;

  const isIndividualSportEventCondition = isNonTeamSportEvent(event);
  const isTeamSportEventCondition = isTeamSportEvent(event);
  const isTeamOrIndividualSportEventCondition = isIndividualSportEventCondition || isTeamSportEventCondition;

  const invitedSchoolIdsFilteredByIndividuals = invitedSchoolIds.filter(invitedSchoolId => {
    const isEveryIndividualsNotEqualSchoolId = individuals.every(individual => individual.schoolId !== invitedSchoolId);
    return isEveryIndividualsNotEqualSchoolId;
  });

  const invitedSchoolIdsFilteredByTeams = invitedSchoolIds.filter(invitedSchoolId => {
    const isEveryTeamsNotEqualSchoolId = teamsData.every(team => team.schoolId !== invitedSchoolId);
    return isEveryTeamsNotEqualSchoolId;
  });

  const isExistSchoolsWithEmptyIndividuals = invitedSchoolIdsFilteredByIndividuals.length > 0;
  const isExistSchoolsWithEmptyTeams = invitedSchoolIdsFilteredByTeams.length > 0;

  const isIndividualEventAndIsExistSchoolsWithEmptyIndividuals =
    isNonTeamSportEvent(event) && isExistSchoolsWithEmptyIndividuals;
  const isTeamEventAndIsExistSchoolsWithEmptyTeams = isTeamSportEvent(event) && isExistSchoolsWithEmptyTeams;

  const isTeamTournamentEventAndReadOnlyCondition =
    isTournamentEventReadOnlyCondition && isTeamTournamentEventCondition;

  const isIndividualTournamentEventAndReadOnlyCondition =
    isIndividualTournamentEventCondition && isTournamentEventReadOnlyCondition;

  const isTournamentSubtypeRegularIndividualOrSwimmingOrJuniorTriathlonCondition =
    isTournamentSubtypeSwimmingCondition ||
    isTournamentSubtypeRegularIndividualCondition ||
    isTournamentSubtypeJuniorTriathlonCondition;

  const isInterSchoolsOrInternalEventForTeamOrTwoOnTwoSportEventCondition =
    isInterSchoolsEventForTeamOrTwoOnTwoSportEventCondition || isInternalEventForTeamOrTwoOnTwoSportEventCondition;

  const isUserActiveSchoolKindSchoolAndNotTournamentEventCondition =
    isUserActiveSchoolKindSchoolCondition && !isTournamentEventCondition;
  const isUserActiveSchoolKindSchoolAndNotTournamentEventOrIsUserActiveSchoolKindSUCondition =
    isUserActiveSchoolKindSchoolAndNotTournamentEventCondition || isUserActiveSchoolKindSUCondition;

  const isNotExpiredEventTimeCondition = isNotExpiredEventTime(event);

  if (isUserActiveSchoolKindSUCondition) {
    if (isTournamentEventCondition) {
      actions.push(EVENT_ACTION_BUTTONS.EDIT_EVENT_SETTINGS);
    }

    if (isIndividualTournamentEventCondition) {
      /*      actions.push(EVENT_ACTION_BUTTONS.CLONE_TOURNAMENT_EVENT);*/
      actions.push(EVENT_ACTION_BUTTONS.ADD_SCHOOL);
    }

    //one text but different actions
    if (isTournamentEventCondition) {
      actions.push(EVENT_ACTION_BUTTONS.REMOVE_SCHOOLS);
      actions.push(EVENT_ACTION_BUTTONS.SEND_EVENT_NOTIFICATION);
    }

    if (isSportNameContainCrossCountryRunningCondition || isSportNameContainTriathlonCondition) {
      actions.push(EVENT_ACTION_BUTTONS.IMPORT_RESULTS_FROM_QUANTUM);
    }

    if (isSportNameContainTriathlonCondition) {
      actions.push(EVENT_ACTION_BUTTONS.EXPORT_START_LIST_TO_MACSHA_ONE_4_ALL);
      actions.push(EVENT_ACTION_BUTTONS.IMPORT_RESULTS_FROM_MACSHA_ONE_4_ALL);
    }
  }

  if (isUserSchoolWorkerCondition) {
    if (!isTournamentEvent(event)) {
      actions.push(EVENT_ACTION_BUTTONS.EDIT_EVENT_SETTINGS);
    }

    if (!isClubEvent(event) && !isTournamentEvent(event)) {
      actions.push(EVENT_ACTION_BUTTONS.DUPLICATE_EVENT);
    }

    if (isIndividualSportEventCondition && isLeagueEventCondition && isInternalOrHousesEventCondition) {
      actions.push(EVENT_ACTION_BUTTONS.ADD_VIDEO);
      actions.push(EVENT_ACTION_BUTTONS.SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS);
      actions.push(EVENT_ACTION_BUTTONS.ARBITER_BOARD);
    }

    if (
      isMultipartyEventCondition &&
      isInterSchoolsEventCondition &&
      isInviterSchoolCondition &&
      !isEventStatusRejectedCondition &&
      !isEventStatusCanceledCondition &&
      !isEventStatusFinishedCondition
    ) {
      actions.push(EVENT_ACTION_BUTTONS.ADD_SCHOOL);
    }

    if (
      isMultipartyEventCondition &&
      isInterSchoolsOrInternalEventForTeamOrTwoOnTwoSportEventCondition &&
      !isEventStatusFinishedCondition &&
      !isEventStatusRejectedCondition &&
      !isEventStatusCanceledCondition &&
      !isInviteStatusRejectedCondition
    ) {
      actions.push(EVENT_ACTION_BUTTONS.ADD_TEAM);
    }
    if (
      !isClubEventCondition &&
      isEventStatusAcceptedCondition &&
      !isTeamTournamentEventAndReadOnlyCondition &&
      isUserActiveSchoolKindSchoolAndNotTournamentEventOrIsUserActiveSchoolKindSUCondition
    ) {
      actions.push(EVENT_ACTION_BUTTONS.ADD_RESULTS);
    }

    if (
      isUserActiveSchoolKindSchoolCondition &&
      !isEventStatusRejectedCondition &&
      !isEventStatusCanceledCondition &&
      !isInviteStatusRejectedCondition
    ) {
      actions.push(EVENT_ACTION_BUTTONS.SEND_CONSENT_REQUEST);
    }

    if (
      isEventStatusFinishedCondition &&
      isUserActiveSchoolKindSchoolAndNotTournamentEventOrIsUserActiveSchoolKindSUCondition &&
      !isTeamTournamentEventAndReadOnlyCondition
    ) {
      actions.push(EVENT_ACTION_BUTTONS.UPDATE_RESULTS);
    }

    if (
      isEventStatusFinishedCondition &&
      isUserActiveSchoolKindSchoolAndNotTournamentEventOrIsUserActiveSchoolKindSUCondition &&
      (isIndividualSportEventCondition || (isTeamSportEventCondition && event.tournamentId))
    ) {
      actions.push(EVENT_ACTION_BUTTONS.UPDATE_POINTS);
    }

    if (
      (isInviterSchoolCondition || (isInvitedSchoolCondition && isNumberInvitedSchoolsLessThanMinNumberParticipants)) &&
      !isEventStatusFinishedCondition &&
      !isEventStatusRejectedCondition &&
      !isEventStatusCanceledCondition &&
      !isInviteStatusRejectedCondition &&
      isUserActiveSchoolKindSchoolAndNotTournamentEventOrIsUserActiveSchoolKindSUCondition
    ) {
      actions.push(EVENT_ACTION_BUTTONS.CANCEL_EVENT);
      actions.push(EVENT_ACTION_BUTTONS.CANCEL_EVENT_AND_EDIT_NOTIFICATION_LIST);
    }

    if (isEventStatusCanceledCondition && isInviterSchoolCondition && !isInviteStatusRejectedCondition) {
      actions.push(EVENT_ACTION_BUTTONS.UNCANCEL_EVENT);
    }

    if (
      !isInviteStatusRejectedCondition &&
      isInviterSchoolCondition &&
      !isEventStatusFinishedCondition &&
      !isEventStatusAcceptedCondition &&
      !isEventStatusInvitesSentCondition &&
      !isEventStatusCollectingInviteResponseCondition
    ) {
      actions.push(EVENT_ACTION_BUTTONS.DELETE_EVENT);
    }

    if (
      isIndividualTournamentEventCondition &&
      isUserActiveSchoolKindSUCondition &&
      (isIndividualEventAndIsExistSchoolsWithEmptyIndividuals || isTeamEventAndIsExistSchoolsWithEmptyTeams)
    ) {
      actions.push(EVENT_ACTION_BUTTONS.CLEAN_SCHOOL_LIST);
    }
    //one text but different actions
    if (!isUserActiveSchoolKindSUCondition) {
      actions.push(EVENT_ACTION_BUTTONS.SEND_EVENT_NOTIFICATION);
    }
  }

  if (isAvailableForStudentOrParentCondition && !isEventStatusFinishedCondition && isNotExpiredEventTimeCondition) {
    actions.push(EVENT_ACTION_BUTTONS.REPORT_AVAILABILITY);
  }

  if (
    isIndividualSportEventCondition &&
    !isIndividualTournamentEventAndReadOnlyCondition &&
    !isParentCondition &&
    !isStudentCondition &&
    !isUserSchoolWorkerCondition
  ) {
    actions.push(EVENT_ACTION_BUTTONS.IMPORT_RESULTS);
  }

  if (
    isSportNameContainSwimmingCondition &&
    !isIndividualTournamentEventAndReadOnlyCondition &&
    !isUserSchoolWorkerCondition
  ) {
    actions.push(EVENT_ACTION_BUTTONS.IMPORT_RESULTS_FROM_QUANTUM_AQ);
  }

  const actionList: ActionItem[] = actions.map(action => ({
    itemText: EVENT_ACTION_BUTTONS_MAPPING[action],
    onItemClick: callbacks[action],
    isActive: true
  }));

  return actionList;
}

export function getActionListWithSubItems(actions) {
  let actionsAreSubItems = [];

  const actionsAreNotSubItems = actions.filter(action => {
    const { itemText } = action;

    return (
      itemText !== EVENT_ACTION_BUTTONS_MAPPING.ADD_SCHOOL &&
      itemText !== EVENT_ACTION_BUTTONS_MAPPING.ADD_TEAM &&
      itemText !== EVENT_ACTION_BUTTONS_MAPPING.SEND_EVENT_NOTIFICATION &&
      itemText !== EVENT_ACTION_BUTTONS_MAPPING.SEND_CONSENT_REQUEST &&
      itemText !== EVENT_ACTION_BUTTONS_MAPPING.ADD_VIDEO &&
      itemText !== EVENT_ACTION_BUTTONS_MAPPING.ARBITER_BOARD &&
      itemText !== EVENT_ACTION_BUTTONS_MAPPING.SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS
    );
  });

  const teamsSubItems = actions.filter(action => {
    const { itemText } = action;

    return itemText === EVENT_ACTION_BUTTONS_MAPPING.ADD_SCHOOL || itemText === EVENT_ACTION_BUTTONS_MAPPING.ADD_TEAM;
  });

  const connectSubItems = actions.filter(action => {
    const { itemText } = action;

    return (
      itemText === EVENT_ACTION_BUTTONS_MAPPING.SEND_EVENT_NOTIFICATION ||
      itemText === EVENT_ACTION_BUTTONS_MAPPING.SEND_CONSENT_REQUEST
    );
  });

  const challengeManagementSubItems = actions.filter(action => {
    const { itemText } = action;

    return (
      itemText === EVENT_ACTION_BUTTONS_MAPPING.ADD_VIDEO ||
      itemText === EVENT_ACTION_BUTTONS_MAPPING.ARBITER_BOARD ||
      itemText === EVENT_ACTION_BUTTONS_MAPPING.SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS
    );
  });

  teamsSubItems.length > 0 && actionsAreSubItems.push(createActionListObjectWithSubItems('Teams', teamsSubItems));

  connectSubItems.length > 0 && actionsAreSubItems.push(createActionListObjectWithSubItems('Connect', connectSubItems));

  challengeManagementSubItems.length > 0 &&
    actionsAreSubItems.push(createActionListObjectWithSubItems('Challenge management', challengeManagementSubItems));

  return [...actionsAreNotSubItems, ...actionsAreSubItems];
}

function createActionListObjectWithSubItems(itemText, subItems) {
  return {
    itemText,
    isActive: true,
    subItems
  };
}

export function getPitchNameFromTournamentByPitchId(tournament, pitchId: string): string {
  const pitches = tournament.pitches || [];
  const pitch = pitches.find(tournamentPitch => tournamentPitch.id === pitchId);

  return typeof pitch !== 'undefined' ? pitch.name : '';
}

export function getTournamentGroupNameByGroupId(groupId: string, customGroups: TournamentCustomGroup[]): string {
  const groupIndex = customGroups.findIndex(customGroup => customGroup.id === groupId);
  if (groupIndex === -1) {
    return '';
  }

  const group = customGroups[groupIndex];
  const groupName = group.name;

  return groupName;
}

export function getSchoolEventLocation(event: SchoolEvent, user: AppUser): string {
  const { activeSchool, activeSchoolId } = user;
  const venue = event.venue;
  const inviterVenue = propz.get(venue, ['venueType'], EVENT_VENUE.TBD);

  let eventLocation = '';

  if (isClubEvent(event)) {
    const schoolPostcodeId = propz.get(activeSchool, ['postcodeId'], undefined);
    const venueName = propz.get(venue, ['placeData', 'name']);

    switch (true) {
      case typeof venueName !== 'undefined':
        eventLocation = `${venueName} `;
        break;
      case typeof schoolPostcodeId !== 'undefined' && schoolPostcodeId === venue.postcodeId:
        eventLocation = `School, `;
        break;
      case inviterVenue === EVENT_VENUE.TBD:
        eventLocation = EVENT_VENUE.TBD;
        break;
      default:
        break;
    }

    const postcode = propz.get(venue, ['postcodeData', 'postcode']);
    if (typeof postcode !== 'undefined') {
      eventLocation += `(${postcode})`;
    }

    return eventLocation;
  } else {
    let eventLocationServer;
    const isActiveSchoolInviter = activeSchoolId === event.inviterSchoolId;

    if (isActiveSchoolInviter) {
      eventLocationServer = inviterVenue;
    } else {
      switch (inviterVenue) {
        case EVENT_VENUE.HOME:
          eventLocationServer = EVENT_VENUE.AWAY;
          break;
        case EVENT_VENUE.AWAY:
          eventLocationServer = EVENT_VENUE.HOME;
          break;
        default:
          eventLocationServer = inviterVenue;
          break;
      }
    }

    eventLocation = VENUE_SERVER_TO_CLIENT_MAPPING[eventLocationServer];

    const postcode = propz.get(venue, ['postcodeData', 'postcode']);
    if (typeof postcode !== 'undefined') {
      eventLocation += `, ${postcode}`;
    }
    const venueName = propz.get(venue, ['placeData', 'name']);
    if (typeof venueName !== 'undefined') {
      eventLocation += `, ${venueName}`;
    }
    return eventLocation;
  }
}

export function getMask(mask: string): boolean | (string | RegExp)[] {
  return typeof mask === 'undefined'
    ? false
    : mask.split('').map(char => {
        if (char === 'h' || char === 'k' || char === 'm' || char === 's' || char === 'c') {
          return /\d/;
        } else {
          return char;
        }
      });
}

export function getIndividualScoreByStudent(event: SchoolEvent, userId: string, permissionId: string): number {
  const userScoreDataIndex = event.results.individualScore.findIndex(
    userScoreData => userScoreData.userId === userId && userScoreData.permissionId === permissionId
  );

  if (userScoreDataIndex === -1) {
    return 0;
  } else {
    if (typeof event.results.individualScore[userScoreDataIndex].score !== 'undefined') {
      return event.results.individualScore[userScoreDataIndex].score;
    } else {
      return 0;
    }
  }
}

export function getAbbreviationResultByStudent(event: SchoolEvent, userId: string, permissionId: string): string {
  const userScoreDataIndex = event.results.individualScore.findIndex(
    userScoreData => userScoreData.userId === userId && userScoreData.permissionId === permissionId
  );

  if (userScoreDataIndex !== -1) {
    const abbreviationResult = event.results.individualScore[userScoreDataIndex].richScore?.abbreviationResult;
    return abbreviationResult || '';
  }

  return '';
}

export function getAbbreviationColor(event, abbreviationCode) {
  const abbreviation = event.sport.abbreviations.find(abbreviation => abbreviation.code === abbreviationCode);
  return abbreviation ? abbreviation.color : '#000000';
}

export function getTeamScoreByTeamId(event: SchoolEvent, teamId: string) {
  const teamScoreDataIndex = event.results.teamScore.findIndex(teamScoreData => teamScoreData.teamId === teamId);

  return teamScoreDataIndex === -1 ? 0 : event.results.teamScore[teamScoreDataIndex].score;
}

export function getHouseScoreByHouseId(event: SchoolEvent, houseId: string) {
  const houseScoreDataIndex = event.results.houseScore.findIndex(houseData => houseData.houseId === houseId);

  return houseScoreDataIndex === -1 ? 0 : event.results.houseScore[houseScoreDataIndex].score;
}

export function getSchoolScoreBySchoolId(event: SchoolEvent, schoolId: string) {
  const schoolScoreDataIndex = event.results.schoolScore.findIndex(schoolData => schoolData.schoolId === schoolId);

  return schoolScoreDataIndex === -1 ? 0 : event.results.schoolScore[schoolScoreDataIndex].score;
}

export function getExtraPointsByStudent(event: SchoolEvent, userId: string): number {
  const userScoreDataIndex = event.results.individualScore.findIndex(userScoreData => userScoreData.userId === userId);

  if (userScoreDataIndex === -1) {
    return 0;
  } else {
    const extraScore = propz.get(event, ['results', 'individualScore', userScoreDataIndex, 'richScore', 'points']);
    if (typeof extraScore !== 'undefined') {
      return extraScore;
    } else {
      return 0;
    }
  }
}

export function getExtraPointsByTeamId(event: SchoolEvent, teamId: string): number {
  const teamScoreDataIndex = event.results.teamScore.findIndex(teamScoreData => teamScoreData.teamId === teamId);

  if (teamScoreDataIndex === -1) {
    return 0;
  } else {
    const extraScore = propz.get(event, ['results', 'teamScore', teamScoreDataIndex, 'richScore', 'points']);
    if (typeof extraScore !== 'undefined') {
      return extraScore;
    } else {
      return 0;
    }
  }
}

export function getExtraPointsBySchoolId(event: SchoolEvent, schoolId: string): number {
  const schoolScoreDataIndex = event.results.schoolScore.findIndex(
    schoolScoreData => schoolScoreData.schoolId === schoolId
  );

  if (schoolScoreDataIndex === -1) {
    return 0;
  } else {
    const extraScore = propz.get(event, ['results', 'schoolScore', schoolScoreDataIndex, 'richScore', 'points']);
    if (typeof extraScore !== 'undefined') {
      return extraScore;
    } else {
      return 0;
    }
  }
}

export function convertPointsToString(event: SchoolEvent, points): string {
  const pointType = propz.get(event, ['sport', 'points', 'display'], POINTS_DISPLAY.TIME);
  const mask = propz.get(event, ['sport', 'points', 'inputMask'], DEFAULT_TIME_MASK);

  const isPlayerDidNotFinish = NON_FINISHED_PLAYER_RESULTS_ARRAY.includes(points);

  switch (true) {
    case isPlayerDidNotFinish:
      return points;
    case pointType === POINTS_DISPLAY.PLAIN:
      return String(points);
    case pointType === POINTS_DISPLAY.TIME:
      return plainPointsToTimeString(points, mask);
    case pointType === POINTS_DISPLAY.DISTANCE:
      return plainPointsToDistanceString(points, mask);
    default:
      console.error('Can not find point type!');
      return '';
  }
}

export function convertPointsToStringWithoutDelimeter(event: SchoolEvent, points): string {
  const pointType = propz.get(event, ['sport', 'points', 'display'], POINTS_DISPLAY.TIME);
  const mask = propz.get(event, ['sport', 'points', 'inputMask'], DEFAULT_TIME_MASK);

  switch (true) {
    case pointType === POINTS_DISPLAY.PLAIN:
      return String(points);
    case pointType === POINTS_DISPLAY.TIME:
      return plainPointsToTimeString(points, mask).replace(/[hmsc]/g, '');
    case pointType === POINTS_DISPLAY.DISTANCE:
      return plainPointsToDistanceString(points, mask).replace(/[kmc]/g, '');
    default:
      console.error('Can not find point type!');
      return '';
  }
}

export function getAbbreviationResultByTeamId(event: SchoolEvent, teamId: string) {
  const teamAbbreviationResultDataIndex = event.results.teamScore.findIndex(
    teamScoreData => teamScoreData.teamId === teamId
  );

  return teamAbbreviationResultDataIndex === -1
    ? 0
    : event.results.teamScore[teamAbbreviationResultDataIndex].richScore.abbreviationResult;
}

export function getAbbreviationResultBySchoolId(event: SchoolEvent, schoolId: string) {
  const schoolAbbreviationResultDataIndex = event.results.schoolScore.findIndex(
    schoolData => schoolData.schoolId === schoolId
  );

  return schoolAbbreviationResultDataIndex === -1
    ? 0
    : event.results.schoolScore[schoolAbbreviationResultDataIndex].richScore.abbreviationResult;
}

export function getIsError(pointType, value, mask): boolean {
  switch (true) {
    case !value:
      return false;
    case pointType === POINTS_DISPLAY.TIME:
      return !validateStringTime(value, mask);
    case pointType === POINTS_DISPLAY.DISTANCE:
      return !stringDistanceValidation(value, mask);
    case pointType === POINTS_DISPLAY.PLAIN:
      return !stringPlainValidation(value);
    default:
      console.error('Can not find point type!');
      return false;
  }
}

export function getIsErrorHashMap(
  event: SchoolEvent,
  valueHashMap: ValueHashMap,
  players = [],
  houses = [],
  teams = []
): {
  [key: string]: boolean;
} {
  const pointType = propz.get(event, ['sport', 'points', 'display'], POINTS_DISPLAY.TIME);
  const mask = propz.get(event, ['sport', 'points', 'inputMask'], DEFAULT_TIME_MASK);

  let isErrorHashMap = {};

  players.forEach(player => {
    const value = valueHashMap[player.id];
    isErrorHashMap[player.id] = getIsError(pointType, value, mask);
  });

  houses.forEach(house => {
    const value = valueHashMap[house.id];
    isErrorHashMap[house.id] = getIsError(pointType, value, mask);
  });

  teams.forEach(team => {
    const value = valueHashMap[team.id];
    isErrorHashMap[team.id] = getIsError(pointType, value, mask);
  });

  return isErrorHashMap;
}

export function getIsErrorHashMapFromDataHashMap(
  event: SchoolEvent,
  dataHashMap: DataHashMap,
  players = [],
  houses = [],
  teams = [],
  schools = []
): {
  [key: string]: boolean;
} {
  const pointType = propz.get(event, ['sport', 'points', 'display'], POINTS_DISPLAY.TIME);
  const mask = propz.get(event, ['sport', 'points', 'inputMask'], DEFAULT_TIME_MASK);

  let isErrorHashMap = {};

  players.forEach(player => {
    const { value } = dataHashMap[player.id];
    isErrorHashMap[player.id] = getIsError(pointType, value, mask);
  });

  houses
    .filter(house => !teams.some(team => team.houseId === house.id))
    .forEach(house => {
      const { value } = dataHashMap[house.id];
      isErrorHashMap[house.id] = getIsError(pointType, value, mask);
    });

  teams.forEach(team => {
    const { value, isInputEnabled } = dataHashMap[team.id];
    isErrorHashMap[team.id] = isInputEnabled ? getIsError(pointType, value, mask) : false;
  });

  schools.forEach(school => {
    const { value } = dataHashMap[school.id];
    isErrorHashMap[school.id] = getIsError(pointType, value, mask);
  });

  return isErrorHashMap;
}

export function convertStringToPoints(event: SchoolEvent, value: string) {
  const pointType = propz.get(event, ['sport', 'points', 'display'], POINTS_DISPLAY.TIME);
  const mask = propz.get(event, ['sport', 'points', 'inputMask'], DEFAULT_TIME_MASK);

  switch (true) {
    case !value:
      return 0;
    case pointType === POINTS_DISPLAY.TIME:
      return stringTimeToPoints(value, mask);
    case pointType === POINTS_DISPLAY.DISTANCE:
      return stringDistanceToPoints(value, mask);
    case pointType === POINTS_DISPLAY.PLAIN:
      return Number(value);
    default:
      console.error('Can not find points type');
      return 0;
  }
}

export function getScorePoints(event: SchoolEvent, valueHashMap, id: string) {
  const value = valueHashMap[id];
  const points = convertStringToPoints(event, value);
  return points;
}

export function getScorePointsForTeamEvents(event: SchoolEvent, dataHashMap: DataHashMap, id: string) {
  const { value } = dataHashMap[id];
  const points = convertStringToPoints(event, value);
  return points;
}

export function getPlayerScoreId(event: SchoolEvent, userId: string, permissionId: string) {
  const { results } = event;
  const { individualScore } = results;
  const scoreIndex = individualScore.findIndex(score => score.userId === userId && score.permissionId === permissionId);
  return scoreIndex !== -1 ? individualScore[scoreIndex]._id : undefined;
}

export function getTeamScoreId(event: SchoolEvent, teamId: string) {
  const { results } = event;
  const { teamScore } = results;
  const scoreIndex = teamScore.findIndex(score => score.teamId === teamId);
  return scoreIndex !== -1 ? teamScore[scoreIndex]._id : undefined;
}

export function getHouseScoreId(event: SchoolEvent, houseId: string) {
  const { results } = event;
  const { houseScore } = results;
  const scoreIndex = houseScore.findIndex(score => score.houseId === houseId);
  return scoreIndex !== -1 ? houseScore[scoreIndex]._id : undefined;
}

export function getSchoolScoreId(event: SchoolEvent, schoolId: string) {
  const { results } = event;
  const { schoolScore } = results;
  const scoreIndex = schoolScore.findIndex(score => score.schoolId === schoolId);
  return scoreIndex !== -1 ? schoolScore[scoreIndex]._id : undefined;
}

function getFirstCharRegExp(event: SchoolEvent): RegExp {
  const sign = propz.get(event, ['sport', 'points', 'sign'], SPORT_POINTS_SIGN.PLUS);
  const isSignPlus = sign === SPORT_POINTS_SIGN.PLUS;
  const isSignMinus = sign === SPORT_POINTS_SIGN.MINUS;

  switch (true) {
    case isSignPlus:
      return /[0-9]/;
    case isSignMinus:
      return /-/;
    default:
      return /-|[0-9]/;
  }
}

export function getDigitMask(value: string): (RegExp | string)[] {
  const mask = value
    .split('')
    .filter((char, index) => {
      const regExp = /[0-9]/;
      const isValidChar = regExp.test(char);
      regExp.lastIndex = 0;

      return isValidChar;
    })
    .map((char, index) => {
      return /\d/;
    });

  return mask;
}

//https://github.com/text-mask/text-mask/issues/806
export function getDefaultDigitMask(event: SchoolEvent, value: string): (RegExp | string)[] {
  const firstCharRegExp = getFirstCharRegExp(event);

  let dotCount = 0;

  const mask = value
    .split('')
    .filter((char, index) => {
      const regExp = index === 0 ? firstCharRegExp : /[0-9]|\./;
      const isValidChar = regExp.test(char);
      regExp.lastIndex = 0;

      const isCharDot = char === '.';
      if (isCharDot) {
        dotCount++;
      }
      const dotCondition = !isCharDot || (isCharDot && dotCount < 2);

      return isValidChar && dotCondition;
    })
    .map((char, index) => {
      switch (true) {
        case char === '.':
          return '.';
        case char === '-':
          return '-';
        default:
          return /\d/;
      }
    });

  return mask;
}

export function getDefaultTimeOrDistanceMask(event: SchoolEvent, value: string): (RegExp | string)[] {
  const mask = value.split('').map((char, index) => {
    switch (true) {
      case char === '.':
        return '.';
      case char === ':':
        return ':';
      case char === ' ':
        return ' ';
      default:
        return /\d/;
    }
  });

  return mask;
}

export function getCricketRunsMask(event: SchoolEvent, value: string): (RegExp | string)[] {
  switch (value.length) {
    case 1:
      return [/\d/];
    case 2:
      return [/\d/, /\d/];
    default:
      return [/\d/, /\d/, /\d/];
  }
}

export function getCricketWicketsMask(event: SchoolEvent, value: string, isPlayer: boolean): (RegExp | string)[] {
  if (isPlayer) {
    return [/[0-1]/];
  }

  switch (value.length) {
    case 1:
      return [/\d/];
    case 2:
      return [/[1]/, /[0]/];
    default:
      return [/\d/];
  }
}

export function getIsErrorRunsHashMapFromCricketDataHashMap(
  event: SchoolEvent,
  dataHashMap: CricketDataHashMap,
  players = [],
  houses = [],
  teams = [],
  schools = []
): {
  [key: string]: boolean;
} {
  let isErrorHashMap = {};

  players.forEach(player => {
    const { runsValue } = dataHashMap[player.id];
    isErrorHashMap[player.id] = !stringPlainValidation(runsValue);
  });

  houses
    .filter(house => !teams.some(team => team.houseId === house.id))
    .forEach(house => {
      const { runsValue } = dataHashMap[house.id];
      isErrorHashMap[house.id] = !stringPlainValidation(runsValue);
    });

  teams.forEach(team => {
    const { runsValue, isInputEnabled } = dataHashMap[team.id];
    isErrorHashMap[team.id] = isInputEnabled ? !stringPlainValidation(runsValue) : false;
  });

  schools.forEach(school => {
    const { runsValue } = dataHashMap[school.id];
    isErrorHashMap[school.id] = !stringPlainValidation(runsValue);
  });

  return isErrorHashMap;
}

export function getIsErrorWicketsHashMapFromCricketDataHashMap(
  event: SchoolEvent,
  dataHashMap: CricketDataHashMap,
  players = [],
  houses = [],
  teams = [],
  schools = []
): {
  [key: string]: boolean;
} {
  let isErrorHashMap = {};

  players.forEach(player => {
    const { wicketsValue } = dataHashMap[player.id];
    isErrorHashMap[player.id] = !stringPlainValidation(wicketsValue);
  });

  houses
    .filter(house => !teams.some(team => team.houseId === house.id))
    .forEach(house => {
      const { wicketsValue } = dataHashMap[house.id];
      isErrorHashMap[house.id] = !stringPlainValidation(wicketsValue);
    });

  teams.forEach(team => {
    const { wicketsValue, isInputEnabled } = dataHashMap[team.id];
    isErrorHashMap[team.id] = isInputEnabled ? !stringPlainValidation(wicketsValue) : false;
  });

  schools.forEach(school => {
    const { wicketsValue } = dataHashMap[school.id];
    isErrorHashMap[school.id] = !stringPlainValidation(wicketsValue);
  });

  return isErrorHashMap;
}

export function isSportPointsDisplayPlain(event: SchoolEvent): boolean {
  const sportPointsDisplay = propz.get(event, ['sport', 'points', 'display'], POINTS_DISPLAY.PLAIN);
  return sportPointsDisplay === POINTS_DISPLAY.PLAIN;
}

export function getEventStartDateAndTime(event: SchoolEventFromForm | SchoolEvent): string {
  const eventStartDateAndTimeFormatted = Moment(event.startTime).format('D MMMM YYYY HH:mm');

  return eventStartDateAndTimeFormatted;
}

export function compareNames(player1, player2) {
  if (player1.lastName !== player2.lastName) {
    return player1.lastName.localeCompare(player2.lastName);
  }
  return player1.firstName.localeCompare(player2.firstName);
}
