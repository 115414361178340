import * as React from 'react';
import { Link } from 'react-router-dom';

interface WidgetProps {
  title: React.ReactNode;
  children: React.ReactNode;
  onRemove?: () => void;
  style?: React.CSSProperties;
}

export const Widget: React.FC<WidgetProps> = ({ title, children, onRemove, style = {} }) => (
  <div className="widget" style={style}>
    <div className="widget-header">
      <h4>{title}</h4>
      {onRemove && (
        <button onClick={onRemove} className="widget-remove">
          ×
        </button>
      )}
    </div>
    <div className="widget-content">{children}</div>
  </div>
);
