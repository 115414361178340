import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import { Component } from 'react';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { TwoPanelEditor } from 'Src/components/TwoPanelEditor/TwoPanelEditor';
import { Loader } from 'Src/components/Loader/Loader';
import { DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE } from 'Src/consts/table';
import { getTwoPanelEditorFilters } from 'Src/helpers/twoPanelEditor/twoPanelEditor';
import { DATE_INTERVAL, LIMIT } from 'Src/consts/table';
import {
  ColumnDefinition,
  getOrder,
  getServerFieldSectionWhere,
  isFilterExist2,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { Button } from 'Src/components/Button/Button';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { AppUser } from 'Src/views/App/App';
import {
  getAllSchoolTournamentTeamPlayers,
  getSchoolTournamentTeam,
  getSchoolTournamentTeamPlayersCount,
  SchoolTournamentTeamPlayersBatchData,
  updateSchoolTournamentTeamPlayersBatch
} from 'Src/helpers/service/admin/tournamentTeamPlayers';
import { SchoolForm } from 'Src/models/form';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getAllHouses } from 'Src/helpers/service/admin/houses';
import { getAllSchoolStudents, getSchoolStudents, getSchoolStudentsCount } from 'Src/helpers/service/admin/students';
import { getGender } from 'Src/helpers/accessor/accessor';
import { getSelectOptionForAge, getSelectOptionForForms, getSelectOptionForGender } from 'Src/helpers/table/select';
import { getUserGenderArrayConvertedFromEventGender } from 'Src/helpers/tournament/tournament';
import { TournamentTeamPlayer } from '../../../../models/tournamentTeamPlayer';
import { Count } from '../../../../models/count';
import { getColorClashes } from '../../../../helpers/cell/cell';
import { AGE_GROUPS } from '../../../../consts/school';
import { getEventClash, getEventTitle, getStudentsMethod, isMultipartyEvent } from '../../../../helpers/event/event';
import {
  getSchoolStudentsWithClashesCount,
  getSchoolStudentsWithoutClashes
} from '../../../../helpers/service/admin/schoolTeamPlayers';
import { SchoolStudentClash } from '../../../../models/schoolStudent';
import { SchoolEventTeamPlayerWithClashes } from '../../../../models/eventTeamPlayer';
import { SchoolEvent } from '../../../../models/event';
import { getSchoolEvent } from '../../../../helpers/service/admin/event';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { CLASHES } from '../../../../consts/player';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { StudentForm } from 'Src/components/StudentForm/StudentForm';
import { addSchoolStudent, updateSchoolStudent } from 'Src/helpers/service/admin/students';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}
interface State {
  items: Item[];
  itemsSelected: Item[];
  itemsCount: number;

  itemsFilters: any;
  isShowItemsFilter: boolean;
  isItemsFiltered: boolean;

  isSelectAllItemsChecked: boolean;

  sortItemsDirection: TABLE_SORT_DIRECTION;
  sortItemsColumnsName: string;

  itemCurrentPage: number;

  itemsAccumulatorInitial: Item[];
  itemsAccumulator: Item[];
  itemsAccumulatorSelected: Item[];
  itemsAccumulatorCount: number;

  itemsAccumulatorFilters: any;
  isShowItemsAccumulatorFilter: boolean;
  isItemsAccumulatorFiltered: boolean;

  isSelectAllItemsAccumulatorChecked: boolean;

  sortItemsAccumulatorDirection: TABLE_SORT_DIRECTION;
  sortItemsAccumulatorColumnsName: string;

  tournament: any;
  team: any;
  forms: SchoolForm[];
  houses: any[];

  isLoading: boolean;

  itemsAddBuffer: Item[];
  itemsRemoveBuffer: Item[];

  studentEventsModalText: string;
  isStudentEventsModalOpen: boolean;

  isShowYounger: boolean;
  isExitConfirmationModalOpen: boolean;

  errorMessage: string;

  isAddStudentModalOpen: boolean;
  studentToAdd: Item | null;

  isEditStudentModalOpen: boolean;
  studentToEdit: Item | null;
}

const ITEMS_ACCUMULATOR_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['form', 'name']
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getGender
  }
];

const ITEMS_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  }
];

/*const ITEMS_COLUMNS_WITH_CLASHES: ColumnDefinition[] = [
  {
    text: 'Clashes',
    field: 'clashes',
    cell: getColorClashes,
    type: FILTER_TYPE.NONE,
    isSort: false
  },
  {
    text: 'Surname',
    field: 'lastName',
    accessor: ['lastName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Name',
    field: 'firstName',
    accessor: ['firstName'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Form',
    field: 'forms',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: ['form', 'name']
  },
  {
    text: 'Gender',
    field: 'gender',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getGender
  }
];
*/

interface Item {
  permissionId: string;
  form: any;
  gender: string;
  firstName: string;
  lastName: string;
  id: string;
  playerId?: string;
  clashes: {
    status?: string;
    fullClashEvents?: any[];
    intervalClashEvents?: any[];
  };
}

const DEFAULT_TEAM_PLAYERS_ORDER = 'firstName ASC';

export class TournamentTeamPlayersManager extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      itemsCount: 0,
      itemsSelected: [],

      itemsFilters: {},
      isShowItemsFilter: false,
      isItemsFiltered: false,

      isSelectAllItemsChecked: false,

      sortItemsDirection: '',
      sortItemsColumnsName: '',

      itemCurrentPage: 1,

      itemsAccumulatorInitial: [],
      itemsAccumulator: [],
      itemsAccumulatorCount: 0,
      itemsAccumulatorSelected: [],

      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,

      isSelectAllItemsAccumulatorChecked: false,

      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',

      tournament: undefined,
      team: undefined,
      forms: [],
      houses: [],

      isLoading: false,

      itemsAddBuffer: [],
      itemsRemoveBuffer: [],

      studentEventsModalText: '',
      isStudentEventsModalOpen: false,

      isShowYounger: false,
      isExitConfirmationModalOpen: false,

      errorMessage: '',

      isAddStudentModalOpen: false,
      studentToAdd: null,

      isEditStudentModalOpen: false,
      studentToEdit: null
    };
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournamentId;
    return tournamentId;
  }

  getTeamId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const teamId = search.teamId;
    return teamId;
  }

  getActionsColumn = (onEditClick?: (item: Item) => void): ColumnDefinition => ({
    text: '',
    field: 'actions',
    type: FILTER_TYPE.NONE,
    isSort: false,
    cell: (item: Item) => {
      return onEditClick ? (
        <Button
          onClick={e => {
            e.stopPropagation();
            onEditClick(item);
          }}
          customClass="btn-icon ml-3"
          text={[
            <FontAwesomeIcon
              icon={faEdit}
              key={`edit-icon-${item.id}`}
              style={{ color: '#3baeda', fontSize: '1rem' }}
            />
          ]}
          aria-label={`Edit ${item.firstName} ${item.lastName}`}
        />
      ) : null;
    }
  });

  getItemsColumns = (): ColumnDefinition[] => [
    {
      text: 'Surname',
      field: 'lastName',
      accessor: ['lastName'],
      type: FILTER_TYPE.TEXT,
      isSort: false
    },
    {
      text: 'Name',
      field: 'firstName',
      accessor: ['firstName'],
      type: FILTER_TYPE.TEXT,
      isSort: false
    },
    {
      text: 'Form',
      field: 'forms',
      isSort: false,
      type: FILTER_TYPE.MULTISELECT,
      accessor: ['form', 'name']
    },
    {
      text: 'Gender',
      field: 'gender',
      isSort: false,
      type: FILTER_TYPE.MULTISELECT,
      accessor: getGender
    },
    this.getActionsColumn(this.handleEditClick)
  ];

  getItemsAccumulatorColumns = (): ColumnDefinition[] => [
    {
      text: 'Surname',
      field: 'lastName',
      accessor: ['lastName'],
      type: FILTER_TYPE.NONE,
      isSort: false
    },
    {
      text: 'Name',
      field: 'firstName',
      accessor: ['firstName'],
      type: FILTER_TYPE.NONE,
      isSort: false
    },
    {
      text: 'Form',
      field: 'forms',
      isSort: false,
      type: FILTER_TYPE.NONE,
      accessor: ['form', 'name']
    },
    {
      text: 'Gender',
      field: 'gender',
      isSort: false,
      type: FILTER_TYPE.NONE,
      accessor: getGender
    },
    this.getActionsColumn(this.handleEditClick)
  ];

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    const itemsFilters = getTwoPanelEditorFilters(ITEMS_COLUMNS);
    const isShowItemsFilter = isFilterExist2(itemsFilters);
    const isItemsFiltered = isFilterExist2(itemsFilters);

    const itemsAccumulatorFilters = getTwoPanelEditorFilters(ITEMS_ACCUMULATOR_COLUMNS);
    const isShowItemsAccumulatorFilter = isFilterExist2(itemsAccumulatorFilters);
    const isItemsAccumulatorFiltered = isFilterExist2(itemsAccumulatorFilters);

    BPromise.all([
      getTournament(user, tournamentId),
      getSchoolTournamentTeam(user, tournamentId, teamId),
      getAllForms(user),
      getAllHouses(user)
    ])
      .then(([_tournament, _team, _forms, _houses]) => {
        const defaultItemsFilter = this.getDefaultItemsFilter(_team, _forms);

        this.setState({
          tournament: _tournament,
          team: _team,
          forms: _forms,
          houses: _houses,
          itemsFilters: defaultItemsFilter
        });

        return this.getItemsAndItemsAccumulator();
      })
      .then(({ teamPlayersInitial, teamPlayers, teamPlayersCount, students, studentsCount }) => {
        const studentsNormalized = this.getStudentsNormalized(students);
        const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
        const teamPlayersInitialNormalized = this.getTeamPlayersNormalized(teamPlayersInitial);

        this.setState({
          items: studentsNormalized,
          itemsCount: studentsCount.count,
          isShowItemsFilter: isShowItemsFilter,
          isItemsFiltered: true,

          itemsAccumulatorInitial: teamPlayersInitialNormalized,
          itemsAccumulator: teamPlayersNormalized,
          itemsAccumulatorCount: teamPlayersCount.count,
          itemsAccumulatorFilters: itemsAccumulatorFilters,
          isShowItemsAccumulatorFilter: isShowItemsAccumulatorFilter,
          isItemsAccumulatorFiltered: isItemsAccumulatorFiltered,

          isLoading: false
        });
      });
  }

  getTeamPlayersNormalized(teamPlayers: TournamentTeamPlayer[]): Item[] {
    return teamPlayers.map(teamPlayer => {
      const { permissionId, form, gender, firstName, lastName, userId, id } = teamPlayer;
      return {
        permissionId,
        form,
        gender,
        firstName,
        lastName,
        id: userId,
        playerId: id,
        clashes: {
          status: CLASHES.NO_CLASH,
          fullClashEvents: [],
          intervalClashEvents: []
        }
      };
    });
  }

  getStudentsNormalized(students: SchoolStudentClash[]): Item[] {
    return students.map(student => {
      const { id, permissionId, form, gender, firstName, lastName, clashes } = student;
      return {
        id,
        permissionId,
        form,
        gender,
        firstName,
        lastName,
        clashes: clashes || {
          status: 'NO_CLASH',
          fullClashEvents: [],
          intervalClashEvents: []
        }
      };
    });
  }

  getFormsFiltered(ages: number[], forms: SchoolForm[], isStrictEqual: boolean) {
    const maxAge = Math.max(...ages);
    const minAge = Math.min(...ages);

    switch (true) {
      case isStrictEqual && ages.length === 1:
        return forms.filter(form => form.age === minAge);
      case !isStrictEqual && ages.length === 1:
        return forms.filter(form => form.age <= maxAge);
      case isStrictEqual && ages.length > 1:
        return forms.filter(form => ages.some(age => form.age === age));
      case !isStrictEqual && ages.length > 1:
        return forms.filter(form => ages.some(age => form.age <= age));
    }
  }

  getDefaultItemsFilter(team, forms, isStrictEqual = true) {
    const { gender, ages = [] } = team;
    const genderArrayConverted = getUserGenderArrayConvertedFromEventGender(gender);

    const formsFiltered = this.getFormsFiltered(ages, forms, isStrictEqual);
    const formIds = formsFiltered.map(form => form.id);

    return {
      firstName: '',
      lastName: '',
      gender: genderArrayConverted,
      forms: formIds
    };
  }

  getItemsAndItemsAccumulator() {
    const { user } = this.props;
    const { itemsAccumulatorFilters } = this.state;
    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    const where = getServerFieldSectionWhere(itemsAccumulatorFilters);

    const getTeamPlayersInitialPromise = getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId);
    const getTeamPlayersPromise = getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId, where);
    const getTeamPlayersCountPromise = getSchoolTournamentTeamPlayersCount(user, tournamentId, teamId, where);

    let teamPlayersInitial: TournamentTeamPlayer[], teamPlayers: TournamentTeamPlayer[], teamPlayersCount: Count;

    return BPromise.all([getTeamPlayersInitialPromise, getTeamPlayersPromise, getTeamPlayersCountPromise])
      .then(([_teamPlayersInitial, _teamPlayers, _teamPlayersCount]) => {
        teamPlayersInitial = _teamPlayersInitial;
        teamPlayers = _teamPlayers;
        teamPlayersCount = _teamPlayersCount;

        const teamPlayersInitialNormalized = this.getTeamPlayersNormalized(teamPlayersInitial);

        const filter = this.getItemsFilter(1, teamPlayersInitialNormalized, []);

        const method = getStudentsMethod(user);
        const getStudentsPromise = method(user, filter);
        const getSchoolStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

        return BPromise.all([getStudentsPromise, getSchoolStudentsCountPromise]);
      })
      .then(([students, studentsCount]) => {
        return {
          teamPlayersInitial,
          teamPlayers,
          teamPlayersCount,
          students,
          studentsCount
        };
      });
  }

  onItemClick = (index: number): void => {
    const { items, itemsSelected } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = itemsSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    console.log('Selected Items Updated:', selectedItemsUpdated);

    this.setState({
      itemsAccumulatorSelected: [],
      itemsSelected: selectedItemsUpdated
    });
  };

  onItemAccumulatorClick = (index: number): void => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;
    const selectedItem = itemsAccumulator[index];

    const selectedItemIndex = itemsAccumulatorSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsAccumulatorSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: selectedItemsUpdated,
      itemsSelected: []
    });
  };

  onAddClick = () => {
    const { itemsSelected, itemsAddBuffer, itemsAccumulatorInitial, itemCurrentPage } = this.state;
    const { user } = this.props;

    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer);
    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);

      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAddBuffer: nextItemsAddBuffer,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false
      });
    });
  };

  onRemoveClick = () => {
    const {
      itemsAccumulatorSelected,
      itemsAddBuffer,
      itemsRemoveBuffer,
      itemsAccumulatorInitial,
      itemsAccumulator,
      itemCurrentPage
    } = this.state;
    const { user } = this.props;

    const nextItemsRemoveBuffer = [...itemsAccumulatorSelected, ...itemsRemoveBuffer];
    const nextItemsAccumulatorInitial = itemsAccumulatorInitial.filter(itemAccumulatorInitial =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulatorInitial.id)
    );
    const nextItemsAccumulator = itemsAccumulator.filter(itemAccumulator =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulator.id)
    );

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, nextItemsAccumulatorInitial, itemsAddBuffer);
    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false,
        itemsRemoveBuffer: nextItemsRemoveBuffer,
        itemsAccumulatorInitial: nextItemsAccumulatorInitial,
        itemsAccumulator: nextItemsAccumulator,
        itemsAccumulatorCount: nextItemsAccumulator.length
      });
    });
  };

  removeItemFromBuffer = (itemId: string) => {
    this.setState(prevState => {
      const removedItem = prevState.itemsAddBuffer.find(item => item.id === itemId);
      const updatedItemsAddBuffer = prevState.itemsAddBuffer.filter(item => item.id !== itemId);
      const updatedItems = removedItem ? [...prevState.items, removedItem] : prevState.items;

      return {
        itemsAddBuffer: updatedItemsAddBuffer,
        items: updatedItems
      };
    });
  };

  onClickSave = () => {
    const { itemsAddBuffer, itemsRemoveBuffer, itemsAccumulatorInitial, tournament } = this.state;

    const currentTeamSet = new Set(itemsAccumulatorInitial.map(item => item.id));

    itemsRemoveBuffer.forEach(item => currentTeamSet.delete(item.id));

    itemsAddBuffer.forEach(item => currentTeamSet.add(item.id));

    const finalTeamSize = currentTeamSet.size;

    if (tournament.maxPlayersPerTeam && finalTeamSize > tournament.maxPlayersPerTeam) {
      this.setState({
        errorMessage: `Cannot save changes: The total number of team members exceeds the maximum allowed (${tournament.maxPlayersPerTeam}).`
      });
      return;
    }

    this.setState({
      errorMessage: '',
      isLoading: true,
      itemsSelected: [],
      itemsAddBuffer: [],
      itemsRemoveBuffer: []
    });

    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();
    const data: SchoolTournamentTeamPlayersBatchData = {
      add: itemsAddBuffer.map(item => ({ userId: item.id, permissionId: item.permissionId })),
      remove: itemsRemoveBuffer.map(item => item.playerId)
    };

    updateSchoolTournamentTeamPlayersBatch(this.props.user, tournamentId, teamId, data)
      .then(() => this.getItemsAndItemsAccumulator())
      .then(({ teamPlayersInitial, teamPlayers, teamPlayersCount, students, studentsCount }) => {
        const studentsNormalized = this.getStudentsNormalized(students);
        const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);
        const teamPlayersInitialNormalized = this.getTeamPlayersNormalized(teamPlayersInitial);

        this.setState({
          items: studentsNormalized,
          itemsCount: studentsCount.count,
          itemsAccumulator: teamPlayersNormalized,
          itemsAccumulatorInitial: teamPlayersInitialNormalized,
          itemsAccumulatorCount: teamPlayersCount.count,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          errorMessage: 'An error occurred while saving changes. Please check your input and try again.',
          isLoading: false
        });
        console.error('Save error:', error);
      });
  };

  handleEditClick = (student: Item) => {
    this.setState({
      isEditStudentModalOpen: true,
      studentToEdit: student
    });
  };

  onItemsFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsFilters: nextFilters
    });
  };

  onItemsAccumulatorFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsAccumulatorFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsAccumulatorFilters: nextFilters
    });
  };

  onItemsFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsFilter } = this.state;

    this.setState({
      isShowItemsFilter: !isShowItemsFilter
    });
  };

  onItemsAccumulatorFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsAccumulatorFilter } = this.state;

    this.setState({
      isShowItemsAccumulatorFilter: !isShowItemsAccumulatorFilter
    });
  };

  getItemsFilter(page, itemsAccumulator, itemsAddBuffer, isAllItems = false) {
    const { itemsFilters, forms } = this.state;

    const {
      gender: itemsFiltersGender,
      firstName: itemsFiltersFirstName = '',
      lastName: itemsFiltersLastName = '',
      forms: itemsFiltersForms = []
    } = itemsFilters;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allItems = [...itemsAccumulator, ...itemsAddBuffer];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    propz.set(where, ['formId', '$in'], itemsFiltersForms);

    let and = [];

    if (itemsFiltersFirstName.length > 0) {
      and.push({ firstName: { like: itemsFiltersFirstName, options: 'i' } });
    }

    if (itemsFiltersLastName.length > 0) {
      and.push({ lastName: { like: itemsFiltersLastName, options: 'i' } });
    }

    if (and.length > 0) {
      propz.set(where, ['$and'], and);
    }

    const filter = {
      where: where,
      limit: isAllItems ? DEFAULT_LIMIT : LIMIT,
      skip: isAllItems ? 0 : (page - 1) * LIMIT,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    return filter;
  }

  itemsSetCurrentPageParams = (currentPage: number): void => {
    const { user } = this.props;
    const { itemsAccumulator, sortItemsColumnsName, sortItemsDirection, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    let orderForFilter = undefined;

    if (sortItemsColumnsName !== '' && sortItemsDirection !== '') {
      orderForFilter = `${sortItemsColumnsName} ${sortItemsDirection}`;
    }

    const filter = this.getItemsFilter(currentPage, itemsAccumulator, itemsAddBuffer, orderForFilter);

    getSchoolStudents(user, filter).then(students => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        itemCurrentPage: currentPage,
        items: studentsNormalized,
        isLoading: false
      });
    });
  };

  itemsOnApplyFilterClick = () => {
    const { itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true,
      isShowYounger: false
    });

    const filter = this.getItemsFilter(1, itemsAccumulator, itemsAddBuffer);

    const { user } = this.props;

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getStudentCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getStudentCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsDirection: '',
        sortItemsColumnsName: ''
      });
    });
  };

  itemsAccumulatorOnApplyFilterClick = () => {};

  itemsOnClearFilterClick = () => {
    const { itemsAccumulatorInitial, itemsAddBuffer, team, forms, isShowYounger } = this.state;

    const defaultItemsFilter = this.getDefaultItemsFilter(team, forms);

    const { ages } = team;

    this.setState({
      isLoading: true,
      itemsFilters: defaultItemsFilter,
      isShowYounger: false
    });

    const { gender: itemsFiltersGender } = defaultItemsFilter;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    const formsFiltered = this.getFormsFiltered(ages, forms, true);
    const formIds = formsFiltered.map(form => form.id);
    propz.set(where, ['formId', '$in'], formIds);

    const filter = {
      where: where,
      limit: LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    const { user } = this.props;

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getSchoolsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getSchoolsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: '',
        sortItemsDirection: '',
        isItemsFiltered: true,
        isShowItemsFilter: false
      });
    });
  };

  itemsAccumulatorOnClearFilterClick = () => {};

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const search = propz.get(state, ['search'], '');
    const prevRoutePath = propz.get(state, ['prevRoutePath'], '');
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: '/tournaments/teams',
      search: `tournamentId=${tournamentId}`,
      state: { search, prevRoutePath }
    });
  };

  renderExitConfirmationModal = () => {
    const { isExitConfirmationModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isExitConfirmationModalOpen}
        title="Go back"
        body="Are you sure you want to leave this page? If you go off this page before saving then you will lose any changes you have made to your event participants."
      >
        <div className="mt-3">
          <Button text={'Cancel'} onClick={this.closeExitConfirmationModal} customClass="btn-secondary mr-3" />
          <Button text={'Back'} onClick={this.goBack} />
        </div>
      </SimpleModal>
    );
  };

  openExitConfirmationModal = () => {
    this.setState({
      isExitConfirmationModalOpen: true
    });
  };

  closeExitConfirmationModal = () => {
    this.setState({
      isExitConfirmationModalOpen: false
    });
  };

  onBackButtonClick = () => {
    const { itemsAddBuffer, itemsRemoveBuffer } = this.state;
    const isThereAnyChanges = itemsAddBuffer.length > 0 || itemsRemoveBuffer.length > 0;

    isThereAnyChanges ? this.openExitConfirmationModal() : this.goBack();
  };

  onSelectAllItemsClick = () => {
    const { itemsSelected, itemCurrentPage, itemsAccumulatorInitial, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;
    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];
    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer, true);

    getSchoolStudentsWithoutClashes(user, filter).then(items => {
      const itemsFiltered = items.filter(item => {
        return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
      });

      const studentsNormalizedFiltered = this.getStudentsNormalized(itemsFiltered);

      const nextSelectedItems = [...itemsSelected, ...studentsNormalizedFiltered];

      this.setState({
        itemsSelected: nextSelectedItems,
        isSelectAllItemsChecked: true,
        itemsAccumulatorSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsOnPageClick = () => {
    const { items, itemsSelected } = this.state;

    const itemsFiltered = items.filter(item => {
      return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsSelected, ...itemsFiltered];

    this.setState({
      itemsSelected: nextSelectedItems,
      isSelectAllItemsChecked: true,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onUnselectAllItemsClick = () => {
    this.setState({
      itemsSelected: [],
      isSelectAllItemsChecked: false,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onSelectAllItemsAccumulatorClick = () => {
    const { itemsAccumulatorSelected, itemsAccumulatorFilters } = this.state;

    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();
    const where = getServerFieldSectionWhere(itemsAccumulatorFilters);

    getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId, where).then(teamPlayers => {
      const teamPlayersNormalized = this.getTeamPlayersNormalized(teamPlayers);

      const teamPlayersFiltered = teamPlayersNormalized.filter(teamPlayer => {
        return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== teamPlayer.id);
      });

      const nextSelectedItems = [...itemsAccumulatorSelected, ...teamPlayersFiltered];

      this.setState({
        itemsAccumulatorSelected: nextSelectedItems,
        isSelectAllItemsAccumulatorChecked: true,
        itemsSelected: [],
        isSelectAllItemsChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsAccumulatorOnPageClick = () => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;

    const itemsFiltered = itemsAccumulator.filter(item => {
      return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsAccumulatorSelected, ...itemsFiltered];

    this.setState({
      itemsAccumulatorSelected: nextSelectedItems,
      isSelectAllItemsAccumulatorChecked: true,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onUnselectAllItemsAccumulatorClick = () => {
    this.setState({
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onSortItemsClick = (sortField: string) => {
    const { sortItemsDirection, sortItemsColumnsName, itemCurrentPage, itemsAccumulator, itemsAddBuffer } = this.state;

    const order = getOrder(sortField, sortItemsDirection, sortItemsColumnsName) as TABLE_SORT_DIRECTION;
    const orderForFilter = `${sortField} ${order}`;

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer);
    const { user } = this.props;
    const getSchoolStudentsPromise = getSchoolStudents(user, filter);
    const getSchoolStudentsCountPromise = getSchoolStudentsCount(user, filter.where);

    const promiseItems = [getSchoolStudentsPromise, getSchoolStudentsCountPromise];

    BPromise.all(promiseItems).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: sortField,
        sortItemsDirection: order
      });
    });
  };

  onSortItemsAccumulatorClick = (sortField: string) => {};

  onClashClick = (student: SchoolStudentClash | SchoolEventTeamPlayerWithClashes) => {
    const { user } = this.props;
    const { clashes, firstName, lastName } = student;
    const { fullClashEvents, intervalClashEvents } = clashes;
    const fullClashEventIds = fullClashEvents.map(fullClashEvent => fullClashEvent._id);
    const intervalClashEventIds = intervalClashEvents.map(intervalClashEvent => intervalClashEvent._id);

    const allPlayerEventIds = [...fullClashEventIds, ...intervalClashEventIds];
    const allPlayerEventIdsUniq = Lazy(allPlayerEventIds)
      .uniq()
      .toArray();

    this.setState({
      isLoading: true
    });

    const eventsPromise: Promise<SchoolEvent>[] = allPlayerEventIdsUniq.map(eventId => getSchoolEvent(user, eventId));

    BPromise.all(eventsPromise).then(events => {
      const eventNames = events
        .map((event, index) => {
          const title = getEventTitle(event, user);
          const clash = getEventClash(event, fullClashEventIds, intervalClashEventIds);
          return `${index + 1}. ${title} ${clash}`;
        })
        .join('\n ');

      this.setState({
        isLoading: false,
        studentEventsModalText: `${firstName} ${lastName} is participating in the following events: \n${eventNames}`,
        isStudentEventsModalOpen: true
      });
    });
  };

  onCloseStudentEventsModalClick = () => {
    this.setState({
      isStudentEventsModalOpen: false
    });
  };

  renderStudentEventsModal(): React.ReactNode {
    const { isStudentEventsModalOpen, studentEventsModalText } = this.state;
    return (
      <SimpleModal
        isOpen={isStudentEventsModalOpen}
        title={'Info'}
        body={studentEventsModalText}
        buttonText={'Ok'}
        onButtonClick={this.onCloseStudentEventsModalClick}
      />
    );
  }

  onShowYoungerChange = () => {
    const { user } = this.props;
    const { team, forms, isShowYounger, itemsAccumulatorInitial, itemsAddBuffer } = this.state;

    const defaultItemsFilter = this.getDefaultItemsFilter(team, forms, isShowYounger);

    this.setState({
      isLoading: true,
      itemsFilters: defaultItemsFilter,
      isShowYounger: !isShowYounger
    });

    const { gender: itemsFiltersGender, forms: itemsFiltersForms = [] } = defaultItemsFilter;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    propz.set(where, ['formId', '$in'], itemsFiltersForms);

    const filter = {
      where: where,
      limit: LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    const method = getStudentsMethod(user);
    const getStudentsPromise = method(user, filter);
    const getSchoolsCountPromise = getSchoolStudentsWithClashesCount(user, filter);

    BPromise.all([getStudentsPromise, getSchoolsCountPromise]).then(([students, studentsCount]) => {
      const studentsNormalized = this.getStudentsNormalized(students);
      this.setState({
        items: studentsNormalized,
        isLoading: false,
        itemsCount: studentsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: '',
        sortItemsDirection: '',
        isItemsFiltered: true,
        isShowItemsFilter: false
      });
    });
  };

  openAddStudentModal = () => {
    this.setState(
      {
        isAddStudentModalOpen: true,
        studentToAdd: null
      },
      () => console.log('Modal Open State:', this.state.isAddStudentModalOpen)
    );
  };

  closeAddStudentModal = () => {
    this.setState({
      isAddStudentModalOpen: false,
      studentToAdd: null
    });
  };

  onSubmitAddStudent = async (data, isContinue) => {
    const { user } = this.props;
    const { itemsAccumulatorInitial, itemsAddBuffer } = this.state;

    const transformedData: any = {
      ...data,
      formId: data.form?.id || null
    };

    if (data.house?.id && /^[a-fA-F0-9]{24}$/.test(data.house.id)) {
      transformedData.houseId = data.house.id;
    } else {
      delete transformedData.houseId;
    }

    try {
      const newStudent = await addSchoolStudent(user, transformedData);
      const filter = this.getItemsFilter(1, itemsAccumulatorInitial, itemsAddBuffer);
      const [updatedStudents, updatedCount] = await Promise.all([
        getStudentsMethod(user)(user, filter),
        getSchoolStudentsCount(user, filter.where)
      ]);

      const normalizedStudents = this.getStudentsNormalized(updatedStudents);

      this.setState(prevState => ({
        isAddStudentModalOpen: isContinue,
        items: normalizedStudents,
        itemsCount: updatedCount.count,
        studentToAdd: isContinue ? null : null,
        errorMessage: ''
      }));

      return true;
    } catch (error) {
      console.error('Error adding student:', error);
      this.setState({
        errorMessage: 'Failed to add student. Please try again.'
      });
      return false;
    }
  };

  openEditStudentModal = () => {
    const { itemsSelected } = this.state;
    if (itemsSelected.length === 1) {
      this.setState({
        isEditStudentModalOpen: true,
        studentToEdit: itemsSelected[0]
      });
    }
  };

  closeEditStudentModal = () => {
    this.setState({
      isEditStudentModalOpen: false,
      studentToEdit: null
    });
  };

  onSubmitEditStudent = async (
    data: any,
    isContinue: boolean,
    isSavingSimilarStudentConfirmed: boolean
  ): Promise<boolean> => {
    const { user } = this.props;
    const { studentToEdit } = this.state;

    if (!studentToEdit) return false;

    const transformedData: any = {
      ...data,
      formId: data.form?.id || null
    };

    if (data.house?.id && /^[a-fA-F0-9]{24}$/.test(data.house.id)) {
      transformedData.houseId = data.house.id;
    } else {
      delete transformedData.houseId;
    }

    try {
      await updateSchoolStudent(user, studentToEdit.id, transformedData);

      this.setState(prevState => {
        const updatedItems = prevState.items.map(item =>
          item.id === studentToEdit.id ? { ...item, ...transformedData } : item
        );

        const updatedItemsAccumulator = prevState.itemsAccumulator.map(item =>
          item.id === studentToEdit.id ? { ...item, ...transformedData } : item
        );

        return {
          items: updatedItems,
          itemsAccumulator: updatedItemsAccumulator
        };
      });

      this.closeEditStudentModal();
      return true;
    } catch (error) {
      console.error('Error updating student:', error);
      this.setState({
        errorMessage: 'Failed to update student. Please try again.'
      });
      return false;
    }
  };

  renderAddStudentModal() {
    const { isAddStudentModalOpen, studentToAdd, forms, houses } = this.state;
    const { user } = this.props;

    if (!isAddStudentModalOpen) return null;

    return (
      <SimpleModal
        isOpen={isAddStudentModalOpen}
        onCloseClick={this.closeAddStudentModal}
        title="Add Student"
        showFooter={false}
      >
        <StudentForm
          student={studentToAdd || { firstName: '', lastName: '', formId: '', houseId: '' }}
          onSubmit={this.onSubmitAddStudent}
          onCancel={this.closeAddStudentModal}
          user={user}
          isCreateStudentsChain={false}
          identicalStudents={[]}
          similarStudents={[]}
          isFormsExist={forms && forms.length > 0}
          isEditStudent={false}
        />
      </SimpleModal>
    );
  }

  renderEditStudentModal() {
    const { isEditStudentModalOpen, studentToEdit, forms, houses } = this.state;
    const { user } = this.props;

    if (!isEditStudentModalOpen || !studentToEdit) return null;

    return (
      <SimpleModal
        isOpen={isEditStudentModalOpen}
        onCloseClick={this.closeEditStudentModal}
        title="Edit Student"
        showFooter={false}
      >
        <StudentForm
          student={studentToEdit}
          onSubmit={this.onSubmitEditStudent}
          onCancel={this.closeEditStudentModal}
          user={user}
          isCreateStudentsChain={false}
          identicalStudents={[]}
          similarStudents={[]}
          isFormsExist={forms && forms.length > 0}
          isEditStudent={true}
        />
      </SimpleModal>
    );
  }

  getColumnsWithClashes = (): ColumnDefinition[] => [
    {
      text: 'Clashes',
      field: 'clashes',
      cell: getColorClashes,
      type: FILTER_TYPE.NONE,
      isSort: false
    },
    ...ITEMS_COLUMNS,
    this.getActionsColumn(this.handleEditClick)
  ];

  render() {
    const {
      items,
      isLoading,
      itemsSelected,
      itemsAccumulatorSelected,
      itemsAccumulator,
      itemsFilters,
      isShowItemsFilter,
      isItemsFiltered,
      itemsCount,
      itemsAccumulatorCount,
      itemCurrentPage,
      itemsAccumulatorFilters,
      isShowItemsAccumulatorFilter,
      isItemsAccumulatorFiltered,
      tournament,
      isSelectAllItemsChecked,
      isSelectAllItemsAccumulatorChecked,
      sortItemsDirection,
      sortItemsColumnsName,
      sortItemsAccumulatorDirection,
      sortItemsAccumulatorColumnsName,
      itemsAddBuffer,
      itemsRemoveBuffer,
      isStudentEventsModalOpen,
      forms,
      isShowYounger,
      errorMessage,
      isAddStudentModalOpen,
      studentToAdd,
      isEditStudentModalOpen,
      studentToEdit
    } = this.state;

    const name = tournament
      ? `${propz.get(tournament, ['name'], '')}${
          tournament.maxPlayersPerTeam && tournament.maxPlayersPerTeam > 0
            ? ` (You can add up to ${tournament.maxPlayersPerTeam} participants)`
            : ''
        }`
      : '';

    if (isLoading) {
      return <Loader />;
    }

    const { user } = this.props;
    const { activeSchool } = user;
    const { ageGroupsNaming, isShowStudentEventClashes } = activeSchool;

    const itemsFiltersOptions = {
      ages: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForGender(),
      forms: getSelectOptionForForms(forms)
    };

    const itemsAccumulatorFiltersOptions = {};

    const classes = isStudentEventsModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderStudentEventsModal()}
        {this.renderExitConfirmationModal()}
        {this.renderAddStudentModal()}
        {this.renderEditStudentModal()}
        <div className="row">
          <div className="col-md-12">
            <Button text={'← back'} onClick={this.onBackButtonClick} customClass={'btn-secondary mr-3 mb-3'} />
            <div className="d-inline h1 mb-3">
              {tournament ? propz.get(tournament, ['name'], '') : ''}
              {tournament && tournament.maxPlayersPerTeam && tournament.maxPlayersPerTeam > 0 && (
                <span style={{ fontSize: '1.5rem', marginLeft: '10px', color: 'grey' }}>
                  (You can add up to {tournament.maxPlayersPerTeam} participants)
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className={'mb-3 mt-3 d-flex align-items-center'}>
                <Checkbox
                  value={isShowYounger}
                  id={'isShowYounger'}
                  onChange={this.onShowYoungerChange}
                  labelText={'Show younger'}
                />
                <Button text={'Add Student'} onClick={this.openAddStudentModal} customClass={'ml-3'} />
              </div>
            </div>
          </div>
        </div>
        <TwoPanelEditor
          //items
          items={items}
          itemsTitle={'Eligible students'}
          itemsColumns={isShowStudentEventClashes ? this.getColumnsWithClashes() : this.getItemsColumns()}
          itemsSelected={itemsSelected}
          onItemClick={this.onItemClick}
          //items filters
          onItemsFilterChange={this.onItemsFilterChange}
          itemsFilters={itemsFilters}
          itemsFiltersOptions={itemsFiltersOptions}
          isShowItemsFilter={isShowItemsFilter}
          isItemsFiltered={isItemsFiltered}
          onItemsFilterClick={this.onItemsFilterClick}
          //accumulator
          itemsAccumulator={itemsAccumulator}
          itemsAccumulatorTitle={'Team members'}
          itemsAccumulatorColumns={ITEMS_ACCUMULATOR_COLUMNS}
          onItemAccumulatorClick={this.onItemAccumulatorClick}
          itemsAccumulatorSelected={itemsAccumulatorSelected}
          //accumulator filters
          onItemsAccumulatorFilterChange={this.onItemsAccumulatorFilterChange}
          itemsAccumulatorFilters={itemsAccumulatorFilters}
          itemsAccumulatorFiltersOptions={itemsAccumulatorFiltersOptions}
          isShowItemsAccumulatorFilter={isShowItemsAccumulatorFilter}
          isItemsAccumulatorFiltered={isItemsAccumulatorFiltered}
          onItemsAccumulatorFilterClick={this.onItemsAccumulatorFilterClick}
          //buttons
          onAddClick={this.onAddClick}
          onRemoveClick={this.onRemoveClick}
          //counts
          itemsCount={itemsCount}
          itemsAccumulatorCount={itemsAccumulatorCount}
          //current pages
          itemAccumulatorCurrentPage={1}
          itemCurrentPage={itemCurrentPage}
          itemsSetCurrentPageParams={this.itemsSetCurrentPageParams}
          //items filter buttons
          itemsOnApplyFilterClick={this.itemsOnApplyFilterClick}
          itemsOnClearFilterClick={this.itemsOnClearFilterClick}
          //items accumulator filter buttons
          itemsAccumulatorOnApplyFilterClick={this.itemsAccumulatorOnApplyFilterClick}
          itemsAccumulatorOnClearFilterClick={this.itemsAccumulatorOnClearFilterClick}
          //items select checkbox
          onSelectAllItemsClick={this.onSelectAllItemsClick}
          onSelectAllItemsOnPageClick={this.onSelectAllItemsOnPageClick}
          onUnselectAllItemsClick={this.onUnselectAllItemsClick}
          isSelectAllItemsChecked={isSelectAllItemsChecked}
          //items accumulator select checkbox
          onSelectAllItemsAccumulatorClick={this.onSelectAllItemsAccumulatorClick}
          onSelectAllItemsAccumulatorOnPageClick={this.onSelectAllItemsAccumulatorOnPageClick}
          onUnselectAllItemsAccumulatorClick={this.onUnselectAllItemsAccumulatorClick}
          isSelectAllItemsAccumulatorChecked={isSelectAllItemsAccumulatorChecked}
          //items sort
          sortItemsDirection={sortItemsDirection}
          sortItemsColumnsName={sortItemsColumnsName}
          onSortItemsClick={this.onSortItemsClick}
          //items accumulator sort
          sortItemsAccumulatorDirection={sortItemsAccumulatorDirection}
          sortItemsAccumulatorColumnsName={sortItemsAccumulatorColumnsName}
          onSortItemsAccumulatorClick={this.onSortItemsAccumulatorClick}
          itemsAddBuffer={itemsAddBuffer}
          itemsRemoveBuffer={itemsRemoveBuffer}
          onCellItemsClick={this.onClashClick}
          onCellItemsAccumulatorClick={this.onClashClick}
          removeItemFromBuffer={this.removeItemFromBuffer}
          onEditClick={this.handleEditClick}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {errorMessage && (
                <div style={{ color: 'red', fontWeight: 'bold', marginBottom: '1rem' }}>{errorMessage}</div>
              )}
              <Button text={'Save'} onClick={this.onClickSave} customClass={'btn-lg mt-3'} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
