import * as React from 'react';
import * as BPromise from 'bluebird';
import { AppUser } from '../App/App';
import { TopNavigation } from '../../components/TopNavigation/TopNavigation';
import { Role } from '../../models/roles';
import { UserProfile } from '../../models/profile';
import { getRoles, getSchool } from '../../helpers/service/admin/user';
import { getSchoolForms, getSchoolHouses } from 'Src/helpers/service/admin/user';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Loader } from '../../components/Loader/Loader';
import {
  adminRoutes,
  adminSURoutes,
  coachRoutes,
  managerRoutes,
  parentRoutes,
  studentRoutes,
  teacherRoutes
} from '../../routing/routing';
import { Route, Switch } from 'react-router';
import {
  isUserAdmin,
  isUserAdminSU,
  isUserCoach,
  isUserManager,
  isUserParent,
  isUserStudent,
  isUserTeacher
} from '../../helpers/user/user';
import { Permission } from '../../models/permission';
import { BurgerButton } from 'Src/components/BurgerButton/BurgerButton';
import { getRequestsCount } from '../../helpers/service/admin/requests';
import { getInboxMessagesCount } from 'Src/helpers/service/admin/messages';
import { getAdminSchoolsInboxInvitesCount } from 'Src/helpers/service/adminSU/schoolInvitesTable';
import { getParentInboxMessagesCount } from 'Src/helpers/service/parent/messagesTable';
import { getStudentInboxMessagesCount } from 'Src/helpers/service/student/messagesTable';
import { getSchoolWaitingListCount } from 'Src/helpers/service/admin/waitingList';
import { getSchoolUpcomingTournamentsCount } from 'Src/helpers/service/admin/tournaments';
import { WINDOW_BREAKPOINT_FOR_SIDEBAR } from 'Src/consts/responsive';
import { getParentOutboxMessagesCount } from 'Src/helpers/service/parent/messagesTable';
import { getStudentOutboxMessagesCount } from 'Src/helpers/service/student/messagesTable';
import { getBasket } from 'Src/helpers/service/parent/basket';
import { getInboxTournamentConfirmationRequestsCount } from 'Src/helpers/service/admin/messages';
import './GenericView.css';

interface Props {
  user: AppUser;
  onLogoutClick: (event) => void;
  setAppDefaultState: () => void;
  onRoleChange: (
    roleName: string,
    permissionSchoolId: string,
    permissionSchoolName: string,
    permission: Permission
  ) => any;
  onSchoolUpdate: () => void;
  updateProfile: (profile: UserProfile) => void;
  history: any;
  roleOut: () => void;
}

interface State {
  availableRoles: Role[];
  isLoading: boolean;
  needUpdate: boolean;
  isSidebarOpen: boolean;
  windowWidth: number;
  basketOrdersCount: string;
  school: any;
  countBadges: {
    requests: string;
    bookings: string;
    inboxMessages: string;
    invites: string;
    waitingList: string;
    upcomingTournaments: string;
    availabilityReports: string;
    absenceRequests: string;
    tournamentConfirmationRequests: string;
  };
}

export class GenericView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      availableRoles: [],
      isLoading: true,
      needUpdate: false,
      isSidebarOpen: true,
      windowWidth: 0,
      basketOrdersCount: '',
      school: null,
      countBadges: {
        requests: '',
        bookings: '',
        inboxMessages: '',
        invites: '',
        waitingList: '',
        upcomingTournaments: '',
        availabilityReports: '',
        absenceRequests: '',
        tournamentConfirmationRequests: ''
      }
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);

    this.setState({
      windowWidth: window.innerWidth,
      isSidebarOpen: window.innerWidth > WINDOW_BREAKPOINT_FOR_SIDEBAR
    });

    this.getRoles();
    this.setCountsForBadges();
    this.setCountForBasket();

    // Fetch school data if necessary
    this.fetchSchoolDataIfNeeded(this.props.history.location.pathname);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    const { needUpdate } = this.state;

    if (needUpdate) {
      this.setCountForBasket();
      this.setCountsForBadges();
      this.unsetNeedUpdate();
    }

    // Fetch school data if the route changes to import-students
    if (this.props.history.location.pathname !== prevProps.history.location.pathname) {
      this.fetchSchoolDataIfNeeded(this.props.history.location.pathname);
    }
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getRoles() {
    const user = this.props.user;
    this.setState({ isLoading: true });

    getRoles(user).then((roles: Role[]) => {
      this.setState({
        availableRoles: roles,
        isLoading: false
      });
    });
  }

  fetchSchoolDataIfNeeded(pathname: string) {
    if (pathname === '/import-students' && !this.state.school) {
      this.fetchSchoolData();
    }
  }

  fetchSchoolData() {
    const { user } = this.props;
    this.setState({ isLoading: true });

    getSchool(user, user.activeSchoolId)
      .then(school => {
        // Fetch forms and houses
        return Promise.all([school, getSchoolForms(user), getSchoolHouses(user)]);
      })
      .then(([school, forms, houses]) => {
        // Attach forms and houses to the school object
        const fullSchoolData = { ...school, forms, houses };
        this.setState({ school: fullSchoolData, isLoading: false });
      })
      .catch(err => {
        console.error('Failed to fetch school data:', err);
        this.setState({ school: null, isLoading: false });
      });
  }

  setCountForBasket() {
    const { user } = this.props;

    if (isUserParent(user)) {
      getBasket(user).then(basket => {
        this.setState({
          basketOrdersCount: basket.items.length
        });
      });
    }
  }

  setCountsForBadges() {
    const { user } = this.props;
    const isAdmin = isUserAdmin(user);
    const isManager = isUserManager(user);
    const isCoach = isUserCoach(user);
    const isTeacher = isUserTeacher(user);
    const isParent = isUserParent(user);
    const isStudent = isUserStudent(user);

    if (isAdmin || isManager) {
      const requestsCount = getRequestsCount(user);
      const inboxMessagesCount = getInboxMessagesCount(user);
      const inboxInvitesCount = getAdminSchoolsInboxInvitesCount(user);
      const waitingListCount = getSchoolWaitingListCount(user);
      const upcomingTournaments = getSchoolUpcomingTournamentsCount(user);
      const tournamentConfirmationRequests = getInboxTournamentConfirmationRequestsCount(user, {});

      const promises = [
        requestsCount,
        inboxMessagesCount,
        inboxInvitesCount,
        waitingListCount,
        upcomingTournaments,
        tournamentConfirmationRequests
      ];

      return BPromise.all(promises).then(
        ([
          requestsCountObj,
          inboxMessagesCountObj,
          inboxInvitesCountObj,
          waitingListCount,
          upcomingTournaments,
          tournamentConfirmationRequests
        ]) => {
          const countBadgesUpdated = {
            requests: `${requestsCountObj.count}`,
            bookings: '',
            inboxMessages: `${inboxMessagesCountObj.count}`,
            invites: `${inboxInvitesCountObj.count}`,
            waitingList: `${waitingListCount.count}`,
            upcomingTournaments: `${upcomingTournaments.count}`,
            availabilityReports: '',
            absenceRequests: '',
            tournamentConfirmationRequests: `${tournamentConfirmationRequests}`
          };

          this.setState({
            countBadges: countBadgesUpdated
          });
        }
      );
    }

    if (isCoach || isTeacher) {
      const inboxMessagesCount = getInboxMessagesCount(user);
      const inboxInvitesCount = getAdminSchoolsInboxInvitesCount(user);

      const promises = [inboxMessagesCount, inboxInvitesCount];

      return BPromise.all(promises).then(([inboxMessagesCountObj, inboxInvitesCountObj]) => {
        const countBadgesUpdated = {
          requests: '',
          bookings: '',
          inboxMessages: `${inboxMessagesCountObj.count}`,
          invites: `${inboxInvitesCountObj.count}`,
          availabilityReports: '',
          waitingList: '',
          upcomingTournaments: '',
          absenceRequests: '',
          tournamentConfirmationRequests: ''
        };

        this.setState({
          countBadges: countBadgesUpdated
        });
      });
    }

    if (isParent) {
      const parentInboxMessagesCount = getParentInboxMessagesCount(user);
      const parentInboxConsentRequestsCount = getParentInboxMessagesCount(user, { kind: 'EventInvitationMessage' });
      const parentInboxClubBookingsCount = getParentInboxMessagesCount(user, { kind: 'ClubParticipantInviteMessage' });
      const parentOutboxAvailabilityReportCount = getParentOutboxMessagesCount(user, {
        kind: 'EventParticipationMessage'
      });
      const parentOutboxAbsenceRequestsCount = getParentOutboxMessagesCount(user, {
        kind: 'AbsenceMessage'
      });

      const promises = [
        parentInboxMessagesCount,
        parentInboxConsentRequestsCount,
        parentInboxClubBookingsCount,
        parentOutboxAvailabilityReportCount,
        parentOutboxAbsenceRequestsCount
      ];

      return BPromise.all(promises).then(
        ([
          parentInboxMessagesCountObj,
          parentInboxConsentRequestsCountObj,
          parentInboxClubBookingsCountObj,
          parentOutboxAvailabilityReportCountObj,
          parentOutboxAbsenceRequestsCountObj
        ]) => {
          const countBadgesUpdated = {
            requests: `${parentInboxConsentRequestsCountObj.count}`,
            bookings: `${parentInboxClubBookingsCountObj.count}`,
            inboxMessages: `${parentInboxMessagesCountObj.count}`,
            availabilityReports: `${parentOutboxAvailabilityReportCountObj.count}`,
            absenceRequests: `${parentOutboxAbsenceRequestsCountObj.count}`,
            invites: '',
            waitingList: '',
            upcomingTournaments: '',
            tournamentConfirmationRequests: ''
          };

          this.setState({
            countBadges: countBadgesUpdated
          });
        }
      );
    }

    if (isStudent) {
      const studentInboxMessagesCount = getStudentInboxMessagesCount(user);
      const studentInboxClubBookingsCount = getStudentInboxMessagesCount(user, {
        kind: 'ClubParticipantInviteMessage'
      });
      const studentOutboxAvailabilityReportsCount = getStudentOutboxMessagesCount(user, {
        kind: 'EventParticipationMessage'
      });

      const promises = [
        studentInboxMessagesCount,
        studentInboxClubBookingsCount,
        studentOutboxAvailabilityReportsCount
      ];

      return BPromise.all(promises).then(
        ([
          studentInboxMessagesCountObj,
          studentInboxClubBookingsCountObj,
          studentOutboxAvailabilityReportsCountObj
        ]) => {
          const countBadgesUpdated = {
            requests: '',
            bookings: `${studentInboxClubBookingsCountObj.count}`,
            inboxMessages: `${studentInboxMessagesCountObj.count}`,
            availabilityReports: `${studentOutboxAvailabilityReportsCountObj.count}`,
            invites: '',
            waitingList: '',
            upcomingTournaments: '',
            absenceRequests: '',
            tournamentConfirmationRequests: ''
          };

          this.setState({
            countBadges: countBadgesUpdated
          });
        }
      );
    }
  }

  setNeedUpdate = () => {
    this.setState({
      needUpdate: true
    });
  };

  unsetNeedUpdate = () => {
    this.setState({
      needUpdate: false
    });
  };

  onSidebarSwitch = () => {
    this.setState(state => ({
      isSidebarOpen: !state.isSidebarOpen
    }));
  };

  render() {
    const {
      isLoading,
      availableRoles,
      needUpdate,
      isSidebarOpen,
      countBadges,
      basketOrdersCount,
      windowWidth,
      school
    } = this.state;
    const { user } = this.props;

    const sidebarClasses = windowWidth > WINDOW_BREAKPOINT_FOR_SIDEBAR ? 'col-md-2 pr-0 pl-2' : 'pr-0 pl-2';

    const contentClasses =
      isSidebarOpen && windowWidth > WINDOW_BREAKPOINT_FOR_SIDEBAR ? 'col-md-10 mt-3' : 'col-12 mt-3';

    if (isLoading) {
      return <Loader />;
    }

    let routes;

    switch (true) {
      case isUserAdminSU(user):
        routes = adminSURoutes;
        break;
      case isUserParent(user):
        routes = parentRoutes;
        break;
      case isUserStudent(user):
        routes = studentRoutes;
        break;
      case isUserAdmin(user):
        routes = adminRoutes;
        break;
      case isUserManager(user):
        routes = managerRoutes;
        break;
      case isUserTeacher(user):
        routes = teacherRoutes;
        break;
      case isUserCoach(user):
        routes = coachRoutes;
        break;
      default:
        routes = studentRoutes;
    }

    return (
      <div className={'bGenericView'}>
        <TopNavigation {...this.props} availableRoles={availableRoles} basketOrdersCount={basketOrdersCount} />
        <div className="container-fluid">
          <div className="row">
            {isSidebarOpen && (
              <div className={sidebarClasses}>
                <Sidebar
                  {...this.props}
                  unsetNeedUpdate={this.unsetNeedUpdate}
                  needUpdate={needUpdate}
                  countBadges={countBadges}
                  onSidebarCloseClick={this.onSidebarSwitch}
                  windowWidth={windowWidth}
                />
              </div>
            )}

            <div className={contentClasses}>
              <div className="mb-2">
                <BurgerButton onClick={this.onSidebarSwitch} />
              </div>
              <Switch>
                {routes.map((route, i) => (
                  <Route
                    exact={route.exact}
                    key={`route_${i}`}
                    path={route.path}
                    render={routeProps => (
                      <route.component
                        {...this.props}
                        {...this.state}
                        setNeedUpdate={this.setNeedUpdate}
                        school={school} // Pass the school data to the routed component
                      />
                    )}
                  />
                ))}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
