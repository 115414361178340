import * as React from 'react';
import { FunctionComponent } from 'react';
import { ColumnDefinition, SelectOption } from '../../helpers/table/table';
import { FILTER_TYPE } from '../../consts/table';
import { MultiSelectField } from '../MultiSelectField/MultiselectField';
import { SelectField } from '../SelectField/SelectField';
import { DateIntervalField } from '../DateIntervalField/DateIntervalField';
import { TimeIntervalField } from '../TimeIntervalField/TimeIntervalField';
import { CountIntervalField } from '../CountIntervalField/CountIntervalField';
import { MultiSelectCheckboxField } from '../MultiSelectCheckboxField/MultiSelectCheckboxField';
import { AutocompleteFilter } from '../AutocompleteFilter/AutocompleteFilter';

interface Props {
  columns: ColumnDefinition[];
  filters: any;
  onTableFilterChange: (event, value: string, options?, index?: number, checkBoxIndex?: number) => void;
  onTableAutocompleteFilterChange?: (item: any, field: string) => any;
  onApplyFilterClick?: () => void;
  filterMinDateInterval?: Date;
  filterMaxDateInterval?: Date;
  autocompleteSearchFunctions?: any;
  autocompleteGetElementsTitles?: any;
  autocompleteDefaultTexts?: any;
  options?: {
    priority?: SelectOption[];
    invitationStatus?: SelectOption[];
    generalMessageStatus?: SelectOption[];
    generalMessageDeliveryStatus?: SelectOption[];
    clubMessageStatus?: SelectOption[];
    participant?: SelectOption[];
    ages?: SelectOption[];
    agesRecord?: SelectOption[];
    days?: SelectOption[];
    userStatus?: SelectOption[];
    forms?: SelectOption[];
    houses?: SelectOption[];
    genders?: SelectOption[];
    tag?: SelectOption[];
    role?: SelectOption[];
    clubStatus?: SelectOption[];
    permissionFormIds?: SelectOption[];
    permissionHouseIds?: SelectOption[];
    ageGroup?: SelectOption[];
    deliveryStatus?: SelectOption[];
    statusOptions?: SelectOption[];
    requestedPermissionPreset?: SelectOption[];
    messageType?: SelectOption[];
    status?: SelectOption[];
    season?: SelectOption[];
    eventTournamentGroup?: SelectOption[];
    action?: SelectOption[];
    tournamentAges?: SelectOption[];
    inviteStatus?: SelectOption[];
    participationStatisticAges?: SelectOption[];
    pupilsPremium?: SelectOption[];
    specialEducationNeeds?: SelectOption[];
    freeSchoolMeals?: SelectOption[];
    tournamentTag?: SelectOption[];
  };
}
// 13 it's enter button key code in Event object.
const ENTER_BUTTON_KEY = 13;

export const FilterRow2: FunctionComponent<Props> = props => {
  const {
    filters,
    columns,
    options,
    onTableFilterChange,
    filterMinDateInterval,
    filterMaxDateInterval,
    autocompleteSearchFunctions,
    autocompleteGetElementsTitles,
    autocompleteDefaultTexts,
    onTableAutocompleteFilterChange,
    onApplyFilterClick
  } = props;

  const onEnterPress = event => {
    event.keyCode === ENTER_BUTTON_KEY && onApplyFilterClick();
  };

  const row = columns.map((column, index) => {
    const { type, field, text } = column;

    switch (type) {
      case FILTER_TYPE.NONE: {
        return <th key={`${text}_filter_${index}`} />;
      }
      case FILTER_TYPE.TEXT: {
        return (
          <th key={`${text}_filter_${index}`}>
            <input
              onChange={event => {
                onTableFilterChange(event, field);
              }}
              onKeyDown={onEnterPress}
              value={filters[field]}
              className="form-control mMaxWidth250"
            />
          </th>
        );
      }
      case FILTER_TYPE.AUTOCOMPLETE: {
        return (
          <th key={`${text}_filter_${index}`}>
            <AutocompleteFilter
              searchFunction={autocompleteSearchFunctions[field]}
              getElementTitle={autocompleteGetElementsTitles[field]}
              customClass="mMaxWidth250"
              defaultText={autocompleteDefaultTexts[field]}
              onSelect={item => onTableAutocompleteFilterChange(item, field)}
              onEnterPress={onEnterPress}
            />
          </th>
        );
      }
      case FILTER_TYPE.SELECT: {
        return (
          <th key={`${text}_filter_${index}`}>
            <SelectField
              items={options[field]}
              field={field}
              onChange={onTableFilterChange}
              value={filters[field]}
              isEmptyOptionRequired
              onEnterPress={onEnterPress}
            />
          </th>
        );
      }
      case FILTER_TYPE.MULTISELECT: {
        let thStyle = {};
        if (field === 'ages' || field === 'gender') {
          thStyle = { minWidth: '130px' };
        }

        return (
          <th key={`${text}_filter_${index}`} style={thStyle}>
            <MultiSelectField
              items={options[field]}
              field={field}
              onChange={onTableFilterChange}
              value={filters[field]}
              onEnterPress={onEnterPress}
              customClass={field === 'gender' ? 'my-gender-select' : undefined}
            />
          </th>
        );
      }
      case FILTER_TYPE.CHECKBOX: {
        return (
          <th key={`${text}_filter_${index}`}>
            <MultiSelectCheckboxField
              items={options[field]}
              onChange={checkBoxIndex => {
                onTableFilterChange(options, field, undefined, index, checkBoxIndex);
              }}
              onEnterPress={onEnterPress}
            />
          </th>
        );
      }
      case FILTER_TYPE.DATE_INTERVAL: {
        return (
          <th key={`${text}_filter_${index}`}>
            <DateIntervalField
              onTableFilterChange={onTableFilterChange}
              field={field}
              to={filters[field] !== '' ? filters[field].to : ''}
              from={filters[field] !== '' ? filters[field].from : ''}
              filterMinDateInterval={filterMinDateInterval}
              filterMaxDateInterval={filterMaxDateInterval}
              onEnterPress={onEnterPress}
            />
          </th>
        );
      }
      case FILTER_TYPE.TIME_INTERVAL: {
        return (
          <th key={`${text}_filter_${index}`}>
            <TimeIntervalField
              onTableFilterChange={onTableFilterChange}
              field={field}
              to={filters[field] !== '' ? filters[field].to : ''}
              from={filters[field] !== '' ? filters[field].from : ''}
              onEnterPress={onEnterPress}
            />
          </th>
        );
      }
      case FILTER_TYPE.COUNT_INTERVAL: {
        return (
          <th key={`${text}_filter_${index}`} style={{ minWidth: '120px' }}>
            <CountIntervalField
              onTableFilterChange={onTableFilterChange}
              field={field}
              to={filters[field] !== '' ? filters[field].to : ''}
              from={filters[field] !== '' ? filters[field].from : ''}
              onEnterPress={onEnterPress}
            />
          </th>
        );
      }
    }
  });

  return (
    <>
      <th />
      {row}
    </>
  );
};

FilterRow2.displayName = 'FilterRow2';
