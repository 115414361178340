import * as React from 'react';
import { useState } from 'react';
import { AppUser } from 'Src/views/App/App';
import { getSchoolUnionSchools } from 'Src/helpers/service/adminSU/schoolsTable';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { Button } from 'Src/components/Button/Button';

interface SchoolUnionSelectSchoolFormProps {
  user: AppUser;
  isOpen: boolean;
  onClose: () => void;
  onContinue: (schoolId: string) => void;
}

export const SchoolUnionSelectSchoolForm: React.FC<SchoolUnionSelectSchoolFormProps> = ({
  user,
  isOpen,
  onClose,
  onContinue
}) => {
  const [searchText, setSearchText] = useState('');
  const [suggestedSchools, setSuggestedSchools] = useState<any[]>([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState('');

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchText(text);

    if (text.length < 2) {
      setSuggestedSchools([]);
      setSelectedSchoolId('');
      return;
    }

    try {
      const filter = {
        where: {
          name: { like: text, options: 'i' }
        },
        limit: 10,
        skip: 0,
        order: 'name ASC'
      };

      const results = await getSchoolUnionSchools(user, filter);
      setSuggestedSchools(results);
    } catch (err) {
      console.error('Error fetching school suggestions:', err);
    }
  };
  const handleSelectSchool = (schoolId: string) => {
    setSelectedSchoolId(schoolId);
  };

  const handleContinue = () => {
    if (!selectedSchoolId) return;
    onContinue(selectedSchoolId);
  };

  return (
    <SimpleModal isOpen={isOpen} onCloseClick={onClose} title="Select Your School" showFooter={false}>
      <div className="form-group">
        <label>School Name</label>
        <input
          className="form-control"
          type="text"
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Type at least 2 letters..."
        />
      </div>

      <ul style={{ maxHeight: '150px', overflowY: 'auto', listStyle: 'none', paddingLeft: 0 }}>
        {suggestedSchools.map(school => (
          <li
            key={school.id}
            onClick={() => handleSelectSchool(school.id)}
            style={{
              padding: '5px',
              cursor: 'pointer',
              backgroundColor: school.id === selectedSchoolId ? '#e0e0e0' : 'transparent'
            }}
          >
            {school.name}
          </li>
        ))}
      </ul>

      <div className="mt-3 d-flex">
        <Button text="Cancel" onClick={onClose} customClass="mr-3" />
        <Button text="Continue" onClick={handleContinue} />
      </div>
    </SimpleModal>
  );
};
