import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { Loader } from 'Src/components/Loader/Loader';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import { Grid2 } from 'Src/components/Grid/Grid2';
import {
  authorizeAbsentMessage,
  getAllInboxMessages,
  getInboxMessages,
  getInboxMessagesCount,
  unAuthorizeAbsentMessage
} from 'Src/helpers/service/admin/messages';
import { getCreatedAtWithTime, getMessageTypeByKind } from 'Src/helpers/accessor/accessor';
import { AbsenceMessageActionType, MessageDefinition } from 'Src/types/message';
import { ABSENCE_MESSAGE_ACTIONS, MESSAGE_TABS, MESSAGE_KIND } from 'Src/consts/message';
import { getSelectOptionsForMessageKindByType } from 'Src/helpers/table/select';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { Button } from 'Src/components/Button/Button';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import { MessageInboxOutboxSummary } from 'Src/components/MessageSummary/MessageInboxOutboxSummary';
import { MessageAdditionalSummary } from 'Src/components/MessageSummary/MessageAdditionalSummary';
import { EventParticipationMessage, TournamentConfirmationRequestMessage } from '../../../../models/message';
import { isEventParticipationMessage } from '../../../../helpers/message/message';
import { doGotItActionForEventMessage } from '../../../../helpers/service/admin/messages';
import { AvailabilityReportForm } from './AvailabilityReportForm/AvailabilityReportForm';
import { MESSAGE_INVITATION_STATUS } from 'Src/consts/message';
import { ConfirmationRequestForm } from './ConfirmationRequestForm/ConfirmationRequestForm';
import {
  rejectConfirmationRequest,
  acceptConfirmationRequest,
  setConfirmationRequestFields
} from '../../../../helpers/service/admin/messages';
import { MessageField } from 'Src/models/message';
import { TournamentConfirmationRequestSummary } from '../../../../components/MessageSummary/TournamentConfirmationRequestSummary';
import ApproveModal from './ApproveModal';
import { getSchoolEvent } from '../../../../helpers/service/admin/event';
import { SchoolEvent } from '../../../../models/event';
import { getEventGeneratedName } from '../../../../helpers/accessor/accessor';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Message type',
    field: 'messageType',
    isSort: false,
    type: FILTER_TYPE.SELECT,
    accessor: getMessageTypeByKind
  },
  {
    text: 'Student name',
    field: 'studentFirstName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['playerDetailsData', 'firstName']
  },
  {
    text: 'Student surname',
    field: 'studentLastName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['playerDetailsData', 'lastName']
  },
  {
    text: 'Created at',
    field: 'createdAt',
    isSort: false,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getCreatedAtWithTime
  }
];

interface State {
  items: MessageDefinition[];
  currentPage: number;
  selectedItems: MessageDefinition[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  userTags: any[];
  total: number;
  basePath: string;
  isApproveModalOpen: boolean;
  isErrorModalOpen: boolean;
  errorMessage: string;
  isAvailabilityReportModalOpen: boolean;
  isAbsenceMessageAuthorizeModalOpen: boolean;
  absenceMessageAction: AbsenceMessageActionType;
  authorizedComment: string;
  schoolEvent: SchoolEvent;
  isConfirmationRequestModalOpen: boolean;

  currentTabIndex: number;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
  setNeedUpdate: () => void;
}

export class MessagesInbox extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      schoolEvent: undefined,
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      userTags: [],
      total: 0,
      basePath: '',
      errorMessage: '',
      isErrorModalOpen: false,
      isAvailabilityReportModalOpen: false,
      isApproveModalOpen: false,
      isAbsenceMessageAuthorizeModalOpen: false,
      absenceMessageAction: '',
      authorizedComment: '',

      isConfirmationRequestModalOpen: false,

      currentTabIndex: 0
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters2(COLUMNS, search);

    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getInboxMessages(user, serverQueryFilter);
    const getItemsCountPromise = getInboxMessagesCount(user, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return BPromise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { user } = this.props;
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    const message = selectedItemsUpdated[0];
    const eventId = propz.get(message, ['eventId']);

    if (selectedItemsUpdated.length === 1 && typeof eventId !== 'undefined') {
      this.setState({
        isLoading: true
      });

      getSchoolEvent(user, eventId).then(schoolEvent => {
        this.setState({
          schoolEvent,
          isLoading: false,
          selectedItems: selectedItemsUpdated
        });
      });
    } else {
      this.setState({
        schoolEvent: undefined,
        selectedItems: selectedItemsUpdated
      });
    }
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    getAllInboxMessages(user, where).then(items => {
      const itemsFiltered = items.filter(user => {
        return selectedItems.every(selectedItem => selectedItem.id !== user.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    this.props.history.push({
      pathname: this.state.basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  onClickGotIt = () => {
    const { user, setNeedUpdate } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      isLoading: true
    });

    doGotItActionForEventMessage(user, selectedItems[0].id).then(() => {
      this.setState({
        isAvailabilityReportModalOpen: false,
        selectedItems: []
      });
      this.setItems().then(() => {
        setNeedUpdate();
      });
    });
  };

  onViewClick = () => {
    const { selectedItems } = this.state;
    const isViewAvailabilityReportActive = isEventParticipationMessage(selectedItems[0]);

    if (isViewAvailabilityReportActive) {
      this.setState({
        isAvailabilityReportModalOpen: true
      });
    }
  };

  closeAvailabilityReportModal = () => {
    this.setState({
      isAvailabilityReportModalOpen: false
    });
  };

  renderAvailabilityReportModal = () => {
    const { user } = this.props;
    const { isAvailabilityReportModalOpen, selectedItems } = this.state;
    const message = selectedItems[0] as EventParticipationMessage;

    return (
      <SimpleModal customClass="" isOpen={isAvailabilityReportModalOpen}>
        <AvailabilityReportForm
          user={user}
          message={message}
          onClickGotIt={this.onClickGotIt}
          closeAvailabilityReportModal={this.closeAvailabilityReportModal}
        />
      </SimpleModal>
    );
  };

  isMarkActionActive() {
    const { selectedItems } = this.state;
    const isSelectedItemsExist = selectedItems.length > 0;
    const isEveryItemActionPerformedSetToFalse = selectedItems.every(item => item.isActionPerformed === false);
    const isEveryItemsAbsenceMessage = selectedItems.every(item => item.kind === MESSAGE_KIND.AbsenceMessage);

    return isSelectedItemsExist && isEveryItemActionPerformedSetToFalse && isEveryItemsAbsenceMessage;
  }

  onAuthorizedClick = () => {
    const { user } = this.props;
    const { selectedItems, authorizedComment } = this.state;

    this.setState({
      isLoading: true,
      selectedItems: [],
      isSelectAllChecked: false,
      isAbsenceMessageAuthorizeModalOpen: false
    });

    BPromise.all(
      selectedItems.map(item => {
        return authorizeAbsentMessage(user, item.id, authorizedComment);
      })
    ).then(res => {
      this.setState({
        authorizedComment: ''
      });
      this.setItems();
    });
  };

  onUnAuthorizedClick = () => {
    const { user } = this.props;
    const { selectedItems, authorizedComment } = this.state;

    this.setState({
      isLoading: true,
      selectedItems: [],
      isSelectAllChecked: false,
      isAbsenceMessageAuthorizeModalOpen: false
    });

    BPromise.all(
      selectedItems.map(item => {
        return unAuthorizeAbsentMessage(user, item.id, authorizedComment);
      })
    ).then(res => {
      this.setState({
        authorizedComment: ''
      });
      this.setItems();
    });
  };

  onMarkAuthorizedClick = (): void => {
    this.setState({
      isAbsenceMessageAuthorizeModalOpen: true,
      absenceMessageAction: ABSENCE_MESSAGE_ACTIONS.Authorised
    });
  };

  onMarkUnAuthorizedClick = (): void => {
    this.setState({
      isAbsenceMessageAuthorizeModalOpen: true,
      absenceMessageAction: ABSENCE_MESSAGE_ACTIONS.Unauthorised
    });
  };

  onCommentChange = (event): void => {
    this.setState({
      authorizedComment: event.target.value
    });
  };

  onModalClose = () => {
    this.setState({
      isAbsenceMessageAuthorizeModalOpen: false
    });
  };

  renderAbsenceMessageAuthorizeModal(): React.ReactNode {
    const { isAbsenceMessageAuthorizeModalOpen, absenceMessageAction, authorizedComment } = this.state;

    switch (absenceMessageAction) {
      case ABSENCE_MESSAGE_ACTIONS.Authorised:
        return (
          <SimpleModal
            isOpen={isAbsenceMessageAuthorizeModalOpen}
            title={'Authorise'}
            onCloseClick={this.onModalClose}
            onButtonClick={this.onAuthorizedClick}
            buttonText={'Authorise'}
          >
            <div className="form-group">
              <label>Comment</label>
              <textarea rows={3} onChange={this.onCommentChange} value={authorizedComment} className="form-control" />
            </div>
          </SimpleModal>
        );
      case ABSENCE_MESSAGE_ACTIONS.Unauthorised:
        return (
          <SimpleModal
            isOpen={isAbsenceMessageAuthorizeModalOpen}
            title={'Unauthorise'}
            onCloseClick={this.onModalClose}
            onButtonClick={this.onUnAuthorizedClick}
            buttonText={'Unauthorise'}
          >
            <div className="form-group">
              <label>Comment</label>
              <textarea rows={3} onChange={this.onCommentChange} value={authorizedComment} className="form-control" />
            </div>
          </SimpleModal>
        );
    }
  }

  renderMessageTable(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total
    } = this.state;

    const isViewAvailabilityReportActive = selectedItems.length === 1 && isEventParticipationMessage(selectedItems[0]);

    const actionItems = [
      {
        itemText: 'Mark as authorised',
        onItemClick: this.onMarkAuthorizedClick,
        isActive: this.isMarkActionActive()
      },
      {
        itemText: 'Mark as unauthorised',
        onItemClick: this.onMarkUnAuthorizedClick,
        isActive: this.isMarkActionActive()
      },
      {
        itemText: 'View',
        onItemClick: this.onViewClick,
        isActive: isViewAvailabilityReportActive
      }
    ];

    const filterOptions = {
      messageType: getSelectOptionsForMessageKindByType()
    };

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
      />
    );
  }

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  getSummaryTabs(): React.ReactNode {
    const { user } = this.props;
    const { currentTabIndex, selectedItems, schoolEvent } = this.state;

    const message = selectedItems[0];
    const { kind } = message;
    const isTournamentConfirmationRequestMessage = kind === MESSAGE_KIND.TournamentConfirmationRequestMessage;

    let eventName = '';
    if (typeof schoolEvent !== 'undefined') {
      eventName = getEventGeneratedName(schoolEvent, { user: user });
    }

    switch (currentTabIndex) {
      case 0:
        if (isTournamentConfirmationRequestMessage) {
          return <TournamentConfirmationRequestSummary message={message} />;
        }
        return (
          <MessageInboxOutboxSummary message={message} user={user} schoolEvent={schoolEvent} eventName={eventName} />
        );

      case 1:
        return <MessageAdditionalSummary message={message} />;
    }
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      currentTabIndex: index
    });
  };

  onViewConfirmationRequestButtonClick = (): void => {
    this.setState({
      isConfirmationRequestModalOpen: true
    });
  };

  onCloseConfirmationRequestClick = (): void => {
    this.setState({
      isConfirmationRequestModalOpen: false
    });
  };

  onDeclineConfirmationRequestClick = (): void => {
    const { user } = this.props;
    const message = this.state.selectedItems[0];
    rejectConfirmationRequest(user, message.id).then(() => {
      this.setState({
        isConfirmationRequestModalOpen: false,
        selectedItems: []
      });
      this.setItems();
    });
    this.onApproveModalClose();
  };

  renderApproveModal = () => {
    const { isApproveModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isApproveModalOpen}>
        <ApproveModal onClose={this.onApproveModalClose} onApprove={this.onDeclineConfirmationRequestClick} />
      </SimpleModal>
    );
  };

  onApproveModalShow = () => {
    this.setState({
      isApproveModalOpen: true
    });
  };

  onApproveModalClose = () => {
    this.setState({
      isApproveModalOpen: false
    });
  };

  onSubmitConfirmationRequest = (data: MessageField[]): void => {
    const { user } = this.props;
    const message = this.state.selectedItems[0];
    acceptConfirmationRequest(user, message.id)
      .then(() => {
        return setConfirmationRequestFields(user, message.id, data);
      })
      .then(() => {
        this.setState({
          isConfirmationRequestModalOpen: false,
          selectedItems: []
        });
        this.setItems();
      });
  };

  renderConfirmationRequestModal = () => {
    const { user } = this.props;
    const { isConfirmationRequestModalOpen: isViewConfirmationRequestModalOpen, selectedItems } = this.state;
    const message = selectedItems[0] as TournamentConfirmationRequestMessage;

    return (
      <SimpleModal customClass="" isOpen={isViewConfirmationRequestModalOpen}>
        <ConfirmationRequestForm
          message={message}
          isEditable={true}
          onAcceptClick={this.onSubmitConfirmationRequest}
          onCloseClick={this.onCloseConfirmationRequestClick}
        />
      </SimpleModal>
    );
  };

  renderSummary(): React.ReactNode {
    const isOneItemSelected = this.isOneItemSelected();

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a message above</div>;
    }

    const { currentTabIndex, selectedItems } = this.state;
    const summaryTabs = this.getSummaryTabs();
    const message = selectedItems[0];
    const { kind } = message;

    const isTournamentConfirmationRequestMessage = kind === MESSAGE_KIND.TournamentConfirmationRequestMessage;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={MESSAGE_TABS} currentTabIndex={currentTabIndex}>
        {isTournamentConfirmationRequestMessage && (
          <Button onClick={this.onViewConfirmationRequestButtonClick} text={'View'} customClass={'mt-3 mb-3 mr-3'} />
        )}
        {summaryTabs}
      </GenericSummary>
    );
  }

  render() {
    const { isLoading, isAbsenceMessageAuthorizeModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isAbsenceMessageAuthorizeModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderAbsenceMessageAuthorizeModal()}
        {this.renderAvailabilityReportModal()}
        {this.renderConfirmationRequestModal()}
        {this.renderApproveModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderMessageTable()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
