import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import { Component } from 'react';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { TwoPanelEditor } from 'Src/components/TwoPanelEditor/TwoPanelEditor';
import { Loader } from 'Src/components/Loader/Loader';
import { DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE } from 'Src/consts/table';
import { AGE_GROUPS } from 'Src/consts/school';
import { getTwoPanelEditorFilters } from 'Src/helpers/twoPanelEditor/twoPanelEditor';
import { DATE_INTERVAL, LIMIT } from 'Src/consts/table';
import {
  ColumnDefinition,
  getOrder,
  getServerFieldSectionWhere,
  isFilterExist2,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { Button } from 'Src/components/Button/Button';
import { getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { AppUser } from 'Src/views/App/App';
import {
  getAllSchoolTournamentTeamPlayers,
  getSchoolTournamentTeam,
  getSchoolTournamentTeamPlayersCount,
  SchoolTournamentTeamPlayersBatchData,
  updateSchoolTournamentTeamPlayersBatch
} from 'Src/helpers/service/admin/tournamentTeamPlayers';
import {
  addSchoolUnionSchoolStudent,
  updateSchoolUnionSchoolStudent,
  getAllSchoolUnionSchoolForms
} from 'Src/helpers/service/adminSU/schoolStudentsTable';
import { SchoolForm } from 'Src/models/form';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import { getSchoolStudents, getSchoolStudentsCount } from 'Src/helpers/service/admin/students';
import { getGender, getAge } from 'Src/helpers/accessor/accessor';
import { getSelectOptionForAge, getSelectOptionForGender } from 'Src/helpers/table/select';
import { getUserGenderArrayConvertedFromEventGender } from 'Src/helpers/tournament/tournament';
import { TournamentTeamPlayer } from 'Src/models/tournamentTeamPlayer';
import { Count } from 'Src/models/count';
import { getSchoolStudentsList } from 'Src/helpers/service/adminSU/schoolTeamPlayers';
import { SchoolStudent } from 'Src/models/schoolStudent';
import { SchoolUnionSchoolStudentForm } from 'Src/components/SchoolUnionSchoolStudentForm/SchoolUnionSchoolStudentForm';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { SchoolUnionSelectSchoolForm } from 'Src/components/SchoolUnionSelectSchoolForm/SchoolUnionSelectSchoolForm';
import { Checkbox } from 'Src/components/Checkbox/Checkbox';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface NormalizedTeamPlayer {
  id: string;
  playerId: string;
  permissionId: string;
  form: any;
  gender: string;
  firstName: string;
  lastName: string;
}

interface State {
  items: SchoolStudent[];
  itemsSelected: SchoolStudent[];
  itemsCount: number;

  itemsFilters: any;
  isShowItemsFilter: boolean;
  isItemsFiltered: boolean;

  isSelectAllItemsChecked: boolean;

  sortItemsDirection: TABLE_SORT_DIRECTION;
  sortItemsColumnsName: string;

  itemCurrentPage: number;

  itemsAccumulatorInitial: NormalizedTeamPlayer[];
  itemsAccumulator: NormalizedTeamPlayer[];
  itemsAccumulatorSelected: NormalizedTeamPlayer[];
  itemsAccumulatorCount: number;

  itemsAccumulatorFilters: any;
  isShowItemsAccumulatorFilter: boolean;
  isItemsAccumulatorFiltered: boolean;

  isSelectAllItemsAccumulatorChecked: boolean;

  sortItemsAccumulatorDirection: TABLE_SORT_DIRECTION;
  sortItemsAccumulatorColumnsName: string;

  tournament: any;
  team: any;
  forms: SchoolForm[];

  isLoading: boolean;

  itemsAddBuffer: SchoolStudent[];
  itemsRemoveBuffer: (NormalizedTeamPlayer | SchoolStudent)[];

  studentEventsModalText: string;
  isStudentEventsModalOpen: boolean;

  isShowYounger: boolean;

  isSelectSchoolModalOpen: boolean;
  chosenSchoolId: string;

  isAddStudentModalOpen: boolean;
  studentToAdd: SchoolStudent | null;

  isEditStudentModalOpen: boolean;
  studentToEdit: SchoolStudent | null;

  selectedSchoolId: string;
  errorMessage: string;
}

const DEFAULT_TEAM_PLAYERS_ORDER = 'firstName ASC';

export class HighLevelTournamentTeamPlayersEdit extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      itemsCount: 0,
      itemsSelected: [],

      itemsFilters: {},
      isShowItemsFilter: false,
      isItemsFiltered: false,

      isSelectAllItemsChecked: false,

      sortItemsDirection: '',
      sortItemsColumnsName: '',

      itemCurrentPage: 1,

      itemsAccumulatorInitial: [],
      itemsAccumulator: [],
      itemsAccumulatorCount: 0,
      itemsAccumulatorSelected: [],

      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,

      isSelectAllItemsAccumulatorChecked: false,

      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',

      tournament: undefined,
      team: undefined,
      forms: [],

      isLoading: false,

      itemsAddBuffer: [],
      itemsRemoveBuffer: [],

      studentEventsModalText: '',
      isStudentEventsModalOpen: false,

      isShowYounger: false,

      isSelectSchoolModalOpen: false,
      chosenSchoolId: '',

      isAddStudentModalOpen: false,
      studentToAdd: null,

      isEditStudentModalOpen: false,
      studentToEdit: null,

      selectedSchoolId: '',
      errorMessage: ''
    };
  }

  private getTeamPlayersNormalized(teamPlayers: TournamentTeamPlayer[]): NormalizedTeamPlayer[] {
    return teamPlayers.map(tp => {
      const { id, userId, form, permissionId, gender, firstName, lastName } = tp;

      return {
        id: userId,
        playerId: id,
        form,
        permissionId,
        gender,
        firstName,
        lastName
      };
    });
  }

  getItemsColumns = (): ColumnDefinition[] => [
    {
      text: 'Name',
      field: 'firstName',
      accessor: ['firstName'],
      type: FILTER_TYPE.TEXT,
      isSort: false
    },
    {
      text: 'Surname',
      field: 'lastName',
      accessor: ['lastName'],
      type: FILTER_TYPE.TEXT,
      isSort: false
    },
    {
      text: 'School',
      field: 'schoolName',
      accessor: ['schoolName'],
      type: FILTER_TYPE.NONE,
      isSort: false
    },
    {
      text: 'Age',
      field: 'ages',
      isSort: false,
      type: FILTER_TYPE.MULTISELECT,
      accessor: getAge
    },
    {
      text: 'Gender',
      field: 'gender',
      isSort: false,
      type: FILTER_TYPE.MULTISELECT,
      accessor: getGender
    },
    this.getActionsColumn(this.handleEditClick)
  ];

  getItemsAccumulatorColumns = (): ColumnDefinition[] => [
    {
      text: 'Name',
      field: 'firstName',
      accessor: ['firstName'],
      type: FILTER_TYPE.NONE,
      isSort: false
    },
    {
      text: 'Surname',
      field: 'lastName',
      accessor: ['lastName'],
      type: FILTER_TYPE.NONE,
      isSort: false
    },
    {
      text: 'School',
      field: 'schoolName',
      accessor: ['schoolName'],
      type: FILTER_TYPE.NONE,
      isSort: false
    },
    {
      text: 'Form',
      field: 'forms',
      isSort: false,
      type: FILTER_TYPE.NONE,
      accessor: ['form', 'name']
    },
    {
      text: 'Gender',
      field: 'gender',
      isSort: false,
      type: FILTER_TYPE.NONE,
      accessor: getGender
    }
  ];

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournamentId;
    return tournamentId as string;
  }

  getTeamId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const teamId = search.teamId;
    return teamId as string;
  }

  getActionsColumn = (onEditClick?: (item: SchoolStudent) => void): ColumnDefinition => ({
    text: '',
    field: 'actions',
    type: FILTER_TYPE.NONE,
    isSort: false,
    cell: (item: SchoolStudent) => {
      if (!onEditClick) return null;
      return (
        <Button
          onClick={e => {
            e.stopPropagation();
            onEditClick(item);
          }}
          customClass="btn-icon ml-3"
          text={[
            <FontAwesomeIcon
              icon={faEdit}
              key={`edit-icon-${item.id}`}
              style={{ color: '#3baeda', fontSize: '1rem' }}
            />
          ]}
          aria-label={`Edit ${item.firstName} ${item.lastName}`}
        />
      );
    }
  });

  componentDidMount() {
    this.setState({ isLoading: true });

    const { user } = this.props;
    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    const itemsFilters = getTwoPanelEditorFilters(this.getItemsColumns());
    const isShowItemsFilter = isFilterExist2(itemsFilters);

    const itemsAccumulatorFilters = getTwoPanelEditorFilters(this.getItemsAccumulatorColumns());
    const isShowItemsAccumulatorFilter = isFilterExist2(itemsAccumulatorFilters);
    const isItemsAccumulatorFiltered = isFilterExist2(itemsAccumulatorFilters);

    BPromise.all([
      getTournament(user, tournamentId),
      getSchoolTournamentTeam(user, tournamentId, teamId),
      getAllForms(user)
    ])
      .then(([fetchedTournament, fetchedTeam, fetchedForms]) => {
        const defaultItemsFilter = this.getDefaultItemsFilter(fetchedTeam, fetchedForms);

        this.setState({
          tournament: fetchedTournament,
          team: fetchedTeam,
          forms: fetchedForms,
          itemsFilters: defaultItemsFilter
        });

        return this.getItemsAndItemsAccumulator();
      })
      .then(({ teamPlayersInitial, teamPlayers, teamPlayersCount, students, studentsCount }) => {
        const normalizedInitial = this.getTeamPlayersNormalized(teamPlayersInitial);
        const normalizedPlayers = this.getTeamPlayersNormalized(teamPlayers);

        this.setState({
          items: students,
          itemsCount: studentsCount.length,
          isShowItemsFilter,
          isItemsFiltered: true,

          itemsAccumulatorInitial: normalizedInitial,
          itemsAccumulator: normalizedPlayers,
          itemsAccumulatorCount: teamPlayersCount.count,
          itemsAccumulatorFilters,
          isShowItemsAccumulatorFilter,
          isItemsAccumulatorFiltered,

          isLoading: false
        });
      });
  }

  getItemsAndItemsAccumulator() {
    const { user } = this.props;
    const { itemsAccumulatorFilters } = this.state;
    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    const where = getServerFieldSectionWhere(itemsAccumulatorFilters);

    const getTeamPlayersInitialPromise = getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId);
    const getTeamPlayersPromise = getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId, where);
    const getTeamPlayersCountPromise = getSchoolTournamentTeamPlayersCount(user, tournamentId, teamId, where);

    let teamPlayersInitial: TournamentTeamPlayer[];
    let teamPlayers: TournamentTeamPlayer[];
    let teamPlayersCount: Count;

    return BPromise.all([getTeamPlayersInitialPromise, getTeamPlayersPromise, getTeamPlayersCountPromise])
      .then(([tpInitial, tpFiltered, tpCount]) => {
        teamPlayersInitial = tpInitial;
        teamPlayers = tpFiltered;
        teamPlayersCount = tpCount;

        const filter = this.getItemsFilter(1, teamPlayersInitial, []);
        const filterAll = this.getItemsFilter(1, teamPlayersInitial, [], true);

        const { user } = this.props;
        return BPromise.all([getSchoolStudentsList(user, filter), getSchoolStudentsList(user, filterAll)]);
      })
      .then(([students, studentsCount]) => {
        return {
          teamPlayersInitial,
          teamPlayers,
          teamPlayersCount,
          students,
          studentsCount
        };
      });
  }

  onItemClick = (index: number): void => {
    const { items, itemsSelected } = this.state;
    const selectedItem = items[index];

    const existingIndex = itemsSelected.findIndex(i => i.id === selectedItem.id);
    const next = [...itemsSelected];
    if (existingIndex !== -1) {
      next.splice(existingIndex, 1);
    } else {
      next.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: [],
      itemsSelected: next
    });
  };

  onItemAccumulatorClick = (index: number): void => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;
    const clickedItem = itemsAccumulator[index];

    const existingIndex = itemsAccumulatorSelected.findIndex(i => i.id === clickedItem.id);
    const next = [...itemsAccumulatorSelected];
    if (existingIndex !== -1) {
      next.splice(existingIndex, 1);
    } else {
      next.push(clickedItem);
    }

    this.setState({
      itemsAccumulatorSelected: next,
      itemsSelected: []
    });
  };

  onAddClick = () => {
    const { itemsSelected, itemsAddBuffer, itemsAccumulatorInitial, itemCurrentPage } = this.state;
    const { user } = this.props;

    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];

    this.setState({ isLoading: true });

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer);
    const filterAll = this.getItemsFilter(1, itemsAccumulatorInitial, [], true);

    BPromise.all([getSchoolStudentsList(user, filter), getSchoolStudentsList(user, filterAll)]).then(
      ([filtered, all]) => {
        this.setState({
          items: filtered,
          itemsCount: all.length,
          itemsAddBuffer: nextItemsAddBuffer,
          itemsAccumulatorSelected: [],
          itemsSelected: [],
          isSelectAllItemsAccumulatorChecked: false,
          isSelectAllItemsChecked: false,
          isLoading: false
        });
      }
    );
  };

  onRemoveClick = () => {
    const {
      itemsAccumulatorSelected,
      itemsAddBuffer,
      itemsRemoveBuffer,
      itemsAccumulatorInitial,
      itemsAccumulator,
      itemCurrentPage
    } = this.state;
    const { user } = this.props;

    const nextItemsRemoveBuffer = [...itemsAccumulatorSelected, ...itemsRemoveBuffer];

    const nextItemsAccumulatorInitial = itemsAccumulatorInitial.filter(accItem =>
      nextItemsRemoveBuffer.every(rem => rem.id !== accItem.id)
    );
    const nextItemsAccumulator = itemsAccumulator.filter(accItem =>
      nextItemsRemoveBuffer.every(rem => rem.id !== accItem.id)
    );

    this.setState({ isLoading: true });

    const filter = this.getItemsFilter(itemCurrentPage, nextItemsAccumulatorInitial, itemsAddBuffer);
    const filterAll = this.getItemsFilter(1, nextItemsAccumulatorInitial, [], true);

    BPromise.all([getSchoolStudentsList(user, filter), getSchoolStudentsList(user, filterAll)]).then(
      ([filtered, all]) => {
        this.setState({
          items: filtered,
          itemsCount: all.length,
          itemsAccumulatorSelected: [],
          itemsSelected: [],
          isSelectAllItemsAccumulatorChecked: false,
          isSelectAllItemsChecked: false,
          itemsRemoveBuffer: nextItemsRemoveBuffer,
          itemsAccumulatorInitial: nextItemsAccumulatorInitial,
          itemsAccumulator: nextItemsAccumulator,
          itemsAccumulatorCount: nextItemsAccumulator.length,
          isLoading: false
        });
      }
    );
  };

  removeItemFromBuffer = (itemId: string) => {
    this.setState(prev => {
      const found = prev.itemsAddBuffer.find(x => x.id === itemId);
      const updated = prev.itemsAddBuffer.filter(x => x.id !== itemId);

      const updatedItems = found ? [...prev.items, found] : prev.items;
      return {
        itemsAddBuffer: updated,
        items: updatedItems
      };
    });
  };

  onClickSave = () => {
    const { itemsAddBuffer, itemsRemoveBuffer, team, forms } = this.state;
    const { user } = this.props;

    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();

    const data: SchoolTournamentTeamPlayersBatchData = {
      add: itemsAddBuffer.map(item => ({
        userId: item.id,
        permissionId: item.permissionId
      })),
      remove: itemsRemoveBuffer
        .map(remItem => {
          const maybeTP = remItem as NormalizedTeamPlayer;
          return maybeTP.playerId || null;
        })
        .filter(Boolean)
    };

    const defaultItemsFilter = this.getDefaultItemsFilter(team, forms);

    this.setState({
      isLoading: true,
      itemsSelected: [],
      itemsFilters: defaultItemsFilter,
      isShowItemsFilter: false,
      isItemsFiltered: true,
      isSelectAllItemsChecked: false,
      sortItemsDirection: '',
      sortItemsColumnsName: '',
      itemCurrentPage: 1,
      itemsAccumulatorSelected: [],
      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,
      isSelectAllItemsAccumulatorChecked: false,
      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',
      itemsAddBuffer: [],
      itemsRemoveBuffer: []
    });

    updateSchoolTournamentTeamPlayersBatch(user, tournamentId, teamId, data)
      .then(() => {
        return getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId);
      })
      .then(unfilteredTeamPlayers => {
        const normalizedFinalTeam = this.getTeamPlayersNormalized(unfilteredTeamPlayers);

        this.setState({
          itemsAccumulatorInitial: normalizedFinalTeam,
          itemsAccumulator: normalizedFinalTeam
        });

        const filter = this.getItemsFilter(1, normalizedFinalTeam, []);
        return BPromise.all([
          getSchoolStudentsList(user, filter),
          getSchoolStudentsList(user, { ...filter, limit: DEFAULT_LIMIT })
        ]);
      })
      .then(([rightSide, rightSideAll]) => {
        this.setState({
          items: rightSide,
          itemsCount: rightSideAll.length,
          isLoading: false
        });
      })
      .catch(err => {
        console.error('Error on save:', err);
        this.setState({
          isLoading: false,
          errorMessage: 'Failed to save changes.'
        });
      });
  };

  onItemsFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (filterField === 'ages') {
      nextFilters.forms = [];
    }

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const col = this.getItemsColumns().find(c => c.field === filterField);
      if (!col) return;

      const filterType = col.type;
      if (filterType === FILTER_TYPE.MULTISELECT) {
        const opts = event.target.options;
        const value: string[] = [];
        for (let i = 0; i < opts.length; i++) {
          if (opts[i].selected) {
            value.push(opts[i].value);
          }
        }
        nextFilters[filterField] = value;
      } else {
        nextFilters[filterField] = filterValue;
      }
    }

    this.setState({ itemsFilters: nextFilters });
  };

  onItemsAccumulatorFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsAccumulatorFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const col = this.getItemsColumns().find(c => c.field === filterField);
      if (!col) return;

      const filterType = col.type;
      if (filterType === FILTER_TYPE.MULTISELECT) {
        const opts = event.target.options;
        const value: string[] = [];
        for (let i = 0; i < opts.length; i++) {
          if (opts[i].selected) {
            value.push(opts[i].value);
          }
        }
        nextFilters[filterField] = value;
      } else {
        nextFilters[filterField] = filterValue;
      }
    }

    this.setState({ itemsAccumulatorFilters: nextFilters });
  };

  onItemsFilterClick = (event): void => {
    event.preventDefault();
    this.setState(prev => ({
      isShowItemsFilter: !prev.isShowItemsFilter
    }));
  };

  onItemsAccumulatorFilterClick = (event): void => {
    event.preventDefault();
    this.setState(prev => ({
      isShowItemsAccumulatorFilter: !prev.isShowItemsAccumulatorFilter
    }));
  };

  getItemsFilter(
    page: number,
    itemsAccumulator: (TournamentTeamPlayer | NormalizedTeamPlayer)[],
    itemsAddBuffer: SchoolStudent[],
    isAllItems = false
  ) {
    const { itemsFilters, forms } = this.state;

    const {
      gender: itemsFiltersGender,
      ages: itemsFiltersAges = [],
      firstName: itemsFiltersFirstName = '',
      lastName: itemsFiltersLastName = '',
      schoolName: itemsFiltersSchoolName = '',
      forms: itemsFiltersForms = []
    } = itemsFilters;

    let where: any = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allLeft = [...itemsAccumulator, ...itemsAddBuffer];
    const ninIds = Lazy(allLeft)
      .map(it => it.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    if (itemsFiltersForms.length > 0) {
      propz.set(where, ['formId', '$in'], itemsFiltersForms);
    } else {
      const ages = itemsFiltersAges.length > 0 ? itemsFiltersAges : AGE_GROUPS.ENGLISH.map((_, idx) => idx);
      const formIds = forms.filter(f => ages.some(a => +f.age === +a)).map(f => f.id);
      if (formIds.length > 0) {
        propz.set(where, ['formId', '$in'], formIds);
      }
    }

    const andArr: any[] = [];
    if (itemsFiltersFirstName.length > 0) {
      andArr.push({ firstName: { like: itemsFiltersFirstName, options: 'i' } });
    }
    if (itemsFiltersLastName.length > 0) {
      andArr.push({ lastName: { like: itemsFiltersLastName, options: 'i' } });
    }
    if (andArr.length > 0) {
      propz.set(where, ['$and'], andArr);
    }

    return {
      where,
      limit: isAllItems ? DEFAULT_LIMIT : LIMIT,
      skip: isAllItems ? 0 : (page - 1) * LIMIT,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };
  }

  itemsSetCurrentPageParams = (currentPage: number): void => {
    const { user } = this.props;
    const { itemsAccumulator, sortItemsColumnsName, sortItemsDirection, itemsAddBuffer } = this.state;

    this.setState({ isLoading: true });

    let orderForFilter = undefined;
    if (sortItemsColumnsName && sortItemsDirection) {
      orderForFilter = `${sortItemsColumnsName} ${sortItemsDirection}`;
    }

    const filter = this.getItemsFilter(currentPage, itemsAccumulator, itemsAddBuffer, !!orderForFilter);
    getSchoolStudents(user, filter).then(students => {
      this.setState({
        itemCurrentPage: currentPage,
        items: students,
        isLoading: false
      });
    });
  };

  itemsOnApplyFilterClick = () => {
    const { itemsAccumulator, itemsAddBuffer } = this.state;
    this.setState({ isLoading: true, isShowYounger: false });

    const filter = this.getItemsFilter(1, itemsAccumulator, itemsAddBuffer);
    const filterAll = this.getItemsFilter(1, itemsAccumulator, [], true);

    const { user } = this.props;
    BPromise.all([getSchoolStudentsList(user, filter), getSchoolStudentsList(user, filterAll)]).then(
      ([filtered, all]) => {
        this.setState({
          items: filtered,
          itemsCount: all.length,
          isLoading: false,
          itemsAccumulatorSelected: [],
          itemsSelected: [],
          itemCurrentPage: 1,
          sortItemsDirection: '',
          sortItemsColumnsName: ''
        });
      }
    );
  };

  itemsAccumulatorOnApplyFilterClick = () => {};

  itemsOnClearFilterClick = () => {
    const { itemsAccumulatorInitial, itemsAddBuffer, team, forms } = this.state;

    const defaultItemsFilter = this.getDefaultItemsFilter(team, forms);

    const { ages } = team;

    this.setState({ isLoading: true, itemsFilters: defaultItemsFilter, isShowYounger: false });

    const { gender: itemsFiltersGender } = defaultItemsFilter;
    let where: any = { gender: { $in: itemsFiltersGender } };

    const allLeftItems = [...itemsAccumulatorInitial, ...itemsAddBuffer];
    const ninIds = Lazy(allLeftItems)
      .map(x => x.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    const formsFiltered = this.getFormsFiltered(ages, forms, true);
    const formIds = formsFiltered.map(f => f.id);
    propz.set(where, ['formId', '$in'], formIds);

    const filter = {
      where,
      limit: LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };
    const filterAll = { ...filter, limit: DEFAULT_LIMIT };

    const { user } = this.props;
    BPromise.all([getSchoolStudentsList(user, filter), getSchoolStudentsList(user, filterAll)]).then(
      ([filtered, all]) => {
        this.setState({
          items: filtered,
          itemsCount: all.length,
          isLoading: false,
          itemsAccumulatorSelected: [],
          itemsSelected: [],
          itemCurrentPage: 1,
          sortItemsColumnsName: '',
          sortItemsDirection: '',
          isItemsFiltered: true,
          isShowItemsFilter: false
        });
      }
    );
  };

  itemsAccumulatorOnClearFilterClick = () => {};

  getFormsFiltered(ages: number[], forms: SchoolForm[], isStrictEqual: boolean) {
    const maxAge = Math.max(...ages);
    const minAge = Math.min(...ages);

    switch (true) {
      case isStrictEqual && ages.length === 1:
        return forms.filter(form => form.age === minAge);
      case !isStrictEqual && ages.length === 1:
        return forms.filter(form => form.age <= maxAge);
      case isStrictEqual && ages.length > 1:
        return forms.filter(form => ages.some(age => form.age === age));
      case !isStrictEqual && ages.length > 1:
        return forms.filter(form => ages.some(age => form.age <= age));
      default:
        return forms;
    }
  }

  getDefaultItemsFilter(team, forms, isStrictEqual = true) {
    const { gender, ages = [] } = team;
    const genderArrayConverted = getUserGenderArrayConvertedFromEventGender(gender);
    const formsFiltered = this.getFormsFiltered(ages, forms, isStrictEqual);
    const formIds = formsFiltered.map(form => form.id);

    return {
      firstName: '',
      lastName: '',
      schoolName: '',
      gender: genderArrayConverted,
      forms: formIds
    };
  }

  goBack = () => {
    const { location } = this.props;
    const { state } = location;
    const { prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    this.props.history.push({
      pathname: '/highLevelTournaments/teams',
      search: `tournamentId=${tournamentId}`,
      state: { tournamentId, prevPath }
    });
  };

  onSelectAllItemsClick = () => {
    const { itemsSelected, itemCurrentPage, itemsAccumulatorInitial, itemsAddBuffer } = this.state;
    const { user } = this.props;

    this.setState({ isLoading: true });

    const nextBuffer = [...itemsAddBuffer, ...itemsSelected];
    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextBuffer, true);

    getSchoolStudentsList(user, filter).then(all => {
      const newOnes = all.filter(x => itemsSelected.every(sel => sel.id !== x.id));
      const nextSelected = [...itemsSelected, ...newOnes];

      this.setState({
        itemsSelected: nextSelected,
        isSelectAllItemsChecked: true,
        itemsAccumulatorSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsOnPageClick = () => {
    const { items, itemsSelected } = this.state;
    const newOnes = items.filter(x => itemsSelected.every(sel => sel.id !== x.id));
    const nextSelected = [...itemsSelected, ...newOnes];

    this.setState({
      itemsSelected: nextSelected,
      isSelectAllItemsChecked: true,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onUnselectAllItemsClick = () => {
    this.setState({
      itemsSelected: [],
      isSelectAllItemsChecked: false,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onSelectAllItemsAccumulatorClick = () => {
    const { itemsAccumulatorSelected, itemsAccumulatorFilters } = this.state;
    const { user } = this.props;

    this.setState({ isLoading: true });

    const tournamentId = this.getTournamentId();
    const teamId = this.getTeamId();
    const where = getServerFieldSectionWhere(itemsAccumulatorFilters);

    getAllSchoolTournamentTeamPlayers(user, tournamentId, teamId, where).then(tpList => {
      const normalized = this.getTeamPlayersNormalized(tpList);
      const newOnes = normalized.filter(tp => itemsAccumulatorSelected.every(sel => sel.id !== tp.id));

      this.setState({
        itemsAccumulatorSelected: [...itemsAccumulatorSelected, ...newOnes],
        isSelectAllItemsAccumulatorChecked: true,
        itemsSelected: [],
        isSelectAllItemsChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsAccumulatorOnPageClick = () => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;
    const newOnes = itemsAccumulator.filter(x => itemsAccumulatorSelected.every(sel => sel.id !== x.id));
    this.setState({
      itemsAccumulatorSelected: [...itemsAccumulatorSelected, ...newOnes],
      isSelectAllItemsAccumulatorChecked: true,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onUnselectAllItemsAccumulatorClick = () => {
    this.setState({
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onSortItemsClick = (sortField: string) => {
    const { sortItemsDirection, sortItemsColumnsName, itemCurrentPage, itemsAccumulator, itemsAddBuffer } = this.state;

    const order = getOrder(sortField, sortItemsDirection, sortItemsColumnsName) as TABLE_SORT_DIRECTION;
    const orderForFilter = `${sortField} ${order}`;
    const { user } = this.props;

    this.setState({ isLoading: true });

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer);
    BPromise.all([getSchoolStudents(user, filter), getSchoolStudentsCount(user, filter.where)]).then(
      ([stud, count]) => {
        this.setState({
          items: stud,
          itemsCount: count.count,
          isLoading: false,
          itemsAccumulatorSelected: [],
          itemsSelected: [],
          itemCurrentPage: 1,
          sortItemsColumnsName: sortField,
          sortItemsDirection: order
        });
      }
    );
  };

  onSortItemsAccumulatorClick = (sortField: string) => {};

  onCloseStudentEventsModalClick = () => {
    this.setState({ isStudentEventsModalOpen: false });
  };

  onSchoolSelected = async (schoolId: string) => {
    this.setState({
      isSelectSchoolModalOpen: false,
      chosenSchoolId: schoolId
    });

    try {
      const [forms] = await Promise.all([getAllSchoolUnionSchoolForms(this.props.user, schoolId)]);
      this.setState({ forms });
      this.openAddStudentModal();
    } catch (err) {
      console.error('Error fetching forms:', err);
    }
  };

  openAddStudentModal = () => {
    this.setState({
      isAddStudentModalOpen: true,
      studentToAdd: null,
      errorMessage: ''
    });
  };

  closeAddStudentModal = () => {
    this.setState({
      isAddStudentModalOpen: false,
      studentToAdd: null,
      errorMessage: ''
    });
  };

  handleEditClick = (student: SchoolStudent) => {
    this.setState({
      isEditStudentModalOpen: true,
      studentToEdit: student,
      errorMessage: ''
    });
  };

  closeEditStudentModal = () => {
    this.setState({
      isEditStudentModalOpen: false,
      studentToEdit: null,
      errorMessage: ''
    });
  };

  renderSelectSchoolModal() {
    const { user } = this.props;
    const { isSelectSchoolModalOpen } = this.state;

    return (
      <SchoolUnionSelectSchoolForm
        user={user}
        isOpen={isSelectSchoolModalOpen}
        onClose={this.closeSelectSchoolModal}
        onContinue={this.onSchoolSelected}
      />
    );
  }

  openSelectSchoolModal = () => {
    this.setState({
      isSelectSchoolModalOpen: true,
      chosenSchoolId: ''
    });
  };

  closeSelectSchoolModal = () => {
    this.setState({
      isSelectSchoolModalOpen: false
    });
  };

  renderAddStudentModal() {
    const { isAddStudentModalOpen, studentToAdd, forms } = this.state;
    const { user } = this.props;
    if (!isAddStudentModalOpen) return null;

    return (
      <SimpleModal
        isOpen={isAddStudentModalOpen}
        onCloseClick={this.closeAddStudentModal}
        title="Add Student"
        showFooter={false}
      >
        <SchoolUnionSchoolStudentForm
          student={studentToAdd || { firstName: '', lastName: '', formId: '' }}
          onSubmit={this.onSubmitAddStudent}
          onCancel={this.closeAddStudentModal}
          user={user}
          isCreateStudentsChain={false}
          identicalStudents={[]}
          similarStudents={[]}
          isFormsExist={forms && forms.length > 0}
          isEditStudent={false}
          schoolId={this.state.chosenSchoolId}
        />
      </SimpleModal>
    );
  }

  renderEditStudentModal() {
    const { isEditStudentModalOpen, studentToEdit, forms, errorMessage } = this.state;
    const { user } = this.props;
    if (!isEditStudentModalOpen || !studentToEdit) return null;

    return (
      <SimpleModal
        isOpen={isEditStudentModalOpen}
        onCloseClick={this.closeEditStudentModal}
        title="Edit Student"
        showFooter={false}
      >
        <SchoolUnionSchoolStudentForm
          student={studentToEdit}
          user={user}
          onSubmit={this.onSubmitEditStudent}
          onCancel={this.closeEditStudentModal}
          isCreateStudentsChain={false}
          identicalStudents={[]}
          similarStudents={[]}
          isFormsExist={forms && forms.length > 0}
          isEditStudent={true}
          schoolId={studentToEdit.schoolId}
        />
      </SimpleModal>
    );
  }

  onSubmitAddStudent = async (
    studentData: any,
    isContinue: boolean,
    isSavingSimilarStudentConfirmed: boolean = false
  ): Promise<boolean> => {
    const { user } = this.props;
    const { chosenSchoolId, itemsAccumulatorInitial, itemsAddBuffer, itemCurrentPage } = this.state;

    try {
      await addSchoolUnionSchoolStudent(user, chosenSchoolId, studentData);

      const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, itemsAddBuffer);
      const [updatedStudents] = await Promise.all([getSchoolStudentsList(user, filter)]);

      this.setState({ items: updatedStudents });

      if (!isContinue) {
        this.closeAddStudentModal();
      }
      return true;
    } catch (err) {
      console.error('Failed to add student:', err);
      return false;
    }
  };

  onSubmitEditStudent = async (
    formData: any,
    isContinue: boolean,
    isSavingSimilarStudentConfirmed: boolean = false
  ): Promise<boolean> => {
    const { user } = this.props;
    const { studentToEdit } = this.state;

    if (!studentToEdit) return false;

    try {
      await updateSchoolUnionSchoolStudent(user, studentToEdit.schoolId, studentToEdit.id, formData);

      this.setState(prev => {
        const updatedItems = prev.items.map(st => (st.id === studentToEdit.id ? { ...st, ...formData } : st));
        const updatedAccumulator = prev.itemsAccumulator.map(st =>
          st.id === studentToEdit.id ? { ...st, ...formData } : st
        );
        return {
          items: updatedItems,
          itemsAccumulator: updatedAccumulator
        };
      });

      this.closeEditStudentModal();
      return true;
    } catch (err) {
      console.error('Edit student error:', err);
      return false;
    }
  };

  renderStudentEventsModal(): React.ReactNode {
    const { isStudentEventsModalOpen, studentEventsModalText } = this.state;
    return (
      <SimpleModal
        isOpen={isStudentEventsModalOpen}
        title="Info"
        body={studentEventsModalText}
        buttonText="Ok"
        onButtonClick={this.onCloseStudentEventsModalClick}
      />
    );
  }

  onShowYoungerChange = () => {
    const { user } = this.props;
    const { team, forms, isShowYounger, itemsAccumulatorInitial, itemsAddBuffer } = this.state;

    const defaultItemsFilter = this.getDefaultItemsFilter(team, forms, isShowYounger);

    this.setState({
      isLoading: true,
      itemsFilters: defaultItemsFilter,
      isShowYounger: !isShowYounger
    });

    const { gender: itemsFiltersGender, forms: itemsFiltersForms = [] } = defaultItemsFilter;

    let where = {
      gender: {
        $in: itemsFiltersGender
      }
    };

    const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer];
    const ninIds = Lazy(allItems)
      .map(item => item.id)
      .uniq()
      .toArray();
    propz.set(where, ['_id', '$nin'], ninIds);

    propz.set(where, ['formId', '$in'], itemsFiltersForms);

    const filter = {
      where,
      limit: LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };
    const filterToGetAllStudents = {
      where,
      limit: DEFAULT_LIMIT,
      skip: DEFAULT_SKIP,
      order: DEFAULT_TEAM_PLAYERS_ORDER
    };

    BPromise.all([getSchoolStudentsList(user, filter), getSchoolStudentsList(user, filterToGetAllStudents)]).then(
      ([students, studentsCount]) => {
        this.setState({
          items: students,
          isLoading: false,
          itemsCount: studentsCount.length,
          itemsAccumulatorSelected: [],
          itemsSelected: [],
          itemCurrentPage: 1,
          sortItemsColumnsName: '',
          sortItemsDirection: '',
          isItemsFiltered: true,
          isShowItemsFilter: false
        });
      }
    );
  };

  render() {
    const {
      items,
      isLoading,
      itemsSelected,
      itemsAccumulatorSelected,
      itemsAccumulator,
      itemsFilters,
      isShowItemsFilter,
      isItemsFiltered,
      itemsCount,
      itemsAccumulatorCount,
      itemCurrentPage,
      itemsAccumulatorFilters,
      isShowItemsAccumulatorFilter,
      isItemsAccumulatorFiltered,
      tournament,
      isSelectAllItemsChecked,
      isSelectAllItemsAccumulatorChecked,
      sortItemsDirection,
      sortItemsColumnsName,
      sortItemsAccumulatorDirection,
      sortItemsAccumulatorColumnsName,
      itemsAddBuffer,
      itemsRemoveBuffer,
      isStudentEventsModalOpen,
      forms,
      isShowYounger
    } = this.state;

    const name = propz.get(tournament, ['name'], '');

    if (isLoading) {
      return <Loader />;
    }

    const { user } = this.props;
    const { activeSchool } = user;
    const { ageGroupsNaming } = activeSchool;

    const itemsFiltersOptions = {
      ages: getSelectOptionForAge(forms, ageGroupsNaming),
      gender: getSelectOptionForGender()
    };

    const itemsAccumulatorFiltersOptions = {};

    const classes = isStudentEventsModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderStudentEventsModal()}
        {this.renderSelectSchoolModal()}
        {this.renderAddStudentModal()}
        {this.renderEditStudentModal()}

        <div className="row">
          <div className="col-md-12">
            <Button text="← back" onClick={this.goBack} customClass="btn-secondary mr-3 mb-3" />
            <div className="d-inline h1 mb-3">{name}</div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="form-inline mb-3 mt-3">
                <Checkbox
                  value={isShowYounger}
                  id="isShowYounger"
                  onChange={this.onShowYoungerChange}
                  labelText="Show younger"
                />
                <Button text="Add Student" onClick={this.openSelectSchoolModal} customClass="ml-3" />
              </div>
            </div>
          </div>
        </div>

        <TwoPanelEditor
          // items (right side)
          items={items}
          itemsTitle="Eligible students"
          itemsColumns={this.getItemsColumns()}
          itemsSelected={itemsSelected}
          onItemClick={this.onItemClick}
          // items filters
          onItemsFilterChange={this.onItemsFilterChange}
          itemsFilters={itemsFilters}
          itemsFiltersOptions={itemsFiltersOptions}
          isShowItemsFilter={isShowItemsFilter}
          isItemsFiltered={isItemsFiltered}
          onItemsFilterClick={this.onItemsFilterClick}
          // accumulator (left side)
          itemsAccumulator={itemsAccumulator}
          itemsAccumulatorTitle="Team members"
          itemsAccumulatorColumns={this.getItemsAccumulatorColumns()}
          onItemAccumulatorClick={this.onItemAccumulatorClick}
          itemsAccumulatorSelected={itemsAccumulatorSelected}
          // accumulator filters
          onItemsAccumulatorFilterChange={this.onItemsAccumulatorFilterChange}
          itemsAccumulatorFilters={itemsAccumulatorFilters}
          itemsAccumulatorFiltersOptions={itemsAccumulatorFiltersOptions}
          isShowItemsAccumulatorFilter={isShowItemsAccumulatorFilter}
          isItemsAccumulatorFiltered={isItemsAccumulatorFiltered}
          onItemsAccumulatorFilterClick={this.onItemsAccumulatorFilterClick}
          // Add/Remove
          onAddClick={this.onAddClick}
          onRemoveClick={this.onRemoveClick}
          // counts
          itemsCount={itemsCount}
          itemsAccumulatorCount={itemsAccumulatorCount}
          // current pages
          itemAccumulatorCurrentPage={1}
          itemCurrentPage={itemCurrentPage}
          itemsSetCurrentPageParams={this.itemsSetCurrentPageParams}
          // items filter buttons
          itemsOnApplyFilterClick={this.itemsOnApplyFilterClick}
          itemsOnClearFilterClick={this.itemsOnClearFilterClick}
          // items accumulator filter buttons
          itemsAccumulatorOnApplyFilterClick={this.itemsAccumulatorOnApplyFilterClick}
          itemsAccumulatorOnClearFilterClick={this.itemsAccumulatorOnClearFilterClick}
          // items select
          onSelectAllItemsClick={this.onSelectAllItemsClick}
          onSelectAllItemsOnPageClick={this.onSelectAllItemsOnPageClick}
          onUnselectAllItemsClick={this.onUnselectAllItemsClick}
          isSelectAllItemsChecked={isSelectAllItemsChecked}
          // items accumulator select
          onSelectAllItemsAccumulatorClick={this.onSelectAllItemsAccumulatorClick}
          onSelectAllItemsAccumulatorOnPageClick={this.onSelectAllItemsAccumulatorOnPageClick}
          onUnselectAllItemsAccumulatorClick={this.onUnselectAllItemsAccumulatorClick}
          isSelectAllItemsAccumulatorChecked={isSelectAllItemsAccumulatorChecked}
          // sorting
          sortItemsDirection={sortItemsDirection}
          sortItemsColumnsName={sortItemsColumnsName}
          onSortItemsClick={this.onSortItemsClick}
          // accumulator sort
          sortItemsAccumulatorDirection={sortItemsAccumulatorDirection}
          sortItemsAccumulatorColumnsName={sortItemsAccumulatorColumnsName}
          onSortItemsAccumulatorClick={this.onSortItemsAccumulatorClick}
          // buffers
          itemsAddBuffer={itemsAddBuffer}
          itemsRemoveBuffer={itemsRemoveBuffer}
          onCellItemsClick={() => {}}
          onCellItemsAccumulatorClick={() => {}}
          removeItemFromBuffer={this.removeItemFromBuffer}
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Button text="Save" onClick={this.onClickSave} customClass="btn-lg mt-3" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
