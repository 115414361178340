export const CELL_ACTION_ICON = {
  REMOVE_FROM_EVENT: 'REMOVE_FROM_EVENT',
  ADD_TO_EVENT: 'ADD_EVENT',
  REMOVE_FROM_TEAM: 'REMOVE_FROM_TEAM',
  LEAVE_TOURNAMENT: 'LEAVE_TOURNAMENT',
  TOURNAMENT_DETAILS: 'TOURNAMENT_DETAILS',
  TOURNAMENT_TEAMS: 'TOURNAMENT_TEAMS',
  TOURNAMENT_EVENTS: 'TOURNAMENT_EVENTS',
  EDIT: 'EDIT'
};
