import * as React from 'react';
import { useState, useEffect } from 'react';
import { Widget } from '../../../../components/Widget/Widget';
import { Link } from 'react-router-dom';
import * as BPromise from 'bluebird';
import './Dashboard.css';
import { getRequestsCount } from 'Src/helpers/service/admin/requests';
import { getAdminSchoolsInboxInvitesCount } from 'Src/helpers/service/adminSU/schoolInvitesTable';
import { getInboxTournamentConfirmationRequestsCount } from 'Src/helpers/service/admin/messages';
import {
  getFilterEventsForDay,
  getFilterFixtureEventsForDay,
  getFilterFixtureEventsForWeek,
  getFilterClubEventsForDay,
  getFilterFixtureEventsForWeekExcludingCancelled,
  getFilterClubEventsForWeekExcludingCancelled
} from 'Src/helpers/calendar/calendar';
import { getSchoolEvents } from 'Src/helpers/service/admin/schoolEvents';
import { getSchoolUpcomingTournaments, getSchoolTournamentsForWeek } from 'Src/helpers/service/admin/tournaments';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TipOfTheDayWidget } from './TipOfTheDayWidget';

function getEventNameForSchool(event, schoolId) {
  if (event.generatedNames && event.generatedNames[schoolId]) {
    return event.generatedNames[schoolId];
  }
  if (event.generatedNames && event.generatedNames.official) {
    return event.generatedNames.official;
  }
  return event.name || 'Unnamed event';
}

function countUniquePlayers(events) {
  const uniquePlayers = new Set();
  events.forEach(event => {
    if (Array.isArray(event.players)) {
      event.players.forEach(player => {
        uniquePlayers.add(String(player.userId));
      });
    }
  });
  return uniquePlayers.size;
}

async function getWeeklyUniquePlayers(user) {
  const today = new Date();

  const fixtureFilter = getFilterFixtureEventsForWeekExcludingCancelled(today, user);
  const clubFilter = getFilterClubEventsForWeekExcludingCancelled(today, user);

  const [fixtureEvents, clubEvents] = await Promise.all([
    getSchoolEvents(user, fixtureFilter),
    getSchoolEvents(user, clubFilter)
  ]);

  return {
    fixturePlayersCount: countUniquePlayers(fixtureEvents),
    clubPlayersCount: countUniquePlayers(clubEvents)
  };
}

const defaultWidgets = [
  'schoolDetails',
  'profile',
  'importantEvents',
  'fixtures',
  'weekFixtures',
  'clubs',
  'upcomingTournaments',
  'thisWeeksStatistics',
  'tipOfTheDay'
];

const getWidgetCustomStyle = widgetKey => {
  if (widgetKey === 'schoolDetails') {
    return {
      width: '100%',
      minWidth: '300px',
      minHeight: '300px'
    };
  }
  if (widgetKey === 'profile') {
    return {
      width: '100%',
      minWidth: '300px',
      minHeight: '300px'
    };
  }
  if (widgetKey === 'importantEvents') {
    return {
      width: '100%',
      minWidth: '300px',
      minHeight: '300px'
    };
  }
  if (widgetKey === 'weekFixtures') {
    return {
      width: '100%',
      minWidth: '300px',
      minHeight: '300px'
    };
  }
  if (widgetKey === 'fixtures') {
    return {
      width: '100%',
      minWidth: '300px',
      minHeight: '300px'
    };
  }
  if (widgetKey === 'clubs') {
    return {
      width: '100%',
      minWidth: '300px',
      minHeight: '300px'
    };
  }
  if (widgetKey === 'upcomingTournaments') {
    return {
      width: '100%',
      minWidth: '300px',
      minHeight: '300px'
    };
  }
  if (widgetKey === 'thisWeekStatistics') {
    return {
      width: '100%',
      minWidth: '300px',
      minHeight: '300px'
    };
  }
  if (widgetKey === 'thisWeekStatistics') {
    return {
      width: '100%',
      minWidth: '300px',
      minHeight: '300px'
    };
  }
  if (widgetKey === 'tipOfTheDay') {
    return {
      width: '100%',
      minWidth: '400px',
      minHeight: '300px'
    };
  }
  return {
    width: '300px',
    minWidth: '300px',
    minHeight: '300px'
  };
};

export const Dashboard = ({ user }) => {
  const avatarSrc =
    user.profile && user.profile.avatar && user.profile.avatar.trim() !== ''
      ? user.profile.avatar
      : '/dist/images/default-avatar.png';

  const firstName = (user.profile && user.profile.firstName) || user.firstName;
  const lastName = (user.profile && user.profile.lastName) || user.lastName;
  const email = (user.profile && user.profile.email) || user.email;
  const schoolName = (user.activeSchool && user.activeSchool.name) || user.activeSchoolName;

  const [data, setData] = useState({
    roleRequestsCount: 0,
    invitesCount: 0,
    tournamentConfirmationRequestsCount: 0
  });
  const [fixtures, setFixtures] = useState([]);
  const [weekFixtures, setWeekFixtures] = useState([]);
  const [clubEvents, setClubEvents] = useState([]);
  const [upcomingTournaments, setUpcomingTournaments] = useState([]);
  const [loading, setLoading] = useState(true);

  const [weekStatistics, setWeekStatistics] = useState({
    fixtures: 0,
    clubs: 0,
    tournaments: 0,
    fixturePlayers: 0,
    clubPlayers: 0
  });

  const [widgets, setWidgets] = useState(() => {
    const stored = localStorage.getItem('dashboardWidgets');
    return stored ? JSON.parse(stored) : defaultWidgets;
  });

  const availableWidgets = defaultWidgets.filter(widget => !widgets.includes(widget));

  useEffect(() => {
    localStorage.setItem('dashboardWidgets', JSON.stringify(widgets));
  }, [widgets]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const today = new Date();

        const [reqCountObj, invitesCountObj, tournCountObj] = await BPromise.all([
          getRequestsCount(user),
          getAdminSchoolsInboxInvitesCount(user),
          getInboxTournamentConfirmationRequestsCount(user, { isActionPerformed: false })
        ]);
        setData({
          roleRequestsCount: reqCountObj.count,
          invitesCount: invitesCountObj.count,
          tournamentConfirmationRequestsCount: tournCountObj
        });

        const baseFilter = getFilterEventsForDay(today, user);
        const fixtureFilterToday = getFilterFixtureEventsForDay(baseFilter);
        const fixtureEvents = await getSchoolEvents(user, fixtureFilterToday);
        setFixtures(fixtureEvents);

        const clubFilterToday = getFilterClubEventsForDay(baseFilter);
        const clubEventsData = await getSchoolEvents(user, clubFilterToday);
        setClubEvents(clubEventsData);

        const weekFixtureFilter = getFilterFixtureEventsForWeek(today, user);
        const weekFixturesData = await getSchoolEvents(user, weekFixtureFilter);
        setWeekFixtures(weekFixturesData);

        const upcomingTournamentsData = await getSchoolUpcomingTournaments(user, {
          limit: 5,
          sort: 'startTime ASC'
        });
        setUpcomingTournaments(upcomingTournamentsData);

        const fixtureWeekFilter = getFilterFixtureEventsForWeekExcludingCancelled(today, user);
        const clubWeekFilter = getFilterClubEventsForWeekExcludingCancelled(today, user);
        const [fixturesWeek, clubsWeek] = await BPromise.all([
          getSchoolEvents(user, fixtureWeekFilter),
          getSchoolEvents(user, clubWeekFilter)
        ]);
        const tournamentsWeek = await getSchoolTournamentsForWeek(user);

        const weeklyPlayers = await getWeeklyUniquePlayers(user);

        setWeekStatistics({
          fixtures: fixturesWeek.length,
          clubs: clubsWeek.length,
          tournaments: tournamentsWeek.length,
          fixturePlayers: weeklyPlayers.fixturePlayersCount,
          clubPlayers: weeklyPlayers.clubPlayersCount
        });
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, [user]);

  const removeWidget = widgetKey => {
    setWidgets(prevWidgets => prevWidgets.filter(key => key !== widgetKey));
  };

  const addWidget = widgetKey => {
    setWidgets(prevWidgets => {
      if (!prevWidgets.includes(widgetKey)) {
        return [...prevWidgets, widgetKey];
      }
      return prevWidgets;
    });
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const newWidgets = Array.from(widgets);
    const [movedItem] = newWidgets.splice(result.source.index, 1);
    newWidgets.splice(result.destination.index, 0, movedItem);
    setWidgets(newWidgets);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const school = user.activeSchool;

  const widgetContentMap = {
    schoolDetails: school && (
      <Widget title="School Details" onRemove={() => removeWidget('schoolDetails')}>
        <div className="school-details-widget">
          <div className="school-logo-wrapper">
            <img src={school.pic || '/dist/images/empty_pic_uploader_box.png'} alt={school.name} />
          </div>
          <h4>
            <Link to="/school/settings/base">{school.name}</Link>
          </h4>
          <p>{school.address}</p>
          {school.domain && (
            <p>
              <a href={`https://${school.domain}.squadintouch.com`} target="_blank" rel="noopener noreferrer">
                Public Website
              </a>
            </p>
          )}
        </div>
      </Widget>
    ),
    profile: (
      <Widget title="Profile" onRemove={() => removeWidget('profile')}>
        <div className="profile-widget">
          <div className="profile-avatar">
            <img src={avatarSrc} alt={`${firstName} ${lastName}`} />
          </div>
          <div className="profile-details">
            <h4>
              <Link to="/profile/summary">{`${firstName} ${lastName}`}</Link>
            </h4>
            <p>
              <span className="field-label">Email: </span>
              <span className="field-value">{email}</span>
            </p>
            <p>
              <span className="field-label">Logged in as: </span>
              <span className="field-value">
                {schoolName || 'Not assigned'} / {user.activeRole || 'Not available'}
              </span>
            </p>
          </div>
        </div>
      </Widget>
    ),
    importantEvents: (
      <Widget
        title={<span className="important-title">Important!</span>}
        onRemove={() => removeWidget('importantEvents')}
      >
        <div className="important-events-widget">
          {data.roleRequestsCount > 0 || data.invitesCount > 0 || data.tournamentConfirmationRequestsCount > 0 ? (
            <>
              {data.roleRequestsCount > 0 && (
                <div className="event-row">
                  <Link to="/requests">
                    <span className="event-count">{data.roleRequestsCount}</span>
                    <span className="event-kind">pending role request{data.roleRequestsCount !== 1 && 's'}</span>
                  </Link>
                </div>
              )}
              {data.invitesCount > 0 && (
                <div className="event-row">
                  <Link to="/invites/inbox">
                    <span className="event-count">{data.invitesCount}</span>
                    <span className="event-kind">new invite{data.invitesCount !== 1 && 's'}</span>
                  </Link>
                </div>
              )}
              {data.tournamentConfirmationRequestsCount > 0 && (
                <div className="event-row">
                  <Link to="/messages/tournamentConfirmationRequests/inbox">
                    <span className="event-count">{data.tournamentConfirmationRequestsCount}</span>
                    <span className="event-kind">
                      pending tournament confirmation request{data.tournamentConfirmationRequestsCount !== 1 && 's'}
                    </span>
                  </Link>
                </div>
              )}
            </>
          ) : (
            <div className="up-to-date-text">
              <span className="up-to-date-first-row">Well done!</span>
              <br />
              <span className="up-to-date-second-row">You are up to date, everything looks great.</span>
            </div>
          )}
        </div>
      </Widget>
    ),
    fixtures: (
      <Widget title="Today's Fixtures" onRemove={() => removeWidget('fixtures')}>
        <ul className="fixtures-list">
          {fixtures.length > 0 ? (
            fixtures.map(event => {
              const eventName = getEventNameForSchool(event, school.id);
              const startTime = new Date(event.startTime).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
              });
              const cancelled = event.status === 'CANCELED';
              return (
                <li key={event.id}>
                  <Link to={`/events/event?id=${event.id}`}>
                    <span className="event-time">{startTime}</span> -{' '}
                    <span
                      className="event-name my-ellipsis"
                      title={eventName}
                      style={{ textDecoration: cancelled ? 'line-through' : 'none' }}
                    >
                      {eventName}
                    </span>
                  </Link>
                </li>
              );
            })
          ) : (
            <li>No fixtures today</li>
          )}
        </ul>
      </Widget>
    ),
    weekFixtures: (
      <Widget title="This Week's Fixtures" onRemove={() => removeWidget('weekFixtures')}>
        <ul className="fixtures-list">
          {weekFixtures.length > 0 ? (
            weekFixtures.map(event => {
              const eventName = getEventNameForSchool(event, school.id);
              const eventDate = new Date(event.startTime).toLocaleDateString();
              const startTime = new Date(event.startTime).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
              });
              const cancelled = event.status === 'CANCELED';
              return (
                <li key={event.id}>
                  <Link to={`/events/event?id=${event.id}`}>
                    <span className="tournament-date">{eventDate}</span> <span className="event-time">{startTime}</span>{' '}
                    -{' '}
                    <span
                      className="event-name my-ellipsis"
                      title={eventName}
                      style={{ textDecoration: cancelled ? 'line-through' : 'none' }}
                    >
                      {eventName}
                    </span>
                  </Link>
                </li>
              );
            })
          ) : (
            <li>No fixtures this week</li>
          )}
        </ul>
      </Widget>
    ),
    clubs: (
      <Widget title="Today's Clubs" onRemove={() => removeWidget('clubs')}>
        <ul className="fixtures-list">
          {clubEvents.length > 0 ? (
            clubEvents.map(event => {
              const eventName = getEventNameForSchool(event, school.id);
              const startTime = new Date(event.startTime).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
              });
              const cancelled = event.status === 'CANCELED';
              return (
                <li key={event.id}>
                  <Link to={`/events/event?id=${event.id}`}>
                    <span className="event-time">{startTime}</span> -{' '}
                    <span
                      className="event-name my-ellipsis"
                      title={eventName}
                      style={{ textDecoration: cancelled ? 'line-through' : 'none' }}
                    >
                      {eventName}
                    </span>
                  </Link>
                </li>
              );
            })
          ) : (
            <li>No clubs today</li>
          )}
        </ul>
      </Widget>
    ),
    upcomingTournaments: (
      <Widget title="Upcoming Tournaments" onRemove={() => removeWidget('upcomingTournaments')}>
        <ul className="tournaments-list">
          {upcomingTournaments.length > 0 ? (
            upcomingTournaments.map(tournament => {
              const tournamentName = tournament.name || 'Unnamed Tournament';
              const startDate = new Date(tournament.startTime).toLocaleDateString();
              const startTime = new Date(tournament.startTime).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit'
              });
              return (
                <li key={tournament.id}>
                  <Link to={`/tournaments/upcoming`}>
                    <span className="tournament-date">{startDate}</span> <span className="event-time">{startTime}</span>{' '}
                    -{' '}
                    <span className="event-name my-ellipsis" title={tournamentName}>
                      {tournamentName}
                    </span>
                  </Link>
                </li>
              );
            })
          ) : (
            <li>No upcoming tournaments</li>
          )}
        </ul>
      </Widget>
    ),
    thisWeeksStatistics: (
      <Widget title="This Week's Statistics" onRemove={() => removeWidget('thisWeeksStatistics')}>
        <div className="statistics-widget">
          <ul className="statistics-list">
            <li>
              <span className="field-label">Fixtures:</span>
              <span className="field-value">{weekStatistics.fixtures}</span>
            </li>
            <li>
              <span className="field-label">Students involved in fixtures:</span>
              <span className="field-value">{weekStatistics.fixturePlayers}</span>
            </li>
            <li>
              <span className="field-label">Club Sessions:</span>
              <span className="field-value">{weekStatistics.clubs}</span>
            </li>
            <li>
              <span className="field-label">Students involved in Clubs:</span>
              <span className="field-value">{weekStatistics.clubPlayers}</span>
            </li>
            <li>
              <span className="field-label">Tournaments:</span>
              <span className="field-value">{weekStatistics.tournaments}</span>
            </li>
          </ul>
        </div>
      </Widget>
    ),
    tipOfTheDay: <TipOfTheDayWidget onRemove={() => removeWidget('tipOfTheDay')} />
  };

  return (
    <div className="dashboard">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="widgetList">
          {provided => (
            <div className="dashboard-widget-list" ref={provided.innerRef} {...provided.droppableProps}>
              {widgets.map((widgetKey, index) => (
                <Draggable key={widgetKey} draggableId={widgetKey} index={index}>
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        marginBottom: '1rem',
                        ...provided.draggableProps.style
                      }}
                    >
                      {React.cloneElement(widgetContentMap[widgetKey], {
                        style: getWidgetCustomStyle(widgetKey)
                      })}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {availableWidgets.length > 0 && (
        <div className="widget-library">
          <h4>Add Widgets</h4>
          {!widgets.includes('schoolDetails') && (
            <button onClick={() => addWidget('schoolDetails')}>School Details</button>
          )}
          {!widgets.includes('profile') && <button onClick={() => addWidget('profile')}>Profile</button>}
          {!widgets.includes('importantEvents') && (
            <button onClick={() => addWidget('importantEvents')}>Important Events</button>
          )}
          {!widgets.includes('fixtures') && <button onClick={() => addWidget('fixtures')}>Today's Fixtures</button>}
          {!widgets.includes('weekFixtures') && (
            <button onClick={() => addWidget('weekFixtures')}>Week Fixtures</button>
          )}
          {!widgets.includes('clubs') && <button onClick={() => addWidget('clubs')}>Today's Clubs</button>}
          {!widgets.includes('upcomingTournaments') && (
            <button onClick={() => addWidget('upcomingTournaments')}>Upcoming Tournaments</button>
          )}
          {!widgets.includes('thisWeeksStatistics') && (
            <button onClick={() => addWidget('thisWeeksStatistics')}>This Week's Statistics</button>
          )}
          {!widgets.includes('tipOfTheDay') && <button onClick={() => addWidget('tipOfTheDay')}>Tip of the Day</button>}
        </div>
      )}
    </div>
  );
};
