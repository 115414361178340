import * as React from 'react';
import * as propz from 'propz';
import * as BPromise from 'bluebird';
import './Registration.scss';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import { History, Location } from 'history';
import { EmptyComponent } from '../../components/EmptyComponent/EmptyComponent';
import { PersonalDetailsForm } from './PersonalDetailsForm/PersonalDetailsForm';
import { register } from '../../helpers/service/nobody/register';
import { login } from '../../helpers/auth/auth';
import { Loader } from '../../components/Loader/Loader';
import { VerificationForm } from './VerificationForm/VerificationForm';
import { SimpleModal } from '../../components/SimpleModal/SimpleModal';
import { EmailChangeForm } from '../GenericView/Profile/VerificationTab/EmailChangeForm/EmailChangeForm';
import { PhoneChangeForm } from '../GenericView/Profile/VerificationTab/PhoneChangeForm/PhoneChangeForm';
import {
  changeEmail,
  changePhone,
  createProfileRequest,
  getProfile,
  resendEmailCode,
  resendPhoneCode
} from '../../helpers/service/admin/user';
import { verifyEmail, verifyPhone } from '../../helpers/service/nobody/confirm';
import { Permission } from './Permission/Permission';
import StorageHelper from '../../helpers/storage/storage';
import { LoginSession } from '../../models/sessions';
import { AppUser } from '../App/App';
import { UserProfile } from '../../models/profile';
import { ROLE } from '../../consts/user';
import { SCHOOL_PERMISSION_REQUEST_FIELD_STATUS } from '../../consts/school';
import { PHONE_CODE } from '../../consts/common';
import { getUKPhoneWithoutFirstZero } from '../../helpers/phone/phone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export type RegistrationPermission = 'STAFF' | 'STUDENT' | 'PARENT';

export const REGISTRATION_PERMISSION: {
  [key: string]: RegistrationPermission;
} = {
  STAFF: 'STAFF',
  STUDENT: 'STUDENT',
  PARENT: 'PARENT'
};

export type RegistrationStaff = 'ADMIN' | 'MANAGER' | 'COACH' | 'TEACHER';

export const REGISTRATION_STAFF_PRESET: {
  [key: string]: RegistrationStaff;
} = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  COACH: 'COACH',
  TEACHER: 'TEACHER'
};

type Step = 'DETAILS' | 'VERIFICATION' | 'PERMISSION';

const REGISTRATION_WIZARD_STEP: {
  [key: string]: Step;
} = {
  DETAILS: 'DETAILS',
  VERIFICATION: 'VERIFICATION',
  PERMISSION: 'PERMISSION'
};

const REGISTRATION_WIZARD_STEP_TITLE_MAPPING: {
  [key: string]: string;
} = {
  DETAILS: 'Personal Details',
  VERIFICATION: 'User Verification',
  PERMISSION: 'Permissions Setup'
};

const REGISTRATION_WIZARD_STEP_DESCRIPTION_MAPPING: {
  [key: string]: any;
} = {
  DETAILS: (
    <div style={{ textAlign: 'left', fontSize: '18px' }} className={'mt-3'}>
      <p style={{ marginBottom: '1rem' }}>
        Please enter your contact details and create a secure password for your new account.
      </p>
      <p style={{ marginBottom: '1rem' }}>
        We require a valid mobile number and email address because we’ll send verification codes to both.
      </p>
      <p style={{ marginBottom: '1rem' }}>
        Your email address will also serve as your login ID, and you won’t be able to access the platform until both
        your email and phone number have been verified.
      </p>
    </div>
  ),
  VERIFICATION: (
    <div style={{ textAlign: 'left', fontSize: '18px' }} className={'mt-3'}>
      We've sent activation codes to your email and mobile phone. Please enter the codes below to complete your
      registration.
    </div>
  ),
  PERMISSION: (
    <div style={{ textAlign: 'left', fontSize: '18px' }} className={'mt-3'}>
      <p>Please select the type of user you want to be: Member of Staff, Parent, or Student.</p>
      <p>
        If you need more than one role — either within the same school or across different schools (for example, both
        teacher and parent) — simply choose one role now. You can request additional permissions once your registration
        is completed.
      </p>
    </div>
  )
};

interface Props {
  history: History;
  location: Location;
  user: AppUser;
  onFormSubmit: (userId: string, key: string, profile: UserProfile) => void;
}
interface State {
  currentStep: Step;
  isLoading: boolean;
  loginSession: LoginSession;
  email: string;
  phone: string;

  isResendPhoneCodeModalOpen: boolean;
  isResendEmailCodeModalOpen: boolean;
  isChangePhoneModalOpen: boolean;
  isChangeEmailModalOpen: boolean;
  isSuccessPhoneChangeModalOpen: boolean;
  isSuccessEmailChangeModalOpen: boolean;

  isEmailConfirmed: boolean;
  isPhoneConfirmed: boolean;

  permission: RegistrationPermission;
  staff: RegistrationStaff;

  isRoleStudentRequestModalOpen: boolean;
  isRoleFirstStaffRequestModalOpen: boolean;
  isRoleStaffRequestModalOpen: boolean;
  isRoleParentRequestModalOpen: boolean;

  school: any;
  schoolId: string;
  oneOffCode: string;

  isStaffRoleSelectorEnabled: boolean;
  isParentDetailsEnabled: boolean;

  students: any[];
}

export class Registration extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentStep: REGISTRATION_WIZARD_STEP.DETAILS,
      isLoading: false,
      loginSession: undefined,
      email: '',
      phone: '',

      isResendPhoneCodeModalOpen: false,
      isResendEmailCodeModalOpen: false,
      isChangePhoneModalOpen: false,
      isChangeEmailModalOpen: false,
      isSuccessPhoneChangeModalOpen: false,
      isSuccessEmailChangeModalOpen: false,

      isEmailConfirmed: undefined,
      isPhoneConfirmed: undefined,
      permission: undefined,
      staff: undefined,
      isRoleStudentRequestModalOpen: false,

      school: undefined,
      schoolId: '',
      oneOffCode: '',
      isStaffRoleSelectorEnabled: false,
      isParentDetailsEnabled: false,
      isRoleFirstStaffRequestModalOpen: false,
      isRoleStaffRequestModalOpen: false,
      isRoleParentRequestModalOpen: false,

      students: []
    };
  }

  getSteps(): Step[] {
    return [
      REGISTRATION_WIZARD_STEP.DETAILS,
      REGISTRATION_WIZARD_STEP.VERIFICATION,
      REGISTRATION_WIZARD_STEP.PERMISSION
    ];
  }

  onCancelClick = () => {
    const { history } = this.props;

    history.push({
      pathname: '/login'
    });
  };

  onSubmitPersonalDetailsClick = data => {
    this.setState({
      isLoading: true
    });

    const { phone, phoneCode, confirmPassword, terms, additionalTerms, ...rest } = data;
    const phoneWithoutFirstZero = getUKPhoneWithoutFirstZero(phone, phoneCode);

    const dataToPost = {
      ...rest,
      phone: `${phoneCode}${phoneWithoutFirstZero}`
    };

    register(dataToPost)
      .then(res => login(data.email, data.password))
      .then(session => {
        this.setState({
          isLoading: false,
          currentStep: REGISTRATION_WIZARD_STEP.VERIFICATION,
          loginSession: session,
          email: data.email,
          phone: `${phoneCode}${phoneWithoutFirstZero}`
        });
      });
  };

  onSubmitVerificationClick = () => {
    this.setState({
      currentStep: REGISTRATION_WIZARD_STEP.PERMISSION
    });
  };

  onStaffClick = (staff: RegistrationStaff) => {
    this.setState({
      staff: staff,
      isRoleStaffRequestModalOpen: true
    });
  };

  onPermissionClick = (permission: RegistrationPermission) => {
    this.setState({
      permission: permission
    });
  };

  onSubmitStaffPermission = data => {
    const { school, schoolId } = data;

    const isStaffExist = propz.get(school, ['stats', 'rolesExistence', 'staff'], true);

    if (!isStaffExist) {
      this.setState({
        school,
        schoolId,
        isRoleFirstStaffRequestModalOpen: true
      });
    } else {
      this.setState({
        school,
        schoolId,
        isStaffRoleSelectorEnabled: true
      });
    }
  };

  onSubmitStudentPermission = data => {
    const { school, schoolId, oneOffCode } = data;
    this.setState({
      school,
      schoolId,
      oneOffCode,
      isRoleStudentRequestModalOpen: true
    });
  };

  isAllRequestFieldsHidden(school: any): boolean {
    const fields = school.additionalPermissionRequestFields;

    return (
      fields.childFirstName === SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN &&
      fields.childLastName === SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN &&
      fields.childDateOfBirth === SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN &&
      fields.childGender === SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN &&
      fields.childForm === SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN &&
      fields.childHouse === SCHOOL_PERMISSION_REQUEST_FIELD_STATUS.HIDDEN
    );
  }

  onSubmitParentPermission = data => {
    const { school, schoolId } = data;

    const isAllFieldsHidden = this.isAllRequestFieldsHidden(school);

    if (isAllFieldsHidden) {
      this.setState({
        school,
        schoolId,
        isRoleParentRequestModalOpen: true
      });
    } else {
      this.setState({
        school,
        schoolId,
        isParentDetailsEnabled: true
      });
    }
  };

  onSubmitParentDetails = data => {
    this.setState({
      isRoleParentRequestModalOpen: true,
      students: [...data]
    });
  };

  renderViewByStep() {
    const {
      currentStep,
      loginSession,
      email,
      phone,
      isPhoneConfirmed,
      isEmailConfirmed,
      permission,
      isStaffRoleSelectorEnabled,
      isParentDetailsEnabled,
      staff,
      school
    } = this.state;

    switch (currentStep) {
      case REGISTRATION_WIZARD_STEP.DETAILS:
        return <PersonalDetailsForm onSubmit={this.onSubmitPersonalDetailsClick} onCancel={this.onCancelClick} />;
      case REGISTRATION_WIZARD_STEP.VERIFICATION:
        return (
          <VerificationForm
            onSubmit={this.onSubmitVerificationClick}
            onCancel={this.onCancelClick}
            email={email}
            phone={phone}
            onSendEmailCodeAgainClick={this.onSendEmailCodeAgainClick}
            onChangeEmailClick={this.onChangeEmailClick}
            onSendPhoneCodeAgainClick={this.onSendPhoneCodeAgainClick}
            onChangePhoneClick={this.onChangePhoneClick}
            isPhoneConfirmed={isPhoneConfirmed}
            isEmailConfirmed={isEmailConfirmed}
            onSubmitEmailClick={this.onSubmitEmailClick}
            onSubmitPhoneClick={this.onSubmitPhoneClick}
          />
        );
      case REGISTRATION_WIZARD_STEP.PERMISSION:
        return (
          <Permission
            phone={phone}
            onSubmitStudentPermission={this.onSubmitStudentPermission}
            onSubmitStaffPermission={this.onSubmitStaffPermission}
            onSubmitParentPermission={this.onSubmitParentPermission}
            onSubmitParentDetails={this.onSubmitParentDetails}
            loginSession={loginSession}
            onPermissionClick={this.onPermissionClick}
            permission={permission}
            isStaffRoleSelectorEnabled={isStaffRoleSelectorEnabled}
            isParentDetailsEnabled={isParentDetailsEnabled}
            staff={staff}
            school={school}
            onStaffClick={this.onStaffClick}
          />
        );
      default:
        return <EmptyComponent />;
    }
  }

  closeResendEmailCodeModal = () => {
    this.setState({
      isResendEmailCodeModalOpen: false
    });
  };

  closeResendPhoneCodeModal = () => {
    this.setState({
      isResendPhoneCodeModalOpen: false
    });
  };

  onPhoneChangeFormSubmit = data => {
    const { phone, phoneCode } = data;
    const { loginSession } = this.state;
    const phoneWithoutFirstZero = getUKPhoneWithoutFirstZero(phone, phoneCode);

    const phoneToPost = `${phoneCode}${phoneWithoutFirstZero}`;
    const user = {
      loginSessionKey: loginSession.key
    };

    this.setState({
      isLoading: true
    });

    changePhone(user, phoneToPost).then(res => {
      this.setState({
        isChangePhoneModalOpen: false,
        isSuccessPhoneChangeModalOpen: true,
        phone: phoneToPost,
        isLoading: false
      });
    });
  };

  onEmailChangeFormSubmit = data => {
    const { loginSession } = this.state;
    const user = {
      loginSessionKey: loginSession.key
    };

    this.setState({
      isLoading: true
    });

    changeEmail(user, data.email).then(res => {
      this.setState({
        isChangeEmailModalOpen: false,
        isSuccessEmailChangeModalOpen: true,
        email: data.email,
        isLoading: false
      });
    });
  };

  onEmailChangeFormCancelClick = () => {
    this.setState({
      isChangeEmailModalOpen: false
    });
  };

  onPhoneChangeFormCancelClick = () => {
    this.setState({
      isChangePhoneModalOpen: false
    });
  };

  closeSuccessEmailChangeModal = () => {
    this.setState({
      isSuccessEmailChangeModalOpen: false
    });
  };

  closeSuccessPhoneChangeModal = () => {
    this.setState({
      isSuccessPhoneChangeModalOpen: false
    });
  };

  renderResendEmailCodeModal() {
    const { isResendEmailCodeModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isResendEmailCodeModalOpen}
        title={
          <div className="modal-title-container">
            <svg
              className="modal-success-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="green"
              viewBox="0 0 24 24"
            >
              <path d="M9 16.17l-4.88-4.88-1.41 1.41L9 19 21 7l-1.41-1.41z" />
            </svg>
            <span className="modal-title-text">Verification Code Sent</span>
          </div>
        }
        body={
          <div className="modal-body-text">
            <p>We have resent your email verification code. Please check your inbox and spam folder.</p>
            <p className="modal-hint-text">Didn't receive it? Make sure your email address is correct.</p>
          </div>
        }
        buttonCancelText={'Got it'}
        onCloseClick={this.closeResendEmailCodeModal}
        customClass={'eVerificationTabModal enhanced-modal'}
      />
    );
  }

  renderResendPhoneCodeModal() {
    const { isResendPhoneCodeModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isResendPhoneCodeModalOpen}
        title={
          <div className="modal-title-container">
            <svg
              className="modal-success-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="green"
              viewBox="0 0 24 24"
            >
              <path d="M9 16.17l-4.88-4.88-1.41 1.41L9 19 21 7l-1.41-1.41z" />
            </svg>
            <span className="modal-title-text">Verification Code Sent</span>
          </div>
        }
        body={
          <div className="modal-body-text">
            <p>We have resent your phone verification code.</p>
            <p className="modal-hint-text">Didn't receive it? Make sure your phone number is correct.</p>
          </div>
        }
        buttonCancelText={'Got it'}
        onCloseClick={this.closeResendPhoneCodeModal}
        customClass={'eVerificationTabModal enhanced-modal'}
      />
    );
  }

  renderEmailChangeModal() {
    const { isChangeEmailModalOpen, email } = this.state;
    return (
      <SimpleModal isOpen={isChangeEmailModalOpen}>
        <EmailChangeForm
          onCancel={this.onEmailChangeFormCancelClick}
          onSubmit={this.onEmailChangeFormSubmit}
          email={email}
        />
      </SimpleModal>
    );
  }

  renderPhoneChangeModal() {
    const { isChangePhoneModalOpen, phone } = this.state;

    return (
      <SimpleModal isOpen={isChangePhoneModalOpen}>
        <PhoneChangeForm
          onCancel={this.onPhoneChangeFormCancelClick}
          onSubmit={this.onPhoneChangeFormSubmit}
          phone={phone}
        />
      </SimpleModal>
    );
  }

  renderSuccessEmailChangeModal() {
    const { isSuccessEmailChangeModalOpen, email } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessEmailChangeModalOpen}
        title={'Info'}
        body={`We have sent the verification code to ${email}`}
        buttonCancelText={'Ok'}
        onCloseClick={this.closeSuccessEmailChangeModal}
      />
    );
  }

  renderSuccessPhoneChangeModal() {
    const { isSuccessPhoneChangeModalOpen, phone } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessPhoneChangeModalOpen}
        title={'Info'}
        body={`We have sent the verification code to ${phone}`}
        buttonCancelText={'Ok'}
        onCloseClick={this.closeSuccessPhoneChangeModal}
      />
    );
  }

  renderStudentRoleRequestModal() {
    const { isRoleStudentRequestModalOpen, school } = this.state;
    const schoolName = propz.get(school, ['name']);

    return (
      <SimpleModal
        isOpen={isRoleStudentRequestModalOpen}
        title={
          <div className="modal-title-container">
            <FontAwesomeIcon icon={faExclamationTriangle} className="modal-icon warning" />
            <span className="modal-title-text">Confirm Role Request</span>
          </div>
        }
        body={`You are about to send a role request to join ${schoolName}. Do you wish to continue?`}
        buttonText="Ok"
        onButtonClick={this.onStudentRoleRequestClick}
        buttonCancelText="Cancel"
        onCloseClick={this.closeStudentRoleRequestModal}
        customClass="eVerificationTabModal enhanced-modal"
      />
    );
  }

  renderFirstStaffRoleRequestModal() {
    const { isRoleFirstStaffRequestModalOpen, school } = this.state;
    const schoolName = propz.get(school, ['name']);
    return (
      <SimpleModal
        isOpen={isRoleFirstStaffRequestModalOpen}
        title={'Role request confirmation'}
        body={`You appear to be the first member of staff for ${schoolName} to have signed up to Squad In Touch. Please confirm that you are an official of ${schoolName} and that you would like to sign up your school for a 30-day free trial subscription.`}
        buttonText={'Ok'}
        onButtonClick={this.onFirstStaffRoleRequestClick}
        buttonCancelText={'Cancel'}
        onCloseClick={this.closeFirstStaffRoleRequestModal}
        customClass={'eVerificationTabModal enhanced-modal'}
      />
    );
  }

  renderStaffRoleRequestModal() {
    const { isRoleStaffRequestModalOpen, school } = this.state;
    const schoolName = propz.get(school, ['name']);

    return (
      <SimpleModal
        isOpen={isRoleStaffRequestModalOpen}
        title={
          <div className="modal-title-container">
            <FontAwesomeIcon icon={faExclamationTriangle} className="modal-icon warning" />
            <span className="modal-title-text">Confirm Role Request</span>
          </div>
        }
        body={`You are about to request a staff role at ${schoolName}. Do you wish to proceed?`}
        buttonText="Ok"
        onButtonClick={this.onStaffRoleRequestClick}
        buttonCancelText="Cancel"
        onCloseClick={this.closeStaffRoleRequestModal}
        customClass="eVerificationTabModal enhanced-modal"
      />
    );
  }

  renderParentRoleRequestModal() {
    const { isRoleParentRequestModalOpen, school } = this.state;
    const schoolName = propz.get(school, ['name']);

    return (
      <SimpleModal
        isOpen={isRoleParentRequestModalOpen}
        title={
          <div className="modal-title-container">
            <FontAwesomeIcon icon={faExclamationTriangle} className="modal-icon warning" />
            <span className="modal-title-text">Confirm Role Request</span>
          </div>
        }
        body={`You are about to request a parent role at ${schoolName}. Do you wish to proceed?`}
        buttonText="Ok"
        onButtonClick={this.onParentRoleRequestClick}
        buttonCancelText="Cancel"
        onCloseClick={this.closeParentRoleRequestModal}
        customClass="eVerificationTabModal enhanced-modal"
      />
    );
  }

  closeParentRoleRequestModal = () => {
    this.setState({
      isRoleParentRequestModalOpen: false
    });
  };

  closeStaffRoleRequestModal = () => {
    this.setState({
      isRoleStaffRequestModalOpen: false
    });
  };

  closeFirstStaffRoleRequestModal = () => {
    this.setState({
      isRoleFirstStaffRequestModalOpen: false
    });
  };

  createRequest(data) {
    const { loginSession } = this.state;
    const { history, user, onFormSubmit } = this.props;
    const { userId, key } = loginSession;
    //copy-paste from login
    createProfileRequest({ loginSessionKey: key }, data)
      .then(res => {
        StorageHelper.Cookie.remove('loginSession', { domain: '.squadintouch.com' });
        StorageHelper.Cookie.set('loginSession', loginSession);

        const userUpdated = {
          ...user,
          authorized: true,
          userId: userId,
          loginSessionKey: key
        };

        return getProfile(userUpdated);
      })
      .then(profile => {
        onFormSubmit(userId, key, profile);

        history.push({
          pathname: '/profile/requests'
        });
      });
  }

  onFirstStaffRoleRequestClick = () => {
    const { schoolId } = this.state;

    this.setState({
      isLoading: true
    });

    const data = {
      schoolId: schoolId,
      preset: ROLE.ADMIN
    };

    this.createRequest(data);
  };

  onStaffRoleRequestClick = () => {
    const { schoolId, staff } = this.state;

    this.setState({
      isLoading: true
    });

    const data = {
      schoolId: schoolId,
      preset: staff
    };

    this.createRequest(data);
  };

  onParentRoleRequestClick = () => {
    const { schoolId, students, loginSession } = this.state;
    const { history, user, onFormSubmit } = this.props;
    const { userId, key } = loginSession;

    this.setState({
      isLoading: true
    });

    const isStudentsExist = students.length > 0;

    if (isStudentsExist) {
      BPromise.all(
        students.map(student => {
          const data = {
            ...student,

            schoolId: schoolId,
            preset: ROLE.PARENT
          };
          return createProfileRequest({ loginSessionKey: key }, data);
        })
      )
        .then(res => {
          StorageHelper.Cookie.remove('loginSession', { domain: '.squadintouch.com' });
          StorageHelper.Cookie.set('loginSession', loginSession);

          const userUpdated = {
            ...user,
            authorized: true,
            userId: userId,
            loginSessionKey: key
          };

          return getProfile(userUpdated);
        })
        .then(profile => {
          onFormSubmit(userId, key, profile);

          history.push({
            pathname: '/profile/requests'
          });
        });
    } else {
      const data = {
        schoolId: schoolId,
        preset: ROLE.PARENT
      };

      this.createRequest(data);
    }
  };

  onStudentRoleRequestClick = () => {
    const { schoolId, permission, oneOffCode } = this.state;

    this.setState({
      isLoading: true
    });

    const data = oneOffCode
      ? {
          schoolId: schoolId,
          preset: permission,
          details: { oneOffCode: oneOffCode }
        }
      : {
          schoolId: schoolId,
          preset: permission
        };

    this.createRequest(data);
  };

  closeStudentRoleRequestModal = () => {
    this.setState({
      isRoleStudentRequestModalOpen: false
    });
  };

  onSendEmailCodeAgainClick = event => {
    event.preventDefault();
    const { loginSession } = this.state;
    const user = {
      loginSessionKey: loginSession.key
    };
    resendEmailCode(user).then(res => {
      this.setState({
        isResendEmailCodeModalOpen: true
      });
    });
  };

  onSendPhoneCodeAgainClick = event => {
    event.preventDefault();
    const { loginSession } = this.state;
    const user = {
      loginSessionKey: loginSession.key
    };
    resendPhoneCode(user).then(res => {
      this.setState({
        isResendPhoneCodeModalOpen: true
      });
    });
  };

  onChangePhoneClick = event => {
    event.preventDefault();
    this.setState({
      isChangePhoneModalOpen: true
    });
  };

  onChangeEmailClick = event => {
    event.preventDefault();
    this.setState({
      isChangeEmailModalOpen: true
    });
  };

  onSubmitEmailClick = ({ emailCode }) => {
    const { loginSession } = this.state;
    const user = {
      loginSessionKey: loginSession.key
    };
    verifyEmail(user, emailCode).then(res => {
      const { confirmed } = res;
      this.setState({
        isEmailConfirmed: confirmed
      });
    });
  };

  onSubmitPhoneClick = ({ phoneCode }) => {
    const { loginSession } = this.state;
    const user = {
      loginSessionKey: loginSession.key
    };
    verifyPhone(user, phoneCode).then(res => {
      const { confirmed } = res;
      this.setState({
        isPhoneConfirmed: confirmed
      });
    });
  };

  render() {
    const {
      currentStep,
      isLoading,
      isResendEmailCodeModalOpen,
      isResendPhoneCodeModalOpen,
      isChangeEmailModalOpen,
      isChangePhoneModalOpen,
      isSuccessPhoneChangeModalOpen,
      isSuccessEmailChangeModalOpen,
      isRoleStudentRequestModalOpen,
      isRoleFirstStaffRequestModalOpen,
      isRoleStaffRequestModalOpen,
      isRoleParentRequestModalOpen
    } = this.state;
    const steps = this.getSteps();
    const totalSteps = steps.length;
    const currentStepIndex = steps.findIndex(step => step === currentStep);
    const stepIndex = currentStepIndex + 1;
    const progress = (stepIndex / totalSteps) * 100;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isResendEmailCodeModalOpen ||
      isResendPhoneCodeModalOpen ||
      isChangeEmailModalOpen ||
      isChangePhoneModalOpen ||
      isSuccessPhoneChangeModalOpen ||
      isSuccessEmailChangeModalOpen ||
      isRoleStudentRequestModalOpen ||
      isRoleFirstStaffRequestModalOpen ||
      isRoleStaffRequestModalOpen ||
      isRoleParentRequestModalOpen
        ? 'registration-container modal-open'
        : 'registration-container';

    return (
      <div className={classes}>
        {this.renderEmailChangeModal()}
        {this.renderPhoneChangeModal()}
        {this.renderResendEmailCodeModal()}
        {this.renderResendPhoneCodeModal()}
        {this.renderSuccessEmailChangeModal()}
        {this.renderSuccessPhoneChangeModal()}
        {this.renderStudentRoleRequestModal()}
        {this.renderFirstStaffRoleRequestModal()}
        {this.renderStaffRoleRequestModal()}
        {this.renderParentRoleRequestModal()}

        <h1 className={'eRegistrationTitle'}>Create Your Squad In Touch Account</h1>
        <div className="row">
          <div className="col-md-6">
            <div className={'mt-3 mb-3 font-weight-bold text-center'}>
              {REGISTRATION_WIZARD_STEP_TITLE_MAPPING[currentStep]}
            </div>
            <ProgressBar progress={progress} step={stepIndex} stepTotal={totalSteps} />
            {REGISTRATION_WIZARD_STEP_DESCRIPTION_MAPPING[currentStep]}
          </div>
          <div className="col-md-6">{this.renderViewByStep()}</div>
        </div>
      </div>
    );
  }
}
