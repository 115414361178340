import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Map } from 'Src/components/Map/Map';
import { Button } from 'Src/components/Button/Button';
import { TournamentConfirmationRequestMessage, MessageField } from 'Src/models/message';
import { TournamentInfo } from './TournamentInfo/TournamentInfo';
import { getSelectOptionForBooleanQuestion } from 'Src/helpers/table/select';
import { QUESTIONS_TEMPLATE_TYPE } from 'Src/consts/message';

interface Props {
  message: TournamentConfirmationRequestMessage;
  onCloseClick: () => void;
}

interface State {
  isMapVisible: boolean;
}

export class ConfirmationRequestViewForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isMapVisible: false
    };
  }

  toggleMap = () => {
    this.setState(prevState => ({
      isMapVisible: !prevState.isMapVisible
    }));
  };

  render() {
    const { message, onCloseClick } = this.props;
    const { isMapVisible } = this.state;

    const picUrl = propz.get(message, ['schoolData', 'pic'], '');
    const isSchoolLogoExist = picUrl !== '';

    const point = propz.get(message, ['tournamentData', 'venue', 'point']);

    return (
      <>
        <div className="eClubInvitationFormRow">
          <img
            src={isSchoolLogoExist ? picUrl : '/dist/images/no-image.jpg'}
            alt="logo"
            className="bClubInvitationFormLogo"
          />
          <TournamentInfo message={message} onMapClick={this.toggleMap} />
        </div>

        {isMapVisible && point && (
          <Map point={point} customStylingClass="mb-3 eClubWizardMap" isMarkerDraggable={false} />
        )}

        <div className="confirmation-header mt-3 mb-3">
          <h3 className="font-weight-bold">Confirmation Details</h3>
          <p>Below are the details your school submitted when responding to this request.</p>
        </div>

        <div className="confirmation-fields">
          {message.fields &&
            message.fields.map((field: MessageField, index: number) => (
              <div key={`view_form_field_${index}`} className="form-field mb-3">
                <div className={field.isRequired ? 'font-weight-bold' : ''}>
                  {field.heading} {field.isRequired ? '(required)' : ''}
                </div>
                <div className="read-only-field" style={{ padding: '5px 0' }}>
                  {field.type === QUESTIONS_TEMPLATE_TYPE.BOOLEAN
                    ? getSelectOptionForBooleanQuestion().find(option => option.value === field.value)?.text ||
                      field.value
                    : field.value}
                </div>
              </div>
            ))}
        </div>

        <div className="confirmation-actions mt-3 text-right">
          <Button onClick={onCloseClick} text={'Close'} customClass={'btn-respond btn-close'} />
        </div>
      </>
    );
  }
}
