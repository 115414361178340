import * as React from 'react';
import { GridBody } from './GridBody';
import { ColumnDefinition, TABLE_SORT_DIRECTION } from 'Src/helpers/table/table';
import './Grid.scss';
import { GridTitle2 } from './GridTitle2';
import { SelectOption } from '../../helpers/table/table';
import { GridPanel } from './GridPanel';
import { ActionItem } from '../ActionsButton/ActionsButton';
import { AppUser } from 'Src/views/App/App';
import { Tournament } from '../../models/tournament';

interface Props {
  dataItems: any[];
  onItemClick: (index: number) => void;
  onSortClick: (sortField: string) => void;
  columns: ColumnDefinition[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  dataItemsSelected: any[];
  onApplyFilterClick: () => void;
  onClearFilterClick: () => void;
  actionItems: ActionItem[];
  currentPage: number;
  total: number;
  isSelectAllChecked: boolean;
  isDataFiltered: boolean;
  setCurrentPageParams: (currentPage: number) => void;
  onSelectAllClick: () => void;
  onSelectAllOnPageClick: () => void;
  onUnselectAllClick: () => void;
  onTableFilterClick: (event) => void;
  onTableFilterChange?: (event, value: string, options?, index?: number, checkBoxIndex?: number) => void;
  onCellIconClick?: (data, cell) => void;
  renderAdditionalOptions?: () => React.ReactNode;
  filters: any;
  goBack?: () => void;
  gridTitle?: string;
  isPDF?: boolean;
  isCSV?: boolean;
  isSelectDisabled?: boolean;
  stylizeCell?: (cell: any) => string;
  autocompleteSearchFunctions?: any;
  autocompleteGetElementsTitles?: any;
  onTableAutocompleteFilterChange?: (item: any, field: string) => any;
  autocompleteDefaultTexts?: any;

  user?: AppUser;
  tournament?: Tournament;

  options?: SelectOptions;
  filterMinDateInterval?: Date;
  filterMaxDateInterval?: Date;
}

export interface SelectOptions {
  priority?: SelectOption[];
  invitationStatus?: SelectOption[];
  generalMessageStatus?: SelectOption[];
  generalMessageDeliveryStatus?: SelectOption[];
  clubMessageStatus?: SelectOption[];
  participant?: SelectOption[];
  ages?: SelectOption[];
  agesRecord?: SelectOption[];
  days?: SelectOption[];
  userStatus?: SelectOption[];
  forms?: SelectOption[];
  houses?: SelectOption[];
  genders?: SelectOption[];
  tag?: SelectOption[];
  role?: SelectOption[];
  clubStatus?: SelectOption[];
  permissionFormIds?: SelectOption[];
  permissionHouseIds?: SelectOption[];
  ageGroup?: SelectOption[];
  deliveryStatus?: SelectOption[];
  statusOptions?: SelectOption[];
  requestedPermissionPreset?: SelectOption[];
  messageType?: SelectOption[];
  status?: SelectOption[];
  season?: SelectOption[];
  eventTournamentGroup?: SelectOption[];
  action?: SelectOption[];
  tournamentAges?: SelectOption[];
  participationStatisticAges?: SelectOption[];
  pupilsPremium?: SelectOption[];
  specialEducationNeeds?: SelectOption[];
  freeSchoolMeals?: SelectOption[];
  gender?: SelectOption[];
  inviteStatus?: SelectOption[];
  userFormName?: SelectOption[];
  userHouseName?: SelectOption[];
  eventType?: SelectOption[];
  eventAges?: SelectOption[];
  venueType?: SelectOption[];
  eventSport?: SelectOption[];
  aggregationType?: SelectOption[];
  subtype?: SelectOption[];
  isPublishOnTheWebsite?: SelectOption[];
  isPromoteTournament?: SelectOption[];
  isPublished?: SelectOption[];
  category?: SelectOption[];
  upcomingTournamentsSeason?: SelectOption[];
  pastTournamentsSeason?: SelectOption[];
  tournamentTag?: SelectOption[];
  availableAges?: SelectOption[];
  availableGender?: SelectOption[];
  areaSU?: SelectOption[];
}

export const Grid2: React.FunctionComponent<Props> = props => {
  return (
    <>
      <GridPanel
        actionItems={props.actionItems}
        currentPage={props.currentPage}
        setCurrentPageParams={props.setCurrentPageParams}
        total={props.total}
        onSelectAllClick={props.onSelectAllClick}
        onSelectAllOnPageClick={props.onSelectAllOnPageClick}
        onUnselectAllClick={props.onUnselectAllClick}
        isSelectAllChecked={props.isSelectAllChecked}
        selectedItems={props.dataItemsSelected}
        onTableFilterClick={props.onTableFilterClick}
        isShowFilter={props.isShowFilter}
        isDataFiltered={props.isDataFiltered}
        goBack={props.goBack}
        gridTitle={props.gridTitle}
        isPDF={props.isPDF}
        isCSV={props.isCSV}
        columns={props.columns}
        filters={props.filters}
        isSelectDisabled={props.isSelectDisabled}
        renderAdditionalOptions={props.renderAdditionalOptions}
      />
      <div className="table-responsive">
        <table className="table table-sm table-hover mt-3 mPre mVerticalAlignMiddle mVerticalAlignTop">
          <GridTitle2
            sortColumnsName={props.sortColumnsName}
            sortDirection={props.sortDirection}
            columns={props.columns}
            onSortClick={props.onSortClick}
            isShowFilter={props.isShowFilter}
            onApplyFilterClick={props.onApplyFilterClick}
            onClearFilterClick={props.onClearFilterClick}
            filters={props.filters}
            options={props.options}
            onTableFilterChange={props.onTableFilterChange}
            filterMinDateInterval={props.filterMinDateInterval}
            filterMaxDateInterval={props.filterMaxDateInterval}
            autocompleteSearchFunctions={props.autocompleteSearchFunctions}
            autocompleteGetElementsTitles={props.autocompleteGetElementsTitles}
            onTableAutocompleteFilterChange={props.onTableAutocompleteFilterChange}
            autocompleteDefaultTexts={props.autocompleteDefaultTexts}
          />
          <GridBody
            dataItems={props.dataItems}
            columns={props.columns}
            dataItemsSelected={props.dataItemsSelected}
            onDataItemClick={props.onItemClick}
            isSelectDisabled={props.isSelectDisabled}
            user={props.user}
            tournament={props.tournament}
            stylizeCell={props.stylizeCell}
            onCellIconClick={props.onCellIconClick}
          />
        </table>
      </div>
    </>
  );
};

Grid2.defaultProps = {
  isPDF: false,
  isCSV: true,
  isSelectDisabled: false
};

Grid2.displayName = 'Grid2';
