import * as React from 'react';

interface Props {
  onClick?: () => void;
}

export const EditIcon = (props: Props) => {
  const { onClick } = props;
  const isOnClickExist = typeof onClick !== 'undefined';
  const emptyFunc = () => {};

  return (
    <svg
      className="bTableCellIcon"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      onClick={isOnClickExist ? onClick : emptyFunc}
    >
      <g fill="#2394be">
        <path d="M3 17.25l11.66-11.66 3.99 3.99-11.66 11.66L3 22.999z" />
        <path d="M14.66 5.59l1.34-1.34c.37-.37.88-.58 1.41-.58.53 0 1.04.21 1.41.58l1.34 1.34c.37.37.58.88.58 1.41s-.21 1.04-.58 1.41l-1.34 1.34-3.99-3.99z" />
      </g>
    </svg>
  );
};

EditIcon.displayName = 'EditIcon';
