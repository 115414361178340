import * as React from 'react';
import { Button } from '../../../components/Button/Button';
import { PhoneVerificationForm } from './PhoneVerificationForm/PhoneVerificationForm';
import { EmailVerificationForm } from './EmailVerificationForm/EmailVerificationForm';

interface Props {
  onSubmit: () => void;
  onCancel: () => void;
  email: string;
  phone: string;
  onSendEmailCodeAgainClick: (event) => void;
  onChangeEmailClick: (event) => void;
  onSendPhoneCodeAgainClick: (event) => void;
  onChangePhoneClick: (event) => void;
  isPhoneConfirmed: boolean;
  isEmailConfirmed: boolean;
  onSubmitEmailClick: ({ emailCode: string }) => void;
  onSubmitPhoneClick: ({ phoneCode: string }) => void;
}
interface State {}

export class VerificationForm extends React.Component<Props, State> {
  render() {
    const {
      onCancel,
      onSubmit,
      email,
      phone,
      onSendEmailCodeAgainClick,
      onChangeEmailClick,
      onSendPhoneCodeAgainClick,
      onChangePhoneClick,
      isPhoneConfirmed,
      isEmailConfirmed,
      onSubmitEmailClick,
      onSubmitPhoneClick
    } = this.props;
    const isSubmitEnabled = isPhoneConfirmed && isEmailConfirmed;

    return (
      <div className={'mt-3'}>
        <EmailVerificationForm
          onSendCodeAgainClick={onSendEmailCodeAgainClick}
          email={email}
          isConfirmed={isEmailConfirmed}
          onSubmit={onSubmitEmailClick}
          onChangeClick={onChangeEmailClick}
        />
        <PhoneVerificationForm
          onSendCodeAgainClick={onSendPhoneCodeAgainClick}
          phone={phone}
          isConfirmed={isPhoneConfirmed}
          onSubmit={onSubmitPhoneClick}
          onChangeClick={onChangePhoneClick}
        />
        <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
        <Button disabled={!isSubmitEnabled} onClick={onSubmit} text={'Submit'} customClass={'mt-3 mb-3 mr-3'} />
      </div>
    );
  }
}
