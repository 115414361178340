import * as React from 'react';
import * as classNames from 'classnames';
import { AppUser } from 'Src/views/App/App';
import { ROLE, ROLE_SERVER_TO_CLIENT_MAPPING } from '../../consts/user';
import { LogOut } from '../LogOut/LogOut';
import { Role } from '../../models/roles';
import { getUserPermissions, isUserAdminSU, isUserParent } from '../../helpers/user/user';
import { Permission } from '../../models/permission';
import { KIND } from '../../consts/school';
import { Avatar } from '../Avatar/Avatar';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import './PermissionSelector.scss';

interface State {
  isOpen: boolean;
}

interface Props {
  user: AppUser;
  history: any;
  onLogoutClick: (event) => void;
  onRoleChange: (
    roleName: string,
    permissionSchoolId: string,
    permissionSchoolName: string,
    permission: Permission
  ) => any;
  availableRoles: Role[];
}

export class PermissionSelector extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  onSelectorClick = event => {
    event.preventDefault();
    const isOpen = this.state.isOpen;

    this.setState({
      isOpen: !isOpen
    });
  };

  onRoleClick = (event, permission: Permission): void => {
    event.preventDefault();
    const roleName = permission.preset;
    const permissionSchoolId = permission.school.id;
    const permissionSchoolName = permission.school.name;

    const history = this.props.history;

    this.setState({
      isOpen: false
    });

    this.props.onRoleChange(roleName, permissionSchoolId, permissionSchoolName, permission).then(res => {
      const isParent = roleName === ROLE.PARENT;
      const isCoach = roleName === ROLE.COACH;
      const isTeacher = roleName === ROLE.TEACHER;
      const isStudent = roleName === ROLE.STUDENT;
      const isManager = roleName === ROLE.MANAGER;
      const isAdmin = roleName === ROLE.ADMIN;
      const isSchoolUnion = permission.school.kind === KIND.SCHOOL_UNION;
      const isSchoolUnionAdmin = isAdmin && isSchoolUnion;

      switch (true) {
        case isSchoolUnionAdmin:
          history.push({
            pathname: '/tournaments/upcoming'
          });
          break;
        case isAdmin && !isSchoolUnion:
          history.push({
            pathname: '/dashboard'
          });
          break;
        case isParent || isStudent || isTeacher || isCoach || isManager:
          history.push({
            pathname: '/calendar'
          });
          break;
        default:
          history.push({
            pathname: '/calendar'
          });
      }
    });
  };

  renderAvailableRoles(): React.ReactFragment {
    const availableRoles = this.props.availableRoles;
    const permissions = getUserPermissions(availableRoles);

    return [...permissions]
      .sort((permission1, permission2) => {
        switch (true) {
          case permission1.preset > permission2.preset:
            return 1;
          case permission1.preset < permission2.preset:
            return -1;
          case permission1.preset === permission2.preset:
            switch (true) {
              case permission1.school.name > permission2.school.name:
                return 1;
              case permission1.school.name < permission2.school.name:
                return -1;
              case permission1.school.name === permission2.school.name:
                return 0;
            }
        }
      })
      .map((role, index) => {
        const roleName = ROLE_SERVER_TO_CLIENT_MAPPING[role.preset];
        const schoolName = role.school.name;
        const schoolKind = role.school.kind;
        const preset = role.preset;

        switch (true) {
          case preset === ROLE.STUDENT:
          case preset === ROLE.TOURNAMENT_FOLLOWER:
          case preset === ROLE.GOVERNOR:
            return null;
          case preset === ROLE.PARENT:
            return (
              <a
                key={`role_${index}`}
                className="dropdown-item"
                href=""
                onClick={event => this.onRoleClick(event, role)}
              >
                <span className={'text-secondary'}>{roleName}</span>
              </a>
            );
          default:
            return (
              <a
                key={`role_${index}`}
                className="dropdown-item"
                href=""
                onClick={event => this.onRoleClick(event, role)}
              >
                <span>{`${schoolName} `}</span>
                <span className={'text-secondary'}>{roleName}</span>
              </a>
            );
        }
      });
  }

  onProfileClick = () => {
    this.props.history.push({
      pathname: '/profile/summary'
    });
  };

  render() {
    const { user } = this.props;
    const { isOpen } = this.state;
    const { activeSchoolName, activeRole, profile } = user;
    const userRoleMapped = ROLE_SERVER_TO_CLIENT_MAPPING[activeRole];

    const isParent = activeRole === ROLE.PARENT;
    const isStudent = activeRole === ROLE.STUDENT;

    const permissionSelectorTitle =
      isParent || isStudent ? `${userRoleMapped}` : `${activeSchoolName} ${userRoleMapped}`;

    const userFullName = getFullName(profile);

    const dropdownClasses = classNames({
      dropdown: true,
      'nav-item': true,
      show: isOpen
    });

    const dropdownMenuClasses = classNames({
      bDropdownWrapper: true,
      'dropdown-menu': true,
      'dropdown-menu-right': true,
      show: isOpen
    });

    return (
      <div className={dropdownClasses}>
        <Avatar user={this.props.user} history={this.props.history} onClick={this.onSelectorClick} />

        {isOpen && <div className="bDropdownOverlay" onClick={this.onSelectorClick}></div>}

        <div className={dropdownMenuClasses}>
          <div className="px-4">
            <div className="font-weight-bold">Active role: {permissionSelectorTitle}</div>
            <div className="eActiveRole" onClick={this.onProfileClick}>
              Profile: {userFullName}
            </div>
          </div>

          <div className="dropdown-divider" />
          <div className="eUserRoles">{this.renderAvailableRoles()}</div>
          <div className="dropdown-divider" />

          <LogOut onLogoutClick={this.props.onLogoutClick} history={this.props.history} />
        </div>
      </div>
    );
  }
}
