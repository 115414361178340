import * as React from 'react';
import * as BPromise from 'bluebird';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import * as Moment from 'moment';
import { AppUser } from 'Src/views/App/App';
import { parse } from 'query-string';
import { getOrder, getSearchFilter, getSearchOrder, getServerFieldSectionWhere } from 'Src/helpers/table/table';
import {
  ColumnDefinition,
  getFilters2,
  getServerQueryFilter2,
  isFilterExist2,
  isSortExist,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import { History, Location } from 'history';
import {
  deleteTournament,
  getAllTournamentEligibleSchools,
  getAllPastTournaments,
  getCsvListTournamentStudents,
  getPastTournaments,
  getPastTournamentsCount,
  updateTournamentAutoEnrollment,
  updateTournament,
  getTournament
} from 'Src/helpers/service/adminSU/tournamentsTable';
import { Grid2 } from 'Src/components/Grid/Grid2';
import { Loader } from 'Src/components/Loader/Loader';
import { DATE_INTERVAL, DEFAULT_LIMIT, DEFAULT_SKIP, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { getEndTime, getSeason, getStartTime, getTournamentTagNames } from 'Src/helpers/accessor/accessor';
import { ResizablePanel } from 'Src/components/ResizablePanel/ResizablePanel';
import { GenericSummary } from 'Src/components/GenericSummary/GenericSummary';
import {
  TEAM_TOURNAMENT_TABS,
  TOURNAMENT_FINAL_STAGE,
  TOURNAMENT_GROUP_STAGE,
  TOURNAMENT_GROUP_TAG_MAPPING,
  TOURNAMENT_LAYOUT_TYPE,
  TOURNAMENT_PLAYOFF_STAGE,
  TOURNAMENT_QUALIFIER_STAGE,
  TOURNAMENT_TABS
} from 'Src/consts/tournament';
import { TournamentSummary } from 'Src/components/TournamentSummary/TournamentSummary';
import { Button } from 'Src/components/Button/Button';
import { TournamentParticipantsSummary } from 'Src/components/TournamentParticipantsSummary/TournamentParticipantsSummary';
import { TournamentEligibleSchoolsSummary } from 'Src/components/TournamentEligibleSchoolsSummary/TournamentEligibleSchoolsSummary';
import { TournamentConfirmationRequestsSummary } from 'Src/components/TournamentConfirmationRequestsSummary/TournamentConfirmationRequestsSummary';
import { getAllTournamentConfirmationRequests } from 'Src/helpers/service/adminSU/tournamentConfirmationRequestsTable';
import { Tournament } from 'Src/models/tournament';
import { EligibleSchool } from 'Src/models/school';
import { TournamentConfirmationRequestMessage } from 'Src/models/message';
import { TournamentEventsSummary } from 'Src/components/TournamentEventsSummary/TournamentEventsSummary';
import { TournamentAutoEnrollmentSummary } from 'Src/components/TournamentAutoEnrollmentSummary/TournamentAutoEnrollmentSummary';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { TournamentAutoEnrollmentForm } from '../TournamentAutonrollmentForm/TournamentAutoEnrollmentForm';
import { TournamentPublicSiteSettingsSummary } from 'Src/components/TournamentPublicSiteSettingsSummary/TournamentPublicSiteSettingsSummary';
import { TournamentPublicSiteSettingsForm } from '../TournamentPublicSiteSettingsForm/TournamentPublicSiteSettingsForm';
import {
  getSelectOptionForArchiveTournamentsSeason,
  getSelectOptionForSeason,
  getSelectOptionForTournamentTag
} from 'Src/helpers/table/select';
import { isIndividualTournament, isMiniTeamTournament, isTeamTournament } from 'Src/helpers/tournament/tournament';
import { TournamentPitchesSummary } from 'Src/components/TournamentPitchesSummary/TournamentPitchesSummary';
import { deleteEvent, getAllTournamentEvents } from 'Src/helpers/service/adminSU/tournamentEventsTable';
import { getAllTournamentAdmins } from 'Src/helpers/service/adminSU/tournamentAdminsTable';
import { TournamentAdminsSummary } from 'Src/components/TournamentAdminsSummary/TournamentAdminsSummary';
import { EmptyComponent } from 'Src/components/EmptyComponent/EmptyComponent';
import { TournamentCustomGroupsSummary } from 'Src/components/TournamentCustomGroupsSummary/TournamentCustomGroupsSummary';
import { getTournamentEnrollmentLogs } from 'Src/helpers/service/adminSU/tournamentEnrollmentLogsTable';
import { TournamentEnrollmentLogsSummary } from 'Src/components/TournamentEnrollmentLogsSummary/TournamentEnrollmentLogsSummary';
import {
  addOrRemoveTournamentsToChampionship,
  getAllChampionships
} from 'Src/helpers/service/adminSU/championshipsTable';
import { Championship } from 'Src/models/championship';
import { TournamentAddToChampionshipModal } from 'Src/components/TournamentAddToChampionshipModal/TournamentAddToChampionshipModal';
import { TournamentRemoveToChampionshipModal } from 'Src/components/TournamentRemoveToChampionshipModal/TournamentRemoveToChampionshipModal';
import { TournamentAddEventForm } from '../TournamentAddEventForm/TournamentAddEventForm';
import { activateEvent, addGroupEvents, addTeam, createEvent } from 'Src/helpers/service/admin/event';
import { DEFAULT_POINT, EVENT_VENUE } from 'Src/consts/venue';
import { GENDER, MODE_TYPE, TABS } from 'Src/consts/common';
import { getAllSchoolUnionTournamentTeams } from 'Src/helpers/service/adminSU/tournamentTeamsTable';
import { TournamentTeamsSummary } from 'Src/components/TournamentTeamsSummary/TournamentTeamsSummary';
import { getAllTournamentParticipants } from 'Src/helpers/service/adminSU/tournamentParticipantsTable';
import { TournamentDefaultGroupsForm } from '../TournamentDefaultGroupsForms/TournamentDefaultGroupsForm';
import { deleteTournamentGroup, createTournamentGroup } from 'Src/helpers/service/adminSU/tournamentCustomGroupsTable';
import { TournamentEventsGroupForm } from '../TournamentEventsGroupForm/TournamentEventsGroupsForm';
import { AGE_GROUPS } from 'Src/consts/school';
import { TournamentEditTournamentTVForm } from '../TournamentEditTournamentTVForm/TournamentEditTournamentTVForm';
import { TournamentTVSummary } from 'Src/components/TournamentTVSummary/TournamentTVSummary';
import { TEAM_TYPE } from 'Src/consts/team';
import { getPagesCount } from 'Src/helpers/table/table';
import { LIMIT } from 'Src/consts/table';
import { getIsPromoteTournament, getIsPublishOnTheWebsite } from 'Src/helpers/accessor/accessor';
import { getSpaces } from 'Src/helpers/tournament/tournament';
import { INVITE_STATUS } from 'Src/consts/invite';
import { getSelectOptionForBooleanQuestion } from 'Src/helpers/table/select';
import { INVITE_KIND } from 'Src/consts/invite';
import { INVITE_KIND_TYPE } from 'Src/types/invite';
import { uploadReport } from 'Src/helpers/service/admin/report';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { ExtendedTournamentScheduleReportForm } from '../Reports/ExtendedTournamentScheduleReportForm/ExtendedTournamentScheduleReportForm';
import { TournamentTeamMembersReportForm } from '../Reports/TournamentTeamMembersReportForm/TournamentTeamMembersReportForm';
import { TournamentParticipationConfirmationsReportForm } from '../Reports/TournamentParticipationConfirmationsReportForm/TournamentParticipationConfirmationsReportForm';
import { TournamentStartListReportForm } from '../Reports/TournamentStartListReportForm/TournamentStartListReportForm';
import { UnionMemberSchoolsStatisticsReportForm } from '../Reports/UnionMemberSchoolsStatisticsReportForm/UnionMemberSchoolsStatisticsReportForm';
import { UnionMemberSchoolsTournamentsReportForm } from '../Reports/UnionMemberSchoolsTournamentsReportForm/UnionMemberSchoolsTournamentsReportForm';
import { UnionMemberSchoolsTournamentsAsPartOfUnionReportForm } from '../Reports/UnionMemberSchoolsTournamentsAsPartOfUnionReportForm/UnionMemberSchoolsTournamentsAsPartOfUnionReportForm';
import { TournamentPlayersReportForm } from '../Reports/TournamentPlayersReportForm/TournamentPlayersReportForm';
import { TournamentScoreCardsReportForm } from '../Reports/TournamentScoreCardsReportForm/TournamentScoreCardsReportForm';
import { TournamentResultsIndividualReportForm } from '../Reports/TournamentResultsIndividualReportForm/TournamentResultsIndividualReportForm';
import { TournamentSchoolsReportForm } from '../Reports/TournamentSchoolsReportForm/TournamentSchoolsReportForm';
import { HighLevelTournamentSchoolAdminsReportForm } from '../Reports/HighLevelTournamentSchoolAdminsReportForm/HighLevelTournamentSchoolAdminsReportForm';
import { getTournamentTags } from 'Src/helpers/service/adminSU/tournamentTags';
import { TournamentTagAssignModal } from 'Src/components/TournamentTagAssignModal/TournamentTagAssignModal';
import { TournamentTagDeleteModal } from 'Src/components/TournamentTagDeleteModal/TournamentTagDeleteModal';
import { assignTournamentTag, deleteTournamentTagByTournament } from 'Src/helpers/service/adminSU/tournamentGrid';
import { TournamentTag } from 'Src/models/tournamentTag';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['name']
  },
  {
    text: 'Season',
    field: 'season',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getSeason
  },
  {
    text: 'Sport',
    field: 'sport',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['sport', 'name']
  },
  {
    text: 'Start',
    field: 'startTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getStartTime
  },
  {
    text: 'End',
    field: 'endTime',
    isSort: true,
    type: FILTER_TYPE.DATE_INTERVAL,
    accessor: getEndTime
  },
  {
    text: 'Spaces',
    field: 'spaces',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: getSpaces
  },
  {
    text: 'Publish on union website',
    field: 'isPublishOnTheWebsite',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getIsPublishOnTheWebsite
  },
  {
    text: 'Highlighted',
    field: 'isPromoteTournament',
    isSort: true,
    type: FILTER_TYPE.SELECT,
    accessor: getIsPromoteTournament
  },
  {
    text: 'Tags',
    field: 'tournamentTag',
    isSort: false,
    type: FILTER_TYPE.MULTISELECT,
    accessor: getTournamentTagNames
  }
];

interface State {
  items: Tournament[];
  currentPage: number;
  selectedItems: Tournament[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  filters: any;
  isDataFiltered: boolean;
  isLoading: boolean;
  total: number;
  totalAll: number;
  basePath: string;
  isSelectAllChecked: boolean;
  tournamentTags: any[];
  isEditableAutoEnrollment: boolean;
  isEditablePublicSiteSettings: boolean;
  isInformationModalShow: boolean;
  isRemoveExistingEventsModalShow: boolean;
  isAddTournamentToChampionshipModalOpen: boolean;
  isRemoveTournamentToChampionshipModalOpen: boolean;
  isAddEventModalOpen: boolean;
  isDefaultGroupsModalOpen: boolean;
  isCreateEventsForGroupModalOpen: boolean;
  isWarningAboutDeletingEventsModalOpen: boolean;
  isEditableTournamentTVSettings: boolean;
  isDeleteTournamentConfirmationModalOpen: boolean;

  currentTabIndex: number;
  eligibleSchools: EligibleSchool[];
  confirmationRequests: TournamentConfirmationRequestMessage[];
  tournamentEvents: any[];
  admins: any[];
  enrollmentLogs: any[];
  championships: Championship[];
  teams: any[];
  participants: any[];
  eventsForGroupFormSubmitData: any;
  modalTitle: string;
  modalText: string;
  errorMessage: string;
  isErrorModalOpen: boolean;
  isTournamentTagModalOpen: boolean;
  isTournamentTagDeleteModalOpen: boolean;
  isExtendedTournamentScheduleReportModalOpen: boolean; //011;
  isTournamentTeamMembersReportModalOpen: boolean; //033
  isTournamentParticipationConfirmationsReportModalOpen: boolean; //012
  isTournamentStartListReportModalOpen: boolean; //034
  isUnionMemberSchoolsStatisticsReportModalOpen: boolean; //018
  isUnionMemberSchoolsTournamentsReportModalOpen: boolean; //038
  isUnionMemberSchoolsTournamentsAsPartOfUnionReportModalOpen: boolean; //039
  isTournamentPlayersReportModalOpen: boolean; //040
  isTournamentScoreCardsReportModalOpen: boolean; //042
  isTournamentResultsIndividualReportModalOpen: boolean; //036
  isTournamentSchoolsReportModalOpen: boolean; //046
  isHighLevelTournamentSchoolAdminsReportModalOpen: boolean; //063

  selectedTournamentTagId: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class TournamentsArchive extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      filters: {},
      isDataFiltered: false,
      isLoading: false,
      total: 0,
      totalAll: 0,
      basePath: '',
      isSelectAllChecked: false,
      tournamentTags: [],
      isEditableAutoEnrollment: false,
      isEditablePublicSiteSettings: false,
      isInformationModalShow: false,
      isRemoveExistingEventsModalShow: false,
      isAddTournamentToChampionshipModalOpen: false,
      isRemoveTournamentToChampionshipModalOpen: false,
      isAddEventModalOpen: false,
      isDefaultGroupsModalOpen: false,
      isCreateEventsForGroupModalOpen: false,
      isWarningAboutDeletingEventsModalOpen: false,
      isEditableTournamentTVSettings: false,
      isDeleteTournamentConfirmationModalOpen: false,
      isExtendedTournamentScheduleReportModalOpen: false,
      isTournamentTeamMembersReportModalOpen: false,
      isTournamentParticipationConfirmationsReportModalOpen: false,
      isTournamentStartListReportModalOpen: false,
      isUnionMemberSchoolsStatisticsReportModalOpen: false,
      isUnionMemberSchoolsTournamentsReportModalOpen: false,
      isUnionMemberSchoolsTournamentsAsPartOfUnionReportModalOpen: false,
      isTournamentPlayersReportModalOpen: false,
      isTournamentScoreCardsReportModalOpen: false,
      isTournamentResultsIndividualReportModalOpen: false,
      isTournamentSchoolsReportModalOpen: false,
      isHighLevelTournamentSchoolAdminsReportModalOpen: false,
      currentTabIndex: 0,
      eligibleSchools: [],
      confirmationRequests: [],
      tournamentEvents: [],
      admins: [],
      enrollmentLogs: [],
      championships: [],
      teams: [],
      participants: [],
      eventsForGroupFormSubmitData: undefined,
      modalTitle: '',
      errorMessage: '',
      isErrorModalOpen: false,
      isTournamentTagModalOpen: false,
      isTournamentTagDeleteModalOpen: false,
      modalText: '',
      selectedTournamentTagId: ''
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true
      });
      this.setItems();
    }
  }

  setAdditionalItems() {
    const { user } = this.props;

    return Promise.all([getAllChampionships(user), getTournamentTags(user)]).then(([championships, tournamentTags]) => {
      this.setState({
        championships: championships,
        tournamentTags: tournamentTags
      });

      return true;
    });
  }

  setItems() {
    const { history, user, location } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;
    const lastSelectedFilters = propz.get(history, ['location', 'state', 'filters']);
    const isExistSelectedFilters = typeof lastSelectedFilters !== 'undefined';
    let sortDirection: TABLE_SORT_DIRECTION = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = isExistSelectedFilters ? lastSelectedFilters : getFilters2(COLUMNS, search);
    const isShowFilter = isFilterExist2(filters);
    const isDataFiltered = isFilterExist2(filters);

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter2(page, where, order);

    const getItemsPromise = getPastTournaments(user, serverQueryFilter);
    const getItemsCountPromise = getPastTournamentsCount(user, where);
    const getAllItemsCountPromise = getPastTournamentsCount(user);

    return BPromise.all([getItemsCountPromise, getAllItemsCountPromise, getItemsPromise]).then(
      ([countObj, allCountObj, items]) => {
        const tournamentId = propz.get(location, ['state', 'tournamentId']);
        if (typeof tournamentId !== 'undefined') {
          const tournament = items.find(item => item.id === tournamentId);
          this.setState({
            currentPage: page,
            sortDirection: sortDirection,
            sortColumnsName: sortColumnsName,
            isShowFilter: isShowFilter,
            isDataFiltered: isDataFiltered,
            filters: filters,
            items: items,
            total: countObj.count,
            totalAll: allCountObj.count,
            basePath: history.location.pathname,
            isLoading: false,
            selectedItems: typeof tournament !== 'undefined' ? [tournament] : []
          });

          //clear history
          const state = { ...(history.location.state as any) };
          delete state.tournamentId;
          history.replace({ ...history.location, state });
        } else {
          this.setState({
            currentPage: page,
            sortDirection: sortDirection,
            sortColumnsName: sortColumnsName,
            isShowFilter: isShowFilter,
            isDataFiltered: isDataFiltered,
            filters: filters,
            items: items,
            total: countObj.count,
            totalAll: allCountObj.count,
            basePath: history.location.pathname,
            isLoading: false
          });
        }

        return true;
      }
    );
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const { history } = this.props;
    const { basePath } = this.state;

    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
      currentTabIndex: 0
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: nextSelectedItems,
      isSelectAllChecked: true
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters, { isCorrectTime: true });

    getAllPastTournaments(user, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: nextSelectedItems,
        isSelectAllChecked: true,
        isLoading: false
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const { history } = this.props;
    const { basePath } = this.state;

    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      filters: nextFilters
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist2(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const { history } = this.props;
    const { basePath } = this.state;

    history.push({
      pathname: basePath,
      search: search.join('&')
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false
    });

    const { history } = this.props;
    const { basePath } = this.state;

    history.push({
      pathname: basePath,
      search: ''
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter
    });
  };

  isOneItemSelected(): boolean {
    const selectedItems = this.state.selectedItems;
    return selectedItems.length === 1;
  }

  onDeleteTournamentClick = () => {
    const { selectedItems } = this.state;
    const user = this.props.user;
    const itemIds = selectedItems.map(item => item.id);

    this.closeDeleteTournamentConfirmationModal();

    if (selectedItems[0].schoolIds.length === 0) {
      this.setState({
        isLoading: true
      });

      BPromise.all(itemIds.map(itemId => deleteTournament(user, itemId))).then(res => {
        this.setState({
          selectedItems: [],
          isSelectAllChecked: false
        });
        this.setItems();
      });
    } else {
      this.setState({
        modalText: 'The tournament cannot be removed as schools/teams have already been added.',
        modalTitle: 'Delete tournament',
        isInformationModalShow: true
      });
    }
  };

  renderDeleteTournamentConfirmationModal(): React.ReactNode {
    const { isDeleteTournamentConfirmationModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteTournamentConfirmationModalOpen}
        title={'Confirm the action'}
        body={'The selected tournament(s) will be deleted. Are you sure?'}
        buttonCancelText="Cancel"
        buttonText="Proceed"
        onButtonClick={this.onDeleteTournamentClick}
        onCloseClick={this.closeDeleteTournamentConfirmationModal}
      />
    );
  }

  openDeleteTournamentConfirmationModal = () => {
    this.setState({
      isDeleteTournamentConfirmationModalOpen: true
    });
  };

  closeDeleteTournamentConfirmationModal = () => {
    this.setState({
      isDeleteTournamentConfirmationModalOpen: false
    });
  };

  onAddTournamentToChampionshipClick = () => {
    this.setState({
      isAddTournamentToChampionshipModalOpen: true
    });
  };

  onRemoveTournamentToChampionshipClick = () => {
    this.setState({
      isRemoveTournamentToChampionshipModalOpen: true
    });
  };

  onClickDownloadCSV = () => {
    const { user } = this.props;
    const tournamentId = this.state.selectedItems[0].id;

    getCsvListTournamentStudents(user, tournamentId)
      .then(csv => {
        this.downloadCSV(csv);
      })
      .catch(error => {
        console.error(error.response.data.details.text);
      });
  };

  downloadCSV = (csv): void => {
    try {
      // copy-paste from old frontend
      /*global Blob:true*/
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (window.navigator.msSaveBlob) {
        // IE 10+
        window.navigator.msSaveBlob(blob, 'file.csv');
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          /*global URL:true*/
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', 'file.csv');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  getSchoolsWaitingListCount = eligibleSchools => {
    const schoolsWaitingList = eligibleSchools.filter(school => {
      const isNoParticipant = !school.isParticipant;
      const inviteStatusAccepted =
        propz.get(school, ['invite', 'status'], INVITE_STATUS.NOT_READY) === INVITE_STATUS.ACCEPTED;
      return isNoParticipant && inviteStatusAccepted;
    });
    return schoolsWaitingList.length;
  };

  onTournamentTagAssignClick = (): void => {
    this.setState({
      isTournamentTagModalOpen: true
    });
  };

  onTournamentTagDeleteClick = (): void => {
    this.setState({
      isTournamentTagDeleteModalOpen: true
    });
  };

  onTournamentTagChange = (event): void => {
    const value = event.target.value;

    this.setState({
      selectedTournamentTagId: value
    });
  };

  onTournamentTagAssignModalCloseClick = (): void => {
    this.setState({
      isTournamentTagModalOpen: false
    });
  };

  onTournamentTagSaveClick = (): void => {
    const tournamentTagId = this.state.selectedTournamentTagId;
    const user = this.props.user;

    const data = {
      tournamentTagId: tournamentTagId
    };

    this.setState({
      isLoading: true
    });

    if (!tournamentTagId) {
      this.setState({
        isTournamentTagModalOpen: false,
        selectedTournamentTagId: ''
      });
      console.error('Can not assign tournament tag id');
    } else {
      const selectedItems = this.state.selectedItems;
      BPromise.all(
        selectedItems.map(selectedItem => {
          const tournamentId = selectedItem.id;
          return assignTournamentTag(user, data, tournamentId);
        })
      ).then(items => {
        this.setState({
          isTournamentTagModalOpen: false,
          selectedTournamentTagId: '',
          selectedItems: []
        });
        this.setItems();
      });
    }
  };

  renderTournamentTagAssignModal(): React.ReactNode {
    const { tournamentTags, selectedTournamentTagId, isTournamentTagModalOpen } = this.state;

    return (
      <TournamentTagAssignModal
        modalTitle={'Assign Tournament Tag'}
        tournamentTags={tournamentTags}
        selectedTournamentTagId={selectedTournamentTagId}
        onTournamentTagChange={this.onTournamentTagChange}
        onCloseClick={this.onTournamentTagAssignModalCloseClick}
        isOpen={isTournamentTagModalOpen}
        onSaveClick={this.onTournamentTagSaveClick}
      />
    );
  }

  getTournamentTags(): TournamentTag[] {
    const { selectedItems, items } = this.state;

    const tournamentTags = Lazy(selectedItems)
      .map(selectedItem => {
        const selectedTournamentId = selectedItem.id;
        const tournament = items.find(tournamentState => tournamentState.id === selectedTournamentId);

        if (tournament && tournament.tournamentTags) {
          return tournament.tournamentTags;
        } else {
          return [];
        }
      })
      .filter(tournamentTagList => tournamentTagList.length > 0)
      .flatten()
      .uniq('id')
      .toArray();

    return tournamentTags;
  }

  onTournamentTagDeleteModalCloseClick = (): void => {
    this.setState({
      isTournamentTagDeleteModalOpen: false
    });
  };

  onTournamentTagDelete = (): void => {
    const { selectedTournamentTagId, selectedItems } = this.state;
    const { user } = this.props;

    if (!selectedTournamentTagId) {
      console.error('Cannot delete tournament tag because no tag ID is selected.');
      this.setState({
        isTournamentTagDeleteModalOpen: false,
        selectedTournamentTagId: ''
      });
      return;
    }

    this.setState({ isLoading: true });

    const deletePromises = selectedItems.map(tournament => {
      return deleteTournamentTagByTournament(user, tournament.id, selectedTournamentTagId);
    });

    BPromise.all(deletePromises)
      .then(() => {
        const updatedTournaments = selectedItems.map(tournament => {
          const tournamentTags = tournament.tournamentTags.filter(
            tournamentTag => tournamentTag.id !== selectedTournamentTagId
          );
          return { ...tournament, tournamentTags };
        });

        this.setState({
          selectedItems: updatedTournaments,
          isTournamentTagDeleteModalOpen: false,
          selectedTournamentTagId: '',
          isSelectAllChecked: false,
          isLoading: false
        });

        this.setItems();
      })
      .catch(error => {
        console.error('Failed to delete tournament tag:', error);
        this.setState({
          isTournamentTagDeleteModalOpen: false,
          isLoading: false
        });
      });
  };

  renderTournamentTagDeleteModal(): React.ReactNode {
    const { selectedTournamentTagId, isTournamentTagDeleteModalOpen } = this.state;

    return (
      <TournamentTagDeleteModal
        modalTitle={'Delete Tournament Tag'}
        tournamentTags={this.getTournamentTags()}
        selectedTournamentTagId={selectedTournamentTagId}
        onTournamentTagChange={this.onTournamentTagChange}
        onCloseClick={this.onTournamentTagDeleteModalCloseClick}
        isOpen={isTournamentTagDeleteModalOpen}
        onDeleteClick={this.onTournamentTagDelete}
      />
    );
  }

  renderGrid() {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      championships,
      tournamentTags
    } = this.state;

    const isDownloadStudentsActive = this.isOneItemSelected();
    const isOnlySameSport = selectedItems.every((selectedItem, i, list) => selectedItem.sportId === list[0].sportId);

    const tournaments = selectedItems;
    const onlyParticipatesChampionships = championships.filter(championship => {
      const championshipTournamentsCount = championship.tournamentIds.length;
      const selectedTournamentsCount = tournaments.length;
      let tournamentId;

      for (let i = 0; i < championshipTournamentsCount; ++i) {
        tournamentId = championship.tournamentIds[i];
        for (let j = 0; j < selectedTournamentsCount; ++j) {
          if (tournamentId === tournaments[j].id) {
            return true;
          }
        }
      }
    });
    const isAlreadyParticipatesChampionship = onlyParticipatesChampionships.length > 0;

    const tournamentIds = tournaments.map(tournament => tournament.id);
    const championshipsFiltered = championships.filter(championship => {
      return tournamentIds.every(tournamentId =>
        championship.tournamentIds.some(championshipTournamentId => tournamentId === championshipTournamentId)
      );
    });
    const isAlreadyParticipatesInOneChampionship = championshipsFiltered.length > 0;

    const isAllYesPromoteTournamentSelectedItems = selectedItems.every(item => item.isPromoteTournament);
    const isAllNoPromoteTournamentSelectedItems = selectedItems.every(item => !item.isPromoteTournament);
    const isAllYesPublishOnTheWebsiteSelectedItems = selectedItems.every(item => item.isPublishOnTheWebsite);
    const isAllNoPublishOnTheWebsiteSelectedItems = selectedItems.every(item => !item.isPublishOnTheWebsite);

    const isIndividualTournamentSelected = selectedItems.some(item => isIndividualTournament(item));
    const isTeamTournamentSelected = selectedItems.some(item => isTeamTournament(item));

    const actionItems = [
      selectedItems.length <= 1 && {
        itemText: selectedItems.length === 0 ? 'Create tournament' : 'Edit tournament',
        onItemClick: selectedItems.length === 0 ? this.onCreateTournamentClick : this.onEditTournamentClick,
        isActive: true
      },
      selectedItems.length > 0 && {
        itemText: selectedItems.length === 1 ? 'Delete tournament' : 'Delete tournaments',
        onItemClick: this.openDeleteTournamentConfirmationModal,
        isActive: true
      },
      selectedItems.length === 1 && {
        itemText: 'Create tournament like this',
        onItemClick: this.onCopyTournamentClick,
        isActive: true
      },
      {
        itemText: isAlreadyParticipatesChampionship ? 'Remove from championship' : 'Add to championship',
        onItemClick: isAlreadyParticipatesInOneChampionship
          ? this.onRemoveTournamentToChampionshipClick
          : this.onAddTournamentToChampionshipClick,
        isActive: isAlreadyParticipatesChampionship
          ? selectedItems.length > 0 && isAlreadyParticipatesInOneChampionship
          : isOnlySameSport && selectedItems.length > 0 && !isAlreadyParticipatesInOneChampionship
      },
      /*      {
        itemText: 'Download list of students',
        onItemClick: this.onClickDownloadCSV,
        isActive: isDownloadStudentsActive
      },
*/
      selectedItems.length >= 1 && {
        itemText: 'Manage appearance',
        isActive: true,
        subItems: [
          {
            itemText:
              isAllYesPublishOnTheWebsiteSelectedItems && !isAllNoPublishOnTheWebsiteSelectedItems
                ? 'Unpublish from union website'
                : 'Publish on union website',
            onItemClick: () =>
              this.onEditTournament(
                !isAllYesPublishOnTheWebsiteSelectedItems && isAllNoPublishOnTheWebsiteSelectedItems,
                'isPublishOnTheWebsite'
              ),
            isActive:
              selectedItems.length > 0 &&
              (isAllYesPublishOnTheWebsiteSelectedItems || isAllNoPublishOnTheWebsiteSelectedItems)
          },
          {
            itemText:
              isAllYesPromoteTournamentSelectedItems && !isAllNoPromoteTournamentSelectedItems
                ? 'Unhighlight'
                : 'Highlight',
            onItemClick: () =>
              this.onEditTournament(
                !isAllYesPromoteTournamentSelectedItems && isAllNoPromoteTournamentSelectedItems,
                'isPromoteTournament'
              ),
            isActive:
              selectedItems.length > 0 &&
              (isAllYesPromoteTournamentSelectedItems || isAllNoPromoteTournamentSelectedItems)
          },
          {
            itemText: 'Assign tag',
            onItemClick: this.onTournamentTagAssignClick,
            isActive: selectedItems.length > 0
          },
          {
            itemText: 'Remove tag',
            onItemClick: this.onTournamentTagDeleteClick,
            isActive: selectedItems.length > 0
          }
        ]
      },
      (selectedItems.length === 1 || selectedItems.length === 0) && {
        itemText: 'Reports',
        isActive: true,
        subItems: [
          selectedItems.length === 0 && {
            itemText: 'Extended tournament schedule',
            onItemClick: this.openExtendedTournamentScheduleReportForm,
            isActive: true
          },
          selectedItems.length === 1 && {
            itemText: 'Tournament schools',
            onItemClick: this.openTournamentSchoolsReportForm,
            isActive: true
          },
          selectedItems.length === 1 && {
            itemText: 'Tournament team members',
            onItemClick: this.openTournamentTeamMembersReportForm,
            isActive: true
          },
          selectedItems.length === 1 && {
            itemText: 'Tournament participation confirmations',
            onItemClick: this.openTournamentParticipationConfirmationsReportForm,
            isActive: true
          },
          isIndividualTournamentSelected &&
            selectedItems.length === 1 && {
              itemText: 'Tournament start list',
              onItemClick: this.openTournamentStartListReportForm,
              isActive: true
            },
          selectedItems.length === 0 && {
            itemText: 'Member schools statistics',
            onItemClick: this.openUnionMemberSchoolsStatisticsReportForm,
            isActive: true
          },
          selectedItems.length === 0 && {
            itemText: 'Member schools tournament participation',
            onItemClick: this.openUnionMemberSchoolsTournamentsReportForm,
            isActive: true
          },
          selectedItems.length === 0 && {
            itemText: 'Member schools tournament participation (as part of area team)',
            onItemClick: this.openUnionMemberSchoolsTournamentsAsPartOfUnionReportForm,
            isActive: true
          },
          selectedItems.length === 1 && {
            itemText: 'Tournament players',
            onItemClick: this.openTournamentPlayersReportForm,
            isActive: true
          },
          selectedItems.length === 1 && {
            itemText: 'Tournament schools contact details',
            onItemClick: this.openHighLevelTournamentSchoolAdminsReportForm,
            isActive: true
          },
          isTeamTournamentSelected &&
            selectedItems.length === 1 && {
              itemText: 'Tournament score cards',
              onItemClick: this.openTournamentScoreCardsReportForm,
              isActive: true
            },
          isIndividualTournamentSelected &&
            selectedItems.length === 1 && {
              itemText: 'Tournament results',
              onItemClick: this.openTournamentResultsIndividualReportForm,
              isActive: true
            }
        ].filter(Boolean)
      }
    ].filter(Boolean);

    const filterOptions = {
      season: getSelectOptionForArchiveTournamentsSeason(),
      isPublishOnTheWebsite: getSelectOptionForBooleanQuestion(),
      isPromoteTournament: getSelectOptionForBooleanQuestion(),
      tournamentTag: getSelectOptionForTournamentTag(tournamentTags)
    };

    const todayMidnight = new Date(new Date().setHours(0, 0, -1));

    return (
      <Grid2
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        filterMaxDateInterval={todayMidnight}
      />
    );
  }

  getSummaryTabs(): React.ReactNode {
    const {
      currentTabIndex,
      selectedItems,
      eligibleSchools,
      confirmationRequests,
      tournamentEvents,
      admins,
      enrollmentLogs,
      championships,
      teams
    } = this.state;

    const { user } = this.props;

    const tournament = selectedItems[0];

    const tabs =
      isTeamTournament(tournament) || isMiniTeamTournament(tournament) ? TEAM_TOURNAMENT_TABS : TOURNAMENT_TABS;
    const currentTab = tabs[currentTabIndex];
    const schoolsWaitingListCount = this.getSchoolsWaitingListCount(eligibleSchools);

    switch (currentTab) {
      case TABS.SUMMARY:
        return <TournamentSummary tournament={tournament} championships={championships} />;
      case TABS.PARTICIPANTS:
        return <TournamentParticipantsSummary tournament={tournament} />;
      case TABS.ELIGIBLE_SCHOOLS:
        return <TournamentEligibleSchoolsSummary tournament={tournament} eligibleSchools={eligibleSchools} />;
      case TABS.CONFIRMATION_REQUESTS:
        return <TournamentConfirmationRequestsSummary messages={confirmationRequests} />;
      case TABS.TOURNAMENT_EVENTS:
        return <TournamentEventsSummary tournament={tournament} events={tournamentEvents} user={user} />;
      case TABS.AUTO_ENROLLMENT:
        return (
          <TournamentAutoEnrollmentSummary tournament={tournament} schoolsWaitingListCount={schoolsWaitingListCount} />
        );
      case TABS.PUBLIC_SITE_SETTINGS:
        return <TournamentPublicSiteSettingsSummary tournament={tournament} user={user} />;
      case TABS.TOURNAMENT_TV:
        return <TournamentTVSummary tournament={tournament} />;
      case TABS.ENROLLMENT_LOG:
        return <TournamentEnrollmentLogsSummary enrollmentLogs={enrollmentLogs} tournament={tournament} />;
      case TABS.ADMINS:
        return <TournamentAdminsSummary admins={admins} />;
      case TABS.PITCHES:
        return <TournamentPitchesSummary tournament={tournament} />;
      case TABS.GROUPS:
        return <TournamentCustomGroupsSummary tournament={tournament} />;
      case TABS.TEAMS:
        return <TournamentTeamsSummary teams={teams} tournament={tournament} user={user} />;
      default:
        return <EmptyComponent />;
    }
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();
    const { user } = this.props;
    const { selectedItems } = this.state;
    const tournament = selectedItems[0];

    const tabs =
      isTeamTournament(tournament) || isMiniTeamTournament(tournament) ? TEAM_TOURNAMENT_TABS : TOURNAMENT_TABS;
    const currentTab = tabs[index];
    const tournamentId = selectedItems[0].id;

    switch (currentTab) {
      case TABS.TEAMS:
        this.setState({
          isLoading: true
        });
        getAllSchoolUnionTournamentTeams(user, tournamentId).then(teams => {
          this.setState({
            currentTabIndex: index,
            teams: teams,
            isLoading: false
          });
        });
        break;
      case TABS.ELIGIBLE_SCHOOLS:
      case TABS.AUTO_ENROLLMENT:
        this.setState({
          isLoading: true
        });
        getAllTournamentEligibleSchools(user, tournamentId).then(schools => {
          this.setState({
            currentTabIndex: index,
            eligibleSchools: schools,
            isLoading: false
          });
        });
        break;
      case TABS.CONFIRMATION_REQUESTS:
        this.setState({
          isLoading: true
        });
        getAllTournamentConfirmationRequests(user, tournamentId).then(messages => {
          this.setState({
            currentTabIndex: index,
            confirmationRequests: messages,
            isLoading: false
          });
        });
        break;
      case TABS.TOURNAMENT_EVENTS:
        this.setState({
          isLoading: true
        });
        getAllTournamentEvents(user, tournamentId).then(events => {
          this.setState({
            currentTabIndex: index,
            tournamentEvents: events,
            isLoading: false
          });
        });
        break;
      case TABS.ENROLLMENT_LOG:
        this.setState({
          isLoading: true
        });
        const filter = {
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP,
          order: 'createdAt DESC'
        };

        getTournamentEnrollmentLogs(user, tournamentId, filter).then(logs => {
          this.setState({
            currentTabIndex: index,
            enrollmentLogs: logs,
            isLoading: false
          });
        });
        break;
      case TABS.ADMINS:
        this.setState({
          isLoading: true
        });
        getAllTournamentAdmins(user, tournamentId).then(admins => {
          this.setState({
            currentTabIndex: index,
            admins: admins,
            isLoading: false
          });
        });
        break;
      default:
        this.setState({
          currentTabIndex: index
        });
    }
  };

  onShowNotificationsButtonClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/notifications`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onEditParticipantsButtonClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/participants`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onEditEligibleSchoolsButtonClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/eligibleSchools`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onShowConfirmationRequestsButtonClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/confirmationRequests`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onEditAutoEnrollmentButtonClick = (): void => {
    this.setState({
      isEditableAutoEnrollment: true
    });
  };

  onCancelAutoEnrollmentButtonClick = (): void => {
    this.setState({
      isEditableAutoEnrollment: false
    });
  };

  onEditPublicSiteSettingsButtonClick = (): void => {
    this.setState({
      isEditablePublicSiteSettings: true
    });
  };

  onEditTournamentTVButtonClick = (): void => {
    this.setState({
      isEditableTournamentTVSettings: true
    });
  };

  onCancelTournamentTVButtonClick = (): void => {
    this.setState({
      isEditableTournamentTVSettings: false
    });
  };

  onCancelPublicSiteSettingsButtonClick = (): void => {
    this.setState({
      isEditablePublicSiteSettings: false
    });
  };

  onSubmitAutoEnrollmentForm = (data): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const [tournament] = selectedItems;
    const tournamentId = tournament.id;

    const {
      autoEnrollmentSettings,
      maximumNumberTeamsOfSchool,
      isReadOnly,
      isAutoEnrollmentTeams,
      isEnableWaitingListWhenFull,
      isAutoEnrollmentToWaitingList,
      autoEnrollmentTeamsSettings: autoEnrollmentTeamsSettingsFromForm
    } = data;

    let dataToTransfer;

    if (isAutoEnrollmentTeams) {
      if (isIndividualTournament(tournament)) {
        let autoEnrollmentTeamsSettings = [];

        const gender = propz.get(tournament, ['gender'], GENDER.MIXED);
        const isGenderMixed = gender === GENDER.MIXED;
        const isGenderMale = gender === GENDER.MALE_ONLY;
        const isGenderFemale = gender === GENDER.FEMALE_ONLY;
        const isMixedTeamsAvailable = propz.get(tournament, ['isMixedTeamsAvailable']);

        const tournamentAges = propz.get(tournament, ['ages'], []);

        switch (true) {
          case isGenderMixed && !isMixedTeamsAvailable:
            tournamentAges.forEach(age => {
              autoEnrollmentTeamsSettings.push(
                {
                  age: Number(age),
                  gender: GENDER.MALE_ONLY,
                  maximumNumberTeams: Number(autoEnrollmentSettings)
                },
                {
                  age: Number(age),
                  gender: GENDER.FEMALE_ONLY,
                  maximumNumberTeams: Number(autoEnrollmentSettings)
                }
              );
            });
            break;
          case isGenderMixed && isMixedTeamsAvailable:
            tournamentAges.forEach(age => {
              autoEnrollmentTeamsSettings.push({
                age: Number(age),
                gender: GENDER.MIXED,
                maximumNumberTeams: Number(autoEnrollmentSettings)
              });
            });
            break;
          case isGenderMale:
            tournamentAges.forEach(age => {
              autoEnrollmentTeamsSettings.push({
                age: Number(age),
                gender: GENDER.MALE_ONLY,
                maximumNumberTeams: Number(autoEnrollmentSettings)
              });
            });
            break;
          case isGenderFemale:
            tournamentAges.forEach(age => {
              autoEnrollmentTeamsSettings.push({
                age: Number(age),
                gender: GENDER.FEMALE_ONLY,
                maximumNumberTeams: Number(autoEnrollmentSettings)
              });
            });
            break;
        }

        dataToTransfer = {
          autoEnrollmentTeamsSettings,
          maximumNumberTeamsOfSchool,
          isReadOnly,
          isAutoEnrollmentTeams,
          isEnableWaitingListWhenFull,
          isAutoEnrollmentToWaitingList
        };
      } else {
        dataToTransfer = {
          autoEnrollmentTeamsSettings: autoEnrollmentTeamsSettingsFromForm,
          maximumNumberTeamsOfSchool,
          isReadOnly,
          isAutoEnrollmentTeams,
          isEnableWaitingListWhenFull,
          isAutoEnrollmentToWaitingList
        };
      }
    } else {
      dataToTransfer = {
        autoEnrollmentTeamsSettings: [],
        maximumNumberTeamsOfSchool,
        isReadOnly,
        isAutoEnrollmentTeams,
        isEnableWaitingListWhenFull,
        isAutoEnrollmentToWaitingList
      };
    }

    this.setState({
      isLoading: true
    });

    updateTournamentAutoEnrollment(user, dataToTransfer, tournamentId).then(
      ({
        isAutoEnrollmentTeams,
        isEnableWaitingListWhenFull,
        isAutoEnrollmentToWaitingList,
        autoEnrollmentTeamsSettings,
        maximumNumberTeamsOfSchool,
        isReadOnly
      }) => {
        const tournamentNext = {
          ...tournament,
          isAutoEnrollmentTeams,
          isEnableWaitingListWhenFull,
          isAutoEnrollmentToWaitingList,
          autoEnrollmentTeamsSettings,
          maximumNumberTeamsOfSchool,
          isReadOnly
        };

        this.setState({
          selectedItems: [tournamentNext],
          isEditableAutoEnrollment: false
        });
        this.setItems();
      }
    );
  };

  onSubmitTournamentSiteSettingsForm = (data): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;
    const tournamentId = selectedItems[0].id;

    this.setState({
      isLoading: true
    });

    updateTournament(user, data, tournamentId).then(tournament => {
      this.setState({
        selectedItems: [tournament],
        isEditablePublicSiteSettings: false,
        isEditableTournamentTVSettings: false
      });
      this.setItems();
    });
  };

  informationModalClose = () => {
    this.setState({
      isInformationModalShow: false
    });
  };

  deleteGroupEvents = () => {
    const { user } = this.props;
    const { eventsForGroupFormSubmitData, tournamentEvents } = this.state;
    const events = tournamentEvents || [];

    this.setState({
      isLoading: true
    });
    const eventIds = events
      .filter(
        event =>
          event.tournamentGroupId === eventsForGroupFormSubmitData.tournamentGroupId &&
          event.ages.some(eventAge => eventAge === eventsForGroupFormSubmitData.age)
      )
      .map(event => event.id);
    BPromise.all(eventIds.map(itemId => deleteEvent(user, itemId))).then(res => {
      this.setState({
        isRemoveExistingEventsModalShow: false
      });
      this.createGroupEvents();
    });
  };

  removeExistingEventsModalClose = () => {
    this.setState({
      isRemoveExistingEventsModalShow: false
    });
  };

  renderInformationModal(): React.ReactNode {
    const { isInformationModalShow, modalText, modalTitle } = this.state;

    return (
      <SimpleModal
        isOpen={isInformationModalShow}
        title={modalTitle}
        body={modalText}
        buttonText={'Ok'}
        onButtonClick={this.informationModalClose}
      />
    );
  }

  renderRemoveExistingEventsModalShowModal(): React.ReactNode {
    const { isRemoveExistingEventsModalShow, modalText, modalTitle } = this.state;

    return (
      <SimpleModal
        isOpen={isRemoveExistingEventsModalShow}
        title={modalTitle}
        body={modalText}
        buttonText={`Remove`}
        onButtonClick={this.deleteGroupEvents}
        onCloseClick={this.removeExistingEventsModalClose}
      />
    );
  }

  renderAutoEnrollmentModal() {
    const { isEditableAutoEnrollment, selectedItems } = this.state;
    const { user } = this.props;

    const [tournament] = selectedItems;

    return (
      <SimpleModal isOpen={isEditableAutoEnrollment} title={'Edit autoenrollment'}>
        <TournamentAutoEnrollmentForm
          user={user}
          tournament={tournament}
          onCancel={this.onCancelAutoEnrollmentButtonClick}
          onSubmit={this.onSubmitAutoEnrollmentForm}
        />
      </SimpleModal>
    );
  }

  renderPublicSiteSettingsModal() {
    const { isEditablePublicSiteSettings, selectedItems } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isEditablePublicSiteSettings} title={'Edit tournament website settings'}>
        <TournamentPublicSiteSettingsForm
          user={user}
          tournament={selectedItems[0]}
          onCancel={this.onCancelPublicSiteSettingsButtonClick}
          onSubmit={this.onSubmitTournamentSiteSettingsForm}
        />
      </SimpleModal>
    );
  }

  renderTournamentTVModal() {
    const { isEditableTournamentTVSettings, selectedItems } = this.state;
    const tournamentName = propz.get(selectedItems, ['0', 'name'], '');
    return (
      <SimpleModal isOpen={isEditableTournamentTVSettings} title={`Manage Tournament TV ${tournamentName}`}>
        <TournamentEditTournamentTVForm
          tournament={selectedItems[0]}
          onCancel={this.onCancelTournamentTVButtonClick}
          onSubmit={this.onSubmitTournamentSiteSettingsForm}
        />
      </SimpleModal>
    );
  }

  onShowPitchesButtonClick = () => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location, user } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/pitches`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onEditCustomGroupsButtonClick = () => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/customGroups`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onGridButtonClick = () => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/grid`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onEditTeamsButtonClick = () => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/teams`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onEditEventsButtonClick = () => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/events`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onAddDefaultGroupsButtonClick = (): void => {
    this.setState({
      isDefaultGroupsModalOpen: true
    });
  };

  onAddEventButtonClick = (): void => {
    const { user } = this.props;
    const { selectedItems } = this.state;

    const tournament = selectedItems[0];
    const { id } = tournament;

    this.setState({
      isLoading: true
    });

    getAllTournamentParticipants(user, id).then(participants => {
      this.setState({
        isAddEventModalOpen: true,
        isLoading: false,
        participants: participants
      });
    });
  };

  onCreateEventsForGroupButtonClick = (): void => {
    this.setState({
      isCreateEventsForGroupModalOpen: true
    });
  };

  onShowAdminsButtonClick = () => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/admins`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onShowEnrollmentLogsButtonClick = () => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/logs`,
      search: `tournament=${tournamentId}`,
      state: { search: search, prevPath: basePath }
    });
  };

  onSubmitChampionshipClick = (data, championshipId): void => {
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    addOrRemoveTournamentsToChampionship(user, data, championshipId).then(() => {
      this.setState({
        isAddTournamentToChampionshipModalOpen: false,
        isRemoveTournamentToChampionshipModalOpen: false,
        isLoading: false
      });

      this.setAdditionalItems();
    });
  };

  onCloseAddTournamentToChampionshipModalClick = (): void => {
    this.setState({
      isAddTournamentToChampionshipModalOpen: false
    });
  };

  onCloseRemoveTournamentToChampionshipModalClick = (): void => {
    this.setState({
      isRemoveTournamentToChampionshipModalOpen: false
    });
  };

  onCreateTournamentClick = (): void => {
    const { totalAll, currentPage, filters, basePath } = this.state;
    const lastPage = getPagesCount(totalAll + 1, LIMIT);

    this.props.history.push({
      pathname: `/tournaments/tournamentView`,
      state: {
        currentTournamentPage: currentPage,
        lastTournamentPage: lastPage,
        filters,
        prevPath: basePath
      }
    });
  };

  onEditTournamentClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;
    const { currentPage, filters, basePath } = this.state;

    this.props.history.push({
      pathname: `/tournaments/tournamentView`,
      search: `tournament=${tournamentId}&mode=${MODE_TYPE.EDIT}`,
      state: {
        currentTournamentPage: currentPage,
        filters,
        prevPath: basePath
      }
    });
  };

  onCopyTournamentClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;
    const { totalAll, currentPage, filters, basePath } = this.state;
    const lastPage = getPagesCount(totalAll + 1, LIMIT);

    this.props.history.push({
      pathname: `/tournaments/tournamentView`,
      state: {
        currentTournamentPage: currentPage,
        lastTournamentPage: lastPage,
        filters,
        prevPath: basePath
      },
      search: `tournament=${tournamentId}&mode=${MODE_TYPE.COPY}`
    });
  };

  onEditTournament = (value, field) => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true
    });

    BPromise.all(
      selectedItems.map(tournament => {
        const dataCopy = { [field]: value };
        return updateTournament(user, dataCopy, tournament.id);
      })
    )
      .then(res => {
        this.setState({
          selectedItems: []
        });
        this.setItems();
      })
      .catch(error => {
        console.error(error.response.data.details.text);
      });
  };

  renderTournamentAddToChampionshipModal(): React.ReactNode {
    const { user } = this.props;
    const { selectedItems, isAddTournamentToChampionshipModalOpen, championships } = this.state;
    const tournaments = selectedItems;
    const isOnlySameSport = selectedItems.every((selectedItem, i, list) => selectedItem.sportId === list[0].sportId);

    const isOpen = isAddTournamentToChampionshipModalOpen && isOnlySameSport;
    const onlyUnusedChampionships = championships.filter(championship => {
      return (
        championship.tournamentIds.every(championshipTournamentId => {
          return tournaments.every(tournament => tournament.id !== championshipTournamentId);
        }) && tournaments.every(tournament => championship.sportId === tournament.sportId)
      );
    });

    return (
      <SimpleModal isOpen={isOpen} title={''}>
        <TournamentAddToChampionshipModal
          user={user}
          onSubmitChampionshipClick={this.onSubmitChampionshipClick}
          onCancelClick={this.onCloseAddTournamentToChampionshipModalClick}
          championships={onlyUnusedChampionships}
          tournaments={tournaments}
          isOpen={true}
        />
      </SimpleModal>
    );
  }

  renderTournamentRemoveToChampionshipModal(): React.ReactNode {
    const { user } = this.props;
    const { selectedItems, isRemoveTournamentToChampionshipModalOpen, championships } = this.state;
    const tournaments = selectedItems;

    return (
      <SimpleModal isOpen={isRemoveTournamentToChampionshipModalOpen} title={''}>
        <TournamentRemoveToChampionshipModal
          user={user}
          onSubmitChampionshipClick={this.onSubmitChampionshipClick}
          onCancelClick={this.onCloseRemoveTournamentToChampionshipModalClick}
          championships={championships}
          tournaments={tournaments}
          isOpen={true}
        />
      </SimpleModal>
    );
  }

  onAddEventFormCancel = (): void => {
    this.setState({
      isAddEventModalOpen: false
    });
  };

  onAddEventFormSubmit = (data: any): void => {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { selectedItems } = this.state;
    const tournament = selectedItems[0];
    const { id } = tournament;

    const { gender, ages, sportId, start, end, groupId, venue, teams, participants } = data;
    const agesNumber = ages.map(age => Number(age));

    //Important! Modify seconds and milliseconds in start time for navigation
    const now = new Date();
    const nowS = now.getSeconds();
    const nowMs = now.getMilliseconds();
    start.setSeconds(nowS, nowMs);

    const body = {
      gender: gender,
      eventType: 'EXTERNAL_SCHOOLS',
      ages: agesNumber,
      sportId: sportId,
      startTime: start.toISOString(),
      endTime: end.toISOString(),
      inviterSchoolId: activeSchoolId,
      tournamentId: id,
      tournamentGroupId: groupId,
      venue: {
        venueType: venue.venueType,
        point: venue.venueType === EVENT_VENUE.TBD ? DEFAULT_POINT : venue.point
      }
    };

    if (isTeamTournament(tournament) || isMiniTeamTournament(tournament)) {
      const teamsUniq = Lazy(teams)
        .map(team => team.schoolId)
        .uniq()
        .toArray();

      propz.set(body, ['invitedSchoolIds'], teamsUniq);
    }

    if (isIndividualTournament(tournament)) {
      const schoolIds = Lazy(participants)
        .map(participant => participant.schoolId)
        .uniq()
        .toArray();

      propz.set(body, ['invitedSchoolIds'], schoolIds);
    }

    const postcodeId = propz.get(data, ['venue', 'postcodeData', 'id']);
    const placeId = propz.get(data, ['venue', 'postcodeData', 'placeId']);

    if (typeof postcodeId !== 'undefined') {
      propz.set(body, ['venue', 'postcodeId'], postcodeId);
      if (typeof placeId !== 'undefined') {
        propz.set(body, ['venue', 'placeId'], placeId);
      }
    }

    this.setState({
      isLoading: true
    });

    createEvent(user, body)
      .then(event => {
        return activateEvent(user, event.id);
      })
      .then(event => {
        if (isIndividualTournament(tournament)) {
          return BPromise.all([]);
        } else {
          return BPromise.all(
            data.teams.map(team => {
              const teamToPost = {
                ...team,
                cloneOf: team.id,
                teamType: TEAM_TYPE.CLONE,
                players: isMiniTeamTournament(tournament) ? [] : team.players
              };
              return addTeam(user, id, event.id, teamToPost);
            })
          );
        }
      })
      .then(teams => {
        return getAllTournamentEvents(user, id);
      })
      .then(events => {
        this.setState({
          isAddEventModalOpen: false,
          isLoading: false,
          tournamentEvents: events
        });
      });
  };

  renderAddEventModal(): React.ReactNode {
    const { isAddEventModalOpen, selectedItems, participants } = this.state;
    const { user } = this.props;
    const tournament = selectedItems[0];

    return (
      <SimpleModal isOpen={isAddEventModalOpen}>
        <TournamentAddEventForm
          tournament={tournament}
          onSubmit={this.onAddEventFormSubmit}
          onCancel={this.onAddEventFormCancel}
          user={user}
          participants={participants}
        />
      </SimpleModal>
    );
  }

  createGroupEvents() {
    const { eventsForGroupFormSubmitData, selectedItems } = this.state;
    const { user } = this.props;
    const tournamentId = selectedItems[0].id;

    this.setState({
      isInformationModalShow: false,
      isLoading: true
    });

    addGroupEvents(user, tournamentId, eventsForGroupFormSubmitData)
      .then(
        () => {
          getAllTournamentEvents(user, tournamentId).then(events => {
            this.setState({
              tournamentEvents: events,
              isLoading: false,
              isInformationModalShow: true,
              modalText: 'Events have been added successfully',
              modalTitle: ''
            });
          });
        },
        err => {
          this.setState({
            isLoading: false,
            isInformationModalShow: true,
            modalText: `Error: ${err.response.data.details.text}`,
            modalTitle: 'Error'
          });
        }
      )
      .catch(err => {
        this.setState({
          isLoading: false,
          isInformationModalShow: true,
          modalText: `Error: ${err.response.data.details.text}`,
          modalTitle: 'Error'
        });
      });
  }

  onDefaultGroupsFormCancel = () => {
    this.setState({
      isDefaultGroupsModalOpen: false
    });
  };

  onCreateEventsForGroupFormCancel = () => {
    this.setState({
      isCreateEventsForGroupModalOpen: false
    });
  };

  onCreateEventsForGroupFormSubmit = data => {
    const { selectedItems, tournamentEvents } = this.state;
    const tournament = selectedItems[0];

    this.setState({
      eventsForGroupFormSubmitData: data,
      isCreateEventsForGroupModalOpen: false
    });

    const events = tournamentEvents || [];

    const eventIds = events
      .filter(
        event =>
          event.tournamentGroupId === data.tournamentGroupId && event.ages.some(eventAge => eventAge === data.age)
      )
      .map(event => event.id);

    this.onCreateEventsForGroupFormCancel();

    if (eventIds.length > 0) {
      const group = tournament.customGroups.find(customGroup => customGroup.id === data.tournamentGroupId);
      const groupName = group.name;
      const bodyText = `There are ${eventIds.length} events in ${
        AGE_GROUPS['U5-U18'][data.age]
      } ${groupName}. Creating new events will remove the existing ones. Are you sure?`;

      this.setState({
        isRemoveExistingEventsModalShow: true,
        modalText: bodyText,
        modalTitle: 'Remove the existing events'
      });
    } else {
      this.createGroupEvents();
    }
  };

  onDefaultGroupsFormSubmit = data => {
    const { selectedItems } = this.state;
    const { user } = this.props;
    const tournament = selectedItems[0];
    const { customGroups: tournamentCustomGroups, tournamentLayout, id } = tournament;

    let groupNames = [];
    let promises = [];

    for (let key in data) {
      const isTrue = data[key] === true;
      const isFalse = data[key] === false;
      const isCustomGroupExist = tournamentCustomGroups.some(
        customGroup => customGroup.name === TOURNAMENT_GROUP_TAG_MAPPING[key]
      );
      if (isTrue && !isCustomGroupExist) {
        groupNames.push({
          name: TOURNAMENT_GROUP_TAG_MAPPING[key]
        });
      }

      if (isFalse && isCustomGroupExist) {
        const customGroup = tournamentCustomGroups.find(
          customGroup => customGroup.name === TOURNAMENT_GROUP_TAG_MAPPING[key]
        );
        const customGroupId = customGroup.id;

        promises.push(deleteTournamentGroup(user, id, customGroupId));
      }
    }

    const groupsWithStage = groupNames.map(groupName => {
      const isGroupStage = TOURNAMENT_GROUP_STAGE.some(group => group === groupName.name);
      const isQualifierStage = TOURNAMENT_QUALIFIER_STAGE.some(group => group === groupName.name);
      const isPlayoffStage = TOURNAMENT_PLAYOFF_STAGE.some(group => group === groupName.name);
      const isFinalStage = TOURNAMENT_FINAL_STAGE.some(group => group === groupName.name);

      switch (true) {
        case isGroupStage:
          return {
            name: groupName.name,
            stage: TOURNAMENT_LAYOUT_TYPE.GROUP
          };
        case isQualifierStage:
          return {
            name: groupName.name,
            stage: TOURNAMENT_LAYOUT_TYPE.QUALIFIER
          };
        case isPlayoffStage:
          return {
            name: groupName.name,
            stage: TOURNAMENT_LAYOUT_TYPE.PLAYOFF
          };
        case isFinalStage:
          return {
            name: groupName.name,
            stage: TOURNAMENT_LAYOUT_TYPE.FINAL
          };
      }
    });

    const stagesNoCreated = groupsWithStage
      .map(groupWithStage => {
        return tournamentLayout.find(stage => stage.type === groupWithStage.stage);
      })
      .filter(stage => typeof stage === 'undefined');

    if (stagesNoCreated.length === 0) {
      const groupsWithStageId = groupsWithStage.map(groupWithStage => {
        const stage = tournamentLayout.find(stage => stage.type === groupWithStage.stage);

        return {
          name: groupWithStage.name,
          stageId: stage.id
        };
      });

      promises = groupsWithStageId.map(groupWithStageId => {
        return createTournamentGroup(user, id, groupWithStageId);
      });

      this.setState({
        isLoading: true
      });

      BPromise.all(promises).then(res => {
        getTournament(user, id).then(nextTournament => {
          this.setState({
            isLoading: false,
            isDefaultGroupsModalOpen: false,
            selectedItems: [nextTournament]
          });
        });
      });
    }
  };

  renderDefaultGroupsModal(): React.ReactNode {
    const { isDefaultGroupsModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];

    return (
      <SimpleModal isOpen={isDefaultGroupsModalOpen} title={'Default groups'}>
        <TournamentDefaultGroupsForm
          tournament={tournament}
          onSubmit={this.onDefaultGroupsFormSubmit}
          onCancel={this.onDefaultGroupsFormCancel}
        />
      </SimpleModal>
    );
  }

  renderCreateEventsForGroupModal(): React.ReactNode {
    const { isCreateEventsForGroupModalOpen, selectedItems } = this.state;
    const { user } = this.props;
    const tournament = selectedItems[0];

    return (
      <SimpleModal isOpen={isCreateEventsForGroupModalOpen} title={'Create events for a group'}>
        <TournamentEventsGroupForm
          user={user}
          tournament={tournament}
          onSubmit={this.onCreateEventsForGroupFormSubmit}
          onCancel={this.onCreateEventsForGroupFormCancel}
        />
      </SimpleModal>
    );
  }

  onConfirmationRequestsButtonClick = (): void => {
    const selectedItems = this.state.selectedItems;
    const tournament = selectedItems[0];
    const tournamentId = tournament.id;

    const { history, location } = this.props;
    const { search } = location;
    const { basePath } = this.state;

    history.push({
      pathname: `/tournaments/confirmationRequests/send`,
      search: `tournament=${tournamentId}`,
      state: {
        search,
        prevPath: basePath,
        inviteKind: INVITE_KIND.TOURNAMENT_SCHOOL_INVITE as INVITE_KIND_TYPE
      }
    });
  };

  renderSummary(): React.ReactNode {
    const { currentTabIndex, selectedItems, tournamentEvents } = this.state;
    const isOneItemSelected = this.isOneItemSelected();

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select a tournament above</div>;
    }

    const tournament = selectedItems[0];
    const customGroups = tournament.customGroups || [];
    const isCustomGroupExist = customGroups.length > 0;
    const summaryTabs = this.getSummaryTabs();
    const tabs =
      isTeamTournament(tournament) || isMiniTeamTournament(tournament) ? TEAM_TOURNAMENT_TABS : TOURNAMENT_TABS;
    const currentTab = tabs[currentTabIndex];

    const isShowNotificationsButtons = currentTab === TABS.SUMMARY;
    const isEditParticipantsButton = currentTab === TABS.PARTICIPANTS;
    const isEditEligibleSchoolsButton = currentTab === TABS.ELIGIBLE_SCHOOLS;
    const isShowConfirmationRequestsButtons = currentTab === TABS.CONFIRMATION_REQUESTS;
    const isShowEditEventsButton = currentTab === TABS.TOURNAMENT_EVENTS && tournamentEvents.length > 0;
    const isShowAddEventButton = currentTab === TABS.TOURNAMENT_EVENTS;
    const isShowCreateEventsGroupButton =
      currentTab === TABS.TOURNAMENT_EVENTS && (isTeamTournament(tournament) || isMiniTeamTournament(tournament));
    const isShowAutoEnrollmentButton = currentTab === TABS.AUTO_ENROLLMENT;
    const isShowPublicSiteSettingsButton = currentTab === TABS.PUBLIC_SITE_SETTINGS;
    const isShowTournamentTVButton =
      currentTab === TABS.TOURNAMENT_TV && (isTeamTournament(tournament) || isMiniTeamTournament(tournament));
    const isShowEnrollmentLogsButton = currentTab === TABS.ENROLLMENT_LOG;
    const isShowAdminsButton = currentTab === TABS.ADMINS;
    const isShowEditPitchesButton = currentTab === TABS.PITCHES;
    const isShowGroupsButtons = currentTab === TABS.GROUPS;
    const isShowEditTeamsButton = currentTab === TABS.TEAMS;

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={tabs} currentTabIndex={currentTabIndex}>
        {isShowNotificationsButtons && (
          <>
            <Button
              onClick={this.onShowNotificationsButtonClick}
              text={'Show notifications'}
              customClass={'mt-3 mr-3 mb-3'}
            />
          </>
        )}
        {isEditParticipantsButton && (
          <Button onClick={this.onEditParticipantsButtonClick} text={'Edit participants'} customClass={'mt-3 mb-3'} />
        )}
        {isEditEligibleSchoolsButton && (
          <Button
            onClick={this.onEditEligibleSchoolsButtonClick}
            text={'Edit eligible schools'}
            customClass={'mt-3 mb-3'}
          />
        )}
        {isShowConfirmationRequestsButtons && (
          <>
            <Button
              onClick={this.onConfirmationRequestsButtonClick}
              text={'Send confirmation requests'}
              customClass={'mt-3 mr-3 mb-3'}
            />
            <Button
              onClick={this.onShowConfirmationRequestsButtonClick}
              text={'Show confirmation requests'}
              customClass={'mt-3 mr-3 mb-3'}
            />
          </>
        )}
        {isShowAutoEnrollmentButton && (
          <Button
            onClick={this.onEditAutoEnrollmentButtonClick}
            text={'Autoenrollment settings'}
            customClass={'mt-3 mb-3'}
          />
        )}
        {isShowPublicSiteSettingsButton && (
          <Button
            onClick={this.onEditPublicSiteSettingsButtonClick}
            text={'Edit tournament website settings'}
            customClass={'mt-3 mb-3'}
          />
        )}
        {isShowTournamentTVButton && (
          <Button
            onClick={this.onEditTournamentTVButtonClick}
            text={'Manage Tournament TV'}
            customClass={'mt-3 mb-3'}
          />
        )}
        {isShowAddEventButton && (
          <Button onClick={this.onAddEventButtonClick} text={'Add events'} customClass={'mt-3 mb-3 mr-3'} />
        )}
        {isShowEditEventsButton && (
          <Button onClick={this.onEditEventsButtonClick} text={'Edit events'} customClass={'mt-3 mb-3 mr-3'} />
        )}
        {isShowCreateEventsGroupButton && (
          <Button
            onClick={this.onCreateEventsForGroupButtonClick}
            text={'Create events for a group'}
            customClass={'mt-3 mb-3'}
          />
        )}
        {isShowEnrollmentLogsButton && (
          <Button onClick={this.onShowEnrollmentLogsButtonClick} text={'View log'} customClass={'mt-3 mb-3'} />
        )}
        {isShowAdminsButton && (
          <Button onClick={this.onShowAdminsButtonClick} text={'Show admins'} customClass={'mt-3 mb-3'} />
        )}
        {isShowEditPitchesButton && (
          <Button onClick={this.onShowPitchesButtonClick} text={'Edit pitches'} customClass={'mt-3 mb-3'} />
        )}
        {isShowGroupsButtons && (
          <>
            <Button
              onClick={this.onAddDefaultGroupsButtonClick}
              text={'Add default groups'}
              customClass={'mt-3 mb-3 mr-3'}
            />
            <Button
              onClick={this.onEditCustomGroupsButtonClick}
              text={'Edit custom groups'}
              customClass={'mt-3 mb-3 mr-3'}
            />
            <Button
              onClick={this.onGridButtonClick}
              text={'Grid'}
              customClass={'mt-3 mb-3'}
              disabled={!isCustomGroupExist}
            />
          </>
        )}
        {isShowEditTeamsButton && (
          <Button onClick={this.onEditTeamsButtonClick} text={'Edit teams'} customClass={'mt-3 mb-3'} />
        )}
        {summaryTabs}
      </GenericSummary>
    );
  }

  onSubmitReportForm = (data, reportType) => {
    const { user } = this.props;
    const { activeSchoolId, activeSchool } = user;
    const { selectedItems } = this.state;
    const tournament = selectedItems[0];
    const tournamentId = propz.get(tournament, ['id'], '');

    const {
      dateTo,
      dateFrom,
      isShowAccepted,
      isShowRejected,
      isShowUnresponded,
      isShowNotSent,
      isShowZero,
      isShowNonZero,
      isShowBlank,
      isHighlightNoAge,
      isShowSchedule,
      isPrintFullNames,
      isShowAllAdmins,
      isPrintScoreCards
    } = data;

    let modData;
    let formsUniq;

    switch (reportType) {
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EXTENDED_TOURNAMENT_SCHEDULE:
        this.setState({
          isExtendedTournamentScheduleReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            unionId: activeSchoolId
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_SCHOOLS:
        this.setState({
          isTournamentSchoolsReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            tournamentId,
            showAllAdmins: isShowAllAdmins
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_TEAM_MEMBERS:
        this.setState({
          isTournamentTeamMembersReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            tournamentId,
            showBlank: isShowBlank,
            highlightNoAge: isHighlightNoAge
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_PARTICIPATION_CONFIRMATIONS:
        this.setState({
          isTournamentParticipationConfirmationsReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            tournamentId,
            showAccepted: isShowAccepted,
            showRejected: isShowRejected,
            showUnresponded: isShowUnresponded,
            showNotSent: isShowNotSent
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_START_LIST:
        this.setState({
          isTournamentStartListReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            tournamentId,
            showBlank: isShowBlank,
            highlightNoAge: isHighlightNoAge,
            printScoreCards: isPrintScoreCards
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_MEMBER_SCHOOLS_STATISTICS:
        this.setState({
          isUnionMemberSchoolsStatisticsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            unionId: activeSchoolId,
            showZero: isShowZero,
            showNonZero: isShowNonZero
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_MEMBER_SCHOOLS_TOURNAMENTS:
        this.setState({
          isUnionMemberSchoolsTournamentsReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            unionId: activeSchoolId
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.UNION_MEMBER_SCHOOLS_TOURNAMENTS_AS_PART_OF_UNION:
        this.setState({
          isUnionMemberSchoolsTournamentsAsPartOfUnionReportModalOpen: false,
          isLoading: true
        });

        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 0);

        modData = {
          reportParamsCSV: {
            dateTo: Moment(dateTo).format('YYYY-MM-DD HH:mm:ss'),
            dateFrom: Moment(dateFrom).format('YYYY-MM-DD HH:mm:ss'),
            unionId: activeSchoolId
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_PLAYERS:
        this.setState({
          isTournamentPlayersReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            tournamentId,
            showBlank: isShowBlank
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.HIGH_LEVEL_TOURNAMENT_SCHOOL_ADMINS:
        this.setState({
          isHighLevelTournamentSchoolAdminsReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            tournamentId,
            showAllAdmins: isShowAllAdmins
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_SCORE_CARDS:
        this.setState({
          isTournamentScoreCardsReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            tournamentId,
            showSchedule: isShowSchedule
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_RESULTS_INDIVIDUAL:
        this.setState({
          isTournamentResultsIndividualReportModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            unionId: activeSchoolId,
            tournamentId,
            showBlank: isShowBlank,
            printFullNames: isPrintFullNames
          }
        };
        break;
    }

    uploadReport(user, reportType, modData)
      .then(data => {
        if (typeof data.reportId !== 'undefined') {
          const reportType = data.reportType;

          this.setState({
            isLoading: false
          });

          const url = `${window.apiBase}/public/report/${data.reportId}/reportType/${reportType}`;
          window.open(url);
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'No read report',
            isErrorModalOpen: true
          });
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  openExtendedTournamentScheduleReportForm = () => {
    this.setState({
      isExtendedTournamentScheduleReportModalOpen: true
    });
  };

  closeExtendedTournamentScheduleReportForm = () => {
    this.setState({
      isExtendedTournamentScheduleReportModalOpen: false
    });
  };

  openTournamentSchoolsReportForm = () => {
    this.setState({
      isTournamentSchoolsReportModalOpen: true
    });
  };

  closeTournamentSchoolsReportForm = () => {
    this.setState({
      isTournamentSchoolsReportModalOpen: false
    });
  };

  openTournamentTeamMembersReportForm = () => {
    this.setState({
      isTournamentTeamMembersReportModalOpen: true
    });
  };

  closeTournamentTeamMembersReportForm = () => {
    this.setState({
      isTournamentTeamMembersReportModalOpen: false
    });
  };

  openTournamentParticipationConfirmationsReportForm = () => {
    this.setState({
      isTournamentParticipationConfirmationsReportModalOpen: true
    });
  };

  closeTournamentParticipationConfirmationsReportForm = () => {
    this.setState({
      isTournamentParticipationConfirmationsReportModalOpen: false
    });
  };

  openTournamentStartListReportForm = () => {
    this.setState({
      isTournamentStartListReportModalOpen: true
    });
  };

  closeTournamentStartListReportForm = () => {
    this.setState({
      isTournamentStartListReportModalOpen: false
    });
  };

  openUnionMemberSchoolsStatisticsReportForm = () => {
    this.setState({
      isUnionMemberSchoolsStatisticsReportModalOpen: true
    });
  };

  closeUnionMemberSchoolsStatisticsReportForm = () => {
    this.setState({
      isUnionMemberSchoolsStatisticsReportModalOpen: false
    });
  };

  openUnionMemberSchoolsTournamentsReportForm = () => {
    this.setState({
      isUnionMemberSchoolsTournamentsReportModalOpen: true
    });
  };

  closeUnionMemberSchoolsTournamentsReportForm = () => {
    this.setState({
      isUnionMemberSchoolsTournamentsReportModalOpen: false
    });
  };

  openUnionMemberSchoolsTournamentsAsPartOfUnionReportForm = () => {
    this.setState({
      isUnionMemberSchoolsTournamentsAsPartOfUnionReportModalOpen: true
    });
  };

  closeUnionMemberSchoolsTournamentsAsPartOfUnionReportForm = () => {
    this.setState({
      isUnionMemberSchoolsTournamentsAsPartOfUnionReportModalOpen: false
    });
  };

  openTournamentPlayersReportForm = () => {
    this.setState({
      isTournamentPlayersReportModalOpen: true
    });
  };

  closeTournamentPlayersReportForm = () => {
    this.setState({
      isTournamentPlayersReportModalOpen: false
    });
  };

  openHighLevelTournamentSchoolAdminsReportForm = () => {
    this.setState({
      isHighLevelTournamentSchoolAdminsReportModalOpen: true
    });
  };

  closeHighLevelTournamentSchoolAdminsReportForm = () => {
    this.setState({
      isHighLevelTournamentSchoolAdminsReportModalOpen: false
    });
  };

  openTournamentScoreCardsReportForm = () => {
    this.setState({
      isTournamentScoreCardsReportModalOpen: true
    });
  };

  closeTournamentScoreCardsReportForm = () => {
    this.setState({
      isTournamentScoreCardsReportModalOpen: false
    });
  };

  openTournamentResultsIndividualReportForm = () => {
    this.setState({
      isTournamentResultsIndividualReportModalOpen: true
    });
  };

  closeTournamentResultsIndividualReportForm = () => {
    this.setState({
      isTournamentResultsIndividualReportModalOpen: false
    });
  };

  renderExtendedTournamentScheduleReportFormModal() {
    const { isExtendedTournamentScheduleReportModalOpen } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isExtendedTournamentScheduleReportModalOpen}>
        <ExtendedTournamentScheduleReportForm
          onCloseClick={this.closeExtendedTournamentScheduleReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderTournamentSchoolsReportFormModal() {
    const { isTournamentSchoolsReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isTournamentSchoolsReportModalOpen}>
        <TournamentSchoolsReportForm
          onCloseClick={this.closeTournamentSchoolsReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  renderTournamentTeamMembersReportFormModal() {
    const { isTournamentTeamMembersReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isTournamentTeamMembersReportModalOpen}>
        <TournamentTeamMembersReportForm
          onCloseClick={this.closeTournamentTeamMembersReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  renderTournamentParticipationConfirmationsReportFormModal() {
    const { isTournamentParticipationConfirmationsReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isTournamentParticipationConfirmationsReportModalOpen}>
        <TournamentParticipationConfirmationsReportForm
          onCloseClick={this.closeTournamentParticipationConfirmationsReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  renderTournamentStartListReportFormModal() {
    const { isTournamentStartListReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isTournamentStartListReportModalOpen}>
        <TournamentStartListReportForm
          onCloseClick={this.closeTournamentStartListReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  renderUnionMemberSchoolsStatisticsReportFormModal() {
    const { isUnionMemberSchoolsStatisticsReportModalOpen } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isUnionMemberSchoolsStatisticsReportModalOpen}>
        <UnionMemberSchoolsStatisticsReportForm
          onCloseClick={this.closeUnionMemberSchoolsStatisticsReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderUnionMemberSchoolsTournamentsReportFormModal() {
    const { isUnionMemberSchoolsTournamentsReportModalOpen } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isUnionMemberSchoolsTournamentsReportModalOpen}>
        <UnionMemberSchoolsTournamentsReportForm
          onCloseClick={this.closeUnionMemberSchoolsTournamentsReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderUnionMemberSchoolsTournamentsAsPartOfUnionReportFormModal() {
    const { isUnionMemberSchoolsTournamentsAsPartOfUnionReportModalOpen } = this.state;
    const { user } = this.props;
    const { activeSchool } = user;
    return (
      <SimpleModal isOpen={isUnionMemberSchoolsTournamentsAsPartOfUnionReportModalOpen}>
        <UnionMemberSchoolsTournamentsAsPartOfUnionReportForm
          onCloseClick={this.closeUnionMemberSchoolsTournamentsAsPartOfUnionReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderTournamentPlayersReportFormModal() {
    const { isTournamentPlayersReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isTournamentPlayersReportModalOpen}>
        <TournamentPlayersReportForm
          onCloseClick={this.closeTournamentPlayersReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  renderHighLevelTournamentSchoolAdminsReportFormModal() {
    const { isHighLevelTournamentSchoolAdminsReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isHighLevelTournamentSchoolAdminsReportModalOpen}>
        <HighLevelTournamentSchoolAdminsReportForm
          onCloseClick={this.closeHighLevelTournamentSchoolAdminsReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  renderTournamentScoreCardsReportFormModal() {
    const { isTournamentScoreCardsReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isTournamentScoreCardsReportModalOpen}>
        <TournamentScoreCardsReportForm
          onCloseClick={this.closeTournamentScoreCardsReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  renderTournamentResultsIndividualReportFormModal() {
    const { isTournamentResultsIndividualReportModalOpen, selectedItems } = this.state;
    const tournament = selectedItems[0];
    return (
      <SimpleModal isOpen={isTournamentResultsIndividualReportModalOpen}>
        <TournamentResultsIndividualReportForm
          onCloseClick={this.closeTournamentResultsIndividualReportForm}
          onSubmit={this.onSubmitReportForm}
          tournament={tournament}
        />
      </SimpleModal>
    );
  }

  render() {
    const {
      isLoading,
      tournamentTags,
      isEditableAutoEnrollment,
      isEditablePublicSiteSettings,
      isInformationModalShow,
      isRemoveExistingEventsModalShow,
      isAddTournamentToChampionshipModalOpen,
      isRemoveTournamentToChampionshipModalOpen,
      isAddEventModalOpen,
      isDefaultGroupsModalOpen,
      isEditableTournamentTVSettings,
      isTournamentTagModalOpen,
      isExtendedTournamentScheduleReportModalOpen,
      isTournamentParticipationConfirmationsReportModalOpen,
      isTournamentStartListReportModalOpen,
      isUnionMemberSchoolsStatisticsReportModalOpen,
      isUnionMemberSchoolsTournamentsReportModalOpen,
      isUnionMemberSchoolsTournamentsAsPartOfUnionReportModalOpen,
      isTournamentPlayersReportModalOpen,
      isHighLevelTournamentSchoolAdminsReportModalOpen,
      isTournamentScoreCardsReportModalOpen,
      isTournamentResultsIndividualReportModalOpen
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isEditableAutoEnrollment ||
      isEditablePublicSiteSettings ||
      isInformationModalShow ||
      isAddTournamentToChampionshipModalOpen ||
      isRemoveTournamentToChampionshipModalOpen ||
      isAddEventModalOpen ||
      isDefaultGroupsModalOpen ||
      isRemoveExistingEventsModalShow ||
      isEditableTournamentTVSettings ||
      isTournamentTagModalOpen ||
      isExtendedTournamentScheduleReportModalOpen ||
      isTournamentParticipationConfirmationsReportModalOpen ||
      isTournamentStartListReportModalOpen ||
      isUnionMemberSchoolsStatisticsReportModalOpen ||
      isUnionMemberSchoolsTournamentsReportModalOpen ||
      isUnionMemberSchoolsTournamentsAsPartOfUnionReportModalOpen ||
      isTournamentPlayersReportModalOpen ||
      isHighLevelTournamentSchoolAdminsReportModalOpen ||
      isTournamentScoreCardsReportModalOpen ||
      isTournamentResultsIndividualReportModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderTournamentTVModal()}
        {this.renderRemoveExistingEventsModalShowModal()}
        {this.renderAutoEnrollmentModal()}
        {this.renderPublicSiteSettingsModal()}
        {this.renderTournamentAddToChampionshipModal()}
        {this.renderTournamentRemoveToChampionshipModal()}
        {this.renderCreateEventsForGroupModal()}
        {this.renderInformationModal()}
        {this.renderAddEventModal()}
        {this.renderDefaultGroupsModal()}
        {this.renderDeleteTournamentConfirmationModal()}
        {this.renderTournamentTagAssignModal()}
        {this.renderTournamentTagDeleteModal()}
        {this.renderExtendedTournamentScheduleReportFormModal()}
        {this.renderTournamentTeamMembersReportFormModal()}
        {this.renderTournamentParticipationConfirmationsReportFormModal()}
        {this.renderTournamentStartListReportFormModal()}
        {this.renderUnionMemberSchoolsStatisticsReportFormModal()}
        {this.renderUnionMemberSchoolsTournamentsReportFormModal()}
        {this.renderUnionMemberSchoolsTournamentsAsPartOfUnionReportFormModal()}
        {this.renderTournamentPlayersReportFormModal()}
        {this.renderHighLevelTournamentSchoolAdminsReportFormModal()}
        {this.renderTournamentScoreCardsReportFormModal()}
        {this.renderTournamentResultsIndividualReportFormModal()}
        {this.renderTournamentSchoolsReportFormModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}
