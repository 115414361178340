import { MONTH_NAMES } from '../../consts/common';
import { SelectOption } from '../table/table';
import * as propz from 'propz';
import { CLUB_SIGN, DEFAULT_RANGE_CALENDAR_YEARS, DEFAULT_SIGN, TOURNAMENT_SIGN } from '../../consts/calendar';
import {
  EVENT_NO_RESULTS_YET,
  EVENT_STATUS,
  EVENT_STATUS_SERVER_TO_CLIENT_MAPPING,
  EVENT_TYPES,
  EVENT_VIEW_DETAILS,
  EVENT_VIEW_RESULTS
} from '../../consts/event';
import { AppUser } from '../../views/App/App';
import { CALENDAR_FILTER, FilterType } from '../../views/GenericView/AdminView/Calendar/Calendar';
import { DEFAULT_LIMIT } from '../../consts/table';
import {
  isClubEvent,
  isEventStatusCanceled,
  isEventStatusFinished,
  isEventStatusRejected,
  isHousesEvent,
  isInternalEvent,
  isInterSchoolsEvent,
  isMultipartyEvent,
  isNonTeamSportEvent,
  isResultsModePlaces,
  isResultsModePoints,
  isResultsModeResults,
  isTournamentEvent
} from '../event/event';
import { SchoolEvent } from '../../models/event';
import { sortByNameAsc } from '../team/team';
import { getCricketResults, isCricket } from '../sport/cricket';

//Get array [currentYear - 5 ... currentYear - 1, currentYear, currentYear + 1 ... currentYear + 5]
export function getYearRangeArray(range: number = DEFAULT_RANGE_CALENDAR_YEARS): number[] {
  const currentYear = new Date().getFullYear();
  const yearRangeArray = [];
  for (let i = currentYear - range; i <= currentYear + range; i++) {
    yearRangeArray.push(i);
  }

  return yearRangeArray;
}

export function getOptionsForMonthDropdown(): SelectOption[] {
  return MONTH_NAMES.map((monthName, index) => ({
    text: monthName,
    value: index
  }));
}

export function getOptionsForYearDropdown(): SelectOption[] {
  const yearRangeArray = getYearRangeArray();
  return yearRangeArray.map(year => ({
    text: String(year),
    value: year
  }));
}

/** How much days in given month of given year */
function daysInMonth(year: number, month: number) {
  return new Date(year, month + 1, 0).getDate();
}

/**
 * For given year and month return array of all dates visible in calendar.
 * It will add some days in begining from previous month and some days to end.
 * It is not too very complex, but it contain few steps.
 * @param {Number} year
 * @param {Number} month
 * @returns {Array<Date>}
 */
export function visibleDays(year: number, month: number): Date[] {
  const firstMonthDayDate = new Date(year, month, 1);
  // as calendar have 7 days and 5 or 6 rows. 6 rows we have when month starts on Sat or Sun
  const datesToDraw: Date[] = [];

  // 0 for Sunday, 1 for Monday...
  const firstMonthDay = firstMonthDayDate.getDay();

  // if first month day is not Monday(1) - adding some days from previous month
  if (firstMonthDay !== 1) {
    // how much days from previous month to add. 0 is Sunday, so this is why we checking it explicitly
    const daysToAdd = firstMonthDay === 0 ? 6 : firstMonthDay - 1;
    // how much days in previous month
    const daysInPrevMonth = daysInMonth(year, month - 1);

    // adding some dates from previous month
    for (let i = 0; i < daysToAdd; i++) {
      datesToDraw[i] = new Date(year, month - 1, daysInPrevMonth - daysToAdd + i + 1);
    }
  }

  const daysInThisMonth = daysInMonth(year, month);
  // adding all dates from current month
  for (let i = 0; i < daysInThisMonth; i++) {
    datesToDraw.push(new Date(year, month, i + 1));
  }

  const daysToAddFromNextMonth = 7 - (datesToDraw.length % 7);
  // just adding days we don't have filled now. This also take in account extra-short Feb :)
  if (daysToAddFromNextMonth !== 0) {
    for (let i = 0; i < daysToAddFromNextMonth; i++) {
      datesToDraw.push(new Date(year, month + 1, i + 1));
    }
  }

  return datesToDraw;
}

export function areDatesInSameDay(d1: Date, d2: Date) {
  return (
    typeof d1 !== 'undefined' &&
    typeof d2 !== 'undefined' &&
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function isEventsExistInDate(date: Date, eventDates: Date[]): boolean {
  const dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

  return eventDates.some(eventDate => {
    const eventDateStr = `${eventDate.getFullYear()}-${eventDate.getMonth()}-${eventDate.getDate()}`;

    return dateStr === eventDateStr;
  });
}
/* filter to load all events for one day */
export function getFilterEventsForDay(date: Date, user: AppUser) {
  const { activeSchoolId } = user;
  const dayStart = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  const dayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0);

  return {
    limit: DEFAULT_LIMIT,
    where: {
      startTime: {
        $gte: dayStart,
        $lt: dayEnd
      },
      $or: [
        {
          // internal events are always shown no matter what
          eventType: { $in: [EVENT_TYPES.INTERNAL_HOUSES, EVENT_TYPES.INTERNAL_TEAMS] }
        },
        {
          // external events created by me always visible with any status
          eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
          inviterSchoolId: activeSchoolId
        },
        {
          // external events where I'm invited shown only in some special statuses
          eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
          inviterSchoolId: { $ne: activeSchoolId },
          invitedSchoolIds: activeSchoolId,
          status: {
            $in: [
              EVENT_STATUS.INVITES_SENT,
              EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
              EVENT_STATUS.ACCEPTED,
              EVENT_STATUS.REJECTED,
              EVENT_STATUS.FINISHED,
              EVENT_STATUS.CANCELED,
              EVENT_STATUS.COLLECTING_INVITE_RESPONSE
            ]
          }
        }
      ]
    }
  };
}
/* filter to load all event dates for one month */
export function getFilterEventDatesForMonth(date: Date, user: AppUser) {
  const { activeSchoolId } = user;
  const monthStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const monthEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);

  const filter = {
    limit: DEFAULT_LIMIT,
    where: {
      startTime: {
        $gte: monthStartDate,
        $lt: monthEndDate
      },
      $or: [
        {
          // internal events are always shown no matter what
          eventType: { $in: [EVENT_TYPES.INTERNAL_HOUSES, EVENT_TYPES.INTERNAL_TEAMS] }
        },
        {
          // external events created by me always visible with any status
          eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
          inviterSchoolId: activeSchoolId
        },
        {
          // external events where I'm invited shown only in some special statuses
          eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
          inviterSchoolId: { $ne: activeSchoolId },
          invitedSchoolIds: activeSchoolId,
          status: {
            $in: [
              EVENT_STATUS.INVITES_SENT,
              EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
              EVENT_STATUS.ACCEPTED,
              EVENT_STATUS.REJECTED,
              EVENT_STATUS.FINISHED,
              EVENT_STATUS.CANCELED
            ]
          }
        }
      ]
    }
  };

  return filter;
}

export function getFilterTournamentDatesForMonth(date: Date) {
  const monthStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const monthEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);

  return {
    limit: DEFAULT_LIMIT,
    where: {
      isParticipant: true,
      startTime: {
        $gte: monthStartDate,
        $lt: monthEndDate
      }
    }
  };
}

export function getFilterTournamentsForDay(date: Date) {
  const dayStart = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  const dayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0);

  return {
    limit: DEFAULT_LIMIT,
    order: 'startedAt DESC',
    where: {
      isParticipant: true,
      startTime: {
        $gte: dayStart,
        $lt: dayEnd
      }
    }
  };
}

export function isAllFilter(filter: FilterType): boolean {
  return filter === CALENDAR_FILTER.ALL;
}

export function isSportEventsFilter(filter: FilterType): boolean {
  return filter === CALENDAR_FILTER.SPORT_EVENTS;
}

export function isClubEventsFilter(filter: FilterType): boolean {
  return filter === CALENDAR_FILTER.CLUB_EVENTS;
}

export function isTournamentEventsFilter(filter: FilterType): boolean {
  return filter === CALENDAR_FILTER.TOURNAMENT_EVENTS;
}

export function isTournamentsFilter(filter: FilterType): boolean {
  return filter === CALENDAR_FILTER.TOURNAMENTS;
}

export function getFilterFixtureEventDatesForMonth(filter) {
  return {
    ...filter,
    where: {
      ...filter.where,
      clubId: { $exists: false },
      tournamentId: { $exists: false }
    }
  };
}
export function getFilterClubEventDatesForMonth(filter) {
  return {
    ...filter,
    where: {
      ...filter.where,
      clubId: { $exists: true }
    }
  };
}
export function getFilterTournamentEventDatesForMonth(filter) {
  return {
    ...filter,
    where: {
      ...filter.where,
      tournamentId: { $exists: true }
    }
  };
}

export function getFilterFixtureEventsForDay(filter) {
  return {
    ...filter,
    where: {
      ...filter.where,
      clubId: { $exists: false },
      tournamentId: { $exists: false }
    }
  };
}

export function getFilterFixtureEventsForWeek(date: Date, user: AppUser) {
  const { activeSchoolId } = user;

  const weekStart = new Date(date);
  const offset = (date.getDay() + 6) % 7;
  weekStart.setDate(date.getDate() - offset);
  weekStart.setHours(0, 0, 0, 0);

  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 7);

  return {
    limit: DEFAULT_LIMIT,
    where: {
      startTime: {
        $gte: weekStart,
        $lt: weekEnd
      },
      clubId: { $exists: false },
      tournamentId: { $exists: false },
      $or: [
        {
          eventType: { $in: [EVENT_TYPES.INTERNAL_HOUSES, EVENT_TYPES.INTERNAL_TEAMS] }
        },
        {
          eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
          inviterSchoolId: activeSchoolId
        },
        {
          eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
          inviterSchoolId: { $ne: activeSchoolId },
          invitedSchoolIds: activeSchoolId,
          status: {
            $in: [
              EVENT_STATUS.INVITES_SENT,
              EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
              EVENT_STATUS.ACCEPTED,
              EVENT_STATUS.REJECTED,
              EVENT_STATUS.FINISHED,
              EVENT_STATUS.CANCELED
            ]
          }
        }
      ]
    }
  };
}

export function getFilterFixtureEventsForWeekExcludingCancelled(date: Date, user: AppUser) {
  const { activeSchoolId } = user;

  const weekStart = new Date(date);
  const offset = (date.getDay() + 6) % 7;
  weekStart.setDate(date.getDate() - offset);
  weekStart.setHours(0, 0, 0, 0);

  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 7);

  return {
    limit: DEFAULT_LIMIT,
    where: {
      startTime: { $gte: weekStart, $lt: weekEnd },
      status: { $ne: EVENT_STATUS.CANCELED },
      clubId: { $exists: false },
      tournamentId: { $exists: false },
      $or: [
        {
          eventType: { $in: [EVENT_TYPES.INTERNAL_HOUSES, EVENT_TYPES.INTERNAL_TEAMS] }
        },
        {
          eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
          inviterSchoolId: activeSchoolId
        },
        {
          eventType: { $in: [EVENT_TYPES.EXTERNAL_SCHOOLS] },
          inviterSchoolId: { $ne: activeSchoolId },
          invitedSchoolIds: activeSchoolId,
          status: {
            $in: [
              EVENT_STATUS.INVITES_SENT,
              EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
              EVENT_STATUS.ACCEPTED,
              EVENT_STATUS.REJECTED,
              EVENT_STATUS.FINISHED
            ]
          }
        }
      ]
    }
  };
}

export function getFilterClubEventsForDay(filter) {
  return {
    ...filter,
    where: {
      ...filter.where,
      clubId: { $exists: true }
    }
  };
}

export function getFilterClubEventsForWeekExcludingCancelled(date: Date, user: AppUser) {
  const weekStart = new Date(date);
  const offset = (date.getDay() + 6) % 7;
  weekStart.setDate(date.getDate() - offset);
  weekStart.setHours(0, 0, 0, 0);

  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 7);

  return {
    limit: DEFAULT_LIMIT,
    where: {
      startTime: { $gte: weekStart, $lt: weekEnd },
      clubId: { $exists: true },
      status: { $ne: EVENT_STATUS.CANCELED }
    }
  };
}

export function getFilterTournamentEventsForDay(filter) {
  return {
    ...filter,
    where: {
      ...filter.where,
      tournamentId: { $exists: true }
    }
  };
}

export function getSignEventType(event) {
  switch (true) {
    case isClubEvent(event):
      return CLUB_SIGN;
    case isTournamentEvent(event):
      return TOURNAMENT_SIGN;
    default:
      return DEFAULT_SIGN;
  }
}

export function getFilterParentEventsForDay(selectedDate: Date, childIdList: string[]) {
  const dayStart = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0);
  const dayEnd = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1, 0, 0, 0);

  const eventAcceptableStatusArray = [
    EVENT_STATUS.INVITES_SENT,
    EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
    EVENT_STATUS.ACCEPTED,
    EVENT_STATUS.FINISHED,
    EVENT_STATUS.CANCELED
  ];

  return {
    limit: 100,
    where: {
      startTime: {
        $gte: dayStart,
        $lt: dayEnd
      },
      status: {
        $in: eventAcceptableStatusArray
      },
      childIdList
    }
  };
}

export function getFilterParentEventDatesForMonth(monthAndYearDate: Date, childIdList: string[]) {
  const monthStartDate = new Date(monthAndYearDate.getFullYear(), monthAndYearDate.getMonth(), 1);
  const monthEndDate = new Date(monthAndYearDate.getFullYear(), monthAndYearDate.getMonth() + 1, 1);
  const eventAcceptableStatusArray = [
    EVENT_STATUS.INVITES_SENT,
    EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
    EVENT_STATUS.ACCEPTED,
    EVENT_STATUS.FINISHED,
    EVENT_STATUS.CANCELED
  ];

  return {
    limit: DEFAULT_LIMIT,
    where: {
      startTime: {
        $gte: monthStartDate,
        $lt: monthEndDate
      },
      status: {
        $in: eventAcceptableStatusArray
      },
      childIdList
    }
  };
}

export function getFilterStudentEventsForDay(selectedDate: Date, schoolIdList: string[]) {
  const dayStart = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0);
  const dayEnd = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1, 0, 0, 0);

  const eventAcceptableStatusArray = [
    EVENT_STATUS.INVITES_SENT,
    EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
    EVENT_STATUS.ACCEPTED,
    EVENT_STATUS.FINISHED,
    EVENT_STATUS.CANCELED
  ];

  return {
    limit: 100,
    where: {
      startTime: {
        $gte: dayStart,
        $lt: dayEnd
      },
      status: {
        $in: eventAcceptableStatusArray
      },
      schoolId: { $in: schoolIdList }
    }
  };
}

export function getFilterStudentEventDatesForMonth(monthAndYearDate: Date, schoolIdList: string[]) {
  const monthStartDate = new Date(monthAndYearDate.getFullYear(), monthAndYearDate.getMonth(), 1);
  const monthEndDate = new Date(monthAndYearDate.getFullYear(), monthAndYearDate.getMonth() + 1, 1);

  const eventAcceptableStatusArray = [
    EVENT_STATUS.INVITES_SENT,
    EVENT_STATUS.COLLECTING_INVITE_RESPONSE,
    EVENT_STATUS.ACCEPTED,
    EVENT_STATUS.FINISHED,
    EVENT_STATUS.CANCELED
  ];

  return {
    limit: DEFAULT_LIMIT,
    where: {
      startTime: {
        $gte: monthStartDate,
        $lt: monthEndDate
      },
      status: {
        $in: eventAcceptableStatusArray
      },
      schoolId: { $in: schoolIdList }
    }
  };
}

export function getEventResultForCalendar(event: SchoolEvent, activeSchoolId: string): string {
  const { teamsData } = event;
  const isResultsModePlacesOrPoints = isResultsModePlaces(event) || isResultsModePoints(event);
  const isInterHousesOrInternal = isHousesEvent(event) || isInternalEvent(event);
  const teamsFilteredByActiveSchoolId = teamsData.filter(team => team.schoolId === activeSchoolId);
  const isMoreThanOneTeamFromActiveSchool = teamsFilteredByActiveSchoolId.length > 1;

  switch (true) {
    case !isEventStatusFinished(event):
      return isClubEvent(event) ? EVENT_VIEW_DETAILS : EVENT_NO_RESULTS_YET;
    case isEventStatusCanceled(event):
      return EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.CANCELED;
    case isEventStatusRejected(event):
      return EVENT_STATUS_SERVER_TO_CLIENT_MAPPING.REJECTED;
    case isCricket(event) && !isMultipartyEvent(event):
      return getCricketResults(event, activeSchoolId);
    case isResultsModeResults(event):
      return isNonTeamSportEvent(event) ? EVENT_VIEW_RESULTS : getResults(event, activeSchoolId);
    case isResultsModePlacesOrPoints && isInterHousesOrInternal:
    case isResultsModePlacesOrPoints && isInterSchoolsEvent(event) && isMoreThanOneTeamFromActiveSchool:
      return EVENT_VIEW_RESULTS;
    case isResultsModePlacesOrPoints && isInterSchoolsEvent(event):
      return getPlacesOrPoints(event, activeSchoolId);
    default:
      return '';
  }
}

function getResults(event: SchoolEvent, activeSchoolId: string): string {
  const { eventType, results, teamsData, housesData } = event;
  const { schoolScore, houseScore, teamScore } = results;
  const teamsCount = teamsData.length;

  const housesDataSorted = [...housesData].sort(sortByNameAsc);
  const teamsDataSorted = [...teamsData].sort(sortByNameAsc);

  switch (eventType) {
    case EVENT_TYPES.EXTERNAL_SCHOOLS:
      switch (teamsCount) {
        case 0: {
          //school vs school
          const activeSchoolScore = schoolScore.find(score => score.schoolId === activeSchoolId);
          const opponentSchoolScore = schoolScore.find(score => score.schoolId !== activeSchoolId);
          return `${activeSchoolScore.score} : ${opponentSchoolScore.score}`;
        }
        case 1: {
          //school vs team
          const activeSchoolTeam = teamsData.find(team => team.schoolId === activeSchoolId);
          const isActiveSchoolTeamExist = typeof activeSchoolTeam !== 'undefined';

          if (isActiveSchoolTeamExist) {
            //active team score, opponent school score
            const { id } = activeSchoolTeam;
            const activeSchoolScore = teamScore.find(score => score.teamId === id);
            const opponentSchoolScore = schoolScore.find(score => score.schoolId !== activeSchoolId);
            return `${activeSchoolScore.score} : ${opponentSchoolScore.score}`;
          } else {
            //active school score, opponent team score
            const opponentSchoolTeam = teamsData.find(team => team.schoolId !== activeSchoolId);
            const { id } = opponentSchoolTeam;
            const activeSchoolScore = schoolScore.find(score => score.schoolId === activeSchoolId);
            const opponentSchoolScore = teamScore.find(score => score.teamId === id);

            return `${activeSchoolScore.score} : ${opponentSchoolScore.score}`;
          }
        }
        case 2: {
          //team vs team
          const activeSchoolTeam = teamsData.find(team => team.schoolId === activeSchoolId);
          const isActiveSchoolTeamExist = typeof activeSchoolTeam !== 'undefined';

          if (isActiveSchoolTeamExist) {
            //active team score, opponent team score
            const { id } = activeSchoolTeam;
            const activeSchoolScore = teamScore.find(score => score.teamId === id);
            const opponentSchoolScore = teamScore.find(score => score.teamId !== id);
            return `${activeSchoolScore.score} : ${opponentSchoolScore.score}`;
          } else {
            //active school score, two opponents - team score
            return EVENT_VIEW_RESULTS;
          }
        }
      }
      break;
    case EVENT_TYPES.INTERNAL_HOUSES:
      switch (teamsCount) {
        case 0: {
          //house vs house
          const [house1, house2] = housesDataSorted;
          const houseScore1 = houseScore.find(score => score.houseId === house1.id);
          const houseScore2 = houseScore.find(score => score.houseId === house2.id);
          return `${houseScore1.score} : ${houseScore2.score}`;
        }
        case 1: {
          //house vs team
          const [house1, house2] = housesDataSorted;
          const team1 = teamsData.find(team => team.houseId === house1.id);
          const team2 = teamsData.find(team => team.houseId === house2.id);
          const isTeamHouse1Exist = typeof team1 !== 'undefined';
          if (isTeamHouse1Exist) {
            //house 1 team score, house 2 house score
            const { id } = team1;
            const houseScore1 = teamScore.find(score => score.teamId === id);
            const houseScore2 = houseScore.find(score => score.houseId === house2.id);
            return `${houseScore1.score} : ${houseScore2.score}`;
          } else {
            //house 1 house score, house 2 team score
            const { id } = team2;
            const houseScore1 = houseScore.find(score => score.houseId === house1.id);
            const houseScore2 = teamScore.find(score => score.teamId === id);
            return `${houseScore1.score} : ${houseScore2.score}`;
          }
        }
        case 2: {
          const [house1, house2] = housesDataSorted;
          const team1 = teamsData.find(team => team.houseId === house1.id);
          const team2 = teamsData.find(team => team.houseId === house2.id);
          const { id: houseTeamId1 } = team1;
          const { id: houseTeamId2 } = team2;
          const houseScore1 = teamScore.find(score => score.teamId === houseTeamId1);
          const houseScore2 = teamScore.find(score => score.teamId === houseTeamId2);
          return `${houseScore1.score} : ${houseScore2.score}`;
        }
      }
      break;
    case EVENT_TYPES.INTERNAL_TEAMS:
      const [team1, team2] = teamsDataSorted;
      switch (true) {
        case teamsDataSorted.length === 2:
          const teamScore1 = teamScore.find(score => score.teamId === team1.id);
          const teamScore2 = teamScore.find(score => score.teamId === team2.id);
          const score1 = propz.get(teamScore1, ['score'], '');
          const score2 = propz.get(teamScore2, ['score'], '');
          return `${score1} : ${score2}`;

        case teamsDataSorted.length === 1:
          const teamScoreSingle = teamScore.find(score => score.teamId === team1.id);
          const score = propz.get(teamScoreSingle, ['score'], '');
          return `${score}`;

        default:
          return '';
      }

    default:
      console.error(`Can not find event type: ${eventType}`);
      return '';
  }
}

function getPlacesOrPoints(event: SchoolEvent, activeSchoolId: string): string {
  const { results, teamsData } = event;
  const { schoolScore, teamScore } = results;
  const team = teamsData.find(team => team.schoolId === activeSchoolId);
  const isTeamExist = typeof team !== 'undefined';
  const totalScores = [...schoolScore, ...teamScore].length;

  switch (true) {
    case isResultsModePlaces(event): {
      const allResultsSortedAsc: any[] = [...schoolScore, ...teamScore].sort((score1, score2) => {
        return score1.score - score2.score;
      });

      if (isTeamExist) {
        const teamScoreIndex = allResultsSortedAsc.findIndex(score => score.teamId === team.id);
        return `Place ${teamScoreIndex + 1} out of ${totalScores}`;
      } else {
        const schoolScoreIndex = allResultsSortedAsc.findIndex(score => score.schoolId === activeSchoolId);
        return `Place ${schoolScoreIndex + 1} out of ${totalScores}`;
      }
    }
    case isResultsModePoints(event): {
      const allResultsSortedDesc: any[] = [...schoolScore, ...teamScore].sort((score1, score2) => {
        return score2.score - score1.score;
      });

      if (isTeamExist) {
        const teamScoreIndex = allResultsSortedDesc.findIndex(score => score.teamId === team.id);
        return `Place ${teamScoreIndex + 1} out of ${totalScores}`;
      } else {
        const schoolScoreIndex = allResultsSortedDesc.findIndex(score => score.schoolId === activeSchoolId);
        return `Place ${schoolScoreIndex + 1} out of ${totalScores}`;
      }
    }
  }
}

export function getStartDateForSchoolYear(currentDate: Date): Date {
  let startDate;

  const currentMonthNumber = currentDate.getMonth();
  const firstTermMonthNumbers = [8, 9, 10, 11];
  const isFirstTerm = firstTermMonthNumbers.includes(currentMonthNumber);

  if (isFirstTerm) {
    startDate = new Date(currentDate.getFullYear(), 8, 1);
  } else {
    startDate = new Date(currentDate.getFullYear() - 1, 8, 1);
  }

  return startDate;
}

export function getFinishDateForSchoolYear(currentDate: Date): Date {
  let finishDate;

  const currentMonthNumber = currentDate.getMonth();
  const firstTermMonthNumbers = [8, 9, 10, 11];
  const isFirstTerm = firstTermMonthNumbers.includes(currentMonthNumber);

  if (isFirstTerm) {
    finishDate = new Date(currentDate.getFullYear() + 1, 7, 31);
  } else {
    finishDate = new Date(currentDate.getFullYear(), 7, 31);
  }

  return finishDate;
}
