import * as React from 'react';
import * as BPromise from 'bluebird';
import { Component } from 'react';
import { Participant, SchoolEvent, SchoolEventCricketResult } from 'Src/models/event';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import './SchoolEventView.scss';
import {
  finishEvent,
  getEventAvailableUsers,
  getSchoolEvent,
  setEventResultsMode,
  sendEventNotification,
  sendEventNotificationEligibleStudents,
  addSchoolInEvent,
  removeSchoolsFromEvent
} from 'Src/helpers/service/admin/event';
import { getSchoolsPublic } from 'Src/helpers/service/public/public';
import { getSchoolEvents, reasonForCancellation } from 'Src/helpers/service/admin/schoolEvents';
import { ActionsButton } from 'Src/components/ActionsButton/ActionsButton';
import {
  getActionList,
  getActionListWithSubItems,
  getNextEventFilter,
  getPreviousEventFilter
} from 'Src/helpers/event/schoolEventViewHelper';
import { SchoolEventNavigationButtons } from './SchoolEventNavigationButtons';
import { SchoolEventMainInformation } from './SchoolEventMainInformation';
import { SchoolEventNewTweetButton } from './SchoolEventNewTweetButton';
import { SchoolEventTabs } from './SchoolEventTabs';
import { SimpleModal } from '../../../../../../components/SimpleModal/SimpleModal';
import {
  getAllSchoolIdsFromEvent,
  getEventParticipants,
  isEventStatusFinished,
  isHousesEvent,
  isInternalEvent,
  isInterSchoolsEvent,
  isMultipartyEvent,
  isNonTeamSportEvent,
  isTeamOrTwoOnTwoSportEvent,
  isClubEvent,
  isLeagueEvent,
  isTournamentEvent,
  isIndividualSportEvent
} from '../../../../../../helpers/event/event';
import { IndividualInternalResults } from './ResultsViews/IndividualInternalResults';
import {
  createCricketResults,
  createHouseScore,
  createIndividualScore,
  createSchoolScore,
  createTeamScore,
  updateHouseScore,
  updateIndividualScore,
  updateSchoolScore,
  updateTeamScore
} from 'Src/helpers/service/admin/eventResults';
import { IndividualInternalHousesResults } from './ResultsViews/IndividualInternalHousesResults';
import { IndividualExternalSchoolsResults } from './ResultsViews/IndividualExternalSchoolsResults';
import { TeamInternalResults } from './ResultsViews/TeamInternalResults';
import { TeamInternalHousesResults } from './ResultsViews/TeamInternalHousesResults';
import { TeamExternalSchoolsResults } from './ResultsViews/TeamExternalSchoolsResults';
import { SchoolEventsEditSettingsForm } from '../SchoolEventsEditSettingsForm/SchoolEventsEditSettingsForm';
import { SchoolForm } from '../../../../../../models/form';
import { getAllForms } from 'Src/helpers/service/admin/forms';
import {
  cancelEvent,
  uncancelEvent,
  deleteEvent,
  getActionDescriptor,
  updateGroupSchoolClubEvents,
  updateActionDescriptor,
  updateSchoolEvent,
  updateSchoolEventDetails,
  updateGroupSchoolEventDetails
} from '../../../../../../helpers/service/admin/schoolEvents';
import { USER_GENDER_TYPE } from '../../../../../../types/user';
import { SendNotificationsForm } from './SendNotificationsForm/SendNotificationsForm';
import { getVenueTypeByPostcode, getNewPoint } from '../../../../../../helpers/venue/venue';
import { getVideoConfig } from 'Src/helpers/service/nobody/config';
import { getKey } from 'Src/helpers/service/nobody/key';
import * as AWS from 'aws-sdk';
import { addConvertJob } from 'Src/helpers/service/nobody/aws';
import { addEventPinnedVideo } from 'Src/helpers/service/admin/eventVideos';
import { SendNotificationEligibleStudentsForm } from './SendNotificationEligibleStudentsForm/SendNotificationEligibleStudentsForm';
import * as propz from 'propz';
import { CLUB_EVENT_EDIT_MODE, EVENT_NOTIFICATION_MODE } from '../../../../../../consts/event';
import { getEventGeneratedName } from '../../../../../../helpers/accessor/accessor';
import { getSafeFileName } from '../../../../../../helpers/file/file';
import { IndividualInternalExtraPoints } from './ExtraPointsView/IndividualInternalExtraPoints';
import { IndividualInternalHousesExtraPoints } from './ExtraPointsView/IndividualInternalHousesExtraPoints';
import { IndividualExternalSchoolsExtraPoints } from './ExtraPointsView/IndividualExternalSchoolsExtraPoints';
import { SchoolEventAddSchoolForm } from '../SchoolEventAddSchoolForm/SchoolEventAddSchoolForm';
import { removeSchoolEventIndividualPlayer } from '../../../../../../helpers/service/admin/tournamentTeams';
import { SchoolEventChangeSchoolForm } from '../SchoolEventChangeSchoolForm/SchoolEventChangeSchoolForm';
import {
  getConsentRequestTemplate,
  sendConsentRequest
} from '../../../../../../helpers/service/admin/consentRequestTemplate';
import { uploadReport } from 'Src/helpers/service/admin/report';
import { ConsentRequestTemplate } from '../../../../../../models/consentRequestTemplate';
import {
  ConsentRequestForm,
  ConsentRequestFormField
} from '../SchoolEventConsentRequestForm/SchoolEventConsentRequestForm';
import * as Moment from 'moment';
import { UK_INPUT_MASK_VALIDATION } from '../../../../../../consts/date';
import { isCricket } from '../../../../../../helpers/sport/cricket';
import { CricketExternalSchoolsResults } from './ResultsViews/CricketExternalSchoolsResults';
import { CricketInternalResults } from './ResultsViews/CricketInternalResults';
import { CricketInternalHousesResults } from './ResultsViews/CricketInternalHousesResults';
import { SchoolEventsCreateAnotherEventAlert } from '../SchoolEventsCreateAnotherEventAlert/SchoolEventsCreateAnotherEventAlert';
import { MODE_TYPE } from '../../../../../../consts/common';
import { removeSchoolEventTeam } from '../../../../../../helpers/service/admin/schoolEventTeams';
import { ROLE, STAFF_LIST } from '../../../../../../consts/user';
import { SchoolEventTwitterModal } from './Twitter/SchoolEventTwitterForm';
import { createTweet } from '../../../../../../helpers/service/admin/integrations';
import { updateEventSetting } from '../../../../../../helpers/service/admin/event';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { Map } from 'Src/components/Map/Map';
import { ExtendedTeamSheetReportForm } from './Reports/ExtendedTeamSheetReportForm/ExtendedTeamSheetReportForm';
import { ExtendedParticipantListReportForm } from './Reports/ExtendedParticipantListReportForm/ExtendedParticipantListReportForm';
import { BasicTeamSheetReportForm } from './Reports/BasicTeamSheetReportForm/BasicTeamSheetReportForm';
import { EventConsentRequestsReportForm } from './Reports/EventConsentRequestsReportForm/EventConsentRequestsReportForm';
import { EventAvailabilityReportsReportForm } from './Reports/EventAvailabilityReportsReportForm/EventAvailabilityReportsReportForm';
import { EventScoreCardReportForm } from './Reports/EventScoreCardReportForm/EventScoreCardReportForm';
import { CautionsDismissalsReportForm } from './Reports/CautionsDismissalsReportForm/CautionsDismissalsReportForm';
import { StudentTargetCompletionReportForm } from './Reports/StudentTargetCompletionReportForm/StudentTargetCompletionReportForm';
import { PaperAttendanceRegisterEventReportForm } from './Reports/PaperAttendanceRegisterEventReportForm/PaperAttendanceRegisterEventReportForm';
import { REPORT_TYPE_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/report';
import { REPORT_PRINT_FORMAT } from 'Src/consts/report';
import { Button } from 'Src/components/Button/Button';

export interface EventActionButtonCallbacks {
  EDIT_EVENT_SETTINGS: () => void;
  DUPLICATE_EVENT: () => void;
  ADD_SCHOOL: () => void;
  ADD_TEAM: () => void;
  ADD_RESULTS: () => void;
  UPDATE_RESULTS: () => void;
  UPDATE_POINTS: () => void;
  SEND_CONSENT_REQUEST: () => void;
  CANCEL_EVENT: () => void;
  CANCEL_EVENT_AND_EDIT_NOTIFICATION_LIST: () => void;
  UNCANCEL_EVENT: () => void;
  REPORT_AVAILABILITY: () => void;
  PRINT: () => void;
  DOWNLOAD_CSV: () => void;
  DELETE_EVENT: () => void;
  IMPORT_RESULTS_FROM_QUANTUM: () => void;
  EXPORT_START_LIST_TO_MACSHA_ONE_4_ALL: () => void;
  IMPORT_RESULTS_FROM_MACSHA_ONE_4_ALL: () => void;
  CLEAN_SCHOOL_LIST: () => void;
  SEND_EVENT_NOTIFICATION: () => void;
  SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS: () => void;
  IMPORT_RESULTS: () => void;
  IMPORT_RESULTS_FROM_QUANTUM_AQ: () => void;
  CLONE_TOURNAMENT_EVENT: () => void;
  ARBITER_BOARD: () => void;
  ADD_VIDEO: () => void;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

interface State {
  isLoading: boolean;
  event: SchoolEvent;
  prevEvent: SchoolEvent;
  nextEvent: SchoolEvent;
  forms: SchoolForm[];
  isResultsModalOpen: boolean;
  isExtraPointsModalOpen: boolean;
  isEditableEventSettings: boolean;
  isSendNotificationModalOpen: boolean;
  isSendNotificationEligibleStudentsModalOpen: boolean;
  availableUsers: AvailableUserExtended[];
  isVideoModalOpen: boolean;
  isCancelEventModalOpen: boolean;
  isUncancelEventModalOpen: boolean;
  cancelReason: string;
  reasonForChange: string;
  errorMessage: string;
  isErrorModalOpen: boolean;
  actionDescriptorId: string;
  actionDescriptorIds: string[];
  tabIndex: number;
  isDeleteEventModalOpen: boolean;
  isSuccessDeleteEventModalOpen: boolean;
  isAddSchoolModalOpen: boolean;
  isChangeSchoolModalOpen: boolean;
  isRemoveParticipantModalOpen: boolean;
  isMapModalOpen: boolean;
  participant: Participant;
  isOneParticipant: boolean;
  consentRequestTemplate: ConsentRequestTemplate;
  isSendConsentRequestModalOpen: boolean;
  isSuccessSendConsentRequestModalOpen: boolean;
  isShowCreateAnotherEventAlert: boolean;
  isTwitterModalOpen: boolean;
  bodyText: string;
  isPostTweetModalOpen: boolean;
  participants: Participant[];
  isBasicTeamSheetReportFormModalOpen: boolean;
  isExtendedTeamSheetReportFormModalOpen: boolean;
  isExtendedParticipantListReportFormModalOpen: boolean;
  isEventConsentRequestsReportFormModalOpen: boolean;
  isEventAvailabilityReportsReportFormModalOpen: boolean;
  isEventScoreCardReportFormModalOpen: boolean;
  isCautionsDismissalsReportFormModalOpen: boolean;
  isStudentTargetCompletionReportFormModalOpen: boolean;
  isPaperAttendanceRegisterEventReportFormModalOpen: boolean;
}

interface AvailableUser {
  extra: {
    type: string;
  };
  firstName: string;
  gender: USER_GENDER_TYPE;
  lastName: string;
  permissionId: string;
  userId: string;
  userIdStr: string;
}

export interface AvailableUserExtended extends AvailableUser {
  isChecked: boolean;
}

export class SchoolEventView extends Component<Props, State> {
  videoFileInput: any;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      event: undefined,
      participants: [],
      prevEvent: undefined,
      nextEvent: undefined,
      forms: [],
      isResultsModalOpen: false,
      isExtraPointsModalOpen: false,
      isVideoModalOpen: false,
      isEditableEventSettings: false,
      availableUsers: [],
      isSendNotificationModalOpen: false,
      isSendNotificationEligibleStudentsModalOpen: false,
      isCancelEventModalOpen: false,
      cancelReason: '',
      isUncancelEventModalOpen: false,
      reasonForChange: '',
      errorMessage: '',
      isErrorModalOpen: false,
      actionDescriptorId: undefined,
      actionDescriptorIds: [],
      tabIndex: undefined,
      isDeleteEventModalOpen: false,
      isSuccessDeleteEventModalOpen: false,
      isAddSchoolModalOpen: false,
      isChangeSchoolModalOpen: false,
      isRemoveParticipantModalOpen: false,
      isMapModalOpen: false,
      participant: undefined,
      isOneParticipant: false,
      consentRequestTemplate: undefined,
      isSendConsentRequestModalOpen: false,
      isSuccessSendConsentRequestModalOpen: false,
      isShowCreateAnotherEventAlert: false,
      isTwitterModalOpen: false,
      bodyText: '',
      isPostTweetModalOpen: false,
      isBasicTeamSheetReportFormModalOpen: false,
      isExtendedTeamSheetReportFormModalOpen: false,
      isExtendedParticipantListReportFormModalOpen: false,
      isEventConsentRequestsReportFormModalOpen: false,
      isEventAvailabilityReportsReportFormModalOpen: false,
      isEventScoreCardReportFormModalOpen: false,
      isCautionsDismissalsReportFormModalOpen: false,
      isStudentTargetCompletionReportFormModalOpen: false,
      isPaperAttendanceRegisterEventReportFormModalOpen: false
    };

    this.videoFileInput = React.createRef();
  }

  getEventId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const eventId = search.id;
    return eventId;
  }

  getMode(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const mode = propz.get(search, ['mode'], '');

    return mode;
  }

  //reload component after change eventId by navigation buttons
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    const { search } = location;
    const { search: nextSearch } = nextLocation;

    if (search !== nextSearch) {
      this.setState({
        isLoading: true
      });
      this.load();
    }
  }

  load() {
    const { user, location } = this.props;
    const calendarFilterType = propz.get(location, ['state', 'filter']);
    const eventId = this.getEventId();
    const mode = this.getMode();
    const isShowCreateAnotherEventAlert = mode === MODE_TYPE.CREATE_ANOTHER_EVENT;

    let event, prevEvent, nextEvent;

    // 1. Get current event
    // 2. Get previous/next events (for navigation) and forms
    getSchoolEvent(user, eventId)
      .then(_event => {
        event = _event;

        const allSchoolIds = getAllSchoolIdsFromEvent(event);

        const filter = {
          where: { id: { $in: allSchoolIds } },
          view: {
            type: 'id_name_pic'
          },
          skip: DEFAULT_SKIP,
          limit: DEFAULT_LIMIT
        };

        getSchoolsPublic(filter).then(schools => {
          const participants = getEventParticipants(event, user, schools);

          this.setState({
            participants
          });
        });

        const currentEventStartTime = new Date(_event.startTime);

        const nextEventFilter = getNextEventFilter(eventId, currentEventStartTime, user, calendarFilterType);
        const prevEventFilter = getPreviousEventFilter(eventId, currentEventStartTime, user, calendarFilterType);

        const prevEventPromise = getSchoolEvents(user, prevEventFilter);
        const nextEventPromise = getSchoolEvents(user, nextEventFilter);
        const getFormsPromise = getAllForms(user);

        return BPromise.all([prevEventPromise, nextEventPromise, getFormsPromise]);
      })
      .then(([_prevEvent, _nextEvent, forms]) => {
        [prevEvent] = _prevEvent;
        [nextEvent] = _nextEvent;

        this.setState({
          isLoading: false,
          event,
          nextEvent,
          prevEvent,
          forms,
          isShowCreateAnotherEventAlert
        });
      });
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    this.load();
  }

  onPrevEventClick = () => {
    const { prevEvent } = this.state;
    const { history, location } = this.props;
    const { id } = prevEvent;
    const state = propz.get(location, ['state'], {});

    history.push({
      pathname: '/events/event',
      search: `id=${id}`,
      state
    });
  };

  onNextEventClick = () => {
    const { nextEvent } = this.state;
    const { history, location } = this.props;
    const { id } = nextEvent;
    const state = propz.get(location, ['state'], {});

    history.push({
      pathname: '/events/event',
      search: `id=${id}`,
      state
    });
  };

  emptyFunc = () => {};

  updateExtraPoints = () => {
    this.setState({
      isExtraPointsModalOpen: true
    });
  };

  closeEvent = () => {
    this.setState({
      isResultsModalOpen: true
    });
  };

  onArbiterBoardClick = () => {
    const { history } = this.props;
    const { event } = this.state;
    const { id } = event;

    history.push({
      pathname: '/events/arbiterBoard',
      search: `id=${id}`
    });
  };

  addTeam = () => {
    const { history } = this.props;
    const { event } = this.state;
    const { id } = event;

    history.push({
      pathname: '/events/teamManager',
      search: `event=${id}`
    });
  };

  addSchool = () => {
    this.setState({
      isAddSchoolModalOpen: true
    });
  };

  onAddVideoClick = () => {
    let event;
    //This is true only for IE,firefox
    //Dirty hack from https://codeproject.com/Tips/893254/JavaScript-Triggering-Event-Manually-in-Internet-E
    //However, MDN recommend use very similar polyfill https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
    if (document.createEvent) {
      // To create a mouse event , first we need to create an event and then initialize it.
      event = document.createEvent('MouseEvent');
      event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    } else {
      event = new MouseEvent('click');
    }
    this.videoFileInput.dispatchEvent(event);
  };

  sendEventNotificationEligibleStudents = () => {
    this.setState({
      isSendNotificationEligibleStudentsModalOpen: true
    });
  };

  sendConsentRequest = () => {
    const { user } = this.props;
    this.setState({
      isLoading: true
    });

    getConsentRequestTemplate(user).then(template => {
      this.setState({
        isSendConsentRequestModalOpen: true,
        isLoading: false,
        consentRequestTemplate: template
      });
    });
  };

  copyEvent = () => {
    const { history } = this.props;
    const { event } = this.state;
    const { id } = event;

    history.push({
      pathname: '/events/copyManager',
      search: `event=${id}`
    });
  };

  getCallbacks(): EventActionButtonCallbacks {
    return {
      EDIT_EVENT_SETTINGS: this.onEditEventSettingsButtonClick,
      DUPLICATE_EVENT: this.copyEvent,
      ADD_SCHOOL: this.addSchool,
      ADD_TEAM: this.addTeam,
      ADD_RESULTS: this.closeEvent,
      UPDATE_RESULTS: this.closeEvent,
      UPDATE_POINTS: this.updateExtraPoints,
      SEND_CONSENT_REQUEST: this.sendConsentRequest,
      CANCEL_EVENT: this.cancelEventModalOpen,
      CANCEL_EVENT_AND_EDIT_NOTIFICATION_LIST: this.emptyFunc,
      UNCANCEL_EVENT: this.uncancelEventModalOpen,
      REPORT_AVAILABILITY: this.emptyFunc,
      PRINT: this.emptyFunc,
      DOWNLOAD_CSV: this.emptyFunc,
      DELETE_EVENT: this.deleteEventModalOpen,
      IMPORT_RESULTS_FROM_QUANTUM: this.emptyFunc,
      EXPORT_START_LIST_TO_MACSHA_ONE_4_ALL: this.emptyFunc,
      IMPORT_RESULTS_FROM_MACSHA_ONE_4_ALL: this.emptyFunc,
      CLEAN_SCHOOL_LIST: this.emptyFunc,
      SEND_EVENT_NOTIFICATION: this.sendEventNotification,
      SEND_EVENT_NOTIFICATION_ELIGIBLE_STUDENTS: this.sendEventNotificationEligibleStudents,
      IMPORT_RESULTS: this.emptyFunc,
      IMPORT_RESULTS_FROM_QUANTUM_AQ: this.emptyFunc,
      CLONE_TOURNAMENT_EVENT: this.emptyFunc,
      ARBITER_BOARD: this.onArbiterBoardClick,
      ADD_VIDEO: this.onAddVideoClick
    };
  }

  sendEventNotification = () => {
    const { user } = this.props;
    const { event } = this.state;

    this.setState({
      isLoading: true
    });

    getEventAvailableUsers(user, event.id).then((availableUsers: AvailableUser[]) => {
      this.setState({
        isLoading: false,
        isSendNotificationModalOpen: true,
        availableUsers: availableUsers.map(user => ({
          ...user,
          isChecked: true
        }))
      });
    });
  };

  getResultsView() {
    const { event } = this.state;
    const { user } = this.props;
    switch (true) {
      case isCricket(event) && isInternalEvent(event) && !isMultipartyEvent(event):
        return (
          <CricketInternalResults
            event={event}
            onSaveClick={this.onCricketResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isCricket(event) && isHousesEvent(event) && !isMultipartyEvent(event):
        return (
          <CricketInternalHousesResults
            event={event}
            onSaveClick={this.onCricketResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isCricket(event) && isInterSchoolsEvent(event) && !isMultipartyEvent(event):
        return (
          <CricketExternalSchoolsResults
            event={event}
            onSaveClick={this.onCricketResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
            user={user}
          />
        );
      case isNonTeamSportEvent(event) && isInternalEvent(event):
        return (
          <IndividualInternalResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isNonTeamSportEvent(event) && isHousesEvent(event):
        return (
          <IndividualInternalHousesResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isNonTeamSportEvent(event) && isInterSchoolsEvent(event):
        return (
          <IndividualExternalSchoolsResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
            user={user}
          />
        );
      case isTeamOrTwoOnTwoSportEvent(event) && isInternalEvent(event):
        return (
          <TeamInternalResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isTeamOrTwoOnTwoSportEvent(event) && isHousesEvent(event):
        return (
          <TeamInternalHousesResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
          />
        );
      case isTeamOrTwoOnTwoSportEvent(event) && isInterSchoolsEvent(event):
        return (
          <TeamExternalSchoolsResults
            event={event}
            onSaveClick={this.onResultsSaveClick}
            onCloseClick={this.onCloseResultsModalClick}
            user={user}
          />
        );
      default:
        return <h1>Coming soon</h1>;
    }
  }

  getExtraPointsView() {
    const { event } = this.state;
    const { user } = this.props;
    switch (true) {
      case isNonTeamSportEvent(event) && isInternalEvent(event):
        return (
          <IndividualInternalExtraPoints
            event={event}
            onSaveClick={this.onExtraPointsSaveClick}
            onCloseClick={this.onCloseExtraPointsModalClick}
          />
        );
      case isNonTeamSportEvent(event) && isHousesEvent(event):
        return (
          <IndividualInternalHousesExtraPoints
            event={event}
            onSaveClick={this.onExtraPointsSaveClick}
            onCloseClick={this.onCloseExtraPointsModalClick}
          />
        );
      case isNonTeamSportEvent(event) && isInterSchoolsEvent(event):
        return (
          <IndividualExternalSchoolsExtraPoints
            event={event}
            user={user}
            onSaveClick={this.onExtraPointsSaveClick}
            onCloseClick={this.onCloseExtraPointsModalClick}
          />
        );
      default:
        return <h1>Coming soon</h1>;
    }
  }

  onExtraPointsSaveClick = scores => {
    const { event } = this.state;
    const { user } = this.props;
    const { id } = event;

    let promises = [];

    promises = promises.concat(
      scores.filter(score => typeof score.scoreId === 'undefined').map(score => createIndividualScore(user, id, score))
    );

    promises = promises.concat(
      scores
        .filter(score => typeof score.scoreId !== 'undefined')
        .map(score => updateIndividualScore(user, id, score.scoreId, score))
    );

    BPromise.all(promises).then(res => {
      this.setState({
        isExtraPointsModalOpen: false,
        isLoading: true
      });
      this.load();
    });
  };

  onCloseExtraPointsModalClick = (): void => {
    this.setState({
      isExtraPointsModalOpen: false
    });
  };

  onCloseResultsModalClick = (): void => {
    this.setState({
      isResultsModalOpen: false
    });
  };

  onCricketResultsSaveClick = (scores: any, cricketResult: SchoolEventCricketResult) => {
    const { event } = this.state;
    const { user } = this.props;
    const { id } = event;

    let promises = [];

    switch (true) {
      case isInternalEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createTeamScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateTeamScore(user, id, score.scoreId, score))
        );

        break;
      case isHousesEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createTeamScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateTeamScore(user, id, score.scoreId, score))
        );

        //houses
        promises = promises.concat(
          scores
            .filter(score => typeof score.houseId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createHouseScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.houseId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateHouseScore(user, id, score.scoreId, score))
        );
        break;
      case isInterSchoolsEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createTeamScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateTeamScore(user, id, score.scoreId, score))
        );

        //schools
        promises = promises.concat(
          scores
            .filter(score => typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createSchoolScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateSchoolScore(user, id, score.scoreId, score))
        );
        break;
      default:
        console.log(scores);
        console.log(cricketResult);
        this.setState({
          isResultsModalOpen: false
        });
    }

    BPromise.all(promises)
      .then(res => {
        if (!isEventStatusFinished(event)) {
          return finishEvent(user, id);
        } else {
          return BPromise.resolve(true);
        }
      })
      .then(res => {
        return createCricketResults(user, id, cricketResult);
      })
      .then(res => {
        this.setState({
          isResultsModalOpen: false,
          isLoading: true
        });
        this.load();
      });
  };

  onResultsSaveClick = (scores, resultsMode?: string, settingData?: any): void => {
    console.log('Initial scores:', JSON.stringify(scores, null, 2));

    const { event } = this.state;
    const { user } = this.props;
    const { id } = event;

    let promises = [];

    switch (true) {
      case isNonTeamSportEvent(event) && isInternalEvent(event):
      case isNonTeamSportEvent(event) && isHousesEvent(event):
      case isNonTeamSportEvent(event) && isInterSchoolsEvent(event):
        promises = promises.concat(
          scores
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              delete scoreData.abbreviationResult;
              return createIndividualScore(user, id, scoreData);
            })
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              delete scoreData.abbreviationResult;
              return updateIndividualScore(user, id, score.scoreId, scoreData);
            })
        );

        BPromise.all(promises)
          .then(res => {
            if (!isEventStatusFinished(event)) {
              return finishEvent(user, id);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof settingData !== 'undefined') {
              return updateEventSetting(user, event.id, settingData);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            this.setState({
              isResultsModalOpen: false,
              isLoading: true
            });
            this.load();
          });
        break;
      case isTeamOrTwoOnTwoSportEvent(event) && isInternalEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createTeamScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateTeamScore(user, id, score.scoreId, score))
        );

        BPromise.all(promises)
          .then(res => {
            if (!isEventStatusFinished(event)) {
              return finishEvent(user, id);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof settingData !== 'undefined') {
              return updateEventSetting(user, event.id, settingData);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof resultsMode !== 'undefined') {
              return setEventResultsMode(user, id, resultsMode);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            this.setState({
              isResultsModalOpen: false,
              isLoading: true
            });
            this.load();
          });
        break;
      case isTeamOrTwoOnTwoSportEvent(event) && isHousesEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createTeamScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateTeamScore(user, id, score.scoreId, score))
        );

        //houses
        promises = promises.concat(
          scores
            .filter(score => typeof score.houseId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createHouseScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.houseId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateHouseScore(user, id, score.scoreId, score))
        );

        BPromise.all(promises)
          .then(res => {
            if (!isEventStatusFinished(event)) {
              return finishEvent(user, id);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof settingData !== 'undefined') {
              return updateEventSetting(user, event.id, settingData);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof resultsMode !== 'undefined') {
              return setEventResultsMode(user, id, resultsMode);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            this.setState({
              isResultsModalOpen: false,
              isLoading: true
            });
            this.load();
          });
        break;
      case isTeamOrTwoOnTwoSportEvent(event) && isInterSchoolsEvent(event):
        //players
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => createIndividualScore(user, id, score))
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => updateIndividualScore(user, id, score.scoreId, score))
        );

        //teams
        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              console.log('Creating Team Score Data:', JSON.stringify(scoreData, null, 2));

              delete scoreData.abbreviationResult;
              return createTeamScore(user, id, scoreData);
            })
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.userId === 'undefined' && typeof score.teamId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              console.log('Creating Team Score Data:', JSON.stringify(scoreData, null, 2));

              delete scoreData.abbreviationResult;
              return updateTeamScore(user, id, score.scoreId, scoreData);
            })
        );

        //schools
        promises = promises.concat(
          scores
            .filter(score => typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId === 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              console.log('Creating Team Score Data:', JSON.stringify(scoreData, null, 2));

              delete scoreData.abbreviationResult;
              return createSchoolScore(user, id, scoreData);
            })
        );

        promises = promises.concat(
          scores
            .filter(score => typeof score.schoolId !== 'undefined')
            .filter(score => typeof score.scoreId !== 'undefined')
            .map(score => {
              let scoreData = {
                ...score,
                richScore: {
                  ...score.richScore,
                  ...(score.abbreviationResult && { abbreviationResult: score.abbreviationResult })
                }
              };
              console.log('Creating Team Score Data:', JSON.stringify(scoreData, null, 2));

              delete scoreData.abbreviationResult;
              return updateSchoolScore(user, id, score.scoreId, scoreData);
            })
        );

        BPromise.all(promises)
          .then(res => {
            if (!isEventStatusFinished(event)) {
              return finishEvent(user, id);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof settingData !== 'undefined') {
              return updateEventSetting(user, event.id, settingData);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            if (typeof resultsMode !== 'undefined') {
              return setEventResultsMode(user, id, resultsMode);
            } else {
              return BPromise.resolve(true);
            }
          })
          .then(res => {
            this.setState({
              isResultsModalOpen: false,
              isLoading: true
            });
            this.load();
          })
          .catch(error => {
            const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
            console.error(error);
            this.setState({
              isLoading: false,
              errorMessage: errorText,
              isErrorModalOpen: true
            });
          });
        break;
      default:
        this.setState({
          isResultsModalOpen: false
        });
    }
  };

  onSubmitEventSettingsForm = (data, editMode, reasonForChange): void => {
    const { user } = this.props;
    const { activeSchool } = user;
    const { event } = this.state;
    const {
      startTime,
      endTime,
      venue,
      leaguePositionsCount,
      photos,
      isStudentCanSubmitResult,
      isStudentCanJoin,
      shortDescription,
      ...rest
    } = data;
    const oldShortDescription = propz.get(event, ['eventDetails', 'shortDescription'], '');
    const isEqualShortDescription = oldShortDescription === shortDescription;
    const isEmptyDescriptions = oldShortDescription === '' && shortDescription === '';
    const detailsData = {
      shortDescription
    };

    const hasStartTimeChanged = Moment(event.startTime).toISOString() !== Moment(startTime).toISOString();
    const hasEndTimeChanged = Moment(event.endTime).toISOString() !== Moment(endTime).toISOString();
    const hasVenueChanged = JSON.stringify(event.venue) !== JSON.stringify(venue);

    console.log('start time: ', hasStartTimeChanged);
    console.log('end time: ', hasEndTimeChanged);
    console.log('venue: ', hasVenueChanged);

    const isNotificationNeeded = hasStartTimeChanged || hasEndTimeChanged || hasVenueChanged;
    console.log('Is notification needed: ', isNotificationNeeded);

    const isEventUpdateNotificationCustomMessageEnabled = propz.get(
      user,
      ['activeSchool', 'isEventUpdateNotificationCustomMessageEnabled'],
      false
    );

    const notificationMode = isNotificationNeeded
      ? isEventUpdateNotificationCustomMessageEnabled
        ? EVENT_NOTIFICATION_MODE.MANUAL
        : EVENT_NOTIFICATION_MODE.AUTO
      : EVENT_NOTIFICATION_MODE.NONE;

    console.log('Sending data with notificationMode:', notificationMode, 'data:', data);

    const body =
      editMode === CLUB_EVENT_EDIT_MODE.SINGLE
        ? {
            ...rest,
            photos,
            venue,
            isStudentCanSubmitResult,
            isStudentCanJoin,
            startTime: Moment(startTime).toISOString(),
            endTime: Moment(endTime).toISOString(),
            leaguePositionsCount: Number(leaguePositionsCount),
            reasonForChange,
            shortDescription
          }
        : {
            ...rest,
            venue,
            startTime: Moment(startTime).toISOString(),
            endTime: Moment(endTime).toISOString(),
            reasonForChange,
            shortDescription
          };

    switch (true) {
      case typeof venue !== 'undefined' && typeof venue.point !== 'undefined' && venue.point.coordinates.length > 0:
        if (typeof venue.postcodeId === 'undefined') {
          body.venue.postcodeId = venue.id;
        }
        body.venue.venueType = getVenueTypeByPostcode(venue, activeSchool);
        break;
      case typeof venue !== 'undefined' && typeof venue.point === 'undefined':
        body.venue.venueType = getVenueTypeByPostcode(venue, activeSchool);
        break;
      default:
        delete body.venue;
    }
    //TODO: fix it
    if (typeof venue.id !== 'undefined') {
      delete body.venue.id;
    }
    if (typeof venue.isHome !== 'undefined') {
      delete body.venue.isHome;
    }
    if (typeof venue.name !== 'undefined') {
      delete body.venue.name;
    }
    if (typeof venue.postcode !== 'undefined') {
      delete body.venue.postcode;
    }

    this.setState({
      isLoading: true
    });

    let currentActionDescriptorId;
    const singleEditMode = editMode === CLUB_EVENT_EDIT_MODE.SINGLE;
    const getEditEventService = singleEditMode
      ? updateSchoolEvent(user, event.id, body, notificationMode)
      : updateGroupSchoolClubEvents(user, event.id, body, notificationMode);

    getEditEventService
      .then(res => {
        currentActionDescriptorId = propz.get(res, ['headers', 'action-descriptor-id']);
        if (!isEqualShortDescription && !isEmptyDescriptions) {
          return singleEditMode
            ? updateSchoolEventDetails(user, event.id, detailsData)
            : updateGroupSchoolEventDetails(user, event.id, detailsData);
        } else {
          return BPromise.resolve(undefined);
        }
      })
      .then(res => {
        const isExistActionDescriptor = typeof currentActionDescriptorId !== 'undefined';

        if (isNotificationNeeded && isEventUpdateNotificationCustomMessageEnabled && isExistActionDescriptor) {
          return this.updateActionDescriptors([currentActionDescriptorId], reasonForChange);
        } else {
          this.setState({
            isEditableEventSettings: false,
            actionDescriptorId: undefined,
            isLoading: false
          });
          this.load();
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  onCancelEventSettingsButtonClick = (): void => {
    this.setState({
      isEditableEventSettings: false
    });
  };

  onEditEventSettingsButtonClick = (): void => {
    this.setState({
      isEditableEventSettings: true
    });
  };

  cancelEventModalOpen = () => {
    this.setState({
      isCancelEventModalOpen: true,
      cancelReason: ''
    });
  };

  cancelEventModalClose = (): void => {
    this.setState({
      isCancelEventModalOpen: false
    });
  };

  uncancelEventModalOpen = () => {
    this.setState({
      isUncancelEventModalOpen: true
    });
  };

  uncancelEventModalClose = (): void => {
    this.setState({
      isUncancelEventModalOpen: false
    });
  };

  deleteEventModalOpen = () => {
    this.setState({
      isDeleteEventModalOpen: true
    });
  };

  deleteSuccessEventModalClose = (): void => {
    const { history } = this.props;
    this.setState({
      isSuccessDeleteEventModalOpen: false
    });
    history.push({
      pathname: '/events'
    });
  };

  deleteEventModalClose = () => {
    this.setState({
      isDeleteEventModalOpen: false
    });
  };

  renderResultsModal() {
    const { isResultsModalOpen, event } = this.state;
    const resultsView = this.getResultsView();
    return (
      <SimpleModal
        customClass={isCricket(event) && !isMultipartyEvent(event) ? 'modal-lg' : 'mSchoolsResultsModalWidth'}
        isOpen={isResultsModalOpen}
      >
        {resultsView}
      </SimpleModal>
    );
  }

  renderExtraPointsModal() {
    const { isExtraPointsModalOpen } = this.state;
    const extraPointsView = this.getExtraPointsView();
    return (
      <SimpleModal customClass="mSchoolsResultsModalWidth" isOpen={isExtraPointsModalOpen}>
        {extraPointsView}
      </SimpleModal>
    );
  }

  onCloseVideoModal = () => {
    this.setState({
      isVideoModalOpen: false
    });
  };

  renderVideoModal() {
    const { isVideoModalOpen } = this.state;
    return (
      <SimpleModal
        title={'Add video'}
        body={'Success!'}
        buttonText={'Ok'}
        onButtonClick={this.onCloseVideoModal}
        isOpen={isVideoModalOpen}
      />
    );
  }

  renderEventSettingModal() {
    const { isEditableEventSettings, event, forms } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal
        customClass="mSchoolEventEditSettingsFormWidth"
        isOpen={isEditableEventSettings}
        title={'Edit event'}
      >
        <SchoolEventsEditSettingsForm
          user={user}
          schoolEvent={event}
          forms={forms}
          onCancel={this.onCancelEventSettingsButtonClick}
          onSubmit={this.onSubmitEventSettingsForm}
        />
      </SimpleModal>
    );
  }

  renderMapModal() {
    const { isMapModalOpen, event } = this.state;
    const { venue } = event;

    const point = getNewPoint(venue);
    const { coordinates } = point;
    const isCoordinatesExist = typeof coordinates !== 'undefined';

    if (isCoordinatesExist) {
      const [latitude, longitude] = coordinates;

      return (
        <SimpleModal
          isOpen={isMapModalOpen}
          title={'Venue'}
          buttonCancelText={'Close'}
          onCloseClick={this.closeMapModal}
        >
          <Map point={point} customStylingClass="mb-3 eClubWizardMap" isMarkerDraggable={false} />
          <div>
            <a
              target="_blank"
              href={`http://maps.google.com/maps?q=${longitude},${latitude}+(My+Point)&z=14&ll=${longitude},${latitude}`}
            >
              View on Google Maps
            </a>
          </div>
        </SimpleModal>
      );
    }
  }

  openMapModal = () => {
    this.setState({
      isMapModalOpen: true
    });
  };

  closeMapModal = () => {
    this.setState({
      isMapModalOpen: false
    });
  };

  onSubmitAddSchoolForm = (schoolId: string): void => {
    const { user } = this.props;
    const { event } = this.state;
    const { id } = event;

    this.setState({
      isLoading: true
    });

    addSchoolInEvent(user, id, schoolId).then(eventUpdated => {
      this.setState({
        isLoading: false,
        event: eventUpdated,
        isAddSchoolModalOpen: false
      });

      this.load();
    });
  };

  onCancelAddSchoolButtonClick = () => {
    this.setState({
      isAddSchoolModalOpen: false
    });
  };

  renderAddSchoolModal() {
    const { isAddSchoolModalOpen, event } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isAddSchoolModalOpen} title={'Add school'}>
        <SchoolEventAddSchoolForm
          event={event}
          user={user}
          onCancel={this.onCancelAddSchoolButtonClick}
          onSubmit={this.onSubmitAddSchoolForm}
        />
      </SimpleModal>
    );
  }

  renderRemoveParticipantModal() {
    const { isRemoveParticipantModalOpen, participant } = this.state;
    const participantTeam = propz.get(participant, ['team']);
    const participantTeamName = propz.get(participant, ['team', 'name']);
    const participantSchoolName = propz.get(participant, ['school', 'name']);

    const body = participantTeam
      ? `Are you sure you want to remove ${participantTeamName} from ${participantSchoolName}`
      : `Are you sure you want to remove opponent ${participantSchoolName}`;

    return (
      <SimpleModal
        isOpen={isRemoveParticipantModalOpen}
        title={'Remove participant'}
        onCloseClick={this.onCancelRemoveParticipantClick}
        onButtonClick={this.onRemoveParticipantModalClick}
        buttonText={'Remove'}
        body={body}
      />
    );
  }

  onCancelSentConsentRequestClick = () => {
    this.setState({
      isSendConsentRequestModalOpen: false
    });
  };

  renderSentConsentRequestModal() {
    const { isSendConsentRequestModalOpen, consentRequestTemplate } = this.state;

    return (
      <SimpleModal
        customClass="mSchoolConsentRequestFormWidth"
        isOpen={isSendConsentRequestModalOpen}
        title={'Send consent requests'}
      >
        <div>
          Here you can select questions that parents will be asked to answer when sending their parental consent. You
          can set up your questions on your school console.{' '}
        </div>
        <ConsentRequestForm
          template={consentRequestTemplate}
          onCancelClick={this.onCancelSentConsentRequestClick}
          onSendConsentRequestsClick={this.onSendConsentRequestClick}
        />
      </SimpleModal>
    );
  }

  onSendConsentRequestClick = (fields: ConsentRequestFormField[], deadlineForAnswers: string) => {
    const { event } = this.state;
    const { id: eventId } = event;
    const { user } = this.props;
    this.setState({
      isLoading: true,
      isSendConsentRequestModalOpen: false
    });

    let data;
    if (deadlineForAnswers) {
      const deadlineForAnswersDate = Moment(deadlineForAnswers, UK_INPUT_MASK_VALIDATION, true).toDate();
      const deadlineForAnswersStr = deadlineForAnswersDate.toISOString();
      data = {
        fields: fields,
        deadlineForAnswers: deadlineForAnswersStr
      };
    } else {
      data = {
        fields: fields
      };
    }

    sendConsentRequest(user, eventId, data).then(res => {
      this.setState({
        isLoading: false,
        isSuccessSendConsentRequestModalOpen: true
      });
    });
  };

  onRemoveParticipantClick = (participant: Participant, isOneParticipant: boolean): void => {
    this.setState({
      isRemoveParticipantModalOpen: true,
      participant: participant,
      isOneParticipant: isOneParticipant
    });
  };

  onCancelRemoveParticipantClick = () => {
    this.setState({
      isRemoveParticipantModalOpen: false,
      participant: undefined,
      isOneParticipant: false
    });
  };

  onCancelEventClick = () => {
    const { user } = this.props;
    const { event, cancelReason } = this.state;
    const eventId = event.id;
    const isEventUpdateNotificationCustomMessageEnabled = propz.get(
      user,
      ['activeSchool', 'isEventUpdateNotificationCustomMessageEnabled'],
      false
    );
    const notificationMode = isEventUpdateNotificationCustomMessageEnabled
      ? EVENT_NOTIFICATION_MODE.MANUAL
      : EVENT_NOTIFICATION_MODE.AUTO;

    this.setState({
      isLoading: true,
      isCancelEventModalOpen: false
    });

    cancelEvent(user, eventId, notificationMode)
      .then(res => {
        const currentActionDescriptorId = propz.get(res, ['headers', 'action-descriptor-id']);
        const updateEvent = res.data;

        if (isEventUpdateNotificationCustomMessageEnabled && cancelReason) {
          const reasonForCancellationPromise = reasonForCancellation(user, eventId, cancelReason);
          return reasonForCancellationPromise.then(() => {
            this.updateActionDescriptors([currentActionDescriptorId], cancelReason);
          });
        } else {
          this.updateActionDescriptors([currentActionDescriptorId]);
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  updateActionDescriptors = (actionDescriptorIds, additionalDescription = '') => {
    const { user } = this.props;
    const promises = actionDescriptorIds
      .filter(id => id)
      .map(actionDescriptorId => {
        return getActionDescriptor(user, actionDescriptorId)
          .then(actionDescriptor => {
            const USERS_COUNT_IN_CHUNK = 30;
            const usersFiltered = [...actionDescriptor.affectedUserList];
            let userChunks = [];

            for (let i = 0; i < usersFiltered.length; i += USERS_COUNT_IN_CHUNK) {
              const chunk = usersFiltered.slice(i, i + USERS_COUNT_IN_CHUNK);
              userChunks.push(chunk);
            }

            const userChunksCount = userChunks.length;

            return BPromise.all(
              userChunks.map((userChunk, index) => {
                const data = {
                  manualConfirmationStatus: index === userChunksCount - 1 ? 'CONFIRMED' : 'AWAITING_CONFIRMATION',
                  usersToNotifyList: userChunk,
                  additionalDescription: additionalDescription
                };
                return updateActionDescriptor(user, actionDescriptor.id, data);
              })
            );
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              console.warn(`ActionDescriptor not found for id: ${actionDescriptorId}`);
            } else {
              const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
              console.error(`Error updating ActionDescriptor ${actionDescriptorId}:`, errorText);
              this.setState({
                isLoading: false,
                errorMessage: errorText,
                isErrorModalOpen: true
              });
            }
          });
      });

    return BPromise.all(promises)
      .then(() => {
        this.setState({
          isLoading: false,
          isEditableEventSettings: false,
          actionDescriptorIds: []
        });
        this.load();
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  renderCancelEventModal() {
    const { isCancelEventModalOpen, cancelReason } = this.state;
    const { user } = this.props;
    const { event } = this.state;
    const eventName = getEventGeneratedName(event, { user: user });
    const isEventUpdateNotificationCustomMessageEnabled = propz.get(
      user,
      ['activeSchool', 'isEventUpdateNotificationCustomMessageEnabled'],
      false
    );

    return (
      <SimpleModal isOpen={isCancelEventModalOpen} title={'Cancel Event'}>
        <div>
          <p>
            Are you sure you want to cancel <b>{eventName}</b> (
            {Moment(event.startTime).format('dddd, MMMM Do YYYY, h:mm a')})?
          </p>
          {isEventUpdateNotificationCustomMessageEnabled && (
            <>
              <p>
                If you need to provide extra details like about the event to be rescheduled, or pitches frozen, etc.,
                specify them in the field below. The reason will be included in the notification sent to team members
                and parents. If you want to cancel event(s) without providing a reason, just keep the field empty.
              </p>
              <label htmlFor="cancelReason">
                <b>Reason for cancellation (optional):</b>
              </label>
              <textarea
                id="cancelReason"
                value={cancelReason}
                placeholder="Please enter a reason for cancellation if applicable"
                onChange={e => this.setState({ cancelReason: e.target.value })}
                className="form-control"
                style={{ width: '100%', height: '100px', marginTop: '10px' }}
              />
            </>
          )}
        </div>
        <div className="modal-footer">
          <Button text="Keep event active" onClick={this.cancelEventModalClose} customClass="btn-secondary" />
          <Button text="Confirm cancellation" onClick={this.onCancelEventClick} />
        </div>
      </SimpleModal>
    );
  }

  onReasonEventSettingsFormSubmit = data => {
    const { actionDescriptorId } = this.state;
    const { user } = this.props;
    const additionalDescription = propz.get(data, ['body'], '');
    const isExistActionDescriptorId = typeof actionDescriptorId !== 'undefined';

    this.setState({
      isLoading: true
    });
    if (isExistActionDescriptorId) {
      getActionDescriptor(user, actionDescriptorId)
        .then(actionDescriptor => {
          const USERS_COUNT_IN_CHUNK = 30;
          const usersFiltered = [...actionDescriptor.affectedUserList];

          let userChunks = [];

          for (let i = 0; i < usersFiltered.length; i += USERS_COUNT_IN_CHUNK) {
            const chunk = usersFiltered.slice(i, i + USERS_COUNT_IN_CHUNK);
            userChunks.push(chunk);
          }
          const userChunksCount = userChunks.length;
          BPromise.all(
            userChunks.map((userChunk, index) => {
              const data = {
                manualConfirmationStatus: index === userChunksCount - 1 ? 'CONFIRMED' : 'AWAITING_CONFIRMATION',
                usersToNotifyList: userChunk,
                additionalDescription: additionalDescription
              };
              return updateActionDescriptor(user, actionDescriptor.id, data);
            })
          ).then(res => {
            this.setState({
              isLoading: false,
              actionDescriptorId: undefined
            });
            return true;
          });
        })
        .catch(error => {
          const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
          console.error(error);
          this.setState({
            isLoading: false,
            errorMessage: errorText,
            isErrorModalOpen: true
          });
        });
      this.load();
    } else {
      console.error('actionDescriptorId is undefined');
      this.setState({
        isLoading: false,
        errorMessage: 'Error',
        isErrorModalOpen: true
      });
    }
  };

  onUncancelEventClick = () => {
    const { user } = this.props;
    const { event } = this.state;
    const eventId = event.id;

    this.setState({
      isLoading: true,
      isUncancelEventModalOpen: false
    });

    uncancelEvent(user, eventId)
      .then(res => {
        this.setState({ isLoading: false });
        this.load();
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  renderUncancelEventModal() {
    const { isUncancelEventModalOpen, event } = this.state;
    const { user } = this.props;
    const eventName = getEventGeneratedName(event, { user });

    return (
      <SimpleModal isOpen={isUncancelEventModalOpen} title={'Restore Event'}>
        <div>
          <p>
            Are you sure you want to revert the cancellation of <b>{eventName}</b>?
          </p>
        </div>
        <div className="modal-footer">
          <Button text="Keep Cancelled" onClick={this.uncancelEventModalClose} customClass="btn-secondary" />
          <Button text="Revert Cancellation" onClick={this.onUncancelEventClick} />
        </div>
      </SimpleModal>
    );
  }

  onDeleteEventClick = () => {
    const { user } = this.props;
    const { event } = this.state;
    const { id } = event;

    this.setState({
      isDeleteEventModalOpen: false,
      isLoading: false
    });

    deleteEvent(user, id)
      .then(res => {
        this.setState({
          isSuccessDeleteEventModalOpen: true
        });
      })

      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });
      });
  };

  renderDeleteEventModal() {
    const { isDeleteEventModalOpen } = this.state;
    const { user } = this.props;
    const { event } = this.state;
    const eventName = getEventGeneratedName(event, { user: user });

    return (
      <SimpleModal
        isOpen={isDeleteEventModalOpen}
        title={'Remove Event'}
        buttonText={'Delete'}
        onButtonClick={this.onDeleteEventClick}
        onCloseClick={this.deleteEventModalClose}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: `Are you sure you want to remove <b>${eventName}</b> (${Moment(event.startTime).format(
              'MMMM Do YYYY, h:mm a'
            )})?`
          }}
        />
      </SimpleModal>
    );
  }

  renderSuccessDeleteEventModal() {
    const { isSuccessDeleteEventModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessDeleteEventModalOpen}
        title={'Success'}
        body={`Event has been successfully removed`}
        buttonText={'Ok'}
        onButtonClick={this.deleteSuccessEventModalClose}
      />
    );
  }

  renderSuccessSendConsentRequestModal() {
    const { isSuccessSendConsentRequestModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessSendConsentRequestModalOpen}
        title={'Success'}
        body={`Consent requests have been successfully sent`}
        buttonText={'Ok'}
        onButtonClick={this.successSendConsentRequestModalClose}
      />
    );
  }

  successSendConsentRequestModalClose = () => {
    this.setState({
      isSuccessSendConsentRequestModalOpen: false
    });
  };

  onSubmitSendNotification = data => {
    const { availableUsers, event } = this.state;
    const { user } = this.props;
    const { title, text, players, parents, staff } = data;

    const users = [].concat(players, parents, staff);
    const usersToPost = availableUsers
      .filter(availableUser => users.some(user => user.value === true && user.name === availableUser.userId))
      .map(user => ({ userId: user.userId, permissionId: user.permissionId }));

    this.setState({
      isLoading: true
    });

    const dataToPost = {
      title,
      text,
      users: usersToPost
    };

    sendEventNotification(user, event.id, dataToPost).then(() => {
      this.setState({
        isLoading: false,
        isSendNotificationModalOpen: false
      });
    });
  };

  onCancelSendNotification = () => {
    this.setState({
      isSendNotificationModalOpen: false
    });
  };

  onSubmitSendNotificationEligibleStudents = data => {
    const { event } = this.state;
    const { user } = this.props;
    const { title, text } = data;

    this.setState({
      isLoading: true
    });

    const dataToPost = {
      title,
      text
    };

    sendEventNotificationEligibleStudents(user, event.id, dataToPost).then(() => {
      this.setState({
        isLoading: false,
        isSendNotificationEligibleStudentsModalOpen: false
      });
    });
  };

  onCancelSendNotificationEligibleStudents = () => {
    this.setState({
      isSendNotificationEligibleStudentsModalOpen: false
    });
  };

  onVideoFileChange = eventDescriptor => {
    const { user } = this.props;
    const { event } = this.state;
    const { id } = event;
    const video = eventDescriptor.target.files[0];
    const { name } = video;
    const safeName = getSafeFileName(name);

    this.setState({
      isLoading: true
    });

    const promises = [getVideoConfig(user), getKey(user)];

    let s3, folderKey, folderName, videoFullName, bucket;

    BPromise.all(promises)
      .then(([videoConfig, keyObj]) => {
        const { accessKey, secretAccessKey, bucketRegion } = videoConfig;
        bucket = videoConfig.bucketName;

        s3 = new AWS.S3({
          region: bucketRegion,
          credentials: {
            accessKeyId: accessKey,
            secretAccessKey: secretAccessKey
          }
        });

        folderKey = keyObj.key;
        folderName = `orig/${folderKey}/`;
        videoFullName = folderName + safeName;

        return s3.putObject({ Key: folderName, Bucket: bucket }).promise();
      })
      .then(res => {
        return s3.putObject({ Key: videoFullName, Bucket: bucket, Body: video }).promise();
      })
      .then(res => {
        const data = {
          key: folderKey,
          fileName: safeName,
          bucketName: bucket
        };
        return addConvertJob(user, data);
      })
      .then(jobObj => {
        const job = jobObj.Job;
        const jobId = job.Id;

        const data = {
          videos: [
            {
              fileName: safeName,
              key: folderKey,
              jobId: jobId
            }
          ]
        };

        return addEventPinnedVideo(user, id, data);
      })
      .then(eventNext => {
        this.setState({
          isLoading: false,
          event: eventNext,
          isVideoModalOpen: true
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isLoading: false,
          errorMessage: JSON.stringify(error),
          isErrorModalOpen: true
        });
      });
  };

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: undefined
    });
  };

  renderError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  renderSendNotificationsModal() {
    const { isSendNotificationModalOpen, availableUsers } = this.state;
    return (
      <SimpleModal customClass={'mSchoolSendNotificationFormWidth'} isOpen={isSendNotificationModalOpen}>
        <SendNotificationsForm
          availableUsers={availableUsers}
          onSubmit={this.onSubmitSendNotification}
          onCancel={this.onCancelSendNotification}
        />
      </SimpleModal>
    );
  }

  renderSendNotificationEligibleStudentsModal() {
    const { isSendNotificationEligibleStudentsModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isSendNotificationEligibleStudentsModalOpen}>
        <SendNotificationEligibleStudentsForm
          onSubmit={this.onSubmitSendNotificationEligibleStudents}
          onCancel={this.onCancelSendNotificationEligibleStudents}
        />
      </SimpleModal>
    );
  }

  renderVideoFileInput() {
    return (
      <input
        className="eAddVideoButton_fileInput"
        type="file"
        onChange={this.onVideoFileChange}
        ref={input => (this.videoFileInput = input)}
      />
    );
  }

  reloadEvent = (tabIndex: number) => {
    const { user } = this.props;
    const eventId = this.getEventId();

    this.setState({
      isLoading: true
    });

    getSchoolEvent(user, eventId).then(event => {
      this.setState({
        isLoading: false,
        event: event,
        tabIndex: tabIndex
      });
    });
  };

  onRemoveParticipantModalClick = (): void => {
    const { event, participant, isOneParticipant } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { team, school, house } = participant;
    const { id: participantSchoolId } = school;
    const isTeamExist = typeof team !== 'undefined';
    const isActiveSchoolParticipant = activeSchoolId === participantSchoolId;
    const eventId = this.getEventId();

    let promises = [];

    if (isNonTeamSportEvent(event)) {
      promises = promises.concat(
        participant.individualPlayers.map(player => removeSchoolEventIndividualPlayer(user, eventId, player.id))
      );
    }

    if (isTeamOrTwoOnTwoSportEvent(event) && isTeamExist) {
      const { id } = team;
      promises = promises.concat(removeSchoolEventTeam(user, eventId, id));
    }

    if (!isActiveSchoolParticipant && isOneParticipant) {
      promises = promises.concat(removeSchoolsFromEvent(user, eventId, [participantSchoolId]));
    }

    if (isHousesEvent(event)) {
      const { id: houseId } = house;
      const houses = event.houses.filter(eventHouseId => houseId !== eventHouseId);
      promises = promises.concat(updateSchoolEvent(user, eventId, { houses: houses }));
    }

    this.setState({
      isLoading: true,
      isRemoveParticipantModalOpen: false,
      participant: undefined,
      isOneParticipant: false
    });

    BPromise.all(promises)
      .then(res => getSchoolEvent(user, eventId))
      .then(event => {
        this.setState({
          isLoading: false,
          event: event
        });

        this.load();
      });
  };

  onChangeSchoolClick = (participant: Participant): void => {
    this.setState({
      isChangeSchoolModalOpen: true,
      participant: participant
    });
  };

  onCancelChangeSchoolButtonClick = () => {
    this.setState({
      isChangeSchoolModalOpen: false,
      participant: undefined
    });
  };

  onSubmitChangeSchoolForm = (schoolId: string) => {
    const { user } = this.props;
    const { event, participant } = this.state;
    const { school } = participant;
    const { id: selectedSchoolId } = school;
    const { id } = event;

    this.setState({
      isLoading: true,
      isChangeSchoolModalOpen: false,
      participant: undefined
    });

    removeSchoolsFromEvent(user, id, [selectedSchoolId])
      .then(res => addSchoolInEvent(user, id, schoolId))
      .then(() => {
        this.load();
      });
  };

  renderChangeSchoolModal() {
    const { isChangeSchoolModalOpen, event, participant } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isChangeSchoolModalOpen} title={'Change school'}>
        <SchoolEventChangeSchoolForm
          event={event}
          user={user}
          participant={participant}
          onCancel={this.onCancelChangeSchoolButtonClick}
          onSubmit={this.onSubmitChangeSchoolForm}
        />
      </SimpleModal>
    );
  }

  onCreateAnotherEventAlertCloseClick = () => {
    this.setState({
      isShowCreateAnotherEventAlert: false
    });
  };

  onCancelTwitterButtonClick = () => {
    this.setState({
      isTwitterModalOpen: false
    });
  };

  onOpenButtonClick = () => {
    this.setState({
      isTwitterModalOpen: true
    });
  };

  isTweetButtonRender() {
    const { user } = this.props;
    const twitterIntegrations = propz.get(user, ['activeSchool', 'integrations', 'twitter'], []);
    const activeRole = propz.get(user, ['activeRole'], '');
    const isActiveRoleStaff = STAFF_LIST.some(role => role === activeRole);

    return isActiveRoleStaff && twitterIntegrations.length > 0;
  }

  onSubmitTweet = values => {
    const { user } = this.props;
    const data = {
      text: values.text
    };

    this.setState({
      isLoading: true
    });

    const twitterId = values.twitterId;

    createTweet(user, twitterId, data).then(res => {
      this.onCancelTwitterButtonClick();
      this.setState({
        bodyText: 'Your tweet has successfully been posted!',
        isLoading: false,
        isPostTweetModalOpen: true
      });
    });
  };

  renderTwitterModal() {
    const { isTwitterModalOpen, event } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isTwitterModalOpen} title={'Create tweet'}>
        <SchoolEventTwitterModal
          event={event}
          user={user}
          onCancel={this.onCancelTwitterButtonClick}
          onSubmit={this.onSubmitTweet}
        />
      </SimpleModal>
    );
  }

  onPostTweetModalClose = () => {
    this.setState({
      isPostTweetModalOpen: false
    });
  };

  renderSuccessPostedModal(): React.ReactNode {
    const { isPostTweetModalOpen, bodyText } = this.state;

    return (
      <SimpleModal
        isOpen={isPostTweetModalOpen}
        title={''}
        body={bodyText}
        buttonText={'Ok'}
        onButtonClick={this.onPostTweetModalClose}
      />
    );
  }

  openBasicTeamSheetReportForm = () => {
    this.setState({
      isBasicTeamSheetReportFormModalOpen: true
    });
  };

  closeBasicTeamSheetReportForm = () => {
    this.setState({
      isBasicTeamSheetReportFormModalOpen: false
    });
  };

  openExtendedTeamSheetReportForm = () => {
    this.setState({
      isExtendedTeamSheetReportFormModalOpen: true
    });
  };

  closeExtendedTeamSheetReportForm = () => {
    this.setState({
      isExtendedTeamSheetReportFormModalOpen: false
    });
  };

  openExtendedParticipantListReportForm = () => {
    this.setState({
      isExtendedParticipantListReportFormModalOpen: true
    });
  };

  closeExtendedParticipantListReportForm = () => {
    this.setState({
      isExtendedParticipantListReportFormModalOpen: false
    });
  };

  openEventConsentRequestsReportForm = () => {
    this.setState({
      isEventConsentRequestsReportFormModalOpen: true
    });
  };

  closeEventConsentRequestsReportForm = () => {
    this.setState({
      isEventConsentRequestsReportFormModalOpen: false
    });
  };

  openEventAvailabilityReportsReportForm = () => {
    this.setState({
      isEventAvailabilityReportsReportFormModalOpen: true
    });
  };

  closeEventAvailabilityReportsReportForm = () => {
    this.setState({
      isEventAvailabilityReportsReportFormModalOpen: false
    });
  };

  openEventScoreCardReportForm = () => {
    this.setState({
      isEventScoreCardReportFormModalOpen: true
    });
  };

  closeEventScoreCardReportForm = () => {
    this.setState({
      isEventScoreCardReportFormModalOpen: false
    });
  };

  openCautionsDismissalsReportForm = () => {
    this.setState({
      isCautionsDismissalsReportFormModalOpen: true
    });
  };

  closeCautionsDismissalsReportForm = () => {
    this.setState({
      isCautionsDismissalsReportFormModalOpen: false
    });
  };

  openStudentTargetCompletionReportForm = () => {
    this.setState({
      isStudentTargetCompletionReportFormModalOpen: true
    });
  };

  closeStudentTargetCompletionReportForm = () => {
    this.setState({
      isStudentTargetCompletionReportFormModalOpen: false
    });
  };

  openPaperAttendanceRegisterEventReportForm = () => {
    this.setState({
      isPaperAttendanceRegisterEventReportFormModalOpen: true
    });
  };

  closePaperAttendanceRegisterEventReportForm = () => {
    this.setState({
      isPaperAttendanceRegisterEventReportFormModalOpen: false
    });
  };

  renderBasicTeamSheetReportFormModal() {
    const { user } = this.props;
    const { isBasicTeamSheetReportFormModalOpen, event } = this.state;
    const { activeSchoolId } = user;

    return (
      <SimpleModal isOpen={isBasicTeamSheetReportFormModalOpen} title="Basic team sheet">
        <BasicTeamSheetReportForm
          event={event}
          activeSchoolId={activeSchoolId}
          onCloseClick={this.closeBasicTeamSheetReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderExtendedTeamSheetReportFormModal() {
    const { user } = this.props;
    const { isExtendedTeamSheetReportFormModalOpen, event } = this.state;
    const { activeSchoolId } = user;

    return (
      <SimpleModal isOpen={isExtendedTeamSheetReportFormModalOpen}>
        <ExtendedTeamSheetReportForm
          event={event}
          activeSchoolId={activeSchoolId}
          onCloseClick={this.closeExtendedTeamSheetReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderExtendedParticipantListReportFormModal() {
    const { user } = this.props;
    const { isExtendedParticipantListReportFormModalOpen, event } = this.state;
    const { activeSchoolId } = user;

    return (
      <SimpleModal isOpen={isExtendedParticipantListReportFormModalOpen}>
        <ExtendedParticipantListReportForm
          event={event}
          activeSchoolId={activeSchoolId}
          onCloseClick={this.closeExtendedParticipantListReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderEventConsentRequestsReportFormModal() {
    const { isEventConsentRequestsReportFormModalOpen, event } = this.state;
    const { user } = this.props;
    const { activeSchoolId } = user;

    return (
      <SimpleModal isOpen={isEventConsentRequestsReportFormModalOpen}>
        <EventConsentRequestsReportForm
          onCloseClick={this.closeEventConsentRequestsReportForm}
          onSubmit={this.onSubmitReportForm}
          activeSchoolId={activeSchoolId}
        />
      </SimpleModal>
    );
  }

  renderEventAvailabilityReportsReportFormModal() {
    const { isEventAvailabilityReportsReportFormModalOpen, event } = this.state;

    return (
      <SimpleModal isOpen={isEventAvailabilityReportsReportFormModalOpen}>
        <EventAvailabilityReportsReportForm
          onCloseClick={this.closeEventAvailabilityReportsReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderEventScoreCardReportFormModal() {
    const { user } = this.props;
    const { isEventScoreCardReportFormModalOpen, event } = this.state;
    const { activeSchoolId } = user;

    return (
      <SimpleModal isOpen={isEventScoreCardReportFormModalOpen} title="Event score card">
        <EventScoreCardReportForm
          event={event}
          activeSchoolId={activeSchoolId}
          onCloseClick={this.closeEventScoreCardReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderCautionsDismissalsReportFormModal() {
    const { user } = this.props;
    const { isCautionsDismissalsReportFormModalOpen, event } = this.state;
    const { activeSchoolId } = user;

    return (
      <SimpleModal isOpen={isCautionsDismissalsReportFormModalOpen} title="Cautions & Dismissals">
        <CautionsDismissalsReportForm
          event={event}
          activeSchoolId={activeSchoolId}
          onCloseClick={this.closeCautionsDismissalsReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderStudentTargetCompletionReportFormModal() {
    const { isStudentTargetCompletionReportFormModalOpen, event } = this.state;

    return (
      <SimpleModal isOpen={isStudentTargetCompletionReportFormModalOpen}>
        <StudentTargetCompletionReportForm
          onCloseClick={this.closeStudentTargetCompletionReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  renderPaperAttendanceRegisterEventReportFormModal() {
    const { user } = this.props;
    const { isPaperAttendanceRegisterEventReportFormModalOpen, event } = this.state;
    const { activeSchoolId } = user;

    return (
      <SimpleModal isOpen={isPaperAttendanceRegisterEventReportFormModalOpen}>
        <PaperAttendanceRegisterEventReportForm
          event={event}
          activeSchoolId={activeSchoolId}
          onCloseClick={this.closePaperAttendanceRegisterEventReportForm}
          onSubmit={this.onSubmitReportForm}
        />
      </SimpleModal>
    );
  }

  onSubmitReportForm = (data, reportType) => {
    const { user } = this.props;
    const { activeSchoolId } = user;
    const { event, tabIndex } = this.state;
    const {
      isPrintFormName,
      isPrintYearGroup,
      isPrintGender,
      isPrintDOB,
      isPrintHouse,
      isPrintPP,
      isPrintSEN,
      isPrintFSM,
      isPrintPosition,
      isPrintMedicalInfo,
      isPrintNextOfKin,
      isPrintPhotoConsentOptOut,
      isShowAll,
      isPrintFullNames,
      schoolId,
      eventId,
      teamId,
      isPrintDescription,
      isPrintKitNotes,
      isPrintComments,
      isPrintTransportDetails,
      isPrintOppositionContactDetails,
      isPrintMembersOfStaff,
      isPrintEventTimings,
      isPrintPOM,
      isShowCompleted,
      isShowUncompleted,
      isPrintXLSX,
      isPrintPDF,
      isThisEvent,
      isFutureEvents,
      isAllEvents
    } = data;

    let modData;

    switch (reportType) {
      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EXTENDED_TEAM_SHEET:
        this.setState({
          isExtendedTeamSheetReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            eventId: event.id,
            printFormName: isPrintFormName,
            printYearGroup: isPrintYearGroup,
            printGender: isPrintGender,
            printDOB: isPrintDOB,
            printHouse: isPrintHouse,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            printPhotoConsentOptOut: isPrintPhotoConsentOptOut,
            printPosition: isPrintPosition,
            printMedicalInfo: isPrintMedicalInfo,
            printNextOfKin: isPrintNextOfKin,
            printDescription: isPrintDescription,
            printKitNotes: isPrintKitNotes,
            printComments: isPrintComments,
            printTransportDetails: isPrintTransportDetails,
            printOppositionContactDetails: isPrintOppositionContactDetails,
            printMembersOfStaff: isPrintMembersOfStaff,
            printEventTimings: isPrintEventTimings,
            printXLSX: isPrintXLSX,
            printPDF: isPrintPDF
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EXTENDED_PARTICIPANT_LIST:
        this.setState({
          isExtendedParticipantListReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            eventId: event.id,
            printFormName: isPrintFormName,
            printYearGroup: isPrintYearGroup,
            printGender: isPrintGender,
            printDOB: isPrintDOB,
            printHouse: isPrintHouse,
            printPP: isPrintPP,
            printSEN: isPrintSEN,
            printFSM: isPrintFSM,
            printPhotoConsentOptOut: isPrintPhotoConsentOptOut,
            printPosition: isPrintPosition,
            printMedicalInfo: isPrintMedicalInfo,
            printNextOfKin: isPrintNextOfKin,
            printDescription: isPrintDescription,
            printKitNotes: isPrintKitNotes,
            printComments: isPrintComments,
            printTransportDetails: isPrintTransportDetails,
            printOppositionContactDetails: isPrintOppositionContactDetails,
            printMembersOfStaff: isPrintMembersOfStaff,
            printEventTimings: isPrintEventTimings,
            printXLSX: isPrintXLSX,
            printPDF: isPrintPDF
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EVENT_CONSENT_REQUESTS:
        this.setState({
          isEventConsentRequestsReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            eventId: event.id,
            printMedicalInfo: isPrintMedicalInfo,
            printNextOfKin: isPrintNextOfKin
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EVENT_AVAILABILITY_REPORTS:
        this.setState({
          isEventAvailabilityReportsReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            eventId: event.id,
            showAll: isShowAll
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.BASIC_TEAM_SHEET:
        this.setState({
          isBasicTeamSheetReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            eventId: event.id,
            teamId: teamId,
            printFullNames: isPrintFullNames,
            printPhotoConsentOptOut: isPrintPhotoConsentOptOut,
            printDescription: isPrintDescription,
            printKitNotes: isPrintKitNotes,
            printComments: isPrintComments,
            printTransportDetails: isPrintTransportDetails,
            printOppositionContactDetails: isPrintOppositionContactDetails,
            printMembersOfStaff: isPrintMembersOfStaff,
            printEventTimings: isPrintEventTimings,
            printXLSX: isPrintXLSX,
            printPDF: isPrintPDF
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.BASIC_TEAM_SHEET_INDIVIDUAL:
        this.setState({
          isBasicTeamSheetReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId,
            eventId,
            printFullNames: isPrintFullNames,
            printPhotoConsentOptOut: isPrintPhotoConsentOptOut,
            printDescription: isPrintDescription,
            printKitNotes: isPrintKitNotes,
            printComments: isPrintComments,
            printTransportDetails: isPrintTransportDetails,
            printOppositionContactDetails: isPrintOppositionContactDetails,
            printMembersOfStaff: isPrintMembersOfStaff,
            printEventTimings: isPrintEventTimings,
            printXLSX: isPrintXLSX,
            printPDF: isPrintPDF
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.EVENT_SCORE_CARD:
        this.setState({
          isEventScoreCardReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            eventId: event.id,
            printPOM: isPrintPOM
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.CAUTIONS_DISMISSALS:
        this.setState({
          isCautionsDismissalsReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            eventId: event.id,
            printFullNames: isPrintFullNames,
            printComments: isPrintComments
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.STUDENT_TARGET_COMPLETION:
        this.setState({
          isStudentTargetCompletionReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            eventId: event.id,
            showCompleted: isShowCompleted,
            showUncompleted: isShowUncompleted
          }
        };
        break;

      case REPORT_TYPE_SERVER_TO_CLIENT_MAPPING.PAPER_ATTENDANCE_REGISTER_EVENT:
        this.setState({
          isPaperAttendanceRegisterEventReportFormModalOpen: false,
          isLoading: true
        });

        modData = {
          reportParamsCSV: {
            schoolId: activeSchoolId,
            eventId: event.id,
            thisEvent: isThisEvent,
            futureEvents: isFutureEvents,
            allEvents: isAllEvents
          }
        };
        break;
    }

    uploadReport(user, reportType, modData)
      .then(data => {
        if (typeof data.reportId !== 'undefined') {
          const reportType = data.reportType;
          this.setState({
            isLoading: false
          });

          let fileType;

          switch (true) {
            case isPrintPDF:
              fileType = REPORT_PRINT_FORMAT.PDF;
              break;
            case isPrintXLSX:
              fileType = REPORT_PRINT_FORMAT.XLSX;
              break;
            default:
              // xlsx format is always available for all reports, pdf - not
              fileType = REPORT_PRINT_FORMAT.XLSX;
              break;
          }

          const url = `${window.apiBase}/public/report/${data.reportId}/reportType/${reportType}/fileType/${fileType}`;
          window.open(url);

          this.reloadEvent(tabIndex);
        }
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');

        this.setState({
          isLoading: false,
          errorMessage: errorText,
          isErrorModalOpen: true
        });

        this.reloadEvent(tabIndex);
      });
  };

  goBack = () => {
    const { event } = this.state;
    const { history, location } = this.props;
    const { state } = location;
    const { prevRoutePath, filter } = state as any;

    const selectedDate = propz.get(event, ['startTime']);

    const isSelectedDateExist = typeof selectedDate !== 'undefined';
    const isFilterExist = typeof filter !== 'undefined';

    const data: any = {
      pathname: prevRoutePath,
      state: {}
    };

    switch (true) {
      case isFilterExist:
        data.state.filter = filter;
        break;

      case isSelectedDateExist:
        data.state.filter = filter;
        break;
    }

    if (isFilterExist) {
      data.state.selectedDate = selectedDate;
    }

    history.push(data);
  };

  render() {
    const {
      isLoading,
      prevEvent,
      nextEvent,
      event,
      isResultsModalOpen,
      isExtraPointsModalOpen,
      isEditableEventSettings,
      isSendNotificationModalOpen,
      isSendNotificationEligibleStudentsModalOpen,
      isVideoModalOpen,
      isErrorModalOpen,
      isAddSchoolModalOpen,
      isRemoveParticipantModalOpen,
      isChangeSchoolModalOpen,
      tabIndex,
      isSendConsentRequestModalOpen,
      isSuccessSendConsentRequestModalOpen,
      isShowCreateAnotherEventAlert,
      participants,
      isExtendedTeamSheetReportFormModalOpen,
      isExtendedParticipantListReportFormModalOpen,
      isEventConsentRequestsReportFormModalOpen,
      isEventAvailabilityReportsReportFormModalOpen,
      isBasicTeamSheetReportFormModalOpen,
      isEventScoreCardReportFormModalOpen,
      isCautionsDismissalsReportFormModalOpen,
      isStudentTargetCompletionReportFormModalOpen,
      isPaperAttendanceRegisterEventReportFormModalOpen
    } = this.state;
    const { user, history, location } = this.props;

    const prevRoutePath = propz.get(location, ['state', 'prevRoutePath']);
    const isPrevRoutePathExist = typeof prevRoutePath !== 'undefined' && prevRoutePath !== '';

    if (isLoading) {
      return <Loader />;
    }

    const activeSchoolId = propz.get(user, ['activeSchoolId'], '');

    const players = propz.get(event, ['players'], []);
    const playersSchoolIds = players.map(player => player.schoolId);

    const isParticipantsExist = participants.length > 0;
    const isPlayersForActiveSchoolExist = playersSchoolIds.includes(activeSchoolId);

    const callbacks = this.getCallbacks();
    const actionItems = getActionList(user, event, callbacks);

    const actionItemsUpdated = getActionListWithSubItems(actionItems);

    const isSportEvent = !isClubEvent(event);

    actionItemsUpdated.push({
      itemText: 'Print forms and Reports',
      isActive: isParticipantsExist,
      subItems: [
        isSportEvent && {
          itemText: 'Basic team sheet',
          isActive: isPlayersForActiveSchoolExist,
          onItemClick: this.openBasicTeamSheetReportForm
        },
        isSportEvent && {
          itemText: 'Extended team sheet',
          isActive: isPlayersForActiveSchoolExist,
          onItemClick: this.openExtendedTeamSheetReportForm
        },
        isClubEvent(event) && {
          itemText: 'Extended participant list',
          isActive: isPlayersForActiveSchoolExist,
          onItemClick: this.openExtendedParticipantListReportForm
        },
        isClubEvent(event) && {
          itemText: 'Paper attendance register',
          isActive: true,
          onItemClick: this.openPaperAttendanceRegisterEventReportForm
        },
        {
          itemText: 'Consent requests summary',
          isActive: isPlayersForActiveSchoolExist,
          onItemClick: this.openEventConsentRequestsReportForm
        },
        {
          itemText: 'Availability reports summary',
          isActive: isPlayersForActiveSchoolExist,
          onItemClick: this.openEventAvailabilityReportsReportForm
        },
        isSportEvent &&
          !isMultipartyEvent(event) && {
            itemText: 'Event score card',
            isActive: true,
            onItemClick: this.openEventScoreCardReportForm
          },
        isSportEvent &&
          !isMultipartyEvent(event) && {
            itemText: 'Cautions & Dismissals',
            isActive: true,
            onItemClick: this.openCautionsDismissalsReportForm
          },
        isLeagueEvent(event) &&
          isIndividualSportEvent(event) && {
            itemText: 'Student Target Completion',
            isActive: isPlayersForActiveSchoolExist,
            onItemClick: this.openStudentTargetCompletionReportForm
          }
      ].filter(item => item)
    });

    const isPrevEventExist = typeof prevEvent !== 'undefined';
    const isNextEventExist = typeof nextEvent !== 'undefined';

    const classes =
      isResultsModalOpen ||
      isEditableEventSettings ||
      isSendNotificationModalOpen ||
      isVideoModalOpen ||
      isSendNotificationEligibleStudentsModalOpen ||
      isErrorModalOpen ||
      isExtraPointsModalOpen ||
      isAddSchoolModalOpen ||
      isRemoveParticipantModalOpen ||
      isChangeSchoolModalOpen ||
      isSendConsentRequestModalOpen ||
      isSuccessSendConsentRequestModalOpen ||
      isExtendedTeamSheetReportFormModalOpen ||
      isExtendedParticipantListReportFormModalOpen ||
      isEventConsentRequestsReportFormModalOpen ||
      isEventAvailabilityReportsReportFormModalOpen ||
      isBasicTeamSheetReportFormModalOpen ||
      isEventScoreCardReportFormModalOpen ||
      isCautionsDismissalsReportFormModalOpen ||
      isStudentTargetCompletionReportFormModalOpen ||
      isPaperAttendanceRegisterEventReportFormModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {this.renderResultsModal()}
        {this.renderExtraPointsModal()}
        {this.renderEventSettingModal()}
        {this.renderCancelEventModal()}
        {this.renderUncancelEventModal()}
        {this.renderDeleteEventModal()}
        {this.renderSuccessDeleteEventModal()}
        {this.renderSendNotificationsModal()}
        {this.renderSendNotificationEligibleStudentsModal()}
        {this.renderVideoModal()}
        {this.renderVideoFileInput()}
        {this.renderError()}
        {this.renderAddSchoolModal()}
        {this.renderChangeSchoolModal()}
        {this.renderRemoveParticipantModal()}
        {this.renderSentConsentRequestModal()}
        {this.renderSuccessSendConsentRequestModal()}
        {this.renderTwitterModal()}
        {this.renderSuccessPostedModal()}
        {this.renderMapModal()}
        {this.renderBasicTeamSheetReportFormModal()}
        {this.renderExtendedTeamSheetReportFormModal()}
        {this.renderExtendedParticipantListReportFormModal()}
        {this.renderEventConsentRequestsReportFormModal()}
        {this.renderEventAvailabilityReportsReportFormModal()}
        {this.renderEventScoreCardReportFormModal()}
        {this.renderCautionsDismissalsReportFormModal()}
        {this.renderStudentTargetCompletionReportFormModal()}
        {this.renderPaperAttendanceRegisterEventReportFormModal()}
        {isShowCreateAnotherEventAlert && (
          <div className={'bCreateOtherEventPanelWrapper'}>
            <SchoolEventsCreateAnotherEventAlert
              history={history}
              onCreateAnotherEventAlertCloseClick={this.onCreateAnotherEventAlertCloseClick}
            />
          </div>
        )}

        {isPrevRoutePathExist && (
          <div>
            <Button text={'← back to calendar'} onClick={this.goBack} customClass={'btn-secondary mr-3 mb-3'} />
          </div>
        )}

        <div className={'d-flex justify-content-between'}>
          <ActionsButton actionItems={actionItemsUpdated} />
          <SchoolEventNavigationButtons
            isPrevButtonDisabled={!isPrevEventExist}
            isNextButtonDisabled={!isNextEventExist}
            onPrevEventClick={this.onPrevEventClick}
            onNextEventClick={this.onNextEventClick}
          />
        </div>
        <SchoolEventMainInformation user={user} event={event} onViewMapClick={this.openMapModal} />
        {this.isTweetButtonRender() && <SchoolEventNewTweetButton onNewTweetButtonClick={this.onOpenButtonClick} />}
        <SchoolEventTabs
          history={history}
          participants={participants}
          user={user}
          eventInitial={event}
          tabIndex={tabIndex}
          reloadEvent={this.reloadEvent}
          onRemoveParticipantClick={this.onRemoveParticipantClick}
          onChangeSchoolClick={this.onChangeSchoolClick}
        />
      </div>
    );
  }
}
