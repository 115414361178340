import * as React from 'react';
import { REGISTRATION_PERMISSION, RegistrationPermission } from '../Registration';
import { useState } from 'react';
import { Button } from '../../../components/Button/Button';

interface Props {
  permission: RegistrationPermission;
  onPermissionClick: (permission: RegistrationPermission) => void;
}

export function RegistrationPermissionSelector(props: Props) {
  const { permission, onPermissionClick } = props;

  const [currentPermission, changePermission] = useState(permission);
  const isCurrentPermissionExist = typeof currentPermission !== 'undefined';

  return (
    <>
      <div className="font-weight-bold" style={{ marginBottom: '2rem' }}>
        Please, select the type of user
      </div>
      <div className="form-check mb-4">
        <input
          className="form-check-input"
          type="radio"
          name="staff"
          id="staff"
          value={REGISTRATION_PERMISSION.STAFF}
          checked={currentPermission === REGISTRATION_PERMISSION.STAFF}
          onChange={() => {
            changePermission(REGISTRATION_PERMISSION.STAFF);
          }}
        />
        <label className="form-check-label" htmlFor="staff">
          I’m a member of staff within my school/association
        </label>
      </div>
      <div className="form-check mb-4">
        <input
          className="form-check-input"
          type="radio"
          name="parent"
          id="parent"
          value={REGISTRATION_PERMISSION.PARENT}
          checked={currentPermission === REGISTRATION_PERMISSION.PARENT}
          onChange={() => {
            changePermission(REGISTRATION_PERMISSION.PARENT);
          }}
        />
        <label className="form-check-label" htmlFor="parent">
          I’m a parent
        </label>
      </div>
      <div className="form-check mb-4">
        <input
          className="form-check-input"
          type="radio"
          name="student"
          id="student"
          value={REGISTRATION_PERMISSION.STUDENT}
          checked={currentPermission === REGISTRATION_PERMISSION.STUDENT}
          onChange={() => {
            changePermission(REGISTRATION_PERMISSION.STUDENT);
          }}
        />
        <label className="form-check-label" htmlFor="student">
          I’m a student
        </label>
      </div>
      <Button
        disabled={!isCurrentPermissionExist}
        onClick={() => {
          onPermissionClick(currentPermission);
        }}
        text={'Continue'}
        customClass={'mt-3 mb-3'}
      />
    </>
  );
}
