import * as React from 'react';
import { FunctionComponent } from 'react';
import { SelectOption } from '../../helpers/table/table';
import * as classNames from 'classnames';
import './MultiselectField.css';

interface Props {
  items: SelectOption[];
  field: string;
  value: string;
  onChange: (event, value: string) => void;
  customClass?: string;
  onEnterPress?: (event) => void;
}

const MAX_SIZE = 3;

export const MultiSelectField: FunctionComponent<Props> = props => {
  const { customClass, onEnterPress } = props;

  const options = props.items.map(item => {
    return (
      <option key={item.value} value={item.value}>
        {item.text}
      </option>
    );
  });

  const value = props.value;

  const isCustomClassExist = typeof customClass !== 'undefined';

  const itemsCount = props.items.length;
  const size = itemsCount < MAX_SIZE ? itemsCount : MAX_SIZE;

  const classes = classNames(
    'form-control',
    {
      'w-auto': !isCustomClassExist,
      mOverflowYAuto: true
    },
    customClass
  );

  return (
    <select
      multiple={true}
      value={value}
      className={classes}
      onChange={event => props.onChange(event, props.field)}
      size={size}
      onKeyDown={onEnterPress}
    >
      {options}
    </select>
  );
};

MultiSelectField.displayName = 'MultiSelectField';
