import axios from 'axios';
import { AppUser } from 'Src/views/App/App';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from 'Src/consts/table';
import { MESSAGE_KIND } from '../../../consts/message';
import { Count } from '../../../models/count';
import { MessageField } from 'Src/models/message';

const DEFAULT_MESSAGES_FILTER: any = {
  allMessageTypes: true
};

const DEFAULT_FILTER: any = {
  limit: DEFAULT_LIMIT,
  skip: DEFAULT_SKIP,
  order: 'createdAt DESC'
};

export function getInboxMessages(user: AppUser, filter = DEFAULT_MESSAGES_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_MESSAGES_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/inbox`, config).then(response => {
    return response.data;
  });
}

export function getArchiveMessages(user: AppUser, filter = DEFAULT_MESSAGES_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...DEFAULT_MESSAGES_FILTER,
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/archive`, config).then(response => {
    return response.data;
  });
}

export function getAllInboxMessages(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/inbox`, config).then(response => {
    return response.data;
  });
}

export function getAllArchiveMessages(user: AppUser, where?) {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          },
          limit: DEFAULT_LIMIT,
          skip: DEFAULT_SKIP
        }
      }
    };
  }

  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/archive`, config).then(response => {
    return response.data;
  });
}

export function getInboxMessagesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          }
        }
      }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/inbox/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getArchiveMessagesCount(user: AppUser, where?): Promise<Count> {
  const { roleSessionKey: key, activeSchoolId } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER,
            ...where
          }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...DEFAULT_MESSAGES_FILTER
          }
        }
      }
    };
  }

  return axios
    .get<Count>(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/archive/count`, config)
    .then(response => {
      return response.data;
    });
}

export function authorizeAbsentMessage(user: AppUser, messageId: string, authorizedComment?: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  let data;

  if (authorizedComment) {
    data = {
      isAuthorized: true,
      authorizedComment: authorizedComment
    };
  } else {
    data = {
      isAuthorized: true
    };
  }

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/${messageId}/authorize`, data, config)
    .then(response => {
      return response.data;
    });
}

export function unAuthorizeAbsentMessage(user: AppUser, messageId: string, authorizedComment?: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  let data;

  if (authorizedComment) {
    data = {
      isAuthorized: false,
      authorizedComment: authorizedComment
    };
  } else {
    data = {
      isAuthorized: false
    };
  }

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/${messageId}/authorize`, data, config)
    .then(response => {
      return response.data;
    });
}

export function getAvailabilityReports(user: AppUser, eventId, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          eventId: eventId,
          $or: [
            { kind: MESSAGE_KIND.EventParticipationMessage },
            { kind: MESSAGE_KIND.EventParticipationRefusalMessage }
          ]
        },
        limit: DEFAULT_FILTER.limit,
        skip: DEFAULT_FILTER.skip,
        order: filter.order || DEFAULT_FILTER.order
      }
    }
  };
  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages`, config).then(response => {
    return response.data;
  });
}

export function getStudentAvailabilityReports(user: AppUser, eventId, filter = DEFAULT_FILTER) {
  const { roleSessionKey: key } = user;
  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          eventId: eventId,
          $or: [
            { kind: MESSAGE_KIND.EventParticipationMessage },
            { kind: MESSAGE_KIND.EventParticipationRefusalMessage }
          ]
        },
        limit: DEFAULT_FILTER.limit,
        skip: DEFAULT_FILTER.skip,
        order: filter.order || DEFAULT_FILTER.order
      }
    }
  };
  return axios.get(`${window.apiBase}/i/events/messages`, config).then(response => {
    return response.data;
  });
}

export function getParentalConsent(user: AppUser, eventId) {
  const { roleSessionKey: key, activeSchoolId } = user;
  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          eventId: eventId,
          kind: MESSAGE_KIND.EventInvitationMessage
        },
        limit: DEFAULT_FILTER.limit,
        skip: DEFAULT_FILTER.skip
      }
    }
  };
  return axios.get(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages`, config).then(response => {
    return response.data;
  });
}

export function doGotItActionForEventMessage(user: AppUser, messageId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/${messageId}/gotit`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function rejectConfirmationRequest(user: AppUser, messageId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/${messageId}/reject`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function acceptConfirmationRequest(user: AppUser, messageId: string) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .post(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/${messageId}/accept`, {}, config)
    .then(response => {
      return response.data;
    });
}

export function setConfirmationRequestFields(user: AppUser, messageId: string, data: MessageField[]) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key }
  };

  return axios
    .put(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/${messageId}`, { fields: data }, config)
    .then(response => {
      return response.data;
    });
}

export function getInboxTournamentConfirmationRequests(user: AppUser, filter = DEFAULT_MESSAGES_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/tournamentConfirmation/inbox`, config)
    .then(response => {
      return response.data;
    });
}

export function getInboxTournamentConfirmationRequestsCount(user: AppUser, where?): Promise<number> {
  const { roleSessionKey: key, activeSchoolId } = user;
  let config;
  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: { ...where }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: { ...DEFAULT_MESSAGES_FILTER }
        }
      }
    };
  }
  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/tournamentConfirmation/inbox/count`, config)
    .then(response => {
      const data = response.data as { count: number };
      return data.count;
    });
}

export function getArchiveTournamentConfirmationRequests(user: AppUser, filter = DEFAULT_MESSAGES_FILTER) {
  const { roleSessionKey: key, activeSchoolId } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter: {
        where: {
          ...filter.where
        },
        skip: filter.skip,
        limit: filter.limit,
        order: filter.order
      }
    }
  };

  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/tournamentConfirmation/archive`, config)
    .then(response => {
      return response.data;
    });
}

export function getArchiveTournamentConfirmationRequestsCount(user: AppUser, where?): Promise<number> {
  const { roleSessionKey: key, activeSchoolId } = user;
  let config;
  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: { ...where }
        }
      }
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: { ...DEFAULT_MESSAGES_FILTER }
        }
      }
    };
  }
  return axios
    .get(`${window.apiBase}/i/schools/${activeSchoolId}/events/messages/tournamentConfirmation/archive/count`, config)
    .then(response => {
      const data = response.data as { count: number };
      return data.count;
    });
}
