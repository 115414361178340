import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';
import './Tabs.css';

interface Props {
  tabs: string[];
  currentTabIndex: number;
  onTabClick: (event: React.MouseEvent<HTMLAnchorElement>, index: number) => void;
  customClass?: string;
}

export const Tabs: FunctionComponent<Props> = ({ tabs, currentTabIndex, onTabClick, customClass }) => {
  const classes = classNames('nav nav-tabs', customClass);

  return (
    <ul className={classes}>
      {tabs.map((tab, index) => {
        const isActive = currentTabIndex === index;
        const tabClassName = classNames('nav-link', { active: isActive });

        return (
          <li key={`tab_${index}`} className="nav-item">
            <a
              className={tabClassName}
              href="#"
              role="tab"
              aria-selected={isActive}
              onClick={event => {
                event.preventDefault();
                onTabClick(event, index);
              }}
            >
              {tab}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

Tabs.displayName = 'Tabs';
