import { FILTER_TYPE, LIMIT, SORT } from '../../consts/table';
import * as Moment from 'moment';
import * as propz from 'propz';
import { ABSENCE_MESSAGE_ACTIONS } from '../../consts/message';
import { DATE_FORMAT } from '../../consts/date';
import { CLUB_STAFF } from '../../consts/club';
import { EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING } from '../../consts/event';
import { GENDER, PENCES_IN_POUND } from '../../consts/common';
import { SCHOOL_GENDER } from '../../consts/school';

export function getPagesCount(total: number, limit: number): number {
  return Math.ceil(total / limit);
}

export type TABLE_SORT_DIRECTION = 'ASC' | 'DESC' | '';

export interface SelectOption {
  text: string | number;
  value: string | number;
}

interface BasicColumnDefinition {
  text: string;
  field: string;
  isSort: boolean;
  type?: string;
  align?: string;
}

export interface PathAccessorColumnDefinition extends BasicColumnDefinition {
  accessor: string[];
}

export interface FunctionAccessorColumnDefinition extends BasicColumnDefinition {
  accessor: (item, options?) => any;
}

export interface CustomCellColumnDefinition extends BasicColumnDefinition {
  cell: (item, options?, onIconClick?) => JSX.Element; // it is jsx. Creator should set key
}

export type ColumnDefinition =
  | PathAccessorColumnDefinition
  | FunctionAccessorColumnDefinition
  | CustomCellColumnDefinition;

export function isPathAccessorBasedColumnDefinition(cd: ColumnDefinition): cd is PathAccessorColumnDefinition {
  return Array.isArray((cd as any).accessor);
}

export function isFunctionAccessorBasedColumnDefinition(cd: ColumnDefinition): cd is FunctionAccessorColumnDefinition {
  return typeof (cd as any).accessor === 'function';
}

export function isCustomCellColumnDefinition(cd: ColumnDefinition): cd is CustomCellColumnDefinition {
  return typeof (cd as any).cell === 'function';
}

export function isFilterExist2(filters): boolean {
  let result = [];
  for (let key in filters) {
    const filterValue = filters[key];
    const isFilterValueArray = Array.isArray(filterValue);
    const isFilterValueString = typeof filterValue === 'string';
    const isFilterValueArrayOrString = isFilterValueArray || isFilterValueString;
    const isFilterValueObject = typeof filterValue === 'object';

    //ex: birthday: {to: '...', from: '...'}
    if (isFilterValueObject && Object.keys(filterValue).length > 0) {
      for (let innerKey in filterValue) {
        result.push(filterValue[innerKey] !== '');
      }
    }

    if (isFilterValueArrayOrString && filterValue.length > 0) {
      result.push(true);
    }
  }

  return result.some(check => check === true);
}

export function isSortExist(sortDirection, sortByField): boolean {
  return sortDirection !== '' && sortByField !== '';
}

export function getServerFieldSectionWhere(filters, options?) {
  let where: any = {
    $and: []
  };

  const isCorrectTime = propz.get<boolean>(options, ['isCorrectTime'], false);
  const isExcludeClubEvents = propz.get<boolean>(options, ['isExcludeClubEvents'], false);
  const isExcludeTournamentEvents = propz.get<boolean>(options, ['isExcludeTournamentEvents'], false);
  const isOnlyTeamEvents = propz.get<boolean>(options, ['isOnlyTeamEvents'], false);
  const isOnlyIndividualEvents = propz.get<boolean>(options, ['isOnlyIndividualEvents'], false);
  const ninEventIds = propz.get<string[]>(options, ['ninEventIds'], []);

  for (let filterField in filters) {
    const isEmptyString = filters[filterField] === '';
    const isEmptyArray = Array.isArray(filters[filterField]) && filters[filterField].length === 0;
    const filterValue = filters[filterField];

    if (!isEmptyString && !isEmptyArray) {
      switch (true) {
        case filterField === 'ages': {
          let formIds;
          if (Array.isArray(filterValue)) {
            formIds = options.forms
              .filter(form => filterValue.some(value => Number(value) === form.age))
              .map(form => form.id);
          } else {
            formIds = options.forms.filter(form => form.age === Number(filterValue)).map(form => form.id);
          }

          if (formIds.length > 0) {
            where['formId'] = { $in: formIds };
          }
          break;
        }

        case filterField === 'agesRecord':
        case filterField === 'teamAges':
        case filterField === 'clubAges':
        case filterField === 'tournamentAges': {
          where['ages'] = { $in: filterValue };
          break;
        }

        case filterField === 'participationStatisticAges': {
          //  filter in ParticipatingStudents.tsx
          const filterFieldNumber = filterValue.map(value => Number(value));
          where['ageGroups'] = { $in: filterFieldNumber };
          break;
        }

        case filterField === 'eventAges': {
          const filterFieldNumber = filterValue.map(value => Number(value));
          where['ages'] = { $in: filterFieldNumber };
          break;
        }

        case filterField === 'inviteStatus': {
          where['status'] = filterValue;
          break;
        }

        case filterField === 'eventType': {
          where['eventType'] = filterValue;
          break;
        }

        case filterField === 'venueType': {
          where['venue.venueType'] = filterValue;
          break;
        }

        case filterField === 'sport':
        case filterField === 'eventSport': {
          where['sportId'] = { $in: filterValue };
          break;
        }

        case filterField === 'aggregationType': {
          where['aggregationType'] = { $in: filterValue };
          break;
        }

        case filterField === 'tag': {
          let tagIds;
          if (Array.isArray(filterValue)) {
            tagIds = filterValue;
          } else {
            tagIds = [filterValue];
          }

          if (tagIds.length > 0) {
            where['permissions.tagIds'] = { $in: tagIds };
          }
          break;
        }

        case filterField === 'tournamentTag': {
          let tournamentTagIds;
          if (Array.isArray(filterValue)) {
            tournamentTagIds = filterValue;
          } else {
            tournamentTagIds = [filterValue];
          }

          if (tournamentTagIds.length > 0) {
            where['tournamentTagIds'] = { $in: tournamentTagIds };
          }
          break;
        }

        case filterField === 'areaSU': {
          let areaSU;
          if (Array.isArray(filterValue)) {
            areaSU = filterValue;
          } else {
            areaSU = [filterValue];
          }

          if (areaSU.length > 0) {
            where['areaSU'] = { $in: areaSU };
          }
          break;
        }

        case filterField === 'availableAges': {
          let availableAges;
          if (Array.isArray(filterValue)) {
            availableAges = filterValue;
          } else {
            availableAges = [filterValue];
          }

          if (availableAges.length > 0) {
            where['availableAges'] = { $in: availableAges };
          }
          break;
        }

        case filterField === 'availableGender': {
          switch (filterValue) {
            case GENDER.MALE_ONLY:
              where['availableGender'] = { $in: [SCHOOL_GENDER.MALE, SCHOOL_GENDER.MIXED] };
              break;
            case GENDER.FEMALE_ONLY:
              where['availableGender'] = { $in: [SCHOOL_GENDER.FEMALE, SCHOOL_GENDER.MIXED] };
              break;
            case GENDER.MIXED:
              where['availableGender'] = { $in: [SCHOOL_GENDER.MALE, SCHOOL_GENDER.FEMALE, SCHOOL_GENDER.MIXED] };
              break;
          }

          break;
        }

        case filterField === 'subtype': {
          switch (filterValue) {
            case EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING.CLUB_EVENT:
              where['$and'].push({
                clubId: { $exists: true },
                tournamentId: { $exists: false }
              });
              break;
            case EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING.TOURNAMENT_EVENT:
              where['$and'].push({
                clubId: { $exists: false },
                tournamentId: { $exists: true }
              });
              break;
            case EVENT_SUB_TYPES_SERVER_TO_CLIENT_MAPPING.FIXTURE_EVENT:
              where['$and'].push({
                clubId: { $exists: false },
                tournamentId: { $exists: false }
              });
              break;
          }
          break;
        }

        case filterField === 'clubWeekDays': {
          let clubWeekDays;
          if (Array.isArray(filterValue)) {
            clubWeekDays = filterValue;
          } else {
            clubWeekDays = [filterValue];
          }

          if (clubWeekDays.length > 0) {
            where['schedule.days'] = { $in: clubWeekDays };
          }
          break;
        }

        case filterField === 'clubRecurrence': {
          let clubRecurrence;
          if (Array.isArray(filterValue)) {
            clubRecurrence = filterValue;
          } else {
            clubRecurrence = [filterValue];
          }

          if (clubRecurrence.length > 0) {
            where['schedule.scheduleType'] = { $in: clubRecurrence };
          }
          break;
        }

        case filterField === 'birthday':
        case filterField === 'sentAt':
        case filterField === 'createdAt':
        case filterField === 'updatedAt':
        case filterField === 'statusSetAt':
        case filterField === 'date': {
          if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
            const toFormatted = Moment(filters[filterField].to, DATE_FORMAT).toDate();
            toFormatted.setHours(23);
            toFormatted.setMinutes(59);

            where[filterField] = { ...where[filterField] };
            where[filterField].$lte = toFormatted.toISOString();
          }
          if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
            const fromFormatted = Moment(filters[filterField].from, DATE_FORMAT).toDate();
            where[filterField] = { ...where[filterField] };
            where[filterField].$gte = fromFormatted.toISOString();
          }
          break;
        }

        case filterField === 'clubStartDate': {
          if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
            const toFormatted = Moment(filters[filterField].to, DATE_FORMAT).toDate();
            toFormatted.setHours(23);
            toFormatted.setMinutes(59);

            propz.set(where, ['schedule.startDate', '$lte'], toFormatted.toISOString());
          }
          if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
            const fromFormatted = Moment(filters[filterField].from, DATE_FORMAT).toDate();
            propz.set(where, ['schedule.startDate', '$gte'], fromFormatted.toISOString());
          }
          break;
        }

        case filterField === 'price': {
          if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
            const to = filters[filterField].to;
            const toFormatted = Number(to) * PENCES_IN_POUND;
            propz.set(where, ['price', '$lte'], toFormatted);
          }
          if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
            const from = filters[filterField].from;
            const fromFormatted = Number(from) * PENCES_IN_POUND;
            propz.set(where, ['price', '$gte'], fromFormatted);
          }
          break;
        }

        case filterField === 'startTime' && isCorrectTime: {
          if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
            const toFormatted = Moment(filters[filterField].to, DATE_FORMAT).toDate();
            toFormatted.setHours(23);
            toFormatted.setMinutes(59);
            propz.set(where, ['startTime', '$lte'], toFormatted.toISOString());
          }
          if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
            const fromFormatted = Moment(filters[filterField].from, DATE_FORMAT).toDate();
            propz.set(where, ['startTime', '$gte'], fromFormatted.toISOString());
          }
          break;
        }

        case filterField === 'endTime' && isCorrectTime: {
          if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
            const toFormatted = Moment(filters[filterField].to, DATE_FORMAT).toDate();
            toFormatted.setHours(23);
            toFormatted.setMinutes(59);
            propz.set(where, ['endTime', '$lte'], toFormatted.toISOString());
          }
          if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
            const fromFormatted = Moment(filters[filterField].from, DATE_FORMAT).toDate();
            propz.set(where, ['endTime', '$gte'], fromFormatted.toISOString());
          }
          break;
        }

        case filterField === 'finishDate': {
          if (typeof filters.finishDate.to !== 'undefined' && filters.finishDate.to !== '') {
            const toFormatted = Moment(filters.finishDate.to, DATE_FORMAT).toDate();
            toFormatted.setHours(23);
            toFormatted.setMinutes(59);
            propz.set(where, ['schedule.finishDate', '$lte'], toFormatted.toISOString());
          }
          if (typeof filters.finishDate.from !== 'undefined' && filters.finishDate.from !== '') {
            const fromFormatted = Moment(filters.finishDate.from, DATE_FORMAT).toDate();
            propz.set(where, ['schedule.finishDate', '$gte'], fromFormatted.toISOString());
          }
          break;
        }

        case filterField === 'season': {
          if (filterValue.length === 1) {
            where['startTime'] = {
              $gte: `${filterValue}-09-01T06:00:00.000Z`,
              $lte: `${Number(filterValue) + 1}-08-31T05:59:00.000Z`
            };
          } else {
            where['$or'] = filterValue.map(value => ({
              startTime: {
                $gte: `${value}-09-01T06:00:00.000Z`,
                $lte: `${Number(value) + 1}-08-31T05:59:00.000Z`
              }
            }));
          }
          break;
        }

        case filterField === 'upcomingTournamentsSeason': {
          const newSeasonStart = new Date(`${filterValue}-09-01T06:00:00.000Z`);
          const currentDate = new Date(new Date().setHours(0, 0, 1));

          const startTimeFrom =
            newSeasonStart > currentDate
              ? `${filterValue}-09-01T06:00:00.000Z`
              : Moment()
                  .hour(0)
                  .minute(0)
                  .second(1)
                  .toISOString();

          if (filterValue.length === 1) {
            where['startTime'] = {
              $gte: startTimeFrom,
              $lte: `${Number(filterValue) + 1}-08-31T05:59:00.000Z`
            };
          } else {
            where['$or'] = filterValue.map(value => ({
              startTime: {
                $gte: `${value}-09-01T06:00:00.000Z`,
                $lte: `${Number(value) + 1}-08-31T05:59:00.000Z`
              }
            }));
          }
          break;
        }

        case filterField === 'pastTournamentsSeason': {
          const currentYear = new Date().getFullYear();
          const todayMidnight = Moment()
            .startOf('day')
            .toISOString();

          const startTimeTo =
            currentYear - 1 === Number(filterValue)
              ? Moment()
                  .endOf('day')
                  .toISOString()
              : `${Number(filterValue) + 1}-08-31T05:59:00.000Z`;

          const cappedStartTimeTo = Moment(startTimeTo).isAfter(todayMidnight) ? todayMidnight : startTimeTo;

          if (filterValue.length === 1) {
            where['startTime'] = {
              $gte: `${filterValue}-09-01T06:00:00.000Z`,
              $lte: cappedStartTimeTo
            };
          } else {
            where['$or'] = filterValue.map(value => {
              const individualStartTimeTo =
                currentYear - 1 === Number(value)
                  ? Moment()
                      .endOf('day')
                      .toISOString()
                  : `${Number(value) + 1}-08-31T05:59:00.000Z`;

              const cappedIndividualStartTimeTo = Moment(individualStartTimeTo).isAfter(todayMidnight)
                ? todayMidnight
                : individualStartTimeTo;

              return {
                startTime: {
                  $gte: `${value}-09-01T06:00:00.000Z`,
                  $lte: cappedIndividualStartTimeTo
                }
              };
            });
          }
          break;
        }

        case filterField === 'clubAcademicYear': {
          if (filterValue.length === 1) {
            where['schedule.startDate'] = {
              $gte: `${filterValue}-09-01T06:00:00.000Z`,
              $lte: `${Number(filterValue) + 1}-08-31T05:59:00.000Z`
            };
          } else {
            const isClubMembersOfStaffFilterExist = typeof filters['clubMembersOfStaff'] === 'undefined';
            const isClubCoachesFilterExist = typeof filters['clubCoaches'] === 'undefined';
            if (!isClubMembersOfStaffFilterExist && !isClubCoachesFilterExist) {
              where['$or'] = filterValue.map(value => ({
                'schedule.startDate': {
                  $gte: `${value}-09-01T06:00:00.000Z`,
                  $lte: `${Number(value) + 1}-08-31T05:59:00.000Z`
                }
              }));
            } else {
              where['$and'].push({
                $or: filterValue.map(value => ({
                  'schedule.startDate': {
                    $gte: `${value}-09-01T06:00:00.000Z`,
                    $lte: `${Number(value) + 1}-08-31T05:59:00.000Z`
                  }
                }))
              });
            }
          }
          break;
        }

        case filterField === 'startDate': {
          if (typeof filters.startDate.to !== 'undefined' && filters.startDate.to !== '') {
            const toFormatted = Moment(filters.startDate.to, DATE_FORMAT).toDate();
            toFormatted.setHours(23);
            toFormatted.setMinutes(59);
            propz.set(where, ['schedule.startDate', '$lte'], toFormatted.toISOString());
          }
          if (typeof filters.startDate.from !== 'undefined' && filters.startDate.from !== '') {
            const fromFormatted = Moment(filters.startDate.from, DATE_FORMAT).toDate();
            propz.set(where, ['schedule.startDate', '$gte'], fromFormatted.toISOString());
          }
          break;
        }

        case filterField === 'eventStartTime': {
          if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
            const toFormatted = Moment(filters[filterField].to, DATE_FORMAT).toDate();
            toFormatted.setHours(23);
            toFormatted.setMinutes(59);
            propz.set(where, ['startTime', '$lte'], toFormatted.toISOString());
          }
          if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
            const fromFormatted = Moment(filters[filterField].from, DATE_FORMAT).toDate();
            propz.set(where, ['startTime', '$gte'], fromFormatted.toISOString());
          }
          break;
        }

        case filterField === 'role': {
          where['permissions.preset'] = { $in: filterValue };
          break;
        }

        case filterField === 'permissionFormIds': {
          where['permissions.formIds'] = { $in: filterValue };
          break;
        }

        case filterField === 'permissionHouseIds': {
          where['permissions.houseIds'] = { $in: filterValue };
          break;
        }

        case filterField === 'clubMembersOfStaff': {
          const isClubCoachesFilterExist = typeof filters['clubCoaches'] !== 'undefined';
          const isClubAcademicYearFilterLengthMoreOne = filters['clubAcademicYear'].length > 1;

          if (!isClubAcademicYearFilterLengthMoreOne && !isClubCoachesFilterExist) {
            where = {
              ...where,
              staff: {
                $elemMatch: {
                  staffRole: CLUB_STAFF.MEMBER_OF_STAFF,
                  $or: [
                    { firstName: { like: filterValue, options: 'i' } },
                    { lastName: { like: filterValue, options: 'i' } }
                  ]
                }
              }
            };
          } else {
            where['$and'].push({
              staff: {
                $elemMatch: {
                  staffRole: CLUB_STAFF.MEMBER_OF_STAFF,
                  $or: [
                    { firstName: { like: filterValue, options: 'i' } },
                    { lastName: { like: filterValue, options: 'i' } }
                  ]
                }
              }
            });
          }
          break;
        }

        case filterField === 'clubCoaches': {
          const isClubMembersOfStaffFilterExist = typeof filters['clubMembersOfStaff'] !== 'undefined';
          const isClubAcademicYearFilterLengthMoreOne = filters['clubAcademicYear'].length > 1;

          if (!isClubAcademicYearFilterLengthMoreOne && !isClubMembersOfStaffFilterExist) {
            where = {
              ...where,
              staff: {
                $elemMatch: {
                  staffRole: CLUB_STAFF.COACH,
                  $or: [
                    { firstName: { like: filterValue, options: 'i' } },
                    { lastName: { like: filterValue, options: 'i' } }
                  ]
                }
              }
            };
          } else {
            where['$and'].push({
              staff: {
                $elemMatch: {
                  staffRole: CLUB_STAFF.COACH,
                  $or: [
                    { firstName: { like: filterValue, options: 'i' } },
                    { lastName: { like: filterValue, options: 'i' } }
                  ]
                }
              }
            });
          }
          break;
        }

        case filterField === 'forms':
        case filterField === 'userFormName': {
          let formIds;
          if (Array.isArray(filterValue)) {
            formIds = filterValue;
          } else {
            formIds = [filterValue];
          }

          if (formIds.length > 0) {
            where['formId'] = { $in: formIds };
          }
          break;
        }

        case filterField === 'houses':
        case filterField === 'userHouseName':
        case filterField === 'studentHouseName': {
          let houseIds;
          if (Array.isArray(filterValue)) {
            houseIds = filterValue;
          } else {
            houseIds = [filterValue];
          }

          if (houseIds.length > 0) {
            where['houseId'] = { $in: houseIds };
          }
          break;
        }

        case filterField === 'gender': {
          let gender;
          if (Array.isArray(filterValue)) {
            gender = filterValue;
          } else {
            gender = [filterValue];
          }

          if (gender.length > 0) {
            where['gender'] = { $in: gender };
          }
          break;
        }

        case filterField === 'category': {
          let category;
          if (Array.isArray(filterValue)) {
            category = filterValue;
          } else {
            category = [filterValue];
          }

          if (category.length > 0) {
            where['category'] = { $in: category };
          }
          break;
        }

        case filterField === 'generalMessageId': {
          where['details.generalMessageId'] = filterValue;
          break;
        }

        case filterField === 'clubMessageStatus':
        case filterField === 'messageStatus': {
          where['messageStatus'] = filterValue;
          break;
        }

        case filterField === 'priority': {
          where['priority'] = filterValue;
          break;
        }

        case filterField === 'messageType': {
          where['kind'] = filterValue;
          break;
        }

        case filterField === 'messageActionStatus': {
          switch (filterValue) {
            case ABSENCE_MESSAGE_ACTIONS.Authorised:
              where['isAuthorized'] = true;
              break;
            case ABSENCE_MESSAGE_ACTIONS.Unauthorised:
              where['isAuthorized'] = false;
              break;
          }
          break;
        }

        case filterField === 'pupilsPremium': {
          const isTrueValue = filterValue === 'true';
          where['pupilsPremium'] = isTrueValue ? isTrueValue : { $nin: true };
          break;
        }

        case filterField === 'specialEducationNeeds': {
          const isTrueValue = filterValue === 'true';
          where['specialEducationNeeds'] = isTrueValue ? isTrueValue : { $nin: true };
          break;
        }

        case filterField === 'freeSchoolMeals': {
          const isTrueValue = filterValue === 'true';
          where['freeSchoolMeals'] = isTrueValue ? isTrueValue : { $nin: true };
          break;
        }

        case filterField === 'participant': {
          where['isParticipant'] = filterValue === 'true';
          break;
        }

        case filterField === 'isPublished': {
          where['isPublished'] = filterValue === 'true';
          break;
        }

        case filterField === 'isPublishOnTheWebsite': {
          where['isPublishOnTheWebsite'] = filterValue === 'true';
          break;
        }

        case filterField === 'isPromoteTournament': {
          where['isPromoteTournament'] = filterValue === 'true';
          break;
        }

        case filterField === 'userStatus':
        case filterField === 'statusOptions':
        case filterField === 'status': {
          where['status'] = { $in: filterValue };
          break;
        }

        case filterField === 'invitationStatus': {
          where['invitationStatus'] = { $in: filterValue };
          break;
        }

        case filterField === 'action': {
          where['action'] = { $in: filterValue };
          break;
        }

        case filterField === 'deliveryStatus': {
          where['deliveryStatus'] = { $in: filterValue };
          break;
        }

        case filterField === 'clubStatus': {
          where['clubStatus'] = { $in: filterValue };
          break;
        }

        case filterField === 'days': {
          where['schedule.days'] = { $in: [filterValue] };
          break;
        }

        case filterField === 'startTime': {
          if (typeof filters.startTime.to !== 'undefined' && filters.startTime.to !== '') {
            where['finishTime'] = filters.startTime.to;
          }
          if (typeof filters.startTime.from !== 'undefined' && filters.startTime.from !== '') {
            where['startTime'] = filters.startTime.from;
          }
          break;
        }

        case filterField === 'ageGroup': {
          let ageGroup;
          if (Array.isArray(filterValue)) {
            ageGroup = filterValue.map(value => Number(value));
          } else {
            ageGroup = [Number(filterValue)];
          }

          if (ageGroup.length > 0) {
            where['age'] = { $in: ageGroup };
          }
          break;
        }

        case filterField === 'email': {
          if (typeof filters['phone'] === 'undefined') {
            where['$or'] = [
              { email: { like: filterValue, options: 'i' } },
              { misEmail: { like: filterValue, options: 'i' } }
            ];
          } else {
            where['$and'].push({
              $or: [{ email: { like: filterValue, options: 'i' } }, { misEmail: { like: filterValue, options: 'i' } }]
            });
          }
          break;
        }

        case filterField === 'userFirstName': {
          where['studentFirstName'] = { like: filterValue, options: 'i' };
          break;
        }

        case filterField === 'userLastName': {
          where['studentLastName'] = { like: filterValue, options: 'i' };
          break;
        }

        case filterField === 'clubName': {
          where['clubName'] = { like: filterValue, options: 'i' };
          break;
        }

        case filterField === 'phone': {
          if (typeof filters['email'] === 'undefined') {
            where['$or'] = [
              { phone: { like: filterValue, options: 'i' } },
              { misPhone: { like: filterValue, options: 'i' } }
            ];
          } else {
            where['$and'].push({
              $or: [{ phone: { like: filterValue, options: 'i' } }, { misPhone: { like: filterValue, options: 'i' } }]
            });
          }
          break;
        }

        case filterField === 'requestedPermissionPreset': {
          where['requestedPermission.preset'] = { $in: filterValue };
          break;
        }

        case filterField === 'eventTournamentGroup': {
          where['tournamentGroupId'] = filterValue;
          break;
        }

        case filterField === 'firstNameClash': {
          where['$or'] = [{ firstName: { like: filterValue, options: 'i' } }];
          break;
        }

        case filterField === 'lastNameClash': {
          where['$or'] = [{ lastName: { like: filterValue, options: 'i' } }];
          break;
        }

        case filterField === 'schoolId': {
          where['schoolId'] = { $in: filterValue };
          break;
        }

        case filterField === 'teamPlayerForm': {
          where['forms'] = { $in: filterValue };
          break;
        }

        case filterField === 'childIdList': {
          where['childIdList'] = filterValue;
          break;
        }

        default: {
          where[filterField] = { like: filterValue, options: 'i' };
        }
      }
    }
  }

  if (where['$and'].length === 0) {
    delete where['$and'];
  }

  if (isExcludeClubEvents) {
    propz.set(where, ['clubId'], { $exists: false });
  }

  if (isExcludeTournamentEvents) {
    propz.set(where, ['tournamentId'], { $exists: false });
  }

  if (isOnlyIndividualEvents) {
    propz.set(where, ['isOnlyIndividualEvents'], true);
  }

  if (isOnlyTeamEvents) {
    propz.set(where, ['isOnlyTeamEvents'], true);
  }

  if (ninEventIds.length > 0) {
    propz.set(where, ['id'], { $nin: ninEventIds });
  }

  return where;
}

export function getPersonListSortedByName(items: any[]): any[] {
  const isItemsExist = items.length > 0;
  const isSchools = isItemsExist && typeof items[0].name !== 'undefined';

  if (isSchools) {
    const sortedSchools: any[] = items.sort((currentSchool, nextSchool) => {
      const currentSchoolName: string = currentSchool.name.toLowerCase();
      const nextSchoolName: string = nextSchool.name.toLowerCase();

      const currentSchoolNameGreater: boolean = currentSchoolName > nextSchoolName;
      const currentSchoolNameLess: boolean = currentSchoolName < nextSchoolName;

      switch (true) {
        case currentSchoolNameGreater:
          return 1;
        case currentSchoolNameLess:
          return -1;

        default:
          return 0;
      }
    });

    return sortedSchools;
  } else {
    const sortedStudents: any[] = items.sort((currentPlayer, nextPlayer) => {
      const currentPlayerLastName: string = currentPlayer.lastName.toLowerCase();
      const nextPlayerLastName: string = nextPlayer.lastName.toLowerCase();
      const currentPlayerFirstName: string = currentPlayer.firstName.toLowerCase();
      const nextPlayerFirstName: string = nextPlayer.firstName.toLowerCase();

      const currentLastNameGreater: boolean = currentPlayerLastName > nextPlayerLastName;
      const currentLastNameLess: boolean = currentPlayerLastName < nextPlayerLastName;
      const lastNameValuesEqual: boolean = currentPlayerLastName === nextPlayerLastName;
      const currentFirstNameGreater: boolean = currentPlayerFirstName > nextPlayerFirstName;
      const currentFirstNameLess: boolean = currentPlayerFirstName < nextPlayerFirstName;

      switch (true) {
        case currentLastNameGreater || (lastNameValuesEqual && currentFirstNameGreater):
          return 1;
        case currentLastNameLess || (lastNameValuesEqual && currentFirstNameLess):
          return -1;

        default:
          return 0;
      }
    });

    return sortedStudents;
  }
}

export function getServerQueryFilter2(currentPage: number, where, order?: string, limit = LIMIT) {
  const isFilter = Object.keys(where).length > 0;
  const isOrderExist = typeof order !== 'undefined';

  switch (true) {
    //sort and filter
    case isOrderExist && isFilter: {
      return {
        limit,
        skip: (currentPage - 1) * limit,
        where: where,
        order: getSortColumnsNameRedefined(order)
      };
    }
    //only sort
    case isOrderExist: {
      return {
        limit,
        skip: (currentPage - 1) * limit,
        order: getSortColumnsNameRedefined(order)
      };
    }
    //only filter
    case isFilter: {
      return {
        limit,
        skip: (currentPage - 1) * limit,
        where: where
      };
    }
    //without sort and without filter
    default:
      return {
        limit,
        skip: (currentPage - 1) * limit
      };
  }
}

export function getSortColumnsNameRedefined(order: string): string {
  let sortColumnsNameRedefined;
  const [sortColumnsName, sortDirection] = order.split(' ');

  switch (sortColumnsName) {
    case 'userFirstName':
      sortColumnsNameRedefined = 'user.firstName';
      break;
    case 'userLastName':
      sortColumnsNameRedefined = 'user.lastName';
      break;
    case 'userHouseName':
      sortColumnsNameRedefined = 'user.houseName';
      break;
    case 'userFormName':
      sortColumnsNameRedefined = 'user.formName';
      break;
    case 'userBirthday':
      sortColumnsNameRedefined = 'user.birthday';
      break;
    case 'club.name':
      sortColumnsNameRedefined = 'clubName';
      break;
    case 'pupilsPremium':
      sortColumnsNameRedefined = 'user.pupilsPremium';
      break;
    case 'specialEducationNeeds':
      sortColumnsNameRedefined = 'user.specialEducationNeeds';
      break;
    case 'freeSchoolMeals':
      sortColumnsNameRedefined = 'user.freeSchoolMeals';
      break;
    case 'venueType':
      sortColumnsNameRedefined = 'venue.venueType';
      break;
    case 'clubStartDate':
      sortColumnsNameRedefined = 'schedule.startDate';
      break;
    case 'requestPreset':
      sortColumnsNameRedefined = 'requestedPermission.preset';
      break;
    case 'requestComment':
      sortColumnsNameRedefined = 'requestedPermission.comment';
      break;
    case 'statusSetAt':
      sortColumnsNameRedefined = 'inviteStatusSetAt';
      break;
    default:
      sortColumnsNameRedefined = sortColumnsName;
  }

  return `${sortColumnsNameRedefined} ${sortDirection}`;
}

export function getOrder(sortField: string, sortDirection: string, sortByField: string): string {
  let order = sortDirection;
  if (sortField !== sortByField) {
    order = SORT.ASC;
  } else {
    order = sortDirection === SORT.ASC ? SORT.DESC : SORT.ASC;
  }

  return order;
}

export function getSearchFilter(filters): string {
  let search = [];

  for (let field in filters) {
    const fieldValue = filters[field];
    const isFieldValueString = typeof fieldValue === 'string';
    const isFieldValueArray = Array.isArray(fieldValue);
    const isFieldValueObject = typeof fieldValue === 'object';

    switch (true) {
      case fieldValue !== '' && isFieldValueString:
        search.push(`${field}=${fieldValue}`);
        break;
      case fieldValue !== '' && isFieldValueArray:
        const subSearch = fieldValue.map(value => `${field}=${value}`).join('&');
        search.push(subSearch);
        break;
      case fieldValue !== '' && isFieldValueObject:
        let addInSearch = false;

        for (let innerField in fieldValue) {
          const innerFieldValue = fieldValue[innerField];
          if (innerFieldValue !== '') {
            addInSearch = true;
          }
        }

        for (let innerField in fieldValue) {
          const innerFieldValue = fieldValue[innerField];
          if (addInSearch) {
            search.push(`${field}=${innerField}_${innerFieldValue}`);
          }
        }
        break;
    }
  }

  return search.filter(val => val !== '').join('&');
}

export function getSearchOrder(sortDirection: string, sortByField: string): string {
  return `order=${sortByField}:${sortDirection}`;
}

export function getColSpanNumber(tableHead): number {
  return tableHead.length + 1;
}

export function getFilters2(columns: ColumnDefinition[], search): any {
  const filters = {};

  const columnsWithFilter = columns.filter(column => column.type !== FILTER_TYPE.NONE);

  columnsWithFilter.forEach(filter => {
    const { type, field } = filter;

    const isFilterTypeMultiSelect = type === FILTER_TYPE.MULTISELECT || type === FILTER_TYPE.CHECKBOX;
    const isFilterTypeCount = type === FILTER_TYPE.COUNT_INTERVAL;
    const searchField = search[field];
    const isSearchFieldUndefined = typeof searchField === 'undefined';
    const isSearchFieldString = typeof searchField === 'string';
    const isSearchFieldArray = Array.isArray(searchField);

    switch (true) {
      case field === 'eventStartTime' && isSearchFieldUndefined:
        const newDateFormatted = Moment(new Date()).format(DATE_FORMAT);
        filters[field] = {
          to: newDateFormatted,
          from: newDateFormatted
        };
        break;
      case isFilterTypeMultiSelect && isSearchFieldUndefined:
        filters[field] = [];
        break;
      case isFilterTypeMultiSelect && isSearchFieldString:
        filters[field] = [searchField];
        break;
      case isFilterTypeMultiSelect && !isSearchFieldString:
        filters[field] = searchField;
        break;
      case isSearchFieldString:
        // query-parse may insert a space instead of a plus, so convert it back for phone/email fields.
        if (field === 'phone' || field === 'email') {
          filters[field] = searchField.replace(' ', '+');
        } else {
          filters[field] = searchField;
        }
        break;
      case isSearchFieldArray:
        const toIndex = (searchField as string[]).findIndex(item => item.indexOf('to') !== -1);
        const fromIndex = (searchField as string[]).findIndex(item => item.indexOf('from') !== -1);
        const to = toIndex >= 0 ? searchField[toIndex] : '';
        const from = fromIndex >= 0 ? searchField[fromIndex] : '';
        filters[field] = {
          to: to ? to.split('_')[1] : '',
          from: from ? from.split('_')[1] : ''
        };
        break;
      case isFilterTypeCount:
      case field === 'birthday':
      case field === 'createdAt':
      case field === 'updatedAt':
      case field === 'sentAt':
      case field === 'date':
      case field === 'finishDate':
      case field === 'startDate':
      case field === 'startTime':
      case field === 'endTime':
      case field === 'eventStartTime':
      case field === 'clubStartDate':
      case field === 'statusSetAt':
      case field === 'tournamentStartDate':
        filters[field] = {
          to: '',
          from: ''
        };
        break;
      case field === 'tournamentName':
        if (isSearchFieldString && searchField.trim() !== '') {
          filters[field] = { like: `%${searchField.trim()}%` };
        } else {
          filters[field] = '';
        }
        break;
      default:
        filters[field] = '';
    }
  });

  return filters;
}

export function getFrom(total: number, currentPage: number, limit?: number): number {
  return total === 0 ? 0 : (currentPage - 1) * (limit || LIMIT) + 1;
}

export function getTo(total: number, currentPage: number, limit?: number): number {
  return currentPage * (limit || LIMIT) < total ? currentPage * (limit || LIMIT) : total;
}
