import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { Component } from 'react';
import { AppUser } from 'Src/views/App/App';
import { History, Location } from 'history';
import { parse } from 'query-string';
import { TwoPanelEditor } from 'Src/components/TwoPanelEditor/TwoPanelEditor';
import { Loader } from 'Src/components/Loader/Loader';
import {
  getTournamentEligibleSchools,
  getTournamentEligibleSchoolsCount
} from 'Src/helpers/service/adminSU/tournamentsTable';
import { DEFAULT_SKIP, FILTER_TYPE } from 'Src/consts/table';
import {
  getTournamentInviteNormalizedStatus,
  getTournamentInviteNormalizedDate
} from 'Src/helpers/twoPanelEditor/twoPanelEditorAccessor';
import { getTwoPanelEditorFilters } from 'Src/helpers/twoPanelEditor/twoPanelEditor';
import { DATE_INTERVAL, LIMIT } from 'Src/consts/table';
import { getSelectOptionForTournamentParticipantsInviteStatus } from 'Src/helpers/twoPanelEditor/twoPanelEditorSelect';
import {
  ColumnDefinition,
  getOrder,
  getServerFieldSectionWhere,
  isFilterExist2,
  TABLE_SORT_DIRECTION
} from 'Src/helpers/table/table';
import {
  addTournamentParticipants,
  getAllTournamentParticipants,
  getTournamentParticipantsCount,
  removeTournamentParticipant
} from 'Src/helpers/service/adminSU/tournamentParticipantsTable';
import { addTournamentTeamsBatch } from 'Src/helpers/service/admin/tournamentTeams';
import { Button } from 'Src/components/Button/Button';
import { getAllTournamentEligibleSchools, getTournament } from 'Src/helpers/service/adminSU/tournamentsTable';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { isIndividualTournament } from 'Src/helpers/tournament/tournament';
import { TEAM_TYPE } from 'Src/consts/team';
import { GENDER } from 'Src/consts/common';
import { AGE_GROUPS } from 'Src/consts/school';
import { TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/tournament';

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}
interface State {
  items: Item[];
  itemsSelected: Item[];
  itemsCount: number;

  itemsFilters: any;
  isShowItemsFilter: boolean;
  isItemsFiltered: boolean;

  isSelectAllItemsChecked: boolean;

  sortItemsDirection: TABLE_SORT_DIRECTION;
  sortItemsColumnsName: string;

  itemCurrentPage: number;

  itemsAccumulatorInitial: Item[];
  itemsAccumulator: Item[];
  itemsAccumulatorSelected: Item[];
  itemsAccumulatorCount: number;

  itemsAccumulatorFilters: any;
  isShowItemsAccumulatorFilter: boolean;
  isItemsAccumulatorFiltered: boolean;

  isSelectAllItemsAccumulatorChecked: boolean;

  sortItemsAccumulatorDirection: TABLE_SORT_DIRECTION;
  sortItemsAccumulatorColumnsName: string;

  tournament: any;

  isLoading: boolean;
  isError: boolean;
  isDeleteParticipantsConfirmationModalOpen: boolean;

  itemsAddBuffer: Item[];
  itemsRemoveBuffer: Item[];
}

interface Item {
  id: string;
  name: string;
  inviteStatus: string;
  inviteStatusSetAt: string;
  isInviteAutoAccepted: string;
  participantId?: string;
}

const ITEMS_ACCUMULATOR_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    accessor: ['name'],
    type: FILTER_TYPE.TEXT,
    isSort: false
  },
  {
    text: 'Invite Status',
    field: 'inviteStatus',
    accessor: getTournamentInviteNormalizedStatus,
    type: FILTER_TYPE.SELECT,
    isSort: false
  },
  {
    text: 'Date',
    field: 'inviteStatusSetAt',
    accessor: getTournamentInviteNormalizedDate,
    type: FILTER_TYPE.NONE,
    isSort: false
  }
];

const ITEMS_COLUMNS: ColumnDefinition[] = [
  {
    text: 'Name',
    field: 'name',
    accessor: ['name'],
    type: FILTER_TYPE.TEXT,
    isSort: true
  },
  {
    text: 'Invite Status',
    field: 'inviteStatus',
    accessor: getTournamentInviteNormalizedStatus,
    type: FILTER_TYPE.SELECT,
    isSort: false
  },
  {
    text: 'Date',
    field: 'inviteStatusSetAt',
    accessor: getTournamentInviteNormalizedDate,
    type: FILTER_TYPE.NONE,
    isSort: true
  }
];

export class TournamentParticipants2 extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      itemsCount: 0,
      itemsSelected: [],

      itemsFilters: {},
      isShowItemsFilter: false,
      isItemsFiltered: false,

      isSelectAllItemsChecked: false,

      sortItemsDirection: '',
      sortItemsColumnsName: '',

      itemCurrentPage: 1,

      itemsAccumulatorInitial: [],
      itemsAccumulator: [],
      itemsAccumulatorCount: 0,
      itemsAccumulatorSelected: [],

      itemsAccumulatorFilters: {},
      isShowItemsAccumulatorFilter: false,
      isItemsAccumulatorFiltered: false,

      isSelectAllItemsAccumulatorChecked: false,

      sortItemsAccumulatorDirection: '',
      sortItemsAccumulatorColumnsName: '',

      tournament: undefined,

      isLoading: false,
      isError: false,
      isDeleteParticipantsConfirmationModalOpen: false,

      itemsAddBuffer: [],
      itemsRemoveBuffer: []
    };
  }

  getTournamentId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const tournamentId = search.tournament;
    return tournamentId;
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    const itemsFilters = getTwoPanelEditorFilters(ITEMS_COLUMNS);
    const isShowItemsFilter = isFilterExist2(itemsFilters);
    const isItemsFiltered = isFilterExist2(itemsFilters);

    const itemsAccumulatorFilters = getTwoPanelEditorFilters(ITEMS_ACCUMULATOR_COLUMNS);
    const isShowItemsAccumulatorFilter = isFilterExist2(itemsAccumulatorFilters);
    const isItemsAccumulatorFiltered = isFilterExist2(itemsAccumulatorFilters);

    let tournament;

    getTournament(user, tournamentId)
      .then(_tournament => {
        tournament = _tournament;
        return this.getItemsAndItemsAccumulator();
      })
      .then(
        ({
          tournamentParticipantsInitial,
          tournamentParticipants,
          tournamentParticipantsCount,
          schools,
          schoolsCount
        }) => {
          const schoolsNormalized = this.getSchoolsNormalized(schools);
          const tournamentParticipantsNormalized = this.getTournamentParticipantsNormalized(tournamentParticipants);
          const tournamentParticipantsInitialNormalized = this.getTournamentParticipantsNormalized(
            tournamentParticipantsInitial
          );

          this.setState({
            items: schoolsNormalized,
            itemsCount: schoolsCount.count,
            itemsFilters: itemsFilters,
            isShowItemsFilter: isShowItemsFilter,
            isItemsFiltered: isItemsFiltered,

            itemsAccumulatorInitial: tournamentParticipantsInitialNormalized,
            itemsAccumulator: tournamentParticipantsNormalized,
            itemsAccumulatorCount: tournamentParticipantsCount.count,
            itemsAccumulatorFilters: itemsAccumulatorFilters,
            isShowItemsAccumulatorFilter: isShowItemsAccumulatorFilter,
            isItemsAccumulatorFiltered: isItemsAccumulatorFiltered,

            tournament: tournament,

            isLoading: false
          });
        }
      );
  }

  getItemsAndItemsAccumulator() {
    const { user } = this.props;
    const { itemsAccumulatorFilters } = this.state;
    const tournamentId = this.getTournamentId();

    const where = getServerFieldSectionWhere(itemsAccumulatorFilters);

    const getTournamentParticipantsInitialPromise = getAllTournamentParticipants(user, tournamentId);
    const getTournamentParticipantsPromise = getAllTournamentParticipants(user, tournamentId, where);
    const getTournamentParticipantsCountPromise = getTournamentParticipantsCount(user, tournamentId, where);

    const promiseAccumulator = [
      getTournamentParticipantsInitialPromise,
      getTournamentParticipantsPromise,
      getTournamentParticipantsCountPromise
    ];

    let tournamentParticipantsInitial, tournamentParticipants, tournamentParticipantsCount;

    return BPromise.all(promiseAccumulator)
      .then(([_tournamentParticipantsInitial, _tournamentParticipants, _tournamentParticipantsCount]) => {
        tournamentParticipantsInitial = _tournamentParticipantsInitial;
        tournamentParticipants = _tournamentParticipants;
        tournamentParticipantsCount = _tournamentParticipantsCount;

        const tournamentParticipantsInitialNormalized = this.getTournamentParticipantsNormalized(
          tournamentParticipantsInitial
        );

        const filter = this.getItemsFilter(1, tournamentParticipantsInitialNormalized, []);

        const getSchoolsPromise = getTournamentEligibleSchools(user, tournamentId, filter);
        const getSchoolsCountPromise = getTournamentEligibleSchoolsCount(user, tournamentId, filter.where);

        const promiseItems = [getSchoolsPromise, getSchoolsCountPromise];

        return BPromise.all(promiseItems);
      })
      .then(([schools, schoolsCount]) => {
        return {
          tournamentParticipantsInitial,
          tournamentParticipants,
          tournamentParticipantsCount,
          schools,
          schoolsCount
        };
      });
  }

  getSchoolsNormalized(schools) {
    return schools.map(school => {
      return {
        id: school.id,
        name: school.name,
        inviteStatus: propz.get(school, ['invite', 'status']),
        inviteStatusSetAt: propz.get(school, ['invite', 'updatedAt']),
        isInviteAutoAccepted: propz.get(school, ['invite', 'isAutoAccepted'])
      };
    });
  }

  getTournamentParticipantsNormalized(tournamentParticipants) {
    return tournamentParticipants.map(tournamentParticipant => {
      return {
        id: tournamentParticipant.schoolId,
        name: tournamentParticipant.school.name,
        inviteStatus: propz.get(tournamentParticipant, ['invite', 'status']),
        inviteStatusSetAt: propz.get(tournamentParticipant, ['invite', 'updatedAt']),
        isInviteAutoAccepted: propz.get(tournamentParticipant, ['invite', 'isAutoAccepted']),
        participantId: tournamentParticipant.id
      };
    });
  }

  onItemClick = (index: number): void => {
    const { items, itemsSelected } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = itemsSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: [],
      itemsSelected: selectedItemsUpdated
    });
  };

  onItemAccumulatorClick = (index: number): void => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;
    const selectedItem = itemsAccumulator[index];

    const selectedItemIndex = itemsAccumulatorSelected.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...itemsAccumulatorSelected];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      itemsAccumulatorSelected: selectedItemsUpdated,
      itemsSelected: []
    });
  };

  onAddClick = () => {
    const { itemsSelected, itemsAddBuffer, itemsAccumulatorInitial, itemCurrentPage } = this.state;
    const { user } = this.props;

    const nextItemsAddBuffer = [...itemsAddBuffer, ...itemsSelected];

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulatorInitial, nextItemsAddBuffer);
    const tournamentId = this.getTournamentId();
    const getSchoolsPromise = getTournamentEligibleSchools(user, tournamentId, filter);
    const getSchoolsCountPromise = getTournamentEligibleSchoolsCount(user, tournamentId, filter.where);
    const promiseItems = [getSchoolsPromise, getSchoolsCountPromise];

    BPromise.all(promiseItems).then(([schools, schoolsCount]) => {
      const schoolsNormalized = this.getSchoolsNormalized(schools);

      this.setState({
        items: schoolsNormalized,
        isLoading: false,
        itemsCount: schoolsCount.count,
        itemsAddBuffer: nextItemsAddBuffer,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false
      });
    });
  };

  onRemoveClick = () => {
    const {
      itemsAccumulatorSelected,
      itemsAddBuffer,
      itemsRemoveBuffer,
      itemsAccumulatorInitial,
      itemsAccumulator,
      itemCurrentPage
    } = this.state;
    const { user } = this.props;

    const nextItemsRemoveBuffer = [...itemsAccumulatorSelected, ...itemsRemoveBuffer];
    const nextItemsAccumulatorInitial = itemsAccumulatorInitial.filter(itemAccumulatorInitial =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulatorInitial.id)
    );
    const nextItemsAccumulator = itemsAccumulator.filter(itemAccumulator =>
      nextItemsRemoveBuffer.every(nextItemRemoveBuffer => nextItemRemoveBuffer.id !== itemAccumulator.id)
    );

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(itemCurrentPage, nextItemsAccumulatorInitial, itemsAddBuffer);
    const tournamentId = this.getTournamentId();
    const getSchoolsPromise = getTournamentEligibleSchools(user, tournamentId, filter);
    const getSchoolsCountPromise = getTournamentEligibleSchoolsCount(user, tournamentId, filter.where);
    const promiseItems = [getSchoolsPromise, getSchoolsCountPromise];

    BPromise.all(promiseItems).then(([schools, schoolsCount]) => {
      const schoolsNormalized = this.getSchoolsNormalized(schools);

      this.setState({
        items: schoolsNormalized,
        isLoading: false,
        itemsCount: schoolsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isSelectAllItemsChecked: false,
        itemsRemoveBuffer: nextItemsRemoveBuffer,
        itemsAccumulatorInitial: nextItemsAccumulatorInitial,
        itemsAccumulator: nextItemsAccumulator,
        itemsAccumulatorCount: nextItemsAccumulator.length
      });
    });
  };

  onClickSave = () => {
    const { itemsAddBuffer, itemsRemoveBuffer, tournament } = this.state;
    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    const isItemsRemoveEmpty = itemsRemoveBuffer.length === 0;
    const isItemsAddEmpty = itemsAddBuffer.length === 0;

    switch (true) {
      case isItemsRemoveEmpty && isItemsAddEmpty:
        {
          this.setState({
            // set default values
            itemsSelected: [],
            itemsFilters: {},
            isShowItemsFilter: false,
            isItemsFiltered: false,
            isSelectAllItemsChecked: false,
            sortItemsDirection: '',
            sortItemsColumnsName: '',
            itemCurrentPage: 1,
            itemsAccumulatorSelected: [],
            itemsAccumulatorFilters: {},
            isShowItemsAccumulatorFilter: false,
            isItemsAccumulatorFiltered: false,
            isSelectAllItemsAccumulatorChecked: false,
            sortItemsAccumulatorDirection: '',
            sortItemsAccumulatorColumnsName: '',
            itemsAddBuffer: [],
            itemsRemoveBuffer: []
          });
        }
        break;
      case isItemsRemoveEmpty:
        {
          this.setState({
            isLoading: true
          });

          const dataToTransfer = { participants: itemsAddBuffer.map(item => item.id) };

          addTournamentParticipants(user, tournamentId, dataToTransfer)
            .then(participants => {
              const schools = participants.map(participant => participant.school);

              return this.createDefaultTournamentTeams(schools);
            })
            .then(() => {
              return this.getItemsAndItemsAccumulator();
            })
            .then(
              ({
                tournamentParticipantsInitial,
                tournamentParticipants,
                tournamentParticipantsCount,
                schools,
                schoolsCount
              }) => {
                const schoolsNormalized = this.getSchoolsNormalized(schools);
                const tournamentParticipantsNormalized = this.getTournamentParticipantsNormalized(
                  tournamentParticipants
                );
                const tournamentParticipantsInitialNormalized = this.getTournamentParticipantsNormalized(
                  tournamentParticipantsInitial
                );

                this.setState({
                  // set default values
                  itemsSelected: [],
                  itemsFilters: {},
                  isShowItemsFilter: false,
                  isItemsFiltered: false,
                  isSelectAllItemsChecked: false,
                  sortItemsDirection: '',
                  sortItemsColumnsName: '',
                  itemCurrentPage: 1,
                  itemsAccumulatorSelected: [],
                  itemsAccumulatorFilters: {},
                  isShowItemsAccumulatorFilter: false,
                  isItemsAccumulatorFiltered: false,
                  isSelectAllItemsAccumulatorChecked: false,
                  sortItemsAccumulatorDirection: '',
                  sortItemsAccumulatorColumnsName: '',
                  itemsAddBuffer: [],
                  itemsRemoveBuffer: [],
                  //set items and items accumulator
                  items: schoolsNormalized,
                  itemsCount: schoolsCount.count,
                  itemsAccumulator: tournamentParticipantsNormalized,
                  itemsAccumulatorInitial: tournamentParticipantsInitialNormalized,
                  itemsAccumulatorCount: tournamentParticipantsCount.count,
                  isLoading: false
                });
              }
            );
        }
        break;
      default: {
        this.setState({
          isDeleteParticipantsConfirmationModalOpen: true
        });
      }
    }
  };

  createDefaultTournamentTeams = schools => {
    const { user } = this.props;
    const { tournament } = this.state;
    const { id: tournamentId, gender, ages, sportId } = tournament;

    const isIndividual = isIndividualTournament(tournament);

    let teamsData = [];

    if (isIndividual) {
      teamsData = schools.map(school => {
        const { id: schoolId, name: schoolName } = school;
        return {
          name: schoolName,
          ages,
          gender,
          sportId,
          schoolId,
          isAutoEnrolledTeam: true,
          teamType: TEAM_TYPE.ADHOC,
          players: []
        };
      });
    } else {
      const teamAge = Array.isArray(ages) ? ages[0] : ages;
      const teamAgeConverted = AGE_GROUPS['U5-U18'][teamAge];

      let baseName = '';
      if (gender === GENDER.MALE_ONLY || gender === GENDER.FEMALE_ONLY) {
        baseName = teamAgeConverted;
      } else {
        baseName = `${teamAgeConverted} ${TOURNAMENT_GENDER_SERVER_TO_CLIENT_MAPPING[gender]}`;
      }

      teamsData = schools.map(school => {
        return {
          name: baseName,
          ages,
          gender,
          sportId,
          schoolId: school.id,
          isAutoEnrolledTeam: true,
          teamType: TEAM_TYPE.ADHOC,
          players: []
        };
      });
    }

    return addTournamentTeamsBatch(user, tournamentId, teamsData);
  };

  onItemsFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsFilters: nextFilters
    });
  };

  onItemsAccumulatorFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.itemsAccumulatorFilters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue
            }
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue
            }
          };
          break;
      }
    } else {
      const filter = ITEMS_COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue
        };
      }
    }

    this.setState({
      itemsAccumulatorFilters: nextFilters
    });
  };

  onItemsFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsFilter } = this.state;

    this.setState({
      isShowItemsFilter: !isShowItemsFilter
    });
  };

  onItemsAccumulatorFilterClick = (event): void => {
    event.preventDefault();

    const { isShowItemsAccumulatorFilter } = this.state;

    this.setState({
      isShowItemsAccumulatorFilter: !isShowItemsAccumulatorFilter
    });
  };

  getItemsFilter(page, itemsAccumulator, itemsAddBuffer, order?) {
    const { itemsFilters } = this.state;

    let where = getServerFieldSectionWhere(itemsFilters);
    const allItems = [...itemsAccumulator, ...itemsAddBuffer];
    const ninIds = allItems.map(item => item.id);
    propz.set(where, ['id', '$nin'], ninIds);

    const isOrderExist = typeof order !== 'undefined';

    const filter = isOrderExist
      ? {
          where: where,
          limit: LIMIT,
          skip: (page - 1) * LIMIT,
          order: order
        }
      : {
          where: where,
          limit: LIMIT,
          skip: (page - 1) * LIMIT
        };

    return filter;
  }

  itemsSetCurrentPageParams = (currentPage: number): void => {
    const { user } = this.props;
    const { itemsAccumulator, sortItemsColumnsName, sortItemsDirection, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const orderForFilter = `${sortItemsColumnsName} ${sortItemsDirection}`;

    const filter = this.getItemsFilter(currentPage, itemsAccumulator, itemsAddBuffer, orderForFilter);
    const tournamentId = this.getTournamentId();

    getTournamentEligibleSchools(user, tournamentId, filter).then(schools => {
      const schoolsNormalized = this.getSchoolsNormalized(schools);

      this.setState({
        itemCurrentPage: currentPage,
        items: schoolsNormalized,
        isLoading: false
      });
    });
  };

  itemsOnApplyFilterClick = () => {
    const { itemsAccumulator, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });

    const filter = this.getItemsFilter(1, itemsAccumulator, itemsAddBuffer);

    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    const getSchoolsPromise = getTournamentEligibleSchools(user, tournamentId, filter);
    const getSchoolsCountPromise = getTournamentEligibleSchoolsCount(user, tournamentId, filter.where);

    const promiseItems = [getSchoolsPromise, getSchoolsCountPromise];

    BPromise.all(promiseItems).then(([schools, schoolsCount]) => {
      const schoolsNormalized = this.getSchoolsNormalized(schools);

      this.setState({
        items: schoolsNormalized,
        isLoading: false,
        itemsCount: schoolsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsDirection: '',
        sortItemsColumnsName: ''
      });
    });
  };

  itemsAccumulatorOnApplyFilterClick = () => {
    const { itemsAccumulatorFilters, itemsRemoveBuffer } = this.state;
    this.setState({
      isLoading: true
    });

    let where = getServerFieldSectionWhere(itemsAccumulatorFilters);
    const ninIds = itemsRemoveBuffer.map(item => item.id);
    propz.set(where, ['id', '$nin'], ninIds);

    const { user } = this.props;

    const tournamentId = this.getTournamentId();

    const getTournamentParticipantsPromise = getAllTournamentParticipants(user, tournamentId, where);
    const getTournamentParticipantsCountPromise = getTournamentParticipantsCount(user, tournamentId, where);

    const promiseItems = [getTournamentParticipantsPromise, getTournamentParticipantsCountPromise];

    BPromise.all(promiseItems).then(([tournamentParticipants, tournamentParticipantsCount]) => {
      const tournamentParticipantsNormalized = this.getTournamentParticipantsNormalized(tournamentParticipants);

      this.setState({
        itemsAccumulator: tournamentParticipantsNormalized,
        isLoading: false,
        itemsAccumulatorCount: tournamentParticipantsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: []
      });
    });
  };

  itemsOnClearFilterClick = () => {
    const { itemsAccumulatorInitial, itemsAddBuffer } = this.state;

    this.setState({
      isLoading: true
    });
    const allItems = [...itemsAccumulatorInitial, ...itemsAddBuffer];
    const ninIds = allItems.map(item => item.id);

    const filter = {
      where: { id: { $nin: ninIds } },
      limit: LIMIT,
      skip: DEFAULT_SKIP
    };

    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    const getSchoolsPromise = getTournamentEligibleSchools(user, tournamentId, filter);
    const getSchoolsCountPromise = getTournamentEligibleSchoolsCount(user, tournamentId, filter.where);

    const promiseItems = [getSchoolsPromise, getSchoolsCountPromise];

    BPromise.all(promiseItems).then(([schools, schoolsCount]) => {
      const schoolsNormalized = this.getSchoolsNormalized(schools);

      this.setState({
        items: schoolsNormalized,
        isLoading: false,
        itemsCount: schoolsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        itemsFilters: {},
        sortItemsColumnsName: '',
        sortItemsDirection: ''
      });
    });
  };

  itemsAccumulatorOnClearFilterClick = () => {
    this.setState({
      isLoading: true
    });

    const { user } = this.props;
    const tournamentId = this.getTournamentId();
    const { itemsRemoveBuffer } = this.state;

    const getTournamentParticipantsPromise = getAllTournamentParticipants(user, tournamentId);
    const getTournamentParticipantsCountPromise = getTournamentParticipantsCount(user, tournamentId);

    const promiseItems = [getTournamentParticipantsPromise, getTournamentParticipantsCountPromise];

    BPromise.all(promiseItems).then(([tournamentParticipants, tournamentParticipantsCount]) => {
      const tournamentParticipantsNormalized = this.getTournamentParticipantsNormalized(tournamentParticipants);
      const nextTournamentParticipantsNormalized = tournamentParticipantsNormalized.filter(
        tournamentParticipantNormalized =>
          itemsRemoveBuffer.every(item => item.id !== tournamentParticipantNormalized.id)
      );

      this.setState({
        itemsAccumulator: nextTournamentParticipantsNormalized,
        isLoading: false,
        itemsAccumulatorCount: tournamentParticipantsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemsAccumulatorFilters: {}
      });
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search, prevPath } = state as any;
    const tournamentId = this.getTournamentId();

    history.push({
      pathname: prevPath,
      search: search,
      state: { tournamentId: tournamentId }
    });
  };

  onSelectAllItemsClick = () => {
    const { itemsSelected, itemsFilters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(itemsFilters);

    const tournamentId = this.getTournamentId();

    getAllTournamentEligibleSchools(user, tournamentId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
      });

      const nextSelectedItems = [...itemsSelected, ...itemsFiltered];

      this.setState({
        itemsSelected: nextSelectedItems,
        isSelectAllItemsChecked: true,
        itemsAccumulatorSelected: [],
        isSelectAllItemsAccumulatorChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsOnPageClick = () => {
    const { items, itemsSelected } = this.state;

    const itemsFiltered = items.filter(item => {
      return itemsSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsSelected, ...itemsFiltered];

    this.setState({
      itemsSelected: nextSelectedItems,
      isSelectAllItemsChecked: true,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onUnselectAllItemsClick = () => {
    this.setState({
      itemsSelected: [],
      isSelectAllItemsChecked: false,
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false
    });
  };

  onSelectAllItemsAccumulatorClick = () => {
    const { itemsAccumulatorSelected, itemsAccumulatorFilters } = this.state;

    this.setState({
      isLoading: true
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(itemsAccumulatorFilters);

    const tournamentId = this.getTournamentId();

    getAllTournamentParticipants(user, tournamentId, where).then(tournamentParticipants => {
      const tournamentParticipantsNormalized = this.getTournamentParticipantsNormalized(tournamentParticipants);

      const tournamentParticipantsFiltered = tournamentParticipantsNormalized.filter(tournamentParticipant => {
        return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== tournamentParticipant.id);
      });

      const nextSelectedItems = [...itemsAccumulatorSelected, ...tournamentParticipantsFiltered];

      this.setState({
        itemsAccumulatorSelected: nextSelectedItems,
        isSelectAllItemsAccumulatorChecked: true,
        itemsSelected: [],
        isSelectAllItemsChecked: false,
        isLoading: false
      });
    });
  };

  onSelectAllItemsAccumulatorOnPageClick = () => {
    const { itemsAccumulator, itemsAccumulatorSelected } = this.state;

    const itemsFiltered = itemsAccumulator.filter(item => {
      return itemsAccumulatorSelected.every(selectedItem => selectedItem.id !== item.id);
    });

    const nextSelectedItems = [...itemsAccumulatorSelected, ...itemsFiltered];

    this.setState({
      itemsAccumulatorSelected: nextSelectedItems,
      isSelectAllItemsAccumulatorChecked: true,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onUnselectAllItemsAccumulatorClick = () => {
    this.setState({
      itemsAccumulatorSelected: [],
      isSelectAllItemsAccumulatorChecked: false,
      itemsSelected: [],
      isSelectAllItemsChecked: false
    });
  };

  onSortItemsClick = (sortField: string) => {
    const { sortItemsDirection, sortItemsColumnsName, itemCurrentPage, itemsAccumulator, itemsAddBuffer } = this.state;

    const order = getOrder(sortField, sortItemsDirection, sortItemsColumnsName) as TABLE_SORT_DIRECTION;
    const orderForFilter = `${sortField} ${order}`;

    const filter = this.getItemsFilter(itemCurrentPage, itemsAccumulator, itemsAddBuffer, orderForFilter);
    const { user } = this.props;
    const tournamentId = this.getTournamentId();
    const getSchoolsPromise = getTournamentEligibleSchools(user, tournamentId, filter);
    const getSchoolsCountPromise = getTournamentEligibleSchoolsCount(user, tournamentId, filter.where);

    const promiseItems = [getSchoolsPromise, getSchoolsCountPromise];

    BPromise.all(promiseItems).then(([schools, schoolsCount]) => {
      const schoolsNormalized = this.getSchoolsNormalized(schools);

      this.setState({
        items: schoolsNormalized,
        isLoading: false,
        itemsCount: schoolsCount.count,
        itemsAccumulatorSelected: [],
        itemsSelected: [],
        itemCurrentPage: 1,
        sortItemsColumnsName: sortField,
        sortItemsDirection: order
      });
    });
  };

  onSortItemsAccumulatorClick = (sortField: string) => {};

  onCloseErrorClick = () => {
    this.setState({
      isError: false
    });
  };

  renderError(): React.ReactNode {
    const { isError } = this.state;
    const errorMessage =
      'The school you are trying to remove is taking part in some events within the tournament. ' +
      'Please, remove those events first to be able to exclude the school from the competition.';

    return (
      <SimpleModal
        isOpen={isError}
        title={'Remove from tournament'}
        body={errorMessage}
        onCloseClick={this.onCloseErrorClick}
        buttonCancelText={'Ok'}
      />
    );
  }

  onCloseDeleteSchoolConfirmationModalClick = () => {
    this.setState({
      isDeleteParticipantsConfirmationModalOpen: false
    });
  };

  onDeleteParticipantModalClick = () => {
    const { itemsRemoveBuffer, itemsAddBuffer } = this.state;
    const { user } = this.props;
    const tournamentId = this.getTournamentId();

    this.setState({
      isLoading: true
    });

    BPromise.each(itemsRemoveBuffer, (item, index) => {
      return removeTournamentParticipant(user, tournamentId, item.participantId);
    })
      .then(res => {
        if (itemsAddBuffer.length > 0) {
          const dataToTransfer = { participants: itemsAddBuffer.map(item => item.id) };
          return addTournamentParticipants(user, tournamentId, dataToTransfer);
        } else {
          return BPromise.resolve(true);
        }
      })
      .catch(err => {
        this.setState({
          // set default values
          itemsSelected: [],
          itemsFilters: {},
          isShowItemsFilter: false,
          isItemsFiltered: false,
          isSelectAllItemsChecked: false,
          sortItemsDirection: '',
          sortItemsColumnsName: '',
          itemCurrentPage: 1,
          itemsAccumulatorSelected: [],
          itemsAccumulatorFilters: {},
          isShowItemsAccumulatorFilter: false,
          isItemsAccumulatorFiltered: false,
          isSelectAllItemsAccumulatorChecked: false,
          sortItemsAccumulatorDirection: '',
          sortItemsAccumulatorColumnsName: '',
          itemsAddBuffer: [],
          itemsRemoveBuffer: [],
          //show error
          isError: true
        });
      })
      .then(res => {
        this.setState({
          // set default values
          itemsSelected: [],
          itemsFilters: {},
          isShowItemsFilter: false,
          isItemsFiltered: false,
          isSelectAllItemsChecked: false,
          sortItemsDirection: '',
          sortItemsColumnsName: '',
          itemCurrentPage: 1,
          itemsAccumulatorSelected: [],
          itemsAccumulatorFilters: {},
          isShowItemsAccumulatorFilter: false,
          isItemsAccumulatorFiltered: false,
          isSelectAllItemsAccumulatorChecked: false,
          sortItemsAccumulatorDirection: '',
          sortItemsAccumulatorColumnsName: '',
          itemsAddBuffer: [],
          itemsRemoveBuffer: []
        });
      })
      .then(res => {
        return this.getItemsAndItemsAccumulator();
      })
      .then(
        ({
          tournamentParticipantsInitial,
          tournamentParticipants,
          tournamentParticipantsCount,
          schools,
          schoolsCount
        }) => {
          const schoolsNormalized = this.getSchoolsNormalized(schools);
          const tournamentParticipantsNormalized = this.getTournamentParticipantsNormalized(tournamentParticipants);
          const tournamentParticipantsInitialNormalized = this.getTournamentParticipantsNormalized(
            tournamentParticipantsInitial
          );

          this.setState({
            //set items and items accumulator
            items: schoolsNormalized,
            itemsCount: schoolsCount.count,
            itemsAccumulator: tournamentParticipantsNormalized,
            itemsAccumulatorInitial: tournamentParticipantsInitialNormalized,
            itemsAccumulatorCount: tournamentParticipantsCount.count,
            isLoading: false,
            isDeleteParticipantsConfirmationModalOpen: false
          });
        }
      );
  };

  renderDeleteParticipantConfirmationModal(): React.ReactNode {
    const { isDeleteParticipantsConfirmationModalOpen } = this.state;
    const { itemsRemoveBuffer } = this.state;

    return (
      <SimpleModal
        isOpen={isDeleteParticipantsConfirmationModalOpen}
        title={'Remove from tournament'}
        onButtonClick={this.onDeleteParticipantModalClick}
        body={`Are you sure you want to remove this ${
          itemsRemoveBuffer.length === 1 ? 'school' : 'schools'
        } from tournament?`}
        buttonText={'Remove'}
        onCloseClick={this.onCloseDeleteSchoolConfirmationModalClick}
      />
    );
  }

  render() {
    const {
      items,
      isLoading,
      itemsSelected,
      itemsAccumulatorSelected,
      itemsAccumulator,
      itemsFilters,
      isShowItemsFilter,
      isItemsFiltered,
      itemsCount,
      itemsAccumulatorCount,
      itemCurrentPage,
      itemsAccumulatorFilters,
      isShowItemsAccumulatorFilter,
      isItemsAccumulatorFiltered,
      tournament,
      isSelectAllItemsChecked,
      isSelectAllItemsAccumulatorChecked,
      sortItemsDirection,
      sortItemsColumnsName,
      sortItemsAccumulatorDirection,
      sortItemsAccumulatorColumnsName,
      itemsAddBuffer,
      itemsRemoveBuffer,
      isError,
      isDeleteParticipantsConfirmationModalOpen
    } = this.state;

    const name = propz.get(tournament, ['name'], '');

    if (isLoading) {
      return <Loader />;
    }

    const itemsFiltersOptions = {
      inviteStatus: getSelectOptionForTournamentParticipantsInviteStatus()
    };

    const itemsAccumulatorFiltersOptions = {
      inviteStatus: getSelectOptionForTournamentParticipantsInviteStatus()
    };

    const classes = isError || isDeleteParticipantsConfirmationModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <>
        <div className={classes}>
          {this.renderError()}
          {this.renderDeleteParticipantConfirmationModal()}
        </div>
        <div className="row">
          <div className="col-md-12">
            <Button text={'← back'} onClick={this.goBack} customClass={'btn-secondary mr-3 mb-3'} />
            <div className="d-inline h1 mb-3">{name}</div>
          </div>
        </div>
        <TwoPanelEditor
          //items
          items={items}
          itemsTitle={'School union schools'}
          itemsColumns={ITEMS_COLUMNS}
          itemsSelected={itemsSelected}
          onItemClick={this.onItemClick}
          //items filters
          onItemsFilterChange={this.onItemsFilterChange}
          itemsFilters={itemsFilters}
          itemsFiltersOptions={itemsFiltersOptions}
          isShowItemsFilter={isShowItemsFilter}
          isItemsFiltered={isItemsFiltered}
          onItemsFilterClick={this.onItemsFilterClick}
          //accumulator
          itemsAccumulator={itemsAccumulator}
          itemsAccumulatorTitle={'Tournament schools'}
          itemsAccumulatorColumns={ITEMS_ACCUMULATOR_COLUMNS}
          onItemAccumulatorClick={this.onItemAccumulatorClick}
          itemsAccumulatorSelected={itemsAccumulatorSelected}
          //accumulator filters
          onItemsAccumulatorFilterChange={this.onItemsAccumulatorFilterChange}
          itemsAccumulatorFilters={itemsAccumulatorFilters}
          itemsAccumulatorFiltersOptions={itemsAccumulatorFiltersOptions}
          isShowItemsAccumulatorFilter={isShowItemsAccumulatorFilter}
          isItemsAccumulatorFiltered={isItemsAccumulatorFiltered}
          onItemsAccumulatorFilterClick={this.onItemsAccumulatorFilterClick}
          //buttons
          onAddClick={this.onAddClick}
          onRemoveClick={this.onRemoveClick}
          //counts
          itemsCount={itemsCount}
          itemsAccumulatorCount={itemsAccumulatorCount}
          //current pages
          itemAccumulatorCurrentPage={1}
          itemCurrentPage={itemCurrentPage}
          itemsSetCurrentPageParams={this.itemsSetCurrentPageParams}
          //items filter buttons
          itemsOnApplyFilterClick={this.itemsOnApplyFilterClick}
          itemsOnClearFilterClick={this.itemsOnClearFilterClick}
          //items accumulator filter buttons
          itemsAccumulatorOnApplyFilterClick={this.itemsAccumulatorOnApplyFilterClick}
          itemsAccumulatorOnClearFilterClick={this.itemsAccumulatorOnClearFilterClick}
          //items select checkbox
          onSelectAllItemsClick={this.onSelectAllItemsClick}
          onSelectAllItemsOnPageClick={this.onSelectAllItemsOnPageClick}
          onUnselectAllItemsClick={this.onUnselectAllItemsClick}
          isSelectAllItemsChecked={isSelectAllItemsChecked}
          //items accumulator select checkbox
          onSelectAllItemsAccumulatorClick={this.onSelectAllItemsAccumulatorClick}
          onSelectAllItemsAccumulatorOnPageClick={this.onSelectAllItemsAccumulatorOnPageClick}
          onUnselectAllItemsAccumulatorClick={this.onUnselectAllItemsAccumulatorClick}
          isSelectAllItemsAccumulatorChecked={isSelectAllItemsAccumulatorChecked}
          //items sort
          sortItemsDirection={sortItemsDirection}
          sortItemsColumnsName={sortItemsColumnsName}
          onSortItemsClick={this.onSortItemsClick}
          //items accumulator sort
          sortItemsAccumulatorDirection={sortItemsAccumulatorDirection}
          sortItemsAccumulatorColumnsName={sortItemsAccumulatorColumnsName}
          onSortItemsAccumulatorClick={this.onSortItemsAccumulatorClick}
          itemsAddBuffer={itemsAddBuffer}
          itemsRemoveBuffer={itemsRemoveBuffer}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Button text={'Save'} onClick={this.onClickSave} customClass={'btn-lg mt-3'} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
