import * as React from 'react';
import { useState } from 'react';
import * as propz from 'propz';
import { Integration } from '../../models/Integration';
import { getFileName, getIntegrationCalendarPublicSiteLink } from '../link/link';
import { INTEGRATION_TYPE_SERVER_TO_CLIENT_MAPPING } from '../../consts/integration';
import { SchoolEvent } from '../../models/event';
import { Link } from 'react-router-dom';
import { CLASHES, CLASHES_STATUS_COLOR } from '../../consts/player';
import { SchoolFile } from '../../models/schoolFile';
import { DeleteIcon } from 'Src/components/SvgIcons/DeleteIcon';
import { AddIcon } from 'Src/components/SvgIcons/AddIcon';
import { EditIcon } from 'Src/components/SvgIcons/EditIcon';
import { CloseIcon } from 'Src/components/SvgIcons/CloseIcon';
import { DetailsIcon } from 'Src/components/SvgIcons/DetailsIcon';
import { TeamsIcon } from 'Src/components/SvgIcons/TeamsIcon';
import { EventsIcon } from 'Src/components/SvgIcons/EventsIcon';
import { CELL_ACTION_ICON } from 'Src/consts/cell';
import { PLAYER_GENDER_SERVER_TO_CLIENT_MAPPING } from 'Src/consts/common';
import { getSchoolEventAges } from 'Src/helpers/event/event';
import { getAgesGroupFormatted } from 'Src/helpers/accessor/accessor';
import { getFullName } from 'Src/helpers/autocomplete/autocomplete';
import { EventParticipants } from 'Src/views/GenericView/AdminSUView/HighLevelTournaments/HighLevelTournamentEvents/EventParticipants/EventParticipants';

export function getColor(house) {
  const result =
    house.colors.length !== 0
      ? house.colors.map((color, clrKey) => {
          return <div key={`color_${clrKey}`} className="eDataList_listItemColor" style={{ background: color }} />;
        })
      : null;

  return <td key={house.id}>{result}</td>;
}

export function getTournamentTagColor(tournamentTag) {
  return <div className="color-thumbnail" style={{ background: tournamentTag.tagColor }}></div>;
}

export function getColorClashes(student, onCellClick) {
  let color;
  const clashesStatus = propz.get(student, ['clashes', 'status'], '');

  const isNoClash = clashesStatus === CLASHES.NO_CLASH;
  const isIntervalClash = clashesStatus === CLASHES.INTERVAL_CLASH;
  const isFullClash = clashesStatus === CLASHES.FULL_CLASH;

  switch (true) {
    case isNoClash:
      color = CLASHES_STATUS_COLOR.NO_CLASH;
      break;
    case isIntervalClash:
      color = CLASHES_STATUS_COLOR.INTERVAL_CLASH;
      break;
    case isFullClash:
      color = CLASHES_STATUS_COLOR.FULL_CLASH;
      break;
    default:
      color = CLASHES_STATUS_COLOR.DEFAULT;
  }

  const onClick = event => {
    event.stopPropagation();
    onCellClick(student);
  };

  return isNoClash ? (
    <span key={`color_${color}`} className="eDataList_listItemColor" style={{ background: color }} />
  ) : (
    <span onClick={onClick} key={`color_${color}`} className="eDataList_listItemColor" style={{ background: color }} />
  );
}

export function getIntegrationName(integration: Integration) {
  if (integration.type === INTEGRATION_TYPE_SERVER_TO_CLIENT_MAPPING.iCalendar) {
    const integrationUrl = getIntegrationCalendarPublicSiteLink(integration);
    return (
      <td key={integration.id}>
        <a href={integrationUrl} target="_blank">
          {integrationUrl}{' '}
        </a>
      </td>
    );
  } else {
    const name = integration.name;
    return typeof name !== 'undefined' ? <td key={integration.id}>{name}</td> : <td />;
  }
}

export function getFileNameWithLink(schoolFile: SchoolFile) {
  const fileName = getFileName(schoolFile);
  const url = schoolFile.url;
  return (
    <td key={schoolFile.id}>
      <a href={url} target="_blank">
        {fileName}
      </a>
    </td>
  );
}

export function getEventNameWithLink(event: SchoolEvent, options) {
  const { user } = options;
  const { activeSchoolId } = user;
  const eventName = propz.get(event, ['generatedNames', activeSchoolId], '');

  return (
    <td key={event.id}>
      <Link to={`/events/event?id=${event.id}`}>{eventName}</Link>
    </td>
  );
}

export function getTournamentEventNameWithLink(event: SchoolEvent, options) {
  const { user } = options;
  const { activeSchoolId } = user;
  const eventName = propz.get(event, ['generatedNames', activeSchoolId], '');

  return (
    <td key={event.id}>
      <Link to={`/tournaments/events/event?id=${event.id}`}>{eventName}</Link>
    </td>
  );
}

export function getTeamTournamentEventNameWithLink(event: SchoolEvent, options) {
  const { user } = options;
  if (event.invitedSchools && event.invitedSchools.length == 2) {
    const school1 = event.invitedSchools[0];
    const school2 = event.invitedSchools[1];
    return (
      <td key={event.id}>
        <Link to={`/tournaments/events/event?id=${event.id}`}>
          {school1.name} v {school2.name}
        </Link>
      </td>
    );
  }
  return <span></span>;
}

export function getBody({ id, body }) {
  return (
    <td key={id}>
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </td>
  );
}

//the options parameter is not used in the function, but is needed according to the CustomCellColumnDefinition type
export function getTeamPlayerEventsIcon(player, options, onIconClick) {
  const { user } = options;
  const { events } = player;

  const isEventsExist = events.length > 0;

  switch (true) {
    case isEventsExist:
      return (
        <td className="mPreWrap">
          {events.map(event => {
            const { gender } = event;
            const name = propz.get(event, ['generatedNames', 'official'], '');
            const data = {
              currentPlayer: player,
              currentEvent: event
            };

            const ages = getAgesGroupFormatted(event, { user });
            const eventName = `${name} ${ages} ${PLAYER_GENDER_SERVER_TO_CLIENT_MAPPING[gender]}`;

            return (
              <div className="d-flex align-items-center justify-content-between p-2 mb-1 rounded bg-light">
                <span className="mr-2">{eventName}</span>
                <DeleteIcon onClick={() => onIconClick(data, CELL_ACTION_ICON.REMOVE_FROM_EVENT)} />
              </div>
            );
          })}
        </td>
      );

    default:
      return <td className="mPreWrap"></td>;
  }
}

//the options parameter is not used in the function, but is needed according to the CustomCellColumnDefinition type
export function getEventParticipantsIcon(event, options, onIconClick) {
  const { user } = options;

  return <EventParticipants user={user} event={event} onClick={onIconClick} />;
}

//the options parameter is not used in the function, but is needed according to the CustomCellColumnDefinition type
export function getTeamPlayerEventsActionIcon(player, options, onIconClick) {
  const data = {
    currentPlayer: player
  };

  return (
    <td className="mPreWrap text-center">
      <AddIcon onClick={() => onIconClick(data, CELL_ACTION_ICON.ADD_TO_EVENT)} />
    </td>
  );
}

//the options parameter is not used in the function, but is needed according to the CustomCellColumnDefinition type
export function getTournamentEventsActionIcon(event, options, onIconClick) {
  const data = {
    currentEvent: event
  };

  return (
    <td className="mPreWrap text-center">
      <AddIcon onClick={() => onIconClick(data, CELL_ACTION_ICON.ADD_TO_EVENT)} />
    </td>
  );
}

//the options parameter is not used in the function, but is needed according to the CustomCellColumnDefinition type
export function getRemovePlayerFromTeamIcon(player, options, onIconClick) {
  const data = {
    currentPlayer: player
  };

  return (
    <td className="mPreWrap text-right">
      <CloseIcon onClick={() => onIconClick(data, CELL_ACTION_ICON.REMOVE_FROM_TEAM)} />
    </td>
  );
}

//the options parameter is not used in the function, but is needed according to the CustomCellColumnDefinition type
export function getLeaveTournamentIcon(tournament, options, onIconClick) {
  const data = {
    tournamentUsingActionIcon: tournament
  };

  return (
    <td className="mPreWrap text-right">
      <CloseIcon onClick={() => onIconClick(data, CELL_ACTION_ICON.LEAVE_TOURNAMENT)} />
    </td>
  );
}

//the options parameter is not used in the function, but is needed according to the CustomCellColumnDefinition type
export function getTournamentActionsIcons(tournament, options, onIconClick) {
  const data = {
    tournamentUsingActionIcon: tournament
  };

  return (
    <td className="mPreWrap text-left">
      <div className="bTournamentsUpcomingActions">
        <div
          className="eTournamentsUpcomingActionsItem"
          onClick={() => onIconClick(data, CELL_ACTION_ICON.TOURNAMENT_DETAILS)}
        >
          <DetailsIcon />
          <span>Details</span>
        </div>
        <div
          className="eTournamentsUpcomingActionsItem"
          onClick={() => onIconClick(data, CELL_ACTION_ICON.TOURNAMENT_TEAMS)}
        >
          <TeamsIcon />
          <span>Teams</span>
        </div>
        <div
          className="eTournamentsUpcomingActionsItem"
          onClick={() => onIconClick(data, CELL_ACTION_ICON.TOURNAMENT_EVENTS)}
        >
          <EventsIcon />
          <span>Events</span>
        </div>
      </div>
    </td>
  );
}

//the options parameter is not used in the function, but is needed according to the CustomCellColumnDefinition type
export function getDetailsActionIcon(tournament, options, onIconClick) {
  const data = {
    tournamentUsingActionIcon: tournament
  };

  return (
    <td className="mPreWrap text-left">
      <div className="bTournamentsUpcomingActions">
        <div
          className="eTournamentsUpcomingActionsItem"
          onClick={() => onIconClick(data, CELL_ACTION_ICON.TOURNAMENT_DETAILS)}
        >
          <DetailsIcon />
          <span>Details</span>
        </div>
      </div>
    </td>
  );
}

export function getEditPlayerIcon(player, options, onIconClick) {
  const data = {
    currentPlayer: player
  };

  return (
    <td className="mPreWrap text-right">
      <EditIcon onClick={() => onIconClick(data, CELL_ACTION_ICON.EDIT)} />
    </td>
  );
}
