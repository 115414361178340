import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

interface Props {
  onSubmit: ({ emailCode: string }) => void;
  isConfirmed: boolean;
  email: string;
  onSendCodeAgainClick: (event) => void;
  onChangeClick: (event) => void;
}
interface State {}

export class EmailVerificationForm extends React.Component<Props, State> {
  render() {
    const { onSubmit, isConfirmed, email, onSendCodeAgainClick, onChangeClick } = this.props;
    const initialValues = {
      emailCode: ''
    };

    const EmailVerificationFormSchema = Yup.object().shape({
      emailCode: Yup.string().required(
        'Please enter your email verification code. Get in touch with our support team at support@squadintouch.com if you have not received the code.'
      )
    });

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={EmailVerificationFormSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        render={({ touched, errors }) => (
          <Form className={'text-left'}>
            <label>Email verification code</label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <Field disabled={isConfirmed} name="emailCode" className={'form-control'} />
                <div className={'mb-3'}>We have sent the verification code to {email}</div>
                {touched.emailCode && errors.emailCode && <div className="alert alert-danger">{errors.emailCode}</div>}
                {isConfirmed === false && (
                  <div className="alert alert-danger">Invalid code. Please check and try again.</div>
                )}
                {isConfirmed && (
                  <div className="font-weight-bold text-success mb-3">Your code has been successfully verified!</div>
                )}
                <div>
                  <a href={''} onClick={onSendCodeAgainClick}>
                    Send code again.
                  </a>
                </div>
                <div>
                  <a href={''} onClick={onChangeClick}>
                    Wrong email?
                  </a>
                </div>
              </div>
              <div className="form-group col-md-6">
                <button disabled={isConfirmed} type="submit" className="btn btn-primary">
                  Verify
                </button>
              </div>
            </div>
          </Form>
        )}
      />
    );
  }
}
