import * as React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';

interface Props {
  onSubmit: ({ phoneCode: string }) => void;
  isConfirmed: boolean;
  phone: string;
  onSendCodeAgainClick: (event) => void;
  onChangeClick: (event) => void;
}
interface State {}

export class PhoneVerificationForm extends React.Component<Props, State> {
  render() {
    const { onSubmit, isConfirmed, phone, onSendCodeAgainClick, onChangeClick } = this.props;
    const initialValues = {
      phoneCode: ''
    };

    const PhoneVerificationFormSchema = Yup.object().shape({
      phoneCode: Yup.string().required(
        'Please enter your phone verification code. Get in touch with our support team at support@squadintouch.com if you have not received the code.'
      )
    });

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={PhoneVerificationFormSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        render={({ touched, errors }) => (
          <Form className={'text-left'}>
            <label>Phone verification code</label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <Field disabled={isConfirmed} name="phoneCode" className={'form-control'} />
                <div className={'mb-3'}>We have sent the verification code to {phone}</div>
                {touched.phoneCode && errors.phoneCode && <div className="alert alert-danger">{errors.phoneCode}</div>}
                {isConfirmed === false && (
                  <div className="alert alert-danger">Invalid code. Please check and try again.</div>
                )}
                {isConfirmed && (
                  <div className="font-weight-bold text-success mb-3">Your code has been successfully verified!</div>
                )}
                <div>
                  <a href={''} onClick={onSendCodeAgainClick}>
                    Send code again.
                  </a>
                </div>
                <div>
                  <a href={''} onClick={onChangeClick}>
                    Wrong phone?
                  </a>
                </div>
              </div>
              <div className="form-group col-md-6">
                <button disabled={isConfirmed} type="submit" className="btn btn-primary">
                  Verify
                </button>
              </div>
            </div>
          </Form>
        )}
      />
    );
  }
}
